import React, { useReducer } from 'react';

import RepeatItemOrmDetailsPopup from '../components/popups/RepeatItemOrmDetailsPopup';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withRepeatItemOrmDetailsPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          {...props}
          updateRepeatItemOrmDetailsPopupHOCState={updateLocalState}
        />
        {localState.open && (
          <RepeatItemOrmDetailsPopup
            open={localState.open}
            data={localState.data}
            onClose={() => updateLocalState({ open: false })}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withRepeatItemOrmDetailsPopupHOC;
