import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import { DataGrid } from '@mui/x-data-grid';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { generatePoAutomationEmailsTrackingApi } from '../apis/generatePoAutomationEmailsTrackingApi';
import { useDataGridFilterHook } from '../hooks/useDataGridFilterHook';

import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, { DataGridToolbarLayout } from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import { FlexRow } from '../components/layouts/FlexLayouts';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import { getUserRoleSelector } from '../selectors/userSelector';

import { exportCSV } from '../utils/csvExportUtils';
import { dateTzSingapore } from '../utils/dateTimeUtils';
import { isSuperAdminRole } from '../utils/roleUtils';

import { EMAIL_BREVO_TYPES } from '../constants/emailConstants';

import { colors } from '../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      whiteSpace: 'normal',
      justifyContent: 'center !important',
    },
  },
}));

function GeneratePoAutomationTrackingTab() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const { data: tableData, isLoading } = useQuery(
    'poAutoTrackingItems',
    generatePoAutomationEmailsTrackingApi
  );
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
    }
  );

  const [
    filteredData,
    { setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: tableData,
  });

  const defaultColumns = [
    {
      headerName: 'Sent Date',
      field: 'sentDate',
      renderCell: ({ row: rowData }) => {
        const dateTimeStr = dateTzSingapore(
          rowData.createdAt,
          'DD MMM YYYY HH:mm'
        );
        const splitStr = dateTimeStr.split(' ');
        const date = splitStr.slice(0, 3).join(' ');
        const time = splitStr[3];
        return (
          <Tooltip title='Date and time displayed in SGT'>
            <div style={{ lineHeight: 'normal' }}>
              {date}
              <br />
              {time}
            </div>
          </Tooltip>
        );
      },
      width: 150,
      valueGetter: ({ row: rowData }) =>
        dateTzSingapore(rowData?.createdAt, 'DD MMM YYYY HH:mm'),
    },
    {
      headerName: 'Email status',
      field: 'emailStatus',
      renderCell: ({ row: rowData }) => {
        if (rowData.status === 'Failed') {
          return (
            <EmailStatusIcon
              status="failed"
              emailType={rowData?.type}
              date={rowData?.createdAt}
            />
          )
        }
        if (!rowData.brevoInfo) {
          return null;
        }
        return (
          <FlexRow>
            <EmailStatusIcon
              status={rowData.brevoInfo?.lastStatus}
              emailType={rowData.brevoInfo?.type}
              date={rowData.brevoInfo?.ebtUpdatedAt}
            />
            {rowData.brevoInfo?.reminderEmailStatus && (
              <EmailStatusIcon
                status={rowData.brevoInfo?.reminderEmailStatus}
                emailType={EMAIL_BREVO_TYPES.REMINDER_ORDERS_ACCEPTANCE}
                date={rowData.brevoInfo?.reminderEmailDate}
              />
            )}
          </FlexRow>
        )
      },
    },
    {
      headerName: 'Type',
      field: 'type',
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
    },
    {
      headerName: 'Part ID',
      field: 'itemID',
    },
    {
      headerName: 'Partner Name',
      field: 'partnerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.partnerName} />
      ),
      width: 150,
    },
    {
      headerName: 'Partner Email',
      field: 'partnerEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.partnerEmail} />
      ),
      width: 150,
    },
    {
      headerName: 'PO Status',
      field: 'status',
    },
    {
      headerName: 'Admin Sent by',
      field: 'adminName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.adminName} />
      ),
      width: 150,
    },
  ];

  const [columns] = useState(defaultColumns);

  useEffect(() => {
    const _columns = defaultColumns.filter((col) => !!col);
    setColumnsDef(_columns);
  }, []);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length || 0 });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleExportCSV = () => {
    const fileName = `All Generate PO Emails Tracking Items`;
    exportCSV(columns, filteredData, fileName);
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleExportCSV}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.container}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={120}
        headerHeight={80}
        components={{
          Toolbar: getToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={isLoading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
    </div>
  );
}

export default GeneratePoAutomationTrackingTab;
