// Import settings
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Import color palette
import { colors } from '../../palette';

// Import material UI components
import {
  CircularProgress,
  IconButton,
  Link,
  Button as MuiButton
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

// Style components
const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 520,
    padding: '0.5rem 1rem',
    textTransform: 'none',
    borderRadius: '0.5rem',
  },
  iconRoot: {
    borderRadius: 10,
    padding: '0.5rem',
  },
  blackButton: {
    color: colors.fontWhite,
    background: colors.neutral080,
    '&:hover': {
      background: colors.neutral090,
    },
    '&:disabled': {
      background: colors.neutral040,
    },
  },
  redButton: {
    color: colors.fontWhite,
    background: colors.red030,
    '&:hover': {
      background: colors.red050,
    },
    '&:disabled': {
      background: colors.red010,
    },
  },
  blueButton: {
    background: colors.blue060,
    color: colors.fontWhite,
    '&:disabled': {
      color: colors.neutral020,
      background: `${colors.blue060}96`,
    },
    '&:hover': {
      background: colors.blue070,
    },
    '&:active': {
      background: colors.blue080,
    },
  },
  outlinedBlue: {
    background: colors.fontWhite,
    color: colors.blue050,
    border: `1.2px solid ${colors.blue050}`,
    '&:hover': {
      background: colors.blue010,
    },
    '&:active': {
      background: colors.blue020,
    },
    '&:disabled': {
      color: colors.neutral040,
      background: colors.fontWhite,
      border: `1.2px solid ${colors.neutral040}`,
    },
  },
  outlinedBlack: {
    background: colors.fontWhite,
    color: colors.neutral070,
    border: `1.2px solid ${colors.neutral070}`,
    '&:hover': {
      background: colors.neutral020,
    },
    '&:active': {
      background: colors.neutral030,
    },
    '&:disabled': {
      color: colors.neutral040,
      background: colors.fontWhite,
      border: `1.2px solid ${colors.neutral040}`,
    },
  },
  outlinedRed: {
    background: colors.fontWhite,
    color: colors.red030,
    border: `1.2px solid ${colors.red030}`,
    '&:hover': {
      background: colors.red010,
    },
    '&:active': {
      background: colors.red020,
    },
    '&:disabled': {
      color: colors.neutral040,
      background: colors.fontWhite,
      border: `1.2px solid ${colors.neutral040}`,
    },
  },
  text: {
    background: 'transparent',
    color: colors.blue060,
    '&:hover': {
      color: colors.blue070,
      background: 'transparent',
    },
    '&:active': {
      color: colors.blue070,
      background: 'transparent',
    },
    '&:disabled': {
      color: colors.neutral050,
      background: 'transparent',
    },
  },
  textBlack: {
    color: colors.neutral070,
    fontWeight: 600,
  },
  circularProgress: {
    color: 'white',
    marginRight: '0.5rem',
    padding: 0,
  },
  outlinedCircularProgress: {
    color: colors.blue050,
    marginRight: '0.5rem',
    padding: 0,
  }
}));

/**
 * Customised MUI Button component
 *
 * @param {object} props - The properties of the Button component.
 * @param {React.ReactNode} props.children - Content to be displayed inside the button.
 * @param {'black' | 'blue'} props.color - Specifies the color of the button.
 * @param {boolean} props.disabled - Disables the button if set to `true`.
 * @param {String} props.id - A unique identifier for the button.
 * @param {boolean} props.fullWidth
 * @param {'small' | 'medium' | 'large'} props.size - Specifies the size of the button.
 * @param {React.ReactNode} props.startIcon - Icon to be displayed at the start of the button.
 * @param {React.ReactNode} props.startIcon - Icon to be displayed at the end of the button.
 * @param {string | null} props.to - URL to navigate to when the button is clicked. If provided, the button will act as a link.
 * @param {Function} props.onClick - Callback function to be executed when the button is clicked.
 * @param {'text' | 'outlined' | 'contained' | 'text-black'} props.variant - Specifies the style variant of the button.
 * @param {Boolean} props.loading - To show loading icon if true.
 * @param {React.CSSProperties} props.style - To custom css
 * @param {String} props.className - To custom className
 * @returns {React.ReactNode} The rendered Button component.
 */
function FtrButton(props) {
  const classes = useStyles();

  const {
    children,
    color = 'black', // 'black' | 'blue' | 'red'
    disabled = false,
    id,
    fullWidth = false,
    size = 'medium', // 'small' | 'medium' | 'large'
    startIcon = null,
    endIcon = null,
    to = null,
    onClick,
    variant = 'contained', // 'text' | 'outlined' | 'contained'
    loading = false,
    style,
    customClass = null,
    ...otherProps
  } = props;

  const colorClass = clsx({
    [classes.blackButton]: color === 'black',
    [classes.blueButton]: color === 'blue',
    [classes.redButton]: color === 'red',
  });

  const variantClass = clsx(classes.root, {
    [classes.outlinedBlue]: variant === 'outlined' && color === 'blue',
    [classes.outlinedBlack]: variant === 'outlined' && color === 'black',
    [classes.outlinedRed]: variant === 'outlined' && color === 'red',
    [classes.textBlue]: variant === 'text' && color === 'blue',
    [classes.text]: variant === 'text' || variant === 'text-black',
    [classes.textBlack]: variant === 'text-black',
  });

  const variantCircularClass = clsx(classes.root, {
    [classes.outlinedCircularProgress]: variant === 'outlined',
    [classes.circularProgress]: variant !== 'outlined',
  });

  return (
    <MuiButton
      className={`${colorClass} ${variantClass} ${customClass}`}
      id={id}
      data-cy={id}
      disabled={disabled || loading}
      disableRipple
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      to={to}
      variant={variant}
      style={style}
      {...otherProps}
    >
      {loading === true && <CircularProgress className={variantCircularClass} size={20} />}
      {children}
    </MuiButton>
  );
}

FtrButton.propTypes = {
  /**
   * Content to be displayed inside the button
   */
  children: PropTypes.node,
  /**
   * Specifies the color of the button.
   */
  color: PropTypes.oneOf(['black', 'blue']),
  /**
   * Disables the button if set to `true`
   */
  disabled: PropTypes.bool,
  /**
   * Specifies the size of the button
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * A unique identifier for the button
   */
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  /**
   * Icon to be displayed at the start of the button
   */
  startIcon: PropTypes.node,
  /**
   * URL to navigate to when the button is clicked. If provided, the button will act as a link
   */
  to: PropTypes.string,
  /**
   * Callback function to be executed when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * Specifies the style variant of the button.
   */
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

FtrButton.defaultProps = {
  color: 'black',
  size: 'medium',
  variant: 'contained',
  disabled: false,
};

export default FtrButton;

export function FtrSmallButton(props) {
  const {
    children,
    startIcon = null,
    color = 'blue',
    style,
    ...rest
  } = props;

  return (
    <FtrButton
      color={color}
      size='small'
      style={{
        height: 35,
        borderRadius: 10,
        paddingLeft: 12,
        paddingRight: 12,
        ...style,
      }}
      startIcon={startIcon}
      {...rest}
    >
      {children}
    </FtrButton>
  )
}

export function FtrUploadFileSmallButton(props) {
  const {
    children,
    accept = '*',
    handleFiles = () => { },
    ...rest
  } = props;

  const hiddenFileInput = React.useRef(null);

  return (
    <>
      <input
        id={`file-upload`}
        ref={hiddenFileInput}
        type='file'
        accept={accept}
        multiple
        onChange={(evt) => handleFiles(evt.target.files)}
        onClick={(event) => event.target.value = null}
        style={{
          display: 'none',
        }}
      />
      <FtrSmallButton
        onClick={() => hiddenFileInput.current.click()}
        {...rest}
      >
        {children}
      </FtrSmallButton>
    </>
  );
}

export function FtrIconButton(props) {
  const classes = useStyles();

  const {
    children,
    id = 'ftr-icon-button',
    color = 'blue', // 'black' | 'blue' | 'red'
    variant = 'contained', // 'text' | 'outlined' | 'contained'
    size = 'medium', // 'small' | 'medium' | 'large'
    disabled = false,
    onClick = () => { },
    loading = false,
    style,
    ...rest
  } = props;

  const colorClass = clsx({
    [classes.blackButton]: color === 'black',
    [classes.blueButton]: color === 'blue',
    [classes.redButton]: color === 'red',
  });

  const variantClass = clsx(classes.root, {
    [classes.outlinedBlue]: variant === 'outlined' && color === 'blue',
    [classes.outlinedBlack]: variant === 'outlined' && color === 'black',
    [classes.outlinedRed]: variant === 'outlined' && color === 'red',
    [classes.textBlue]: variant === 'text' && color === 'blue',
    [classes.text]: variant === 'text' || variant === 'text-black',
    [classes.textBlack]: variant === 'text-black',
  });

  const renderLoadingIcon = () => {
    return (
      <CircularProgress
        size={20}
        style={{
          color: 'white',
          padding: 0,
        }}
      />
    );
  }

  return (
    <IconButton
      id={id}
      className={`${classes.iconRoot} ${colorClass} ${variantClass}`}
      onClick={onClick}
      color='primary'
      size={size}
      style={{
        ...style,
      }}
      {...rest}
      disabled={disabled}
    >
      {loading === true && renderLoadingIcon()}
      {!loading && children}
    </IconButton>
  )
}

export function FtrLinkButton(props) {
  const {
    children,
    onClick = () => { },
  } = props;

  return (
    <Link
      style={{
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: 14,
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
