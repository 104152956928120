import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

import {
  AttachFile as AttachFileIcon,
} from '@material-ui/icons';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { downloadS3File, isPdfFile } from '../../utils/fileUtils';
import { getFileNameFromUrl } from '../../utils/fileUtils';
import { openInNewTab } from '../../utils/navigationUtils';
import {
  isFileNeedWaterMark,
  downloadCadFileWithWatermark,
} from '../../utils/supplierCadDownloadUtils';

import { generatePresignedUrl } from '../../services/s3Service';

import { ACTION_TYPE } from '../../constants/actionTrackingConstants';


// ---------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  itemPropertyKey: {
    fontWeight: 'bold',
    margin: 0,
  },
  itemFile: {
    display: 'flex',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },
}));

const Link = withActionTrackingHOC(ReactRouterLink);


function FileDownloadListDisplay(props) {
  const classes = useStyles();

  const {
    title,
    urlList = [],
    showTitle = true,
    trackingInfo = {},
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [{ isIPad }] = useDeviceCheck();

  return (
    <div>
      {showTitle && (
        <Typography
          gutterBottom
          variant='body1'
          className={classes.itemPropertyKey}
        >
          {title}
        </Typography>
      )}
      <div>
        {urlList?.map((link) => {
          const isPdf = isPdfFile(link);
          const filenameFromUrl = link.split('/').reverse()[0];
          return (
            <div
              key={link}
              className={classes.itemFile}
            >
              <div>
                <AttachFileIcon color='action' fontSize='small' />
              </div>
              <div>
                <Link
                  actionTrackingKey={ACTION_TYPE.DOWNLOAD_FILE}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isPdf && !isMobile && !isTablet && !isIPad) {
                      generatePresignedUrl(link, 300, true) // 5 minutes expiration
                        .then(openInNewTab);
                    } else if (isFileNeedWaterMark(link)) {
                      downloadCadFileWithWatermark(link);
                    } else {
                      downloadS3File(link);
                    }
                  }}
                  additionalInfo={{
                    urls: [link],
                  }}
                  trackingInfo={trackingInfo}
                >
                  {getFileNameFromUrl(filenameFromUrl)}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FileDownloadListDisplay;
