import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import { FtrTextField } from '../ftr-components/FtrField';
import Title from '../Title';
import { FtrButton } from '../ftr-components';
import { FlexRowCenter } from '../layouts/FlexLayouts';
import { isEmptyValue } from '../../utils/commonUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/**
 * A popup to edit a single line of text, with a save button.
 *
 * @param {Object} props
 * @prop {Function} onClose - Called when the user closes the popup.
 * @prop {String} [id='note-title'] - The id of the title element.
 * @prop {String} title - The title of the popup.
 * @prop {Boolean} [open=false] - Whether the popup is open.
 * @prop {String} [label='field'] - The label of the text field.
 * @prop {String} [value=''] - The initial value of the text field.
 * @prop {Function} [updateHandler=() => null] - Called when the user saves the text.
 * @prop {Boolean} [autoFocus=true] - Whether the text field should be focused on open.
 * @prop {Boolean} [multiline=true] - Whether the text field should be a multi-line text field.
 * @prop {Object} [inputProps={}] - Additional props to pass to the text field.
 * @prop {String} [submitText='Update'] - The text of the save button.
 * @prop {Boolean} [loading=false] - Whether the save button should be in a loading state.
 */
function SingleFieldPopup(props) {
  const {
    onClose,
    id = 'single-field-popup',
    title = 'Title',
    open = false,
    label = 'field',
    defaultValue = '',
    onSubmit = () => null,
    textFieldProps = {},
    submitText = 'Update',
    loading = false,
    buttonSubmitProps = {},
  } = props;

  const classes = useStyles();

  const [field, setField] = useState(defaultValue);

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id={id}>
        <div style={{ padding: '0 1rem' }}>
          <Title contentTitle={title} size='small' />
        </div>
      </DialogTitle>
      <DialogContent>
        <FtrFieldLabel>{label}</FtrFieldLabel>
        <FtrTextField
          value={field}
          onChange={(e) => setField(e.target.value)}
          multiline
          autoFocus
          {...textFieldProps}
        />
      </DialogContent>
      <FlexRowCenter
        style={{
          padding: '0.5rem 0 1rem 0',
        }}
      >
        <FtrButton
          color='blue'
          onClick={() => onSubmit(field)}
          disabled={isEmptyValue(field)}
          loading={loading}
          {...buttonSubmitProps}
        >
          {submitText}
        </FtrButton>
      </FlexRowCenter>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default SingleFieldPopup;
