import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';

/**
 *
 * @param {Object} props
 * @param {{label: String}[]} props.children
 * @param {{label: String}} props.parent
 * @param {(Boolean) => void} props.isAllChecked
 */
export default function CheckboxWithChild(props) {
  const {
    children = [{ label: 'Label 1' }, { label: 'Label 2' }],
    parent = { label: 'Parent' },
    isAllChecked = () => null,
  } = props;
  const [checked, setChecked] = useState({});

  useEffect(() => {
    const copyChildren = [...children];
    let defaultChecked = {};
    for (const item of copyChildren) {
      defaultChecked[item.label] = false;
    }
    setChecked(defaultChecked);
  }, [children]);

  const isAllCheckedValue = Object.values(checked).every(
    (item) => item === true
  );

  useEffect(() => {
    isAllChecked(isAllCheckedValue);
  }, [isAllCheckedValue]);

  const handleChangeChild = (event) => {
    const { checked, name } = event.target;
    setChecked((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleChangeParent = (event) => {
    const newChecked = { ...checked };
    for (const label in newChecked) {
      newChecked[label] = event.target.checked;
    }
    setChecked(newChecked);
  };

  const renderChildren = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {children.map((child, index) => {
        return (
          <div
            style={{
              display: 'flex',
              fontSize: '1rem',
              columnGap: '0.5rem',
              alignItems: 'center',
            }}
            key={index}
          >
            <input
              style={{ width: '1rem', height: '1rem' }}
              type='checkbox'
              checked={checked[child.label]}
              onChange={handleChangeChild}
              name={child.label}
              id={`checkbox-with-child-${child.label}`}
            />
            <label htmlFor={`checkbox-with-child-${child.label}`}>
              {child.label}
            </label>
          </div>
        );
      })}
    </Box>
  );

  return (
    <div>
      <FormControlLabel
        label={parent.label}
        control={
          <Checkbox
            checked={isAllCheckedValue}
            indeterminate={
              Object.values(checked).some((item) => item === false) &&
              Object.values(checked).some((item) => item === true)
            }
            onChange={handleChangeParent}
          />
        }
      />
      {renderChildren()}
    </div>
  );
}
