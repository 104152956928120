import React, { useReducer } from 'react';

import RequestMoreQuotesFeatureTour from '../components/tour/RequestMoreQuotesFeatureTour';
import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withRequestMoreQuotesFeatureTourHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      start: false,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          {...props}
          updateTourState={updateLocalState}
        />
        <RequestMoreQuotesFeatureTour
          startTour={localState.start}
        />
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withRequestMoreQuotesFeatureTourHOC;
