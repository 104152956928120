import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";
import {
  AssignmentReturn as UploadIcon,
  Settings as AttachFileIcon,
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
  Visibility as PreviewIcon,
  VisibilityOff as PreviewHiddenIcon
} from "@material-ui/icons";

import { notifySuccess, notifyError } from "../../services/notificationService";

import RequestedCard from "../cards/RequestedCard";
import { FlexColumn, FlexRow } from "../layouts/FlexLayouts";

import { convertToDigits } from "../../utils/stringUtils";
import { getFileNameFromCadFile } from "../../utils/itemUtils";
import { getPresignedUrl } from "../../utils/presignedUrlUtils";
import { isEmptyValue } from "../../utils/commonUtils";
import { isPdfFile, downloadS3File, getFileExtension } from "../../utils/fileUtils";
import { isSuperAdminRole } from "../../utils/roleUtils";

import { colors } from "../../palette";


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  previewPdfListItem: {
    cursor: 'pointer'
  },
  previewPdf: {
    backgroundColor: colors.successGreen
  }
}));

const createStepFileFromUrl = async (url, fileName) => {
  const presignedPdfUrl = await getPresignedUrl(url)
  // Fetch the file data from the URL
  const response = await fetch(presignedPdfUrl);

  // Check if the fetch was successful
  if (!response.ok) {
    throw new Error(`Failed to fetch the file: ${response.statusText}`);
  }

  // Convert the response data to a Blob
  const blob = await response.blob();

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: blob.type });

  return file;
}

const UploadedListItem = (props) => {
  const {
    index,
    link,
    fileList,
    previewUrl,
    item,
    setPreviewUrl,
    role,
    setEditFiles,
    type,
    handleStepFileUpload = () => { },
    revisions = 1
  } = props;
  const classes = useStyles();

  const isCustomerUploadedList = type === 'customer-uploaded'
  const encodedFileName = link.split("/").pop();
  const fileName = getFileNameFromCadFile(encodedFileName);
  const itemInfo = isCustomerUploadedList ? item.customerCadFileUploadInfo : item.factoremCadFileUploadInfo;
  const uploadInfo = itemInfo.find(
    (info) => info.s3ObjectUrl === link
  );
  const previewedFile = link === previewUrl

  return (
    <ListItem
      alignItems='flex-start'
      disableGutters={true}
    >
      {/* FILE ICON */}
      <ListItemAvatar
        className={isPdfFile(link) && classes.previewPdfListItem}
        onClick={() => {
          if (isPdfFile(link)) {
            setPreviewUrl(link)
          }
        }}
      >
        <Avatar className={previewedFile && classes.previewPdf}>
          {
            isPdfFile(link) ?
              previewedFile ? (<PreviewIcon />) : (<PreviewHiddenIcon />)
              : (<AttachFileIcon />)
          }
        </Avatar>
      </ListItemAvatar>
      {/* FILE LINKS LIST */}
      <FlexColumn style={{ gap: 0 }}>
        <FlexColumn style={{ gap: 0 }}>
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(link);
            }}
          >
            {
              fileName.length < 20 ?
                fileName :
                fileName.slice(0, 20) + "...." + getFileExtension(fileName)
            }
          </Link>

          {
            !isEmptyValue(uploadInfo) && (
              <RequestedCard
                requestedAt={uploadInfo.uploadedAt}
                requestedByName={uploadInfo.uploadedBy}
                label={"Uploaded at"}
              />
            )
          }
        </FlexColumn>
        {/* ACTION ICONS */}
        <FlexRow style={{ gap: 0 }}>
          {/* submit step file to factorem uploaded list */}
          {
            (!isPdfFile(link) && isCustomerUploadedList) && (
              <IconButton
                edge='start'
                onClick={async () => {
                  try {
                    const extension = getFileExtension(link)
                    const newFileName = `${item.itemID}${convertToDigits(revisions, 2)}.${extension}`
                    const existingStepFile = await createStepFileFromUrl(link, newFileName);
                    handleStepFileUpload([existingStepFile])
                  } catch (error) {
                    console.error("Error re-uploading step file:", error);
                    notifyError('Renaming part file failed!')
                  }
                }}
              >
                <Tooltip
                  title='Rename and upload the file'
                  arrow
                >
                  <UploadIcon />
                </Tooltip>
              </IconButton>
            )
          }
          {/* copy file */}
          <IconButton
            edge='start'
            onClick={() => {
              navigator.clipboard.writeText(link)
              notifySuccess('File link is copied!')
            }}
          >
            <Tooltip
              title='Copy file link'
              arrow
            >
              <FileCopyIcon />
            </Tooltip>
          </IconButton>
          {/* delete file */}
          {isSuperAdminRole(role) && (
            <IconButton
              edge='start'
              aria-label='delete'
              onClick={() => {
                const cadPartFiles = [
                  ...fileList,
                ];
                cadPartFiles.splice(index, 1);
                setEditFiles(cadPartFiles);
                notifySuccess('File deleted!')
              }}
            >
              <Tooltip
                title='Delete file'
                arrow
              >
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          )}
        </FlexRow>
      </FlexColumn>
    </ListItem >
  )
}

const VerifyItemUploadedFiles = (props) => {
  return (
    <List>
      {
        props.fileList.map((link, index) => {
          return <UploadedListItem
            key={index}
            link={link}
            index={index}
            {...props}
          />
        })
      }
    </List>
  )
}

export default VerifyItemUploadedFiles;
