import React, { useRef, useReducer } from "react";

import { Grid } from "@material-ui/core";
import { Add as AddIcon } from '@material-ui/icons';
import { marked } from 'marked';

import { UNIT_TYPES } from '../../../constants/unitConstants';

import { useModifyPdfCanvasContext } from "../../../context/ModifyPdfCanvasContext";

import { getSurfaceFinishWithCustomizationsText } from '../../../utils/itemUtils';
import { isEmptyValue } from "../../../utils/commonUtils";

import { FlexRow } from "../../layouts/FlexLayouts";
import FtrButton from '../../ftr-components/FtrButton';

import LandscapeTitleblock from "./LandscapeTitleblock";
import PortraitTitleblock from "./PortraitTitleblock";
import TitleBlockForm from "./TitleBlockForm";

const TitleBlockDisplay = (props) => {
  const { titleblockPayload, revision, setRevision } = props;
  const { addImg } = useModifyPdfCanvasContext()
  const landscapeTitleblockRef = useRef(null)
  const portraitTitleblockRef = useRef(null)

  const {
    surfaceFinish,
    otherSurfaceFinish,
    color,
    anodizingType,
  } = titleblockPayload

  const colorSurfaceFinish = isEmptyValue(surfaceFinish)
        ? 'NONE'
        : getSurfaceFinishWithCustomizationsText({
            surfaceFinish,
            otherSurfaceFinish,
            color,
            anodizingType,
          })
      .toUpperCase();

  const defaultTitleblockState = {
    ...titleblockPayload,
    region: 'Singapore',
    rev: revision || 1,
    address: '81 AYER RAJAH CRESCENT, #01-54, SINGAPORE 139967',
    mainNote: marked.parse(`
- UNLESS OTHERWISE SPECIFIED DEBURR AND BREAK SHARP EDGES
- DIMENSIONS ARE IN ${titleblockPayload.unitType === UNIT_TYPES.IMPERIAL ? "INCHES" : "MILLIMETERS"}
- TOLERANCES TO COMPLY WITH: 3.2 ISO 2768/1 TOLERANCE CLASS "MEDIUM"
- TAPPING HOLE TOLERANCE: ISO 2 (6H)
- ANY INTERNAL RADII TO BE A MAX OF 0.2MM, UNLESS OTHERWISE STATED
- ANY SHARP EDGES TO BE BROKEN WITH A 0.2MM X 45° CHAMFER UNLESS OTHERWISE STATED
`),
    colorSurfaceFinish,
    extraNote: ''
  }

  const [titleblockState, updateTitleblockState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    defaultTitleblockState
  );

  const handleChange = (field) => (event) => {
    updateTitleblockState({
      [field]: event.target.value
    })

    if (field === 'rev') {
      setRevision(event.target.value);
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <FlexRow>
          <FtrButton
            color="blue"
            startIcon={<AddIcon />}
            onClick={() => addImg(landscapeTitleblockRef.current)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          >
            Add Landscape Titleblock
          </FtrButton>
          <FtrButton
            color="blue"
            startIcon={<AddIcon />}
            onClick={() => addImg(portraitTitleblockRef.current)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          >
            Add Portrait Titleblock
          </FtrButton>
        </FlexRow>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
        <LandscapeTitleblock
          ref={landscapeTitleblockRef}
          titleblockState={titleblockState}
          {...props}
          />
        </Grid>
        <Grid item xs={6}>
          <PortraitTitleblock
            ref={portraitTitleblockRef}
            titleblockState={titleblockState}
            {...props}
          />
        </Grid>
        <TitleBlockForm
          handleChange={handleChange}
          titleblockState={titleblockState}
          updateTitleblockState={updateTitleblockState}
        />
      </Grid>
    </Grid>
  )
}

export default TitleBlockDisplay;
