import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import ProjectMultiCheckoutInfo from '../../pages/ProjectMultiCheckoutInfo';

import ViewShipmentsCalendarContext from '../../context/ViewShipmentsCalendarContext';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const ProjectMultiCheckoutInfoPopup = ({
  open,
  projectID,
  itemsFilterFn,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <ViewShipmentsCalendarContext.Provider value={{
      isCalendarView: true,
      projectID,
      itemsFilterFn,
    }}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"lg"}
        fullWidth={true}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        PaperProps={{ className: classes.dialog }}
      >
        <Close className={classes.closeIcon} onClick={onClose} />
        <DialogContent>
          {projectID && (
            <ProjectMultiCheckoutInfo />
          )}
        </DialogContent>
      </Dialog>
    </ViewShipmentsCalendarContext.Provider>
  );
};

export default ProjectMultiCheckoutInfoPopup;
