import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, { DataGridToolbarLayout } from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { getAllLeaveQuoteFeedback } from '../../apis/userFeedbackApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { QC_NO_QC_REPORT } from '../../constants/projectConstants';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 100;

function LeaveQuoteFeedbackDataGridPagination() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: tableData,
  });

  const getTableData = () => {
    updateTableQueryParams({ loading: true })
    getAllLeaveQuoteFeedback()
      .then((data) => {
        setTableData(data);
      })
      .catch((e) => console.log(e))
      .finally(() => updateTableQueryParams({ loading: false }))
  }

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const getPartCost = (row) => {
    const { items, currency } = row;
    const textArr = items.map(item => {
      const { name, totalPrice } = item;
      const totalPriceText = !isEmptyValue(totalPrice)
        ? convertPriceToCurrency({
          price: totalPrice,
          currency: currency || CURRENCY_CODE.SGD,
          exchangeRate: 1,
        })
        : 'N.A.';
      return `${name}: ${totalPriceText}`;
    });
    return textArr;
  }

  const getShippingCostText = (row) => {
    const { costEstimationInfo } = row;
    if (isEmptyValue(costEstimationInfo)) {
      return 'N.A.';
    }
    const {
      shippingCost,
      currency,
    } = costEstimationInfo;
    const shippingCostText = !isEmptyValue(shippingCost)
      ? `${convertPriceToCurrency({
        price: shippingCost,
        currency,
        exchangeRate: 1,
      })}`
      : 'N.A.';
    return shippingCostText;
  }

  const getEstimatedTotalText = (row) => {
    const { costEstimationInfo } = row;
    if (isEmptyValue(costEstimationInfo)) {
      return 'N.A.';
    }

    const {
      estimatedTotal,
      currency,
    } = costEstimationInfo;
    const estimatedTotalText = !isEmptyValue(estimatedTotal)
      ? `${convertPriceToCurrency({
        price: estimatedTotal,
        currency,
        exchangeRate: 1,
      })}`
      : 'N.A.';

    return estimatedTotalText;
  }

  const defaultColumns = [
    {
      headerName: 'Created At',
      field: 'createdAt',
      width: 150,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}
          />
        );
      },
    },
    {
      headerName: 'Feedback ID',
      field: 'id',
      width: 120,
    },
    {
      headerName: 'Customer (ID)',
      field: 'customerID',
      valueGetter: ({ row }) => {
        return `${row.name} (${row.userID})`;
      },
      renderCell: ({ row }) => {
        return (
          <DataGridWrapTextCell
            text={`${row.name} (${row.userID})`}
          />
        );
      },
      width: 180,
    },
    {
      headerName: 'Feedback',
      field: 'feedback',
      valueGetter: ({ row }) => {
        const { feedback } = row;
        const textArr = Object.entries(feedback)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, value]) => value === true)
          // eslint-disable-next-line no-unused-vars
          .map(([key, value]) => key);
        return textArr.join(' | ');
      },
      renderCell: ({ row }) => {
        const { feedback } = row;
        const textArr = Object.entries(feedback)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, value]) => value === true)
          // eslint-disable-next-line no-unused-vars
          .map(([key, value]) => key);
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem',
              boxSizing: 'border-box',
            }}
          >
            {textArr.map(text => <DataGridWrapTextCell text={`- ${text}`} key={text} />)}
          </FlexColumn>
        );
      },
      minWidth: 290,
      flex: 0.5,
    },
    {
      headerName: 'Additional Remarks',
      field: 'additionalRemarks',
      valueGetter: ({ row }) => {
        return `${isEmptyValue(row.additionalRemarks) ? 'N.A.' : row.additionalRemarks}`;
      },
      renderCell: ({ row }) => {
        const text = isEmptyValue(row.additionalRemarks) ? 'N.A.' : row.additionalRemarks;
        return (
          <DataGridWrapTextCell
            text={text}
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0.5rem 0.5rem 0',
              boxSizing: 'border-box',
            }}
          />
        );
      },
      width: 250,
    },
    {
      headerName: 'Part Cost',
      field: 'partCost',
      valueGetter: ({ row }) => {
        const { items } = row;
        if (isEmptyValue(items)) {
          return 'N.A.';
        }

        const textArr = getPartCost(row);
        return textArr.join(' | ');
      },
      renderCell: ({ row }) => {
        const { items } = row;
        if (isEmptyValue(items)) {
          return 'N.A.';
        }

        const textArr = getPartCost(row);
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0',
              boxSizing: 'border-box',
            }}
          >
            {textArr.map(text => {
              return (
                <DataGridWrapTextCell
                  key={text}
                  text={text}
                />
              );
            })}
          </FlexColumn>
        );
      },
      minWidth: 290,
      flex: 0.5,
    },
    {
      headerName: 'Instant Log IDs',
      field: 'instantLogID',
      valueGetter: ({ row }) => {
        const { items } = row;
        const instantLogIDArr = items
          .map((item) => item.ppePriceLogId);
        return instantLogIDArr.join(' | ');
      },
      renderCell: ({ row }) => {
        const { items } = row;
        const instantLogIDArr = items
          .map((item) => item.ppePriceLogId);
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem',
              boxSizing: 'border-box',
            }}
          >
            {instantLogIDArr.map(text => {
              return (
                <DataGridWrapTextCell text={text} key={text} />
              );
            })}
          </FlexColumn>
        );
      },
      minWidth: 330,
      flex: 0.5,
    },
    {
      headerName: 'Shipping Fees',
      field: 'shippingCost',
      valueGetter: ({ row }) => {
        return getShippingCostText(row);
      },
      renderCell: ({ row }) => {
        const shippingCostText = getShippingCostText(row);
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0',
              boxSizing: 'border-box',
            }}
          >
            <DataGridWrapTextCell text={shippingCostText} />
          </FlexColumn>
        );
      },
      minWidth: 120,
    },
    {
      headerName: 'Certification Fees',
      field: 'certificationFees',
      valueGetter: ({ row }) => {
        const { costEstimationInfo } = row;
        if (isEmptyValue(costEstimationInfo)) {
          return 'N.A.';
        }

        const {
          qcReports,
          qcReportFee,
          currency,
          qcOptionPrices,
        } = costEstimationInfo;
        if (isEmptyValue(qcReports?.main) || qcReports?.main === QC_NO_QC_REPORT) {
          return 'N.A.';
        }

        const selectedCertificates = [
          qcReports.main,
          ...qcReports.addOns,
        ]
        const totalQcReportFee = !isEmptyValue(qcReportFee)
          ? `Total: ${convertPriceToCurrency({
            price: qcReportFee,
            currency,
            exchangeRate: 1,
          })}`
          : 'N.A.';
        const textArr = [
          ...selectedCertificates.map((cert) => `${cert}: ${qcOptionPrices[cert]}`),
          totalQcReportFee,
        ]
        return textArr.join(' | ');
      },
      renderCell: ({ row }) => {
        const { costEstimationInfo } = row;
        if (isEmptyValue(costEstimationInfo)) {
          return 'N.A.';
        }

        const {
          qcReports,
          qcReportFee,
          currency,
          qcOptionPrices,
        } = costEstimationInfo;
        if (isEmptyValue(qcReports?.main) || qcReports?.main === QC_NO_QC_REPORT) {
          return 'N.A.';
        }

        const selectedCertificates = [
          qcReports.main,
          ...qcReports.addOns,
        ]
        const totalQcReportFee = !isEmptyValue(qcReportFee)
          ? `Total: ${convertPriceToCurrency({
            price: qcReportFee,
            currency,
            exchangeRate: 1,
          })}`
          : 'N.A.';
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0',
              boxSizing: 'border-box',
            }}
          >
            {selectedCertificates.map((cert) => {
              return (
                <DataGridWrapTextCell
                  key={cert}
                  text={`- ${cert}: ${qcOptionPrices[cert]}`}
                />
              );
            })}
            <DataGridWrapTextCell text={totalQcReportFee} />
          </FlexColumn>
        );
      },
      minWidth: 380,
    },
    {
      headerName: 'Estimated Total Cost',
      field: 'estimatedTotalCost',
      valueGetter: ({ row }) => {
        return getEstimatedTotalText(row);
      },
      renderCell: ({ row }) => {
        const estimatedTotalText = getEstimatedTotalText(row);

        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0',
              boxSizing: 'border-box',
            }}
          >
            <DataGridWrapTextCell text={estimatedTotalText} />
          </FlexColumn>
        );
      },
      minWidth: 150,
    },
    {
      headerName: 'Lead Time',
      field: 'leadTime',
      valueGetter: ({ row }) => {
        const { costEstimationInfo } = row;
        if (isEmptyValue(costEstimationInfo)) {
          return 'N.A.';
        }
        const {
          leadTime,
          avgLeadTime,
        } = costEstimationInfo;
        const leadTimeText = !isEmptyValue(leadTime)
          ? `Lead Time: ${leadTime}`
          : 'Lead Time: N.A.';
        const textArr = [leadTimeText];
        if (!isEmptyValue(avgLeadTime)) {
          textArr.push(avgLeadTime);
        }
        return textArr.join('. ');
      },
      renderCell: ({ row }) => {
        const { costEstimationInfo } = row;
        if (isEmptyValue(costEstimationInfo)) {
          return 'N.A.';
        }
        const {
          leadTime,
          avgLeadTime,
        } = costEstimationInfo;
        const leadTimeText = !isEmptyValue(leadTime)
          ? `Lead Time: ${leadTime}`
          : 'Lead Time: N.A.';
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0',
              boxSizing: 'border-box',
            }}
          >
            <DataGridWrapTextCell text={leadTimeText} />
            {!isEmptyValue(avgLeadTime) && <DataGridWrapTextCell text={avgLeadTime} />}
          </FlexColumn>
        );
      },
      minWidth: 200,
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    getTableData();
    const _columns = defaultColumns.filter(col => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  const handleDownloadCsv = async () => {
    setIsGeneratingCsv(true);
    const fileName = `All Leave Quote Feedback ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData,
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.id}
          rowHeight={ROW_HEIGHT}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
      </div>
    </div>
  );
}

export default LeaveQuoteFeedbackDataGridPagination;
