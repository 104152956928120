import { create } from 'zustand';
import { memoize } from 'lodash';

import { getAddMultiplePartsTourSteps } from './addMultiplePartsTour';
import { getPartUploadStepTwoTourSteps } from './partUploadStepTwoTour';
import { getMultiplePartSummaryTourSteps } from './multiplePartSummaryTour';
import { getPartUploadStepOneTourSteps } from './partUploadStepOneTour';
import { getRequestMoreQuotesFeatureTourSteps } from './manageProjectItemsTour';

import {
  getGuidedTourCompletionStatus,
  updateGuidedTourCompletionStatus,
} from '../../../apis/tourApi';

import { isEmptyValue } from '../../../utils/commonUtils';

import {
  BUYER_UPLOAD_PARTS_TOUR,
  BUYER_UPLOAD_PARTS_SUMMARY_TOUR,
  PART_UPLOAD_STEP_ONE_TOUR,
  PART_UPLOAD_STEP_TWO_TOUR,
  REQUEST_MORE_QUOTES_FEATURE_TOUR,
} from './tourConstants';


// -------------------------------------------------------------------------------------------------

const updateGuidedTourCompletionStatusMemoize = memoize(updateGuidedTourCompletionStatus)
const getGuidedTourCompletionStatusMemoize = memoize(getGuidedTourCompletionStatus)

const guidedTourState = create((set, get) => ({
  tourKey: '',
  userID: null,
  isMobile: false,
  userCompletedTourBefore: null,
  showAll: true,
  isTourLoading: false,
  getCompleteTourStatus: async () => {
    const userID = get().userID;
    const tourKey = get().tourKey;
    if (isEmptyValue(userID) || isEmptyValue(tourKey)) {
      return null;
    }
    const status = await getGuidedTourCompletionStatusMemoize(userID, tourKey)
      .then((response) => {
        return Boolean(response?.completed);
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
    set(() => ({
      userCompletedTourBefore: status,
    }));
  },
  tourOptions: {
    defaultStepOptions: {
      arrow: false,
      cancelIcon: {
        enabled: true
      },
      canClickTarget: false,
      scrollTo: true,
    },
    useModalOverlay: true,
  },
  tourSteps: () => {
    const params = get();
    const tourKey = params?.tourKey;
    switch (tourKey) {
      case BUYER_UPLOAD_PARTS_TOUR:
        return getAddMultiplePartsTourSteps(params);
      case BUYER_UPLOAD_PARTS_SUMMARY_TOUR:
        return getMultiplePartSummaryTourSteps(params);
      case PART_UPLOAD_STEP_ONE_TOUR:
        return getPartUploadStepOneTourSteps(params);
      case PART_UPLOAD_STEP_TWO_TOUR:
        return getPartUploadStepTwoTourSteps(params);
      case REQUEST_MORE_QUOTES_FEATURE_TOUR:
        return getRequestMoreQuotesFeatureTourSteps(params);
      default:
        return [];
    }
  },
  updateTourState: (params) => {
    set(() => ({
      ...params,
    }));
    getGuidedTourCompletionStatusMemoize.cache.clear();
  },
  setTourEnded: () => {
    const userID = get().userID;
    const tourKey = get().tourKey;
    updateGuidedTourCompletionStatusMemoize(userID, tourKey, true)
      .catch(e => console.error(e));
    set(() => ({
      userCompletedTourBefore: true,
    }));
  }
}));

export default guidedTourState;
