import { useReducer } from 'react';
import { useQuery } from 'react-query';

import { getProjectInfoByID } from '../../apis/projectApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { ITEM_STATUS_MAPPING } from '../../constants/itemStatus';


// -------------------------------------------------------------------------------------------------

const useCustomerProjectInfo = (projectID) => {
  const defaultLocalState = {
    open: false,
  }

  const [
    localState,
    updateLocalState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    },
    defaultLocalState,
  );

  const {
    isLoading,
    isFetching,
    refetch: refetchProjectInfoByID,
  } = useQuery(
    ['getProjectInfoByID', projectID],
    () => {
      if (isEmptyValue(projectID)) {
        return {};
      }

      return getProjectInfoByID(projectID);
    },
    {
      onSuccess: (response) => {
        const userID = response.userId;
        const projectDetail = {
          projectID: response.id,
          userID,
          name: response.name,
          dateCreated: response.dateCreated?.slice(0, 19)?.replace('T', ' '),
          status: response.status,
          items: response.items,
          userEmail: response.userEmail,
          userName: response.userName,
          qcReports: response.qcReports,
          multipleCheckouts: response.multipleCheckouts,
        };

        const isProjectDeletable = isEmptyValue(response.items)
          || response.items?.every((item) => {
            return item.status <= ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
              && item.priceBidded === null;
          });

        updateLocalState({
          customerID: userID,
          currentProjectDetail: projectDetail,
          currentProjectName: projectDetail.name,
          allParts: response.items?.filter(object => {
            return object.status !== 0;
          }),
          isProjectDeletable,
          projectOwner: {
            ownerEmail: response.userEmail,
            ownerName: response.userName,
          },
          creatorEmail: response.userEmail,
        });
      }
    }
  );

  return [
    {
      ...localState,
      isProjectLoading: isLoading || isFetching,
    },
    {
      refetchProjectInfoByID,
    }
  ]
};

export default useCustomerProjectInfo;
