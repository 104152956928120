import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import TreeViewBootstrapDropDownFuzzySearch from '../dropdowns/TreeViewBootstrapDropDownFuzzySearch';
import { FlexRow } from '../layouts/FlexLayouts';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';


import { useMaterialInfo } from '../../hooks/useMaterialInfo';

import { renderSkeletonOverlay } from '../util/skeleton';

import { isEmptyValue } from '../../utils/commonUtils';

import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    color: colors.fontGrey,
    fontWeight: 700,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  inputLabelRowDisplayFixWidth: {
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
  margins: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  dataSheetContainer: {
    marginLeft: 'auto', // Pushes content to the right
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem', // Adjust margin for spacing
  },
  dataSheetLink: {
    fontSize: 'small',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    width: 'auto',
    color: colors.blue050, // Set link color
    textDecoration: 'none', // Remove underline
  },
}));

/**
 * This starts using fuzzy search TreeViewBootstrapDropDownFuzzySearch
 *
 * @param {*} props
 * @returns
 */
function MaterialCategoriesInputField(props) {
  const classes = useStyles();

  const {
    technology,
    visible = false,
    materialCategoryOptions = [],
    onSelect,
    value,
    TDEGen = false,
    rowDisplay = false,
    bootstrapStyle = true,
    overrideStyle = {},
    onBlur = () => { },
    disabled = false,
    loading = false,
    isBuyer,
    withLabel = true,
    datasheetPosition = 'top',
    treeStyle
  } = props;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [
    {
      // description,
      datasheetUrl,
    },
    {
      setParams,
    }
  ] = useMaterialInfo();

  useEffect(() => {
    if (isEmptyValue(technology)
      || isEmptyValue(value)
      || isEmptyValue(selectedCategory)
      || technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
    ) {
      return;
    }

    const params = {
      technology,
      category: selectedCategory,
      material: value,
    }
    setParams(params);
  }, [technology, value, selectedCategory]);

  useEffect(() => {
    if (isEmptyValue(technology) || isEmptyValue(value) || isEmptyValue(materialCategoryOptions)) {
      return;
    }

    for (const treeItem of materialCategoryOptions) {
      const obj = treeItem.options.find(o => o === value);
      if (obj) {
        setSelectedCategory(treeItem.category);
        break;
      }
    }
  }, [technology, value, materialCategoryOptions]);

  if (!visible) {
    return null;
  }

  const renderDataSheetUrl = () => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        }}
      >
        <a
          style={{
            fontSize: 'small',
            fontStyle: 'italic',
            whiteSpace: 'nowrap',
            width: 'auto',
          }}
          // href={datasheetUrl}
          target="_blank"
          rel="noreferrer"
        >
          {value} Datasheet
        </a>
      </div>
    )
  }

  if (bootstrapStyle) {
    return (
      <div
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        {(withLabel || datasheetPosition === 'top') && (
          <div
            className={rowDisplay ? classes.inputLabelRowDisplayFixWidth : null}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 7,
            }}
          >
            {withLabel && (
              <FtrFieldLabel>
                <FlexRow>
                  Material
                  {TDEGen}
                </FlexRow>
              </FtrFieldLabel>
            )}
            <div className={classes.dataSheetContainer}>
              {!loading && datasheetUrl && datasheetPosition === 'top' && (
                <a className={classes.dataSheetLink} href={datasheetUrl} target="_blank" rel="noreferrer">
                  {value} Datasheet
                </a>
              )}
            </div>
          </div>
        )}
        <div style={{position: 'relative', marginBottom: withLabel ? 0 : '1rem'}}>
          {loading && renderSkeletonOverlay()}
          <TreeViewBootstrapDropDownFuzzySearch
            technology={technology}
            options={materialCategoryOptions}
            onSelect={(value) => onSelect(value)}
            onBlur={onBlur}
            value={value}
            small={rowDisplay}
            disabled={disabled}
            materialCategoryOptions={materialCategoryOptions}
            isBuyer={isBuyer}
            treeStyle={treeStyle}
          />
          {rowDisplay && datasheetUrl && renderDataSheetUrl()}
        </div>
        {!loading && datasheetUrl && datasheetPosition === 'bottom' && (
          <div className={classes.dataSheetContainer} style={{ marginTop: 0, marginLeft: 12 }}>
            <a className={classes.dataSheetLink} href={datasheetUrl} target="_blank" rel="noreferrer">
              {value} Datasheet
            </a>
          </div>
        )}
        <div>
          {rowDisplay && datasheetUrl && renderDataSheetUrl()}
        </div>
      </div>
    );
  }
  return (
    <div
      className={classes.margins }
      style={{ ...overrideStyle, position: 'relative' }}
    >
      {loading && renderSkeletonOverlay()}
      <TreeViewBootstrapDropDownFuzzySearch
        technology={technology}
        options={materialCategoryOptions}
        onSelect={(value) => onSelect(value)}
        onBlur={onBlur}
        value={value || ''}
        bootstrapStyle={false}
        disabled={disabled}
        materialCategoryOptions={materialCategoryOptions}
        isBuyer={isBuyer}
        treeStyle={treeStyle}
      />
    </div>
  );
}

export default MaterialCategoriesInputField;
