import { Cookies } from "react-cookie";
import { toast } from "react-toastify";

export const GET_INVOICES_LOADING = "GET_INVOICES_LOADING";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_LOADING = "CREATE_INVOICE_LOADING";
export const CREATE_INVOICE_FAILURE = "CREATE_INVOICE_FAILURE";

const getInvoicesLoading = () => {
  return {
    type: GET_INVOICES_LOADING
  };
};

const getInvoicesSuccess = invoices => {
  return {
    type: GET_INVOICES_SUCCESS,
    invoices
  };
};

const getInvoicesFailure = () => {
  return {
    type: GET_INVOICES_FAILURE
  };
};

const createInvoiceLoading = () => {
  return {
    type: CREATE_INVOICE_LOADING
  };
};

const createInvoiceSuccess = () => {
  toast.success("Successfully created an invoice!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: CREATE_INVOICE_SUCCESS
  };
};

const createInvoiceFailure = () => {
  toast.error("Unable to create invoice. Please try again later.", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: CREATE_INVOICE_FAILURE
  };
};

const cookies = new Cookies();

export const getAllInvoices = () => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/invoice`;
  fetch(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${cookies.get("token")}`
    }
  })
    .then(function(response) {
      dispatch(getInvoicesLoading());
      response.json().then(response => {
        dispatch(getInvoicesSuccess(response.data));
      });
    })
    .catch(function() {
      dispatch(getInvoicesFailure());
    });
};

export const createInvoice = (itemIDs, date) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/invoice/create`;
  const payload = {
    itemIDs,
    invoiceDate: date
      .toISOString()
      .slice(0, 19)
      .replace("T", " ")
  };
  dispatch(createInvoiceLoading());
  fetch(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${cookies.get("token")}`
    },
    body: JSON.stringify(payload)
  })
    .then(function(response) {
      response.json().then(() => {
        dispatch(createInvoiceSuccess());
      });
    })
    .catch(function() {
      dispatch(createInvoiceFailure());
    });
};
