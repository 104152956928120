import queryString from 'query-string';
import { get } from 'lodash';

import { fetchWithErrorHandling, getAuthorizedHeader, witchCacheResponse } from '../utils/apiUtils';
import { filterReadyForQuoteItems } from '../utils/itemUtils';

import { BACKEND_SERVICE_URL } from '../constants';
import {
  FEATURE_FLAG_ROCKET_QUOTE,
  FEATURE_FLAG_SUPPLIER_MANAGEMENT,
} from '../constants/featureFlagConstants';


// ------------------------------------------------------------------------------------------------

let currentController = new AbortController();
export const adminGetAllItems = async (params) => {
  if (currentController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    currentController.abort();
  }
  currentController = new AbortController();
  const signal = currentController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/items/search?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
    signal,
  });
}

export const adminExportItemsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/search/csv-export?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
}

let adminGetAllItemsV2Controller = new AbortController();
export const adminGetAllItemsV2 = async (params) => {
  if (adminGetAllItemsV2Controller) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    adminGetAllItemsV2Controller.abort();
  }
  adminGetAllItemsV2Controller = new AbortController();
  const signal = adminGetAllItemsV2Controller.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/items/v2/search?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
    signal,
  });
}

export const adminExportItemsAsCsvV2 = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/v2/search/csv-export?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
}

export const adminItemStatusUpdate = async (itemId, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/${itemId}/admin-status-update`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

/**
 * get all details of item, should be used for admin only
 *
 * @param {*} itemID
 * @returns
 */
export const getItemDetailsApi = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/${itemID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data[0];
  });
};

export const getVerifyingItemDetails = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/verifying`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getItemDetailsForSupplierApi = async (itemID, userID) => {
  const params = {
    forSupplier: true,
    supplierID: userID,
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/item/${itemID}?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data[0];
  });
};

/**
 * get all details of items, should be used for admin only
 *
 * @param {*} itemIDList
 * @returns
 */
export const getItemsByIDList = async (itemIDList) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items?${queryString.stringify({ itemIDList })}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const getVerifyingItems = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/verifying`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

/**
 * @deprecated
 * @returns
 */
export const getReadyForQuoteItems = async () => {
  const requestUrl = FEATURE_FLAG_SUPPLIER_MANAGEMENT === 'true'
    ? `${BACKEND_SERVICE_URL}/items/ready-for-quote`
    : `${BACKEND_SERVICE_URL}/items`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: items } = await response.json();
    const validItems = filterReadyForQuoteItems(items);
    return validItems;
  });
}

export const getReadyForQuoteItemsGroupByProject = async ({
  userID,
  userRole,
}) => {
  const params = {
    userID,
    userRole,
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/items/ready-for-quote/by-project?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: projects } = await response.json();
    return projects;
  });
}

export const getReadyForQuoteItemsGroupByProjectID = async ({ projectID, params }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/ready-for-quote/projects/${projectID}?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const transformRocketQuoteItem = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/transform-rocket-quote`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export const quotePpeItem = async (itemID, body) => {
  const requestUrl = FEATURE_FLAG_ROCKET_QUOTE === 'true'
    ? `${BACKEND_SERVICE_URL}/items/${itemID}/quote-ppe`
    : `${BACKEND_SERVICE_URL}/item/convertToQuoted`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const json = await response.json();
      const errorCode = get(json, ['message', 'code']);
      if (errorCode === 'ER_DUP_ENTRY') {
        throw Error(errorCode);
      }
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export const addItemCustomerPoFiles = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/customer-files`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
}

export const removeItemCustomerPoFiles = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/customer-files`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
}

export const generateTitleplate = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/generate-titleblock`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const adminAllowedDeniedSuppliersForItem = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/allowed-denied-suppliers`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getAllAvailableViewAsAccounts = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/ready-for-quote/view-as-available-list`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getInstantQuoteForMultiQuantities = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe/v2/items/${itemID}/price-for-quantities`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (resp) => {
    const json = await resp.json();
    if (!resp.ok) {
      throw Error(json.error ?? resp.statusText);
    }
    return json;
  });
};

export const customerDisableItem = async (body) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/items/disable`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (resp) => {
    const json = await resp.json();
    if (!resp.ok) {
      throw Error(json.error ?? resp.statusText);
    }
    return json;
  });
}

export const updatePpeInformationItem = async (itemID, body) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/items/ppe-information/${itemID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (resp) => {
    const json = await resp.json();
    if (!resp.ok) {
      throw Error(json.error ?? resp.statusText);
    }
    return json;
  });
};

export const getQuotationFormForItem = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/quotation-form`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
}

export const getQuotationFormForItemWithCache = witchCacheResponse(
  getQuotationFormForItem,
  24 * 60 * 60 // 24 hours in seconds
);

export const editItemApi = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/edit/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const editItemV2Api = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/v2/edit/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const deleteItemApi = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/delete/${itemID}`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
  });
}

export const deleteItemsApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export const getPastQuotesForRepeatItem = async ({ itemID, params }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/${itemID}/repeat-item-orders?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export const getAllQuotationsOfItem = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/${itemID}/quotations`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const generateItemImageApi = async (itemId) => {
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  const url = `${BACKEND_SERVICE_URL}/items/${itemId}/generate-image`;
  return fetchWithErrorHandling(url, requestOptions);
}

export const getItemShipmentInfo = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/shipment-info/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getItemShipmentInfoByIDList = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/shipment-info`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}
