import { PARTS_LIBRARY_STATUS } from "../constants/partsLibraryConstants";
import { isEmptyValue } from "./commonUtils";

export const isIDSame = (item, param) => {
  return (item.itemLibraryID || item.id) === (param.itemLibraryID || param.id);
};

export const isVersionSame = (item, param) => {
  return item.version === param.version;
};

/**
 * Sort items by date created in descending order, placing those with empty values at the top
 * @param {Object[]} items 
 * @returns {Object[]}
 */
export const sortedItemsLibrary = (items) => {
  return items?.sort((a, b) => {
    // Place items with empty dateCreated at the top
    if (!b.dateCreated || !a.dateCreated) return -1;

    // Parse date strings into Date objects
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);

    // Sort by dateCreated in descending order
    return dateB - dateA;
  });
};

/**
 * Returns the label status if it is either 'PURCHASED' or 'ORDER_ENQUIRY', otherwise returns null.
 *
 * @param {string} labelStatus - The status of the parts library label.
 * @return {string|null} The label status if it matches the specified conditions, otherwise null.
 */
export const showLabelPartsLibraryInImage = (labelStatus) => {
  if ([PARTS_LIBRARY_STATUS.PURCHASED, PARTS_LIBRARY_STATUS.ORDER_ENQUIRY].includes(labelStatus)) {
    return labelStatus
  }
  return null;
}

export const getDefectItemLibrary = (dfmDefectsProjectLibrary, item) => {
  if (isEmptyValue(dfmDefectsProjectLibrary) || isEmptyValue(item)) {
    return null;
  }
  return dfmDefectsProjectLibrary?.find(
    (defect) =>
      defect.itemLibraryID === item.itemLibraryID &&
      defect.version === item.version
  );
};