import { Decimal } from 'decimal.js';
import { DEFAULT_MARKUP_PERCENT } from '../constants';
import { CURRENCY_CODE } from '../constants/currencyConstants';

const calculateTotalPrice = function(...args) {
  let priceBidded;
  let markupPercent;
  let gst;
  let deliveryFee;
  let currency;
  let exchangeRate;

  switch (args.length) {
    case 1: {
      let quotation = args[0];
      priceBidded = quotation.priceBidded;
      markupPercent = quotation.markupPercent;
      gst = quotation.gst;
      deliveryFee = quotation.deliveryFee;
      break;
    }
    case 4:
      priceBidded = args[0];
      markupPercent = args[1];
      gst = args[2];
      deliveryFee = args[3];
      break;
    case 6:
      priceBidded = args[0];
      markupPercent = args[1];
      gst = args[2];
      deliveryFee = args[3];
      currency = args[4];
      exchangeRate = args[5];
      break;
    default:
      return null;
  }

  let totalPrice = 0;
  const markupPercentDec = new Decimal(markupPercent)
    .dividedBy(100)
    .plus(1)
    .toFixed();
  const gstDec = new Decimal(gst)
    .dividedBy(100)
    .plus(1)
    .toFixed();

  if (currency && currency !== CURRENCY_CODE.SGD) {
    totalPrice = new Decimal(priceBidded)
      .dividedBy(exchangeRate)
      .times(markupPercentDec)
      .times(gstDec)
      .plus(deliveryFee)
      .toFixed(2);
  } else {
    totalPrice = new Decimal(priceBidded)
      .times(markupPercentDec)
      .times(gstDec)
      .plus(deliveryFee)
      .toFixed(2);
  }

  return totalPrice;
};

//subTotal does not include delivery fees
const calculateSubTotal = function(...args) {
  let priceBidded;
  let markupPercent;
  let gst;

  switch (args.length) {
    case 1: {
      let quotation = args[0];
      priceBidded = quotation.priceBidded;
      markupPercent = quotation.markupPercent;
      gst = quotation.gst;
      break;
    }
    case 3:
      priceBidded = args[0];
      markupPercent = args[1];
      gst = args[2];
      break;
    default:
      return null;
  }

  let subTotal = (
    priceBidded *
    (1 + markupPercent / 100) *
    (1 + gst / 100)
  ).toFixed(2);

  return subTotal;
};

const calculateDefaultTotalPrice = (quote, currency, exchangeRate) => {
  return calculateTotalPrice(
    quote,
    DEFAULT_MARKUP_PERCENT * 100,
    0, // remove supplier gst
    0,
    currency,
    exchangeRate
  );
};

export default {
  calculateTotalPrice,
  calculateSubTotal,
  calculateDefaultTotalPrice,
};
