import React from "react";
import { makeStyles } from '@material-ui/core/styles/index';

import { Typography } from "@material-ui/core";
import { colors } from "../../palette";
import { clsx } from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: 4,
    position: 'relative',
    padding: '15px 12px 12px',
    marginTop: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    position: 'absolute',
    color: colors.fontLightGrey,
    backgroundColor: 'white',
    top: -14,
    left: 12,
    padding: 2,
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: '1rem',
  },
  hover: {
    '&:hover': {
      border: `1px solid ${colors.greenTagColor}`,
      backgroundColor: '#E5FFD2',
    },
  },
  selected: {
    border: `1px solid ${colors.greenTagColor}`,
    backgroundColor: '#C8FFAA',
  },
  titleSelected: {
    color: colors.green050,
  }
}));

const SelectableOutlinedDiv = (props) => {
  const classes = useStyles();

  const {
    children,
    label,
    selected = false,
    onClick,
    ...rest
  } = props;

  return (
    <div
      className={
        clsx(
          classes.container,
          selected ? classes.selected : null,
          selected ? null : classes.hover,
        )
      }
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
      {...rest}
    >
      <Typography
        className={clsx(classes.title, selected ? classes.titleSelected : null)}
        variant="body2"
      >
        {label}
      </Typography>
      {children}
    </div>
  );
};
export default SelectableOutlinedDiv;
