import React from 'react';
import Decimal from 'decimal.js';

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';

import OutlinedDiv from '../panels/OutlinedDiv';
import { FlexRowCenter } from '../layouts/FlexLayouts';

import { isCustomMaterial } from '../../utils/inputUtils';
import { getUrlFileBySupportedFileTypes } from '../../utils/fileUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getPpeMaterialCode } from '../../utils/ppeUtils';
import { is3DPTechnology } from '../../utils/itemUtils';

import { getDfmExtractDimensions } from '../../apis/dfmApi';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { techMapping } from '../../constants/PPEConstants';
import { THREE_D_P_TECH_ABBRE_MAPPING } from '../../constants/NewPartConstants';
import { colors } from '../../palette';
import { updatePpeInformationItem } from '../../apis/itemApi';

/**
 * A component to input and display dimensions of a part.
 *
 * It takes in the following props:
 * - `isDimensionDataFieldRequired`: A boolean indicating whether the dimension fields are required or not.
 * - `ppeInformation`: An object containing the DFM data and other related information.
 * - `handleUpdateDimension`: A callback function to update a dimension value.
 * - `showDimensionFieldError`: A function to check if a dimension field has an error.
 * - `setIsDimensionDataRequired`: A callback function to update whether the dimension data is required or not.
 * - `showDimensionDataNotRequired`: A boolean indicating whether the dimension data is not required or not.
 * - `updatePpeInformation`: A callback function to update the `ppeInformation` state.
 * - `isDimensionDataRequired`: A boolean indicating whether the dimension data is required or not.
 * - `sizeXRef`, `sizeYRef`, `sizeZRef`: Refs to the size input fields.
 * - `cadFile`, `originalFiles`, `material`, `otherMaterial`, `technology`, `threeDTechnology`, `imageFile`: Values related to the part.
 *
 * It renders a form with input fields for size X, Y, Z, volume, surface area, weight and weight per unit.
 * It also renders a button to fetch the dimensions from the latest CAD file and a button to save the dimensions.
 * It also renders a checkbox to indicate whether the dimension data is required or not.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isDimensionDataFieldRequired - A boolean indicating whether the dimension fields are required or not.
 * @param {Object} props.ppeInformation - An object containing the DFM data and other related information.
 * @param {Function} props.handleUpdateDimension - A callback function to update a dimension value.
 * @param {Function} props.showDimensionFieldError - A function to check if a dimension field has an error.
 * @param {Function} props.setIsDimensionDataRequired - A callback function to update whether the dimension data is required or not.
 * @param {boolean} props.showDimensionDataNotRequired - A boolean indicating whether the dimension data is not required or not.
 * @param {Function} props.updatePpeInformation - A callback function to update the `ppeInformation` state.
 * @param {boolean} props.isDimensionDataRequired - A boolean indicating whether the dimension data is required or not.
 * @param {React.Ref} props.sizeXRef - A ref to the size X input field.
 * @param {React.Ref} props.sizeYRef - A ref to the size Y input field.
 * @param {React.Ref} props.sizeZRef - A ref to the size Z input field.
 * @param {React.Ref} props.weightRef - A ref to the total weight input field.
 * @param {React.Ref} props.weightPerUnitRef - A ref to the weight per unit input field.
 * @param {File} props.cadFile - The CAD file.
 * @param {File[]} props.originalFiles - The original files.
 * @param {string} props.material - The material.
 * @param {string} props.otherMaterial - The other material.
 * @param {string} props.technology - The technology.
 * @param {string} props.threeDTechnology - The 3D technology.
 * @param {File} props.imageFile - The image file.
 */
function DimensionsInput(props) {
  const {
    isDimensionDataFieldRequired,
    ppeInformation,
    handleUpdateDimension,
    showDimensionFieldError,
    setIsDimensionDataRequired,
    showDimensionDataNotRequired,
    updatePpeInformation,
    isDimensionDataRequired,
    sizeXRef,
    sizeYRef,
    sizeZRef,
    weightRef,
    weightPerUnitRef,
    quantity = 1,
    cadFile,
    originalFiles,
    material,
    otherMaterial,
    technology,
    threeDTechnology,
    imageFile,
    itemID,
  } = props;

  const handleFetchDimensions = async () => {
    updatePpeInformation({ fetchLoading: true });
    const _cadFile = !isEmptyValue(cadFile) ? cadFile : originalFiles;
    const materialDefault = isCustomMaterial(material)
      ? otherMaterial
      : material;
    const params = {
      process: techMapping[technology] || technology,
      file_url: await getUrlFileBySupportedFileTypes(_cadFile),
      material: getPpeMaterialCode({ technology, material }) || materialDefault,
      quantity: Number(quantity),
      customer_image_file_location: imageFile,
    };
    if (is3DPTechnology(technology)) {
      params.threeDTechnology =
        THREE_D_P_TECH_ABBRE_MAPPING[threeDTechnology] || threeDTechnology;
    }
    getDfmExtractDimensions(params)
      .then((data) => {
        if (isEmptyValue(data) || !data?.dim_success) {
          notifyError("Item dimensions cannot be fetched! Please configure dimension and weight parameters manually to proceed.")
        } else {
          notifySuccess("Item dimensions fetched successfully!")
          updatePpeInformation({
            dfm: data,
            weightPerUnit: data.weight
              ? new Decimal(data.weight).dividedBy(quantity || 1).toNumber()
              : null,
          });
        }
      })
      .catch((err) => {
        notifyError(err?.message || 'Unexpected error!');
      })
      .finally(() => {
        updatePpeInformation({ fetchLoading: false });
      })
  };

  const handleSavePpeInformation = () => {
    updatePpeInformation({ saveLoading: true })
    const params = {
      boundingBoxX: ppeInformation.dfm?.boundingBoxX ?? ppeInformation.boundingBoxX ?? null,
      boundingBoxY: ppeInformation.dfm?.boundingBoxY ?? ppeInformation.boundingBoxY ?? null,
      boundingBoxZ: ppeInformation.dfm?.boundingBoxZ ?? ppeInformation.boundingBoxZ ?? null,
      volume: ppeInformation.dfm?.volume ?? ppeInformation.volume ?? null,
      weight: ppeInformation.dfm?.weight ?? ppeInformation.weight ?? null,
      surfaceArea: ppeInformation.dfm?.surfaceArea ?? ppeInformation.surfaceArea ?? null,
    }
    updatePpeInformationItem(itemID, params)
      .then(() => {
        updatePpeInformation({ dfm: null, saveLoading: false, ...params });
        notifySuccess('Item dimensions have been updated successfully!');
      })
      .catch((err) => {
        notifyError(err?.message || "Unexpected error!")
        updatePpeInformation({ saveLoading: false });
      });
  }

  function renderWeightPerUnit() {
    const weight = ppeInformation.dfm?.weight ?? ppeInformation.weight;
    const weightPerUnit =
      ppeInformation.weightPerUnit ?? !isEmptyValue(weight)
        ? new Decimal(weight).dividedBy(quantity || 1).toNumber()
        : null;

    return (
      <TextField
        variant='outlined'
        margin='dense'
        label='Weight Per Unit'
        style={{ flexGrow: '1' }}
        type='number'
        required={isDimensionDataFieldRequired}
        value={weightPerUnit}
        onChange={(evt) => {
          const value = evt.target.valueAsNumber;
          handleUpdateDimension('weightPerUnit', value);
          const totalWeight = value * quantity;
          handleUpdateDimension('weight', totalWeight);
        }}
        InputProps={{
          endAdornment: <InputAdornment position='end'>kgs</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        ref={weightPerUnitRef}
        error={showDimensionFieldError('weightPerUnit')}
        helperText={showDimensionFieldError('weightPerUnit')}
      />
    );
  }

  return (
    <OutlinedDiv label='Instant Quote Log'>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: '0.6rem',
        }}
      >
        <FlexRowCenter>
          <TextField
            variant='outlined'
            margin='dense'
            required={isDimensionDataFieldRequired}
            style={{ flexGrow: '1' }}
            label='Size X'
            type='number'
            value={
              ppeInformation.dfm?.boundingBoxX ??
              ppeInformation.boundingBoxX ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('boundingBoxX', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
            }}
            ref={sizeXRef}
            error={showDimensionFieldError('boundingBoxX')}
            helperText={showDimensionFieldError('boundingBoxX')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
        <FlexRowCenter>
          <TextField
            variant='outlined'
            margin='dense'
            required={isDimensionDataFieldRequired}
            style={{ flexGrow: '1' }}
            label='Size Y'
            type='number'
            value={
              ppeInformation.dfm?.boundingBoxY ??
              ppeInformation.boundingBoxY ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('boundingBoxY', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
            }}
            ref={sizeYRef}
            error={showDimensionFieldError('boundingBoxY')}
            helperText={showDimensionFieldError('boundingBoxY')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
        <FlexRowCenter>
          <TextField
            variant='outlined'
            margin='dense'
            required={isDimensionDataFieldRequired}
            style={{ flexGrow: '1' }}
            label='Size Z'
            type='number'
            value={
              ppeInformation.dfm?.boundingBoxZ ??
              ppeInformation.boundingBoxZ ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('boundingBoxZ', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
            }}
            ref={sizeZRef}
            error={showDimensionFieldError('boundingBoxZ')}
            helperText={showDimensionFieldError('boundingBoxZ')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '0.6rem',
        }}
      >
        <FlexRowCenter>
          <TextField
            variant='outlined'
            margin='dense'
            style={{ flexGrow: '1' }}
            label='Volume'
            type='number'
            value={ppeInformation.dfm?.volume ?? ppeInformation.volume ?? ''}
            onChange={(evt) =>
              handleUpdateDimension('volume', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>cm3</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
        <FlexRowCenter>
          <TextField
            variant='outlined'
            margin='dense'
            style={{ flexGrow: '1' }}
            label='Surface Area'
            type='number'
            value={
              ppeInformation.dfm?.surfaceArea ??
              ppeInformation.surfaceArea ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('surfaceArea', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>cm2</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
        <FlexRowCenter>
          <TextField
            variant='outlined'
            margin='dense'
            label={`Total Weight (for quantity ${quantity})`}
            required={isDimensionDataFieldRequired}
            style={{ flexGrow: '1' }}
            type='number'
            value={ppeInformation.dfm?.weight ?? ppeInformation.weight ?? ''}
            onChange={(evt) => {
              const value = evt.target.valueAsNumber;
              handleUpdateDimension('weight', value);
              const weightPerUnit = value ? new Decimal(value).dividedBy(quantity || 1).toNumber() : null;
              updatePpeInformation({ weightPerUnit });
            }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>kgs</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            ref={weightRef}
            error={showDimensionFieldError('weight')}
            helperText={showDimensionFieldError('weight')}
          />
        </FlexRowCenter>
        <FlexRowCenter>{renderWeightPerUnit()}</FlexRowCenter>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <Tooltip title='Regenerate dimensions from the latest CAD file' arrow>
          <Button
            variant='contained'
            color='primary'
            style={{
              backgroundColor: colors.purple,
              height: '2.25rem',
              color: colors.fontWhite,
            }}
            onClick={handleFetchDimensions}
            disabled={ppeInformation.fetchLoading}
          >
            {ppeInformation.fetchLoading && (
              <CircularProgress
                size={24}
                color='inherit'
                style={{ marginRight: '0.5rem' }}
              />
            )}
            Fetch Dimensions
          </Button>
        </Tooltip>
        {ppeInformation.dfm && (
          <>
            <Button
              variant='contained'
              color='primary'
              style={{
                backgroundColor: colors.green050,
                height: '2.25rem',
                color: colors.fontWhite,
              }}
              onClick={handleSavePpeInformation}
              disabled={ppeInformation.saveLoading}
            >
              {ppeInformation.saveLoading && (
                <CircularProgress
                  size={24}
                  color='inherit'
                  style={{ marginRight: '0.5rem' }}
                />
              )}
              Save
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{
                backgroundColor: colors.red030,
                height: '2.25rem',
              }}
              onClick={() => updatePpeInformation({ dfm: null })}
            >
              Cancel
            </Button>
          </>
        )}
        {showDimensionDataNotRequired && (
          <FormControlLabel
            control={
              <Checkbox
                checked={!isDimensionDataRequired}
                onChange={() =>
                  setIsDimensionDataRequired(!isDimensionDataRequired)
                }
                name='checkbox-dimension-data-required'
                data-cy='checkbox-dimension-data-not-required'
              />
            }
            label='Dimension Data Not Required'
          />
        )}
      </div>
    </OutlinedDiv>
  );
}

export default DimensionsInput;
