import React, { useEffect, useReducer, useState } from 'react';
import { format } from 'date-fns';
import { ceil } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';
import RequestResponseInfoPopup from '../../components/popups/RequestResponseInfoPopup';

import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Link as LinkButton,
  Tooltip,
} from '@material-ui/core';


import CustomToolbar from '../../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';

import { getAllDefectsFeedback } from '../../apis/dfmApi';


import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { exportCSV } from '../../utils/csvExportUtils';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 100;

function DefectsFeedbackDataGridPagination() {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [DFMLogInfoPopup, updateDFMLogInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: tableData,
  });

  const getTableData = () => {
    updateTableQueryParams({ loading: true })
    getAllDefectsFeedback()
      .then((data) => {
        setTableData(data);
        updateTableQueryParams({ loading: false })
      })
      .catch(() => updateTableQueryParams({ loading: false })
    )
  }

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const defaultColumns = [
    {
      headerName: 'DFM ID',
      field: 'dfmTrackingID',
      width: 100,
    },
    {
      headerName: "Part ID",
      field: "itemID",
      renderCell: ({ row: rowData }) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Tooltip title="Hold Ctrl/Cmd and click to edit in new tab">
              <Button
                className={classes.linkButton}
                href={`/item/edit/${rowData.itemID}`}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: 120,
    },
    {
      headerName: 'User (ID)',
      field: 'userID',
      width: 100,
    },
    {
      headerName: 'User Email',
      field: 'email',
      width: 100,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.email} />,
    },
    {
      headerName: 'Role',
      field: 'role',
      width: 100,
    },
    {
      headerName: 'deep holes',
      field: 'deepHolesFeedback',
      width: 100,
    },
    {
      headerName: 'small holes',
      field: 'smallHolesFeedback',
      width: 100,
    },
    {
      headerName: 'holes near edge',
      field: 'holesNearEdgeFeedback',
      width: 100,
    },
    {
      headerName: 'part size too big',
      field: 'partSizeTooBigFeedback',
      width: 100,
    },
    {
      headerName: 'sharp edges',
      field: 'sharpEdgesFeedback',
      width: 100,
    },
    {
      headerName: 'thin walls',
      field: 'thinWallsFeedback',
      width: 100,
    },
    {
      headerName: 'Request',
      field: 'request',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.request, null, 2) ?? '';
        const rows = content.split('\n');
        const length = rows.length;
        content = rows.slice(0, 4).join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
             <DataGridWrapTextCell text={content} />
            {length > 4 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateDFMLogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.request) ?? '';
      },
      width: 350,
    },
    {
      headerName: 'Response',
      field: 'response',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.response, null, 2) ?? '';
        const rows = content.split('\n');
        const length = rows.length;
        content = rows.slice(0, 4).join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            
            {content}
            {length > 4 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateDFMLogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.response) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Last Modified At',
      field: 'lastModifiedAt',
      width: 150,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={format(new Date(rowData.lastModifiedAt), 'yyyy-MM-dd HH:mm')}
          />
        );
      },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    getTableData();
    const _columns = defaultColumns.filter(col => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  const handleDownloadCsv = async () => {
    exportCSV(columns, filteredData, `All Defects Feedback`);
  };

  const getToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
          gap: '1rem'
        }}
      >
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton key='export-csv' handleClick={handleDownloadCsv} />,
          ]}
        />
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => (row.dfmTrackingID + row.userID)}
          rowHeight={ROW_HEIGHT}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
      </div>
      {DFMLogInfoPopup.open && (
        <RequestResponseInfoPopup
          open={DFMLogInfoPopup.open}
          handleClose={() => updateDFMLogInfoPopup({ open: false })}
          request={JSON.stringify(DFMLogInfoPopup.selectedData?.request, null, 2)}
          response={JSON.stringify(DFMLogInfoPopup.selectedData?.response, null, 2)}
          title={`DFM ID ${DFMLogInfoPopup.selectedData?.dfmTrackingID}${
            DFMLogInfoPopup.selectedData?.itemID
              ? ` - ITEM ID ${DFMLogInfoPopup.selectedData?.itemID}`
              : ''
          }`}
        />
      )}
    </div>
  );
}

export default DefectsFeedbackDataGridPagination;
