import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { isEmptyValue } from '../utils/commonUtils';

/**
 * A higher-order component (HOC) that adds popup functionality to a wrapped component.
 *
 * Note that due to the spread operator, if there are keys in localState with the same value as certain props, then the values
 * in localState will override the props' values, due to the spread operator.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped by the HOC.
 * @param {React.Component} ModalComponent - The modal component (popup) that will be rendered.
 * @param {string} statePropName - The name of the prop that holds the local state (e.g., `'internalNotePopupState'`).
 * @param {string} updaterPropName - The name of the prop that holds the function to update the local state (e.g., `'updateInternalNotePopupState'`).
 * @param {Object} defaultLocalState - The default local state for the popup.
 *
 * @returns {React.Component} - The wrapped component with additional popup functionality.
 *
 * @example
 * const EnhancedComponent = withPopupHOC({
 *   WrappedComponent: MyComponent,
 *   ModalComponent: MyModal,
 *   statePropName: 'modalState',
 *   updaterPropName: 'updateModalState',
 *   defaultLocalState: { open: false, title: 'My Modal' },
 * });
 */
function withPopupHOC({
  WrappedComponent,
  ModalComponent,
  statePropName,
  updaterPropName,
  defaultLocalState = { open: false },
}) {
  function WrappedComponentWithHOC(props) {
    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          {...props}
          {...{ [statePropName]: localState }}
          {...{ [updaterPropName]: updateLocalState }}
        />
        {localState.open && (
          <ModalComponent
            {...localState}
            {...{ [updaterPropName]: updateLocalState }}
            onClose={() => updateLocalState(defaultLocalState)}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withPopupHOC(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

withPopupHOC.propTypes = {
  WrappedComponent: PropTypes.elementType.isRequired,
  ModalComponent: PropTypes.elementType.isRequired,
  statePropName: PropTypes.string.isRequired,
  updaterPropName: PropTypes.string.isRequired,
  defaultLocalState: PropTypes.object,
};

export default withPopupHOC;
