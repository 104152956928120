import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Decimal } from 'decimal.js'
import { useQuery } from 'react-query';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Tune as TuneIcon
} from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles/index';

import {
  editOrder,
  getAllUsers,
} from '../actions';

import OtherMaterialField from '../components/forms/form-fields/OtherMaterialField';
import OtherSurfaceFinishingField from '../components/forms/form-fields/OtherSurfaceFinishingField';
import ThreeDInfillFieldSelect from '../components/forms/form-fields/ThreeDInfillFieldSelect';
import ThreeDLayerThicknessFieldSelect from '../components/forms/form-fields/ThreeDLayerThicknessFieldSelect';
import MaterialCategoriesInputField from '../components/inputs/MaterialCategoriesInputField';
import ThreeDPrintingTechnologyInputField from '../components/inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../components/inputs/ThreeDPrintingMaterialField';
import ColorInputField from '../components/inputs/ColorInputField';
import SurfaceFinishingField from '../components/inputs/SurfaceFinishingField';
import InfoMatchValue from '../components/info/InfoMatchValue';
import InfoIcon from '../components/icons/InfoIcon';
import IOSSwitch from '../components/IOSSwitch';
import SearchInputDropdown from '../components/dropdowns/SearchInputDropdown';
import FtrAccordion from '../components/ftr-components/FtrAccordion';
import { FtrDropdownV2 } from '../components/ftr-components';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import withDeleteQuotePopupHOC from '../hocs/withDeleteQuotePopupHOC';

import { upsertPriceFeedback } from '../apis/ppeFeedbackApi';
import { getConfigByKey } from '../apis/configurationApi';
import { adminGetAllQuotationsByParams, adminUpdateQuotation, getQuotationByID } from '../apis/quotationApi';
import { getItemDetailsApi } from '../apis/itemApi';
import { calculateQuotationTotalPrice } from '../apis/priceApi';
import { checkSimilarityPoQuotation } from '../apis/purchaseOrderApi';

import { notifyError, notifySuccess } from '../services/notificationService';

import { useItemInputConfig } from '../hooks/useItemInputConfig';

import { is3DPTechnology } from '../utils/itemUtils';
import { formatDateWithTime, dateTzSingapore } from '../utils/dateTimeUtils';
import { hasReviewPermission, isAdminOrHigherRole } from '../utils/roleUtils';
import { getDefaultExpiredDate } from '../utils/dateTimeUtils';
import { countDecimalsUtil, handleDigitDecimalUtil, inToleranceNumber } from '../utils/numberUtils';
import { isCustomMaterial, isCustomSurfaceFinish, isAnodizingSurfaceFinish } from '../utils/inputUtils';
import { isEmptyValue } from '../utils/commonUtils';

import formulas from '../formulas/formulas';

import { getUserSelector } from '../selectors/userSelector';

import { ADMIN_FEEDBACK } from '../constants/ppeFeedbackConstants';
import { FEEDBACK_STATUS } from '../constants/feedbackConstants';
import { QC_FORMAL_CMM_REPORT } from '../constants/projectConstants';
import { MAX_TOTAL_WEIGHT_FEDEX_IN_KG } from '../constants/deliveryInfoConstants';
import { KEY_CONFIGURATION } from '../constants/configurations';
import { COUNTRY_NAMES } from '../constants/countryConstants';
import {
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  THREE_D_P_FDM_TECH,
  THREE_D_P_OLD_SLA_VALUE_TECH,
  THREE_D_P_SLA_TECH,
} from '../constants/NewPartConstants';
import {
  DEFAULT_FACTOREM_GST,
  DEFAULT_MARKUP_PERCENT,
  DEFAULT_SUPPLIER_GST_PERCENT,
  ORDER_STATUS, ROLE_TYPES,
} from '../constants';
import { ANODIZING_TYPE_OPTIONS } from "../constants/itemConstants";

import { colors } from '../palette';


const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  property: {
    margin: '25px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%'
    }
  },
  infoIcon: {
    marginLeft: '10px'
  },
  greenText: {
    '& .MuiOutlinedInput-input': {
      color: colors.successGreen
    }
  },
  redText: {
    '& .MuiOutlinedInput-input': {
      color: colors.errorRed
    }
  },
  switchBlue: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.buttonBlue
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));

const orderStatus = [
  {
    value: 'verifying',
    label: 'Verifying'
  },
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'accepted',
    label: 'Accepted'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  },
  {
    value: 'expired',
    label: 'Expired'
  },
  {
    value: 'disabled',
    label: 'Disabled'
  },
  {
    value: 'waiting_for_quote',
    label: 'Waiting for quote'
  },
];

export function EditOrder(props) {
  const classes = useStyles();

  const {
    match,
    isDialog,
    setOpen,
    isVerifyQuote,
    onDeleteQuotePopup
  } = props;

  const editPriceBiddedFieldRef = useRef(null);
  const otherMaterialFieldRef = useRef(null);
  const otherSurfaceFinishFieldRef = useRef(null);
  const supplierFieldRef = useRef(null)
  const searchSupplierRef = useRef(null)

  const loggedInUser = useSelector(getUserSelector) || {};
  const { role } = loggedInUser;
  const users = useSelector(state => state?.users?.users);

  const dispatch = useDispatch();

  const fetchAllUsers = (params) => dispatch(getAllUsers(params));
  const submitEditOrder = (editedOrder) => dispatch(editOrder(editedOrder));

  // set original states from order details page
  const editOrderId = props.quotationID || match.params.orderID;
  const [order, setOrder] = useState(null);
  const [editName, setEditName] = useState('');
  const [technology, setTechnology] = useState('');
  const [editStatus, setEditStatus] = useState('');
  const [editQuantity, setEditQuantity] = useState('');
  const [editPriceBidded, setEditPriceBidded] = useState('');
  const [editMarkupPercent, setEditMarkupPercent] = useState('');
  const [material, setMaterial] = useState('');
  const [otherMaterial, setOtherMaterial] = useState('');
  const [materialColor, setMaterialColor] = useState('');
  const [editRemarks, setEditRemarks] = useState('');
  const [editCustomerRemarks, setEditCustomerRemarks] = useState('');
  const [editLeadTime, setEditLeadTime] = useState('');
  const [editMarkupLeadTime, setEditMarkupLeadTime] = useState('');
  const [editGst, setEditGst] = useState('');
  const [editPayPerSale, setEditPayPerSale] = useState('');
  const [editDeliveryFee, setEditDeliveryFee] = useState('');
  const [editUserID, setEditUserID] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [editUserError, setEditUserError] = useState(null);
  const [supplierID, setSupplierID] = useState('');
  const [editDateOfExpiry, setEditDateOfExpiry] = useState(
    new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
  );
  const [suppliers, setSuppliers] = useState([]);
  const [warningDialog, setWarningDialog] = useState(false);
  const [poWarningDialog, setPoWarningDialog] = useState(false);
  const [acceptedOrder, setAcceptedOrder] = useState(null);
  const [editedTotalPrice, setTotalPrice] = useState('');
  const [editedTotalPriceInclGst, setTotalPriceInclGst] = useState('');
  const [surfaceFinish, setSurfaceFinish] = useState('');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');
  const [anodizingType, setAnodizingType] = useState('');
  const [color, setColor] = useState('');
  const [threeDTechnology, setThreeDTechnology] = useState('');
  const [threeDInfill, setThreeDInfill] = useState('');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState('');
  const [disablePriceChange, setDisablePriceChange] = useState(true);
  const [ppeQuotes, setPpeQuotes] = useState([]);
  const [formError, setFormError] = useState({});
  const [itemQuotation, setItemQuotation] = useState(null);
  const [disableVerifyButton, setDisableVerifyButton] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [editUnitQuote, setEditUnitQuote] = useState('')
  const [editedCmmPrice, setEditedCmmPrice] = useState(null)
  const [markupCmmPrice, setMarkupCmmPrice] = useState(0)
  const [cmmPriceCustomer, setCmmPriceCustomer] = useState(null)
  const [platformFee, setPlatformFee] = useState(0);
  const [projectID, setProjectID] = useState('');
  const [ppeDataPoint, setPpeDataPoint] = useState(false);
  const [biDataPoint, setBiDataPoint] = useState(false);
  const [dataPointLoaded, setDataPointLoaded] = useState(false)
  const [filterText, setFilterText] = useState('');
  const [revisedPriceBidded, setRevisedPriceBidded] = useState(null);
  const [revisedTotalPrice, setRevisedTotalPrice] = useState(null);

  const [
    {
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setSurfaceFinishOptions,
    }
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const totalPriceIncPlatformFee = Number(editedTotalPrice) + (Number(platformFee) || 0);

  let supplierName = users.find(user => user.userID === supplierID);
  supplierName = supplierName ? supplierName.name : '';

  const validateForm = () => {
    if (isEmptyValue(editPriceBidded)) {
      setFormError({
        editPriceBidded: 'Required field',
      });
      editPriceBiddedFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (isCustomMaterial(material) && isEmptyValue(otherMaterial)) {
      setFormError({
        otherMaterial: 'Required field',
      });
      otherMaterialFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmptyValue(otherSurfaceFinish)) {
      setFormError({
        otherSurfaceFinish: 'Required field',
      });
      otherSurfaceFinishFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    const isSupplierExist = suppliers.find((sup => sup.userID === editUserID))
    if (!isSupplierExist && isVerifyQuote) {
      setFormError({
        supplier: 'Required field',
      });
      supplierFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    return true;
  }
  const getOrderForPoCheck = () => {
    const editedOrder = {
      quantity: editQuantity,
      priceBidded: editPriceBidded,
      material,
      customMaterial: otherMaterial,
      surfaceFinish,
      customSurfaceFinish: otherSurfaceFinish,
      remarks: editRemarks,
      customerRemarks: editCustomerRemarks,
      gst: editGst,
      totalPrice: editedTotalPrice,
      payPerSale: editPayPerSale,
      userID: editUserID,
    }
    return editedOrder
  }

  const getEditedOrder = (poRegenerated = false) => {
    let newStatus = editStatus;
    if (isVerifyQuote && editStatus === ORDER_STATUS.VERIFYING) {
      if (order.rfqQuote && order.rocketQuote) {
        newStatus = ORDER_STATUS.PENDING;
      } else if (order.price !== null && (!order.rocketQuote || order.verifiedDate)) {
        newStatus = ORDER_STATUS.ACCEPTED;
      } else {
        newStatus = ORDER_STATUS.PENDING;
      }
    }
    const editedOrder = {
      quotationID: editOrderId,
      editName,
      editStatus: newStatus,
      editDateOfExpiry,
      editQuantity,
      editPriceBidded,
      editMarkupPercent,
      editMaterial: material,
      otherMaterial,
      materialColor,
      editRemarks,
      editLeadTime,
      editMarkupLeadTime,
      editGst,
      editPayPerSale,
      editDeliveryFee,
      editCustomerRemarks,
      editUserID,
      editedTotalPrice,
      editSurfaceFinish: surfaceFinish,
      otherSurfaceFinish,
      color,
      isPPE: order.price !== null ? 1 : 0,
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
      editedCmmPrice,
      markupCmmPrice,
      cmmPriceCustomer,
      platformFee,
      ppeDataPoint,
      poRegenerated,
      revisedPriceBidded,
      revisedTotalPrice,
      anodizingType
    }

    return editedOrder;
  }

  const handleConfirmSubmit = () => {
    setFormError({});
    const valid = validateForm();
    if (!valid) {
      return;
    }
    if (acceptedOrder !== null) { //this means editStatus is accepted and there is an existing accepted quotation
      checkSimilarityPoQuotation(editOrderId, getOrderForPoCheck())
        .then(isSimilar => {
          setWarningDialog(false);
          if (!isSimilar) {
            setPoWarningDialog(true)
          }
          else {
            submitEditOrder(getEditedOrder());
            if (isDialog) setOpen(false);
          }
        })
        .catch(e => console.log(e));
    } else {
      submitEditOrder(getEditedOrder());
      if (isDialog) setOpen(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (editStatus === ORDER_STATUS.ACCEPTED) {
      adminGetAllQuotationsByParams({ itemIDList: order.itemID, status: ORDER_STATUS.ACCEPTED })
        .then(data => {
          const findAcceptedOrder = data?.find(
            x => x.itemID === order.itemID && x.status === ORDER_STATUS.ACCEPTED
          );
          if (findAcceptedOrder) {
            setAcceptedOrder(findAcceptedOrder);
            setWarningDialog(true);
          } else {
            handleConfirmSubmit();
          }
        })
        .catch(e => console.log(e));
    } else {
      handleConfirmSubmit();
    }
  };

  const handleVerifyButtonClick = (e) => {
    setFormError({});
    const valid = validateForm();
    if (!valid) {
      return;
    }
    if (
      isVerifyQuote
      && order.status === ORDER_STATUS.VERIFYING
      && order.price
      && !isEmptyValue(ppeQuotes)
      && editUserID == null
    ) {
      setEditUserError(`Please select supplier to verify quote acceptance`);
      return;
    }
    handleSubmit(e);
  }

  useEffect(() => {
    if (hasReviewPermission(role)
      || role === ROLE_TYPES.SALES_CONSULTANT
    ) {
      const params = {
        roles: [
          ROLE_TYPES.ADMIN,
          ROLE_TYPES.SUPER_ADMIN,
          ROLE_TYPES.SUPPLIER,
        ],
      }
      fetchAllUsers(params);
    }
  }, []);

  const { data: defaultNonSgpDeliveryFee } = useQuery('getDefaultNonSgpDeliveryFee', () => getConfigByKey(KEY_CONFIGURATION.DEFAULT_NON_SGP_DELIVERY_FEE));

  const { refetch: refetchQuotation } = useQuery(['getQuotationByID', editOrderId], () => {
    getQuotationByID(editOrderId)
      .then(async (quotationDetails) => {
        const {
          technology,
          material,
          surfaceFinish,
        } = quotationDetails;

        loadSelectColorSurfaces({ technology });

        const metadata = quotationDetails.metadata || {};
        const threeDTechnology = !is3DPTechnology(technology)
          ? null
          : metadata.threeDTechnology === THREE_D_P_OLD_SLA_VALUE_TECH
            ? THREE_D_P_SLA_TECH
            : metadata.threeDTechnology;

        setOrder(quotationDetails);
        setEditName(quotationDetails.name || '');
        setTechnology(technology);
        setProjectID(quotationDetails.projectID);
        setEditStatus(quotationDetails.status || orderStatus[0].value);
        setEditQuantity(quotationDetails.quantity);
        setEditPriceBidded(Number(quotationDetails.priceBidded).toFixed(2));
        setPlatformFee(quotationDetails.platformFee);
        setPpeDataPoint(quotationDetails.ppeDataPoint);
        setBiDataPoint(quotationDetails.biDataPoint);
        setDataPointLoaded(true)
        setRevisedPriceBidded(
          quotationDetails.revisedPriceBidded
            ? Number(quotationDetails.revisedPriceBidded).toFixed(2)
            : quotationDetails.revisedPriceBidded
        );
        setRevisedTotalPrice(quotationDetails.revisedTotalPrice
          ? Number(quotationDetails.revisedTotalPrice).toFixed(2)
          : quotationDetails.revisedTotalPrice
        );
        setAnodizingType(quotationDetails.metadata?.anodizingType)

        const cmmPrice = get(quotationDetails, 'metadata.cmmPrice')
        if (cmmPrice && cmmPrice > 0) {
          setEditedCmmPrice(cmmPrice.toFixed(2))
          const defaultMarkupCmmPrice = get(quotationDetails, 'metadata.markupCmmPrice', 0)
          setMarkupCmmPrice(defaultMarkupCmmPrice)
          const defaultCmmPriceCustomer = get(quotationDetails, 'metadata.cmmPriceCustomer') ?? get(quotationDetails, 'metadata.cmmPrice') ?? 0
          setCmmPriceCustomer(defaultCmmPriceCustomer.toFixed(2))
        }

        let materialOptions = [];
        if (!is3DPTechnology(technology)) {
          const { options: materialCategories } = await loadMaterialCategoryOptions({
            technology,
          }, false);
          materialOptions = materialCategories.flatMap(item => item.options);
        } else {
          await load3DTechnologyOptions(false);
          const { options: threeDPMaterials } = await loadThreeDMaterialOptions({
            technology,
            threeDTechnology,
          }, false).catch(() => {
            setDisableVerifyButton(true);
            setDisableSubmitButton(true);
          });
          materialOptions = threeDPMaterials;
        }

        if (isCustomMaterial(material) || !materialOptions.includes(material)) {
          setMaterial('Custom Material');
          setOtherMaterial(quotationDetails.customMaterial || material);
        } else {
          setMaterial(material);
          setOtherMaterial(quotationDetails.customMaterial);
        }

        const params = {
          technology,
          threeDTechnology,
          material,
        }
        const { options: allSurfaceOptions } = await loadSurfaceFinishOptions(params, false);

        if (isCustomSurfaceFinish(surfaceFinish) || (surfaceFinish && !(allSurfaceOptions || []).includes(surfaceFinish))) {
          if (surfaceFinish && isEmptyValue(allSurfaceOptions)) {
            setSurfaceFinishOptions(['Custom Finish']);
          }
          setSurfaceFinish('Custom Finish');
          setOtherSurfaceFinish(quotationDetails.customSurfaceFinish || surfaceFinish);
        } else {
          setSurfaceFinish(surfaceFinish);
          setOtherSurfaceFinish(quotationDetails.customSurfaceFinish);
        }

        setMaterialColor(quotationDetails.materialColor);
        setEditRemarks(quotationDetails.remarks);
        setEditCustomerRemarks(quotationDetails.customerRemarks);
        setEditLeadTime(quotationDetails.leadTime);
        setSupplierID(quotationDetails.userID);
        setEditMarkupLeadTime(quotationDetails.markupLeadTime);
        setColor(quotationDetails.color);
        setEditUserID(quotationDetails.userID);

        const _deliveryFee = isVerifyQuote
          ? quotationDetails.deliveryFee
          : isEmptyValue(quotationDetails.deliveryFee) ? 20 : quotationDetails.deliveryFee;
        setEditDeliveryFee(_deliveryFee);

        const dataItemQuotation = await getItemDetailsApi(quotationDetails.itemID)
        setItemQuotation(dataItemQuotation)

        let totalPrice = 0;
        if (quotationDetails.price == null) {
          // for RFQ Quote
          const deltaPrice = new Decimal(quotationDetails.totalPrice)
            .minus(quotationDetails.priceBidded)
            .toFixed();
          const markupPercentDec = quotationDetails.priceBidded > 0
            ? new Decimal(deltaPrice)
              .dividedBy(quotationDetails.priceBidded)
              .times(100)
              .toFixed()
            : quotationDetails.markupPercent;
          let markupPercent = quotationDetails.totalPrice
            ? quotationDetails.markupPercent || markupPercentDec
            : quotationDetails.markupPercent || DEFAULT_MARKUP_PERCENT * 100;
          if (quotationDetails.status === ORDER_STATUS.ACCEPTED) {
            markupPercent = markupPercentDec;
          }
          markupPercent = handleDigitDecimalUtil(markupPercent);
          setEditMarkupPercent(markupPercent);
          totalPrice = Number(
            formulas.calculateTotalPrice(
              quotationDetails.priceBidded,
              markupPercent,
              0,
              0
            )
          );
        } else {
          // for PPE Quote
          totalPrice = Number(quotationDetails.totalPrice);
          const deltaPrice = new Decimal(totalPrice)
            .minus(quotationDetails.priceBidded)
            .toFixed();
          const markupPercentDec = quotationDetails.priceBidded > 0
            ? new Decimal(deltaPrice)
              .dividedBy(quotationDetails.priceBidded)
              .times(100)
              .toFixed()
            : quotationDetails.markupPercent;
          let markupPercent =
            quotationDetails.markupPercent !== 0 &&
              quotationDetails.markupPercent !== ''
              ? quotationDetails.markupPercent
              : markupPercentDec
          markupPercent = handleDigitDecimalUtil(markupPercent);
          setEditMarkupPercent(markupPercent);
        }
        setTotalPrice(totalPrice.toFixed(2));
        let unitQuote = new Decimal(totalPrice)
          .dividedBy(quotationDetails.quantity)
          .toFixed();
        unitQuote = handleDigitDecimalUtil(unitQuote);
        setEditUnitQuote(unitQuote)
        setEditDateOfExpiry(
          quotationDetails.dateOfExpiry
            ? new Date(quotationDetails.dateOfExpiry)
            : getDefaultExpiredDate()
        );

        if (is3DPTechnology(technology)) {
          setThreeDTechnology(threeDTechnology);
          if (threeDTechnology === THREE_D_P_FDM_TECH) {
            setThreeDInfill(metadata.threeDInfill);
            setThreeDLayerThickness(metadata.threeDLayerThickness);
          }
        }
        if (isVerifyQuote && !(quotationDetails.rfqQuote && quotationDetails.rocketQuote)) {
          setDisablePriceChange(!!quotationDetails.price);
        } else {
          setDisablePriceChange(false);
        }
        if (is3DPTechnology(technology)) {
          loadThreeDMaterialOptions({
            technology,
            threeDTechnology,
          }, false).catch(() => {
            setDisableVerifyButton(true);
            setDisableSubmitButton(true);
          });
        }
        if (quotationDetails.materialColor) {
          loadMaterialColorOptions(params, false);
        }
        if (quotationDetails.color) {
          loadSurfaceFinishColorOptions({
            technology,
            surfaceFinish: quotationDetails.surfaceFinish,
          }, false);
        }
      });
  })

  useEffect(() => {
    const totalPriceInclGst = totalPriceIncPlatformFee * (1 + DEFAULT_FACTOREM_GST);
    setTotalPriceInclGst(totalPriceInclGst.toFixed(2));
  }, [editedTotalPrice, platformFee])

  useEffect(() => {
    let filteredSuppliers = users.filter(
      user =>
        user.role === ROLE_TYPES.SUPPLIER ||
        user.role === ROLE_TYPES.ADMIN ||
        user.role === ROLE_TYPES.REVIEWER ||
        role === ROLE_TYPES.SUPER_ADMIN ||
        user.userID === loggedInUser.userID
    );
    const quotedSupplierIDs = order && order.ppeQuotes
      ? order.ppeQuotes.quotes.map(quote => quote.supplierID)
      : [];

    if (!isEmptyValue(quotedSupplierIDs)) {
      filteredSuppliers = filteredSuppliers.filter(
        supplier => quotedSupplierIDs.includes(supplier.userID)
      );
      setPpeQuotes(order.ppeQuotes.quotes);
    }

    filteredSuppliers = filterText
      ? filteredSuppliers.filter((supplier) =>
        supplier.name.toLowerCase().includes(filterText.toLowerCase()) ||
        supplier.email.toLowerCase().includes(filterText.toLowerCase())
      )
      : filteredSuppliers

    if (order && filteredSuppliers) {
      for (const supplier of filteredSuppliers) {
        const quote = (order.ppeQuotes?.quotes ?? []).find(
          (q) => q.supplierID === supplier.userID
        );
        if (quote) {
          supplier.quoteCreatedAt = formatDateWithTime(dateTzSingapore(quote.createdAt));
        }
      }
    }

    filteredSuppliers.sort((b, a) => new Date(b.createdAt) - new Date(a.createdAt))

    setSuppliers(filteredSuppliers);
  }, [users, order, filterText]);

  useEffect(() => {
    if (isEmptyValue(suppliers) || isEmptyValue(order)) {
      return;
    }
    const _supplier = suppliers.find(sup => {
      return sup.userID === order.userID;
    });
    const _gst = order.status === ORDER_STATUS.VERIFYING
      ? _supplier && !isEmptyValue(_supplier.supplierGstPercent)
        ? _supplier.supplierGstPercent
        : DEFAULT_SUPPLIER_GST_PERCENT
      : isEmptyValue(order.gst) ? DEFAULT_SUPPLIER_GST_PERCENT : order.gst;
    setEditGst(_gst);
    const _payPerSale = order.status === ORDER_STATUS.VERIFYING
      ? _supplier && !isEmptyValue(_supplier.supplierDiscountPercent)
        ? _supplier.supplierDiscountPercent
        : 0
      : isEmptyValue(order.payPerSale)
        ? (_supplier && !isEmptyValue(_supplier.supplierDiscountPercent)
          ? _supplier.supplierDiscountPercent
          : 0)
        : order.payPerSale;
    setEditPayPerSale(_payPerSale);

  }, [suppliers, order]);

  useEffect(() => {
    // ppe quote accepted but supplier quotes are being verified
    const orderPpeAcceptedInVerifying = !order?.rfqQuote && [ORDER_STATUS.ACCEPTED, ORDER_STATUS.VERIFYING].includes(order?.status);
    if (orderPpeAcceptedInVerifying || disablePriceChange === true) {
      return;
    }

    if (isEmptyValue(editPriceBidded)) {
      setTotalPrice('');
      setTotalPriceInclGst('');
      return;
    }

    calculateQuotationTotalPrice({
      priceBidded: editPriceBidded,
      markupPercent: editMarkupPercent,
    }).then(data => {
      const totalPrice = data.totalPrice;
      setTotalPrice(totalPrice);
      const totalPriceInclFactoremGst = totalPrice * (1 + DEFAULT_FACTOREM_GST);
      setTotalPriceInclGst(totalPriceInclFactoremGst.toFixed(2));
      let unitQuote = new Decimal(totalPrice).dividedBy(editQuantity).toFixed();
      unitQuote = handleDigitDecimalUtil(unitQuote)
      setEditUnitQuote(unitQuote)
    });
  }, [editPriceBidded, editMarkupPercent, editDeliveryFee]);

  useEffect(() => {
    if (
      !isVerifyQuote
      || isEmptyValue(order)
      || order.status !== ORDER_STATUS.VERIFYING
      || isEmptyValue(ppeQuotes)
    ) {
      return;
    }
    const supplierList = ppeQuotes.map(quote => quote.supplierID);
    if (!supplierList.includes(order.userID)) {
      setEditUserID(null);
    }
  }, [ppeQuotes, order]);

  useEffect(() => {
    if (!isEmptyValue(ppeQuotes)) {
      const supplierQuote = ppeQuotes.find(item => item.supplierID === editUserID);
      if (supplierQuote) {
        const { remarks } = supplierQuote;
        setEditRemarks(remarks || '');
      } else {
        setEditRemarks('');
      }
    }
  }, [editUserID]);

  const handleChangeUnitQuote = (unitQuote) => {
    const totalPrice = new Decimal(unitQuote).times(editQuantity).toFixed(2);
    let markupPercent = new Decimal(totalPrice).minus(editPriceBidded).dividedBy(editPriceBidded).times(100).toFixed()
    markupPercent = handleDigitDecimalUtil(markupPercent)
    unitQuote = handleDigitDecimalUtil(unitQuote, false);
    setEditUnitQuote(unitQuote)
    setEditMarkupPercent(markupPercent);
  }

  const handleChangeCmmPrice = (value) => {
    setEditedCmmPrice(value)
    const markupPercentDec = new Decimal(markupCmmPrice)
      .dividedBy(100)
      .plus(1)
      .toFixed();
    const cmmPriceCustomerDec = new Decimal(markupPercentDec).times(value).toFixed(2)
    setCmmPriceCustomer(cmmPriceCustomerDec)
  }

  const handleChangeMarkupCmmPrice = (value) => {
    setMarkupCmmPrice(value)
    const markupPercentDec = new Decimal(value)
      .dividedBy(100)
      .plus(1)
      .toFixed();
    const cmmPriceCustomerDec = new Decimal(markupPercentDec).times(editedCmmPrice).toFixed(2)
    setCmmPriceCustomer(cmmPriceCustomerDec)
  }

  const handleChangeCmmPriceCustomer = (value) => {
    setCmmPriceCustomer(value)
    const markupPercentDec = new Decimal(value)
      .minus(editedCmmPrice)
      .dividedBy(editedCmmPrice)
      .times(100)
      .toFixed(2);
    setMarkupCmmPrice(markupPercentDec)
  }

  const handleAssignQuoteToChange = (value) => {
    setEditUserID(value);
    const supplier = suppliers.find(sup => sup.userID === value);
    if (supplier && !isEmptyValue(supplier.supplierGstPercent)) {
      setEditGst(supplier.supplierGstPercent);
    }
    if (supplier && !isEmptyValue(supplier.supplierDiscountPercent)) {
      setEditPayPerSale(supplier.supplierDiscountPercent);
    }
  }

  const handleUpdatePpeDataPoint = (event) => {
    const oldPpeDataPoint = ppeDataPoint;
    const newPpeDataPoint = event.target.checked;
    setPpeDataPoint(newPpeDataPoint);
    const body = {
      ppeDataPoint: newPpeDataPoint,
    }
    return adminUpdateQuotation(editOrderId, body)
      .then(() => notifySuccess('Updated successfully'))
      .catch(() => {
        notifyError('Updated failed');
        setPpeDataPoint(oldPpeDataPoint);
      });
  }

  const handleUpdateBiDataPoint = (event) => {
    const oldBiDataPoint = biDataPoint;
    const newBiDataPoint = event.target.checked;
    setBiDataPoint(newBiDataPoint);
    const body = {
      biDataPoint: newBiDataPoint,
    }
    return adminUpdateQuotation(editOrderId, body)
      .then(() => notifySuccess('Updated successfully'))
      .catch(() => {
        notifyError('Updated failed');
        setPpeDataPoint(oldBiDataPoint);
      });
  }

  const selectedOption = suppliers.find(
    (option) => option.userID === editUserID
  );

  const renderAssignQuoteTo = () => {
    const hasPermission = hasReviewPermission(role) || role === ROLE_TYPES.SALES_CONSULTANT;

    if (!hasPermission
      || isEmptyValue(suppliers)
    ) {
      return null;
    }

    return (
      <FormControl style={{ marginTop: '0.9rem' }}>
        <Typography>Assign Quote to</Typography>
        <Select
          select
          variant='outlined'
          margin='dense'
          id='assign-quote-to'
          data-cy='assign-quote-to'
          labelId='assign-quote-to'
          value={editUserID}
          ref={supplierFieldRef}
          error={formError?.supplier}
          onFocus={() => setFormError({})}
          renderValue={(selectedKey) => {
            if (!selectedKey) {
              return null;
            }
            if (selectedOption) {
              return `${selectedOption.name} (${selectedOption.email})`;
            }
          }}
        >
          <SearchInputDropdown
            key='select-project'
            id='select-project'
            inputRef={searchSupplierRef}
            setFilterText={setFilterText}
            filterText={filterText}
          />
          {!isVerifyQuote ? (
            suppliers.map((supplier) => (
              <div key={supplier.userID}>
                <MenuItem
                  value={supplier.userID}
                  onClick={() => handleAssignQuoteToChange(supplier.userID)}
                  data-cy='supplier-item'
                >
                  {supplier.name} ({supplier.email})
                  {supplier.quoteCreatedAt
                    ? ` Accepted at: ${supplier.quoteCreatedAt}`
                    : ''}
                </MenuItem>
              </div>
            ))
          ) : order.ppeQuotes ? (
            suppliers.map((supplier) => (
              <div key={supplier.userID}>
                <MenuItem
                  value={supplier.userID}
                  onClick={() => handleAssignQuoteToChange(supplier.userID)}
                  data-cy='supplier-item'
                >
                  {supplier.name} ({supplier.email})
                  {supplier.quoteCreatedAt
                    ? ` Accepted at: ${supplier.quoteCreatedAt}`
                    : ''}
                </MenuItem>
              </div>
            ))
          ) : (
            <MenuItem
              value={selectedOption.userID}
              onClick={() => handleAssignQuoteToChange(selectedOption.userID)}
              data-cy='supplier-item'
            >
              {selectedOption.name} ({selectedOption.email}) Created At: {formatDateWithTime(dateTzSingapore(order.dateOfOrder))}
            </MenuItem>
          )}
        </Select>
        {formError?.supplier && (
          <span style={{ color: colors.errorRed, fontSize: '0.75rem' }}>
            {formError.supplier}
          </span>
        )}
      </FormControl>
    );
  }

  if (isEmptyValue(order) || isEmptyValue(itemQuotation)) {
    return <div />;
  }

  const render3DPrintingTechnologySelect = () => {
    return (
      <div className={classes.rowContainer}>
        <ThreeDPrintingTechnologyInputField
          visible={is3DPTechnology(technology)}
          value={threeDTechnology}
          onChange={(newValue) => {
            setThreeDTechnology(newValue);
            setFormError({});
            const params = {
              technology,
              threeDTechnology: newValue,
            }
            threeDTechnologyHasChanged(params);
            if (newValue === THREE_D_P_FDM_TECH) {
              setThreeDInfill(threeDPrintingInfillDefault);
              setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
            } else {
              setThreeDInfill('');
              setThreeDLayerThickness('');
            }
          }}
          // error={formError.threeDTechnology}
          threeDTechnologyOptions={threeDTechnologyOptions}
          bootstrapStyle={false}
        />
        {isVerifyQuote && (
          <InfoMatchValue
            name='3D Printing Technology'
            valueCustomer={itemQuotation.metadata.threeDTechnology}
            valueSupplier={threeDTechnology}
          />
        )}
      </div>
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmptyValue(materialColorOptions)}
        value={materialColor}
        colorInputLabel='Material Color'
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) => setMaterialColor(selectedColor || materialColor)}
        bootstrapStyle={false}
        valueCustomer={itemQuotation.materialColor}
        isVerifyQuote={isVerifyQuote}
      />
    );
  }

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmptyValue(surfaceFinishColorOptions)}
        value={color}
        valueCustomer={itemQuotation.color}
        colorInputLabel={'Color'}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => setColor(selectedColor || color)}
        bootstrapStyle={false}
        isVerifyQuote={isVerifyQuote}
      />
    );
  }

  const renderPriceBiddedField = (disabled = false) => {
    return (
      <Tooltip title={order.rfqQuote ? '' : `Unit quote for supplier: S$ ${(editPriceBidded / editQuantity).toFixed(2)}`} arrow>
        <TextField
          disabled={disabled || disablePriceChange || role === ROLE_TYPES.SALES_CONSULTANT}
          label='Price bidded'
          variant='outlined'
          onChange={evt => setEditPriceBidded(evt.target.value)}
          onFocus={() => setFormError({})}
          value={editPriceBidded}
          error={formError.editPriceBidded}
          helperText={formError.editPriceBidded}
          margin='dense'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>S$</InputAdornment>
            )
          }}
          fullWidth
        />
      </Tooltip>
    );
  }

  const renderPriceFeedbackInfo = () => {
    if (isEmptyValue(order?.priceFeedback)) {
      return null;
    }

    const {
      isAdminRevisedLatestFeedback,
      adminFeedbackSorted,
      adminRevisedLatestFeedback,
      supplierFeedbackSorted,
      supplierLatestFeedback,
    } = order.priceFeedback;

    const isAdminValueInTolerance = isAdminRevisedLatestFeedback && inToleranceNumber(adminRevisedLatestFeedback?.targetPrice * editQuantity, Number(editPriceBidded));
    const isSupplierValueInTolerance = !isAdminRevisedLatestFeedback && inToleranceNumber(supplierLatestFeedback?.unitPrice * editQuantity, Number(editPriceBidded));

    const invalidFeedbackPriceValue = isAdminValueInTolerance || isSupplierValueInTolerance;

    const handleUpsertPriceFeedbackApi = () => {
      if (invalidFeedbackPriceValue) {
        notifyError('The target unit price cannot be the same as the unit price for supplier!')
        return;
      }
      if (isEmptyValue(supplierLatestFeedback)) {
        notifyError('No supplier feedback found!');
        return;
      }
      upsertPriceFeedback({
        iqLogsID: supplierLatestFeedback?.iqLogsID,
        userID: loggedInUser?.userID,
        targetPrice: new Decimal(editPriceBidded).dividedBy(editQuantity).toFixed(2),
        feedbackDetails: { [ADMIN_FEEDBACK.REVISED_PRICE]: true },
        unitPrice: new Decimal(order.priceBidded).dividedBy(editQuantity).toFixed(2),
        quotationID: order.quotationID,
        sendRevisedPriceEmail: true,
        priceFeedback: FEEDBACK_STATUS.REVISED,
      }).then(() => {
        handleConfirmSubmit();
        setTimeout(() => {
          refetchQuotation(); // refetchQuotation after update it
        }, 3000);
      })
    }

    const displayInfo = (data, textDisplay = 'Admin Feedback:') => {
      if (isEmptyValue(data)) {
        return null;
      }
      return (
        <div>
          <div>{textDisplay}</div>
          <ul style={{ paddingLeft: '1rem', margin: 0 }}>
            {data?.map((feedback) => {
              const feedbackDetailsText = Object.entries(
                feedback.feedbackDetails || {}
              )
                .filter(([_key, value]) => value === true)
                .map(([key]) => key)
                .join(' | ');
              const targetPriceText = feedback.feedback === FEEDBACK_STATUS.REVISED ? 'Revised' : 'Target';
              return (
                <li
                  key={feedback.createdAt + feedback.userID + feedback.iqLogsID}
                >
                  {dateTzSingapore(feedback.createdAt)}{feedback.feedback && ` - Feedback: ${feedback.feedback}`} - User ID: {feedback.userID}
                  <ul style={{ paddingLeft: '1rem' }}>
                    <li>
                      Unit Price: S$ {feedback.unitPrice?.toFixed(2)} - Price Bidded: S$ {(editQuantity * feedback.unitPrice)?.toFixed(2)}
                    </li>
                    <li>
                      {targetPriceText} Unit Price: S$ {feedback.targetPrice?.toFixed(2)} - {targetPriceText} Price Bidded: S$ {(editQuantity * feedback.targetPrice)?.toFixed(2)}
                    </li>
                    {feedbackDetailsText && (
                      <li>Details: {feedbackDetailsText}</li>
                    )}
                    {feedback.additionalRemarks && (
                      <li>Additional Remarks: {feedback.additionalRemarks}</li>
                    )}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    let tooltipTuneIcon = isAdminRevisedLatestFeedback
      ? `Admin has revised the unit price from S$ ${(adminRevisedLatestFeedback.unitPrice)?.toFixed(2)} to S$ ${adminRevisedLatestFeedback.targetPrice?.toFixed(2)} and sent it to supplier.
        ${!invalidFeedbackPriceValue ? 'Click here if you want to save and send the new revised price to supplier!' : ''} `
      : 'Click here to save and send the new revised price to supplier!';

    if (invalidFeedbackPriceValue && !isAdminRevisedLatestFeedback) {
      tooltipTuneIcon = 'The targetPrice cannot be the same as the unit price for supplier!'
    }
    if (isEmptyValue(supplierLatestFeedback)) {
      tooltipTuneIcon += ' No supplier feedback found!'
    }
    return (
      <FlexRow style={{ gap: 0 }}>
        <Tooltip title={tooltipTuneIcon} arrow>
          <IconButton onClick={handleUpsertPriceFeedbackApi}>
            <TuneIcon
              style={{
                color:
                  (invalidFeedbackPriceValue && !isAdminRevisedLatestFeedback) || isEmptyValue(supplierLatestFeedback)
                    ? colors.neutral060
                    : colors.blue050,
                cursor: invalidFeedbackPriceValue || isEmptyValue(supplierLatestFeedback) ? 'not-allowed' : 'pointer',
              }}
            />
          </IconButton>
        </Tooltip>
        <InfoIcon
          toolTipText={
            <FlexColumn style={{ gap: '5px' }}>
              {displayInfo(adminFeedbackSorted, 'Admin Feedback:')}
              {displayInfo(supplierFeedbackSorted, 'Supplier Feedback:')}
            </FlexColumn>
          }
        />
      </FlexRow>
    );
  };

  const renderRevisedPriceBiddedField = () => {
    return (
      <TextField
        label='Revised Price Bidded'
        variant='outlined'
        onChange={evt => setRevisedPriceBidded(evt.target.value)}
        onFocus={() => setFormError({})}
        value={revisedPriceBidded}
        error={formError.revisedPriceBidded}
        helperText={formError.revisedPriceBidded}
        margin='dense'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>S$</InputAdornment>
          )
        }}
        fullWidth
      />
    );
  }

  const renderTotalPriceField = () => {
    return (
      <TextField
        label='Total price'
        type='number'
        disabled
        variant='outlined'
        value={editedTotalPrice}
        margin='dense'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>S$</InputAdornment>
          )
        }}
      />
    );
  }

  const renderRevisedTotalPriceField = () => {
    return (
      <TextField
        label='Revised Total Price'
        variant='outlined'
        onChange={evt => setRevisedTotalPrice(evt.target.value)}
        onFocus={() => setFormError({})}
        value={revisedTotalPrice}
        error={formError.revisedTotalPrice}
        helperText={formError.revisedTotalPrice}
        margin='dense'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>S$</InputAdornment>
          )
        }}
        fullWidth
      />
    );
  }

  const renderMarkupPercentageField = (disabled = false) => {
    return (
      <TextField
        disabled={disabled || disablePriceChange || role === ROLE_TYPES.SALES_CONSULTANT}
        label='Markup percentage'
        variant='outlined'
        type='number'
        onWheel={(e) => e.target.blur()}
        onChange={evt => setEditMarkupPercent(evt.target.value)}
        value={editMarkupPercent ? Number(editMarkupPercent).toFixed(2) : editMarkupPercent}
        margin='dense'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>%</InputAdornment>
          )
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
    );
  }

  const renderRevisedMarkupPercentageField = () => {
    const totalPrice = revisedTotalPrice || editedTotalPrice;
    const priceBidded = revisedPriceBidded || editPriceBidded;
    const revisedMarkup = ((totalPrice - priceBidded) / priceBidded * 100).toFixed(2);

    return (
      <TextField
        disabled
        label='Revised Markup percentage'
        variant='outlined'
        type='number'
        value={revisedMarkup}
        margin='dense'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>%</InputAdornment>
          )
        }}
        fullWidth
      />
    );
  }

  const renderPricingFeedbackDetails = () => {
    return (
      <FlexColumn>
        <FlexRow>
          {renderPriceBiddedField(true)}
          {renderRevisedPriceBiddedField()}
        </FlexRow>
        <FlexRow>
          {renderMarkupPercentageField(true)}
          {renderRevisedMarkupPercentageField()}
        </FlexRow>
        <FlexRow>
          {renderTotalPriceField()}
          {renderRevisedTotalPriceField()}
        </FlexRow>
      </FlexColumn>
    )
  }

  const renderPricingFeedbackAccordion = () => {
    return (
      <div style={{ margin: '1.5rem 0' }}>
        <FtrAccordion
          accordionKey={'pricing-feedback'}
          header='Pricing Feedback'
          details={renderPricingFeedbackDetails()}
        />
      </div>
    );
  }

  const renderDeliveryFee = () => {
    const weight = parseFloat(itemQuotation?.ppeInformation?.weight) || 1;
    const totalWeight = new Decimal(weight)
      .dividedBy(itemQuotation.quantity)
      .times(Number(editQuantity))
      .toNumber()

    const DEFAULT_DELIVERY_FEES = [0, defaultNonSgpDeliveryFee?.value];

    const showError =
      totalWeight > MAX_TOTAL_WEIGHT_FEDEX_IN_KG &&
      DEFAULT_DELIVERY_FEES.includes(Number(editDeliveryFee)) &&
      itemQuotation.country !== COUNTRY_NAMES.SINGAPORE;

    return (
      <TextField
        disabled={disablePriceChange || role === ROLE_TYPES.SALES_CONSULTANT}
        label='Delivery Fee'
        variant='outlined'
        onChange={(evt) => setEditDeliveryFee(evt.target.value)}
        value={editDeliveryFee}
        margin='dense'
        InputProps={{
          startAdornment: <InputAdornment position='start'>S$</InputAdornment>,
        }}
        error={showError}
        helperText={
          showError
            ? `Please update the delivery fee, as the international shipping fee is invalid. The maximum allowed weight is 998 kg, but the current total weight is ${totalWeight} kg`
            : ''
        }
      />
    );
  };

  return (
    <div className={classes.body}>
      <Paper
        elevation={isDialog ? 0 : 1}
        variant={isDialog ? 'elevation' : 'outlined'}
        style={{ padding: isDialog ? 0 : '70px' }}
      >
        <div className={classes.container}>
          <div style={{ width: '100%', marginRight: isDialog ? 0 : '70px' }}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <div> Project ID: {projectID}</div>
                  <div> Part ID: {order.itemID}</div>
                  <Typography variant='h4'>
                    Quotation ID: {order.quotationID}
                  </Typography>
                  {isAdminOrHigherRole(role) && (
                    <div>Supplier name:{` ${supplierName}`}</div>
                  )}
                </div>
                {renderAdminDataControlArea()}
              </div>
              <div className={classes.property}>
                <TextField
                  label='Order name'
                  variant='outlined'
                  onChange={evt => setEditName(evt.target.value)}
                  value={editName}
                  margin='dense'
                />
                <TextField
                  id='standard-select-status'
                  select
                  label='Status'
                  value={editStatus || orderStatus[0].value}
                  onChange={evt => setEditStatus(evt.target.value)}
                  fullWidth={true}
                  variant='outlined'
                  margin='dense'
                >
                  {orderStatus.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <div className={classes.rowContainer}>
                  <TextField
                    label='Quantity'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    onChange={evt => setEditQuantity(evt.target.value)}
                    value={editQuantity}
                    margin='dense'
                    disabled={isVerifyQuote}
                  />
                  {isVerifyQuote && (
                    <InfoMatchValue
                      name='Quantity'
                      valueCustomer={itemQuotation.quantity}
                      valueSupplier={editQuantity}
                    />
                  )}
                </div>
                <TextField
                  label='Lead time'
                  variant='outlined'
                  onChange={evt => setEditLeadTime(evt.target.value)}
                  value={editLeadTime}
                  margin='dense'
                />
                {role === ROLE_TYPES.REVIEWER ? (
                  <div />
                ) : (
                  <TextField
                    label='Markup lead time'
                    variant='outlined'
                    onChange={evt => setEditMarkupLeadTime(evt.target.value)}
                    value={editMarkupLeadTime}
                    margin='dense'
                  />
                )}
                <TextField
                  label='Technology'
                  variant='outlined'
                  value={technology}
                  margin='dense'
                  disabled
                />
                {is3DPTechnology(technology) && render3DPrintingTechnologySelect()}
                <div className={classes.rowContainer}>
                  <MaterialCategoriesInputField
                    visible={!is3DPTechnology(technology)}
                    value={material}
                    onSelect={(value) => {
                      setMaterial(value);
                      const params = {
                        technology,
                        threeDTechnology,
                        material: value,
                      }
                      materialHasChanged(params);
                    }}
                    materialCategoryOptions={materialCategoryOptions}
                    bootstrapStyle={false}
                  />
                  <ThreeDPrintingMaterialField
                    visible={is3DPTechnology(technology)}
                    value={material}
                    onSelect={(_material) => {
                      setMaterial(_material);
                      const params = {
                        technology,
                        threeDTechnology,
                        material: _material,
                      }
                      materialHasChanged(params);
                    }}
                    threeDMaterialOptions={threeDMaterialOptions}
                    defaultThreeDMaterial={defaultThreeDMaterial}
                    bootstrapStyle={false}
                  />
                  {isVerifyQuote && (
                    <InfoMatchValue
                      name='Material'
                      valueCustomer={itemQuotation.material}
                      valueSupplier={material}
                    />
                  )}
                </div>
                {isCustomMaterial(material) && (
                  <div ref={otherMaterialFieldRef}>
                    <OtherMaterialField
                      value={otherMaterial}
                      error={formError.otherMaterial}
                      onChange={evt => setOtherMaterial(evt.target.value)}
                      onFocus={() => setFormError({})}
                    />
                  </div>
                )}
                {!isEmptyValue(materialColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderMaterialColorInputField()}
                  </div>
                )}
                <SurfaceFinishingField
                  visible={!isEmptyValue(surfaceFinishOptions) || isCustomSurfaceFinish(surfaceFinish) || surfaceFinish}
                  value={surfaceFinish}
                  onSelect={(newValue) => {
                    setSurfaceFinish(newValue);
                    const params = {
                      technology,
                      material,
                      surfaceFinish: newValue,
                    }
                    surfaceFinishHasChanged(params);
                  }}
                  surfaceFinishOptions={surfaceFinishOptions}
                  selectColorSurfaces={selectColorSurfaces}
                  bootstrapStyle={false}
                  valueCustomer={itemQuotation.surfaceFinish}
                  isVerifyQuote={isVerifyQuote}
                />
                {isCustomSurfaceFinish(surfaceFinish) && (
                  <div ref={otherSurfaceFinishFieldRef}>
                    <OtherSurfaceFinishingField
                      value={otherSurfaceFinish}
                      error={formError.otherSurfaceFinish}
                      onChange={evt => setOtherSurfaceFinish(evt.target.value)}
                      onFocus={() => setFormError({})}
                    />
                  </div>
                )}
                {!isEmptyValue(surfaceFinishColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderSurfaceFinishColor()}
                  </div>
                )}
                {isAnodizingSurfaceFinish(surfaceFinish) && (
                  <div className={classes.sectionFormField}>
                    <FtrDropdownV2
                      id='anodizing-type-dropdown'
                      key='anodizing-type-dropdown'
                      fullWidth
                      value={anodizingType || ANODIZING_TYPE_OPTIONS[0].key}
                      handleChange={(newType) => setAnodizingType(newType)}
                      items={ANODIZING_TYPE_OPTIONS}
                    />
                  </div>
                )}
                {/* {technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && (
                  <ThreeDPrintingTechSelectField
                    value={threeDTechnology}
                    onChange={evt => setThreeDTechnology(evt.target.value)}
                  />
                )} */}
                {threeDTechnology === THREE_D_P_FDM_TECH && (
                  <div className={classes.rowContainer}>
                    <ThreeDInfillFieldSelect
                      value={threeDInfill}
                      onChange={(event) => setThreeDInfill(event.target.value)}
                    />
                    {isVerifyQuote && (
                      <InfoMatchValue
                        name='3D Infill'
                        valueCustomer={itemQuotation.metadata.threeDInfill}
                        valueSupplier={threeDInfill}
                      />
                    )}
                  </div>
                )}
                {threeDTechnology === THREE_D_P_FDM_TECH && (
                  <div className={classes.rowContainer}>
                    <ThreeDLayerThicknessFieldSelect
                      value={threeDLayerThickness}
                      onChange={event => setThreeDLayerThickness(event.target.value)}
                    />
                    {isVerifyQuote && (
                      <InfoMatchValue
                        name='3D Layer Thickness'
                        valueCustomer={itemQuotation.metadata.threeDLayerThickness}
                        valueSupplier={threeDLayerThickness}
                      />
                    )}
                  </div>
                )}
                <DatePicker
                  label={'Quote valid till:'}
                  value={editDateOfExpiry}
                  onChange={setEditDateOfExpiry}
                  animateYearScrolling
                  inputVariant='outlined'
                  margin='dense'
                  clearable
                  clearLabel='No Preference'
                />
                <TextField
                  style={{ marginBottom: 20 }}
                  label='Customer remarks'
                  variant='outlined'
                  multiline={true}
                  minRows={6}
                  maxRows={6}
                  onChange={evt => setEditCustomerRemarks(evt.target.value)}
                  value={editCustomerRemarks}
                  margin='dense'
                />
                <Divider />
                <FlexRow style={{ marginTop: 20 }}>
                  <div
                    ref={editPriceBiddedFieldRef}
                    style={{ width: '100%' }}
                  >
                    {renderPriceBiddedField()}
                  </div>
                  {renderPriceFeedbackInfo()}
                </FlexRow>
                {role === ROLE_TYPES.REVIEWER
                  ? (
                    <div />
                  )
                  : renderMarkupPercentageField()
                }
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={role === ROLE_TYPES.SALES_CONSULTANT ? 12 : 6}>
                    <TextField
                      disabled={disablePriceChange}
                      label='Supplier GST'
                      variant='outlined'
                      onChange={evt => setEditGst(evt.target.value)}
                      value={editGst}
                      fullWidth
                      margin='dense'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>%</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {renderPayPerSaleProgramField()}
                  </Grid>
                </Grid>
                {renderDeliveryFee()}
                {order?.qcReports?.main === QC_FORMAL_CMM_REPORT && (
                  <React.Fragment>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          disabled={disablePriceChange}
                          type='number'
                          label='CMM Price'
                          variant='outlined'
                          onChange={evt => handleChangeCmmPrice(evt.target.value)}
                          value={editedCmmPrice}
                          margin='dense'
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>S$</InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled={disablePriceChange}
                          label='Markup for CMM Price'
                          type='number'
                          variant='outlined'
                          onChange={evt => handleChangeMarkupCmmPrice(evt.target.value)}
                          value={markupCmmPrice}
                          margin='dense'
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>%</InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      disabled={disablePriceChange}
                      label='CMM Price for Customer'
                      type='number'
                      variant='outlined'
                      onChange={evt => handleChangeCmmPriceCustomer(evt.target.value)}
                      value={cmmPriceCustomer}
                      margin='dense'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>S$</InputAdornment>
                        )
                      }}
                    />
                  </React.Fragment>
                )}
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={4}>
                    <TextField
                      disabled={disablePriceChange || role === ROLE_TYPES.SALES_CONSULTANT}
                      label='Unit Quote'
                      className={countDecimalsUtil(editUnitQuote) < 3 ? classes.greenText : classes.redText}
                      variant='outlined'
                      value={editUnitQuote ?? 0}
                      margin='dense'
                      onChange={evt => handleChangeUnitQuote(evt.target.value)}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>S$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      label='Target Unit Price'
                      variant='outlined'
                      value={[null, 0].includes(itemQuotation.expectedPrice) ? 'N/A' : itemQuotation.expectedPrice}
                      margin='dense'
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>S$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      label='Quantity'
                      variant='outlined'
                      value={editQuantity}
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={4}>
                    {renderTotalPriceField()}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label='Service fee'
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      disabled={role !== ROLE_TYPES.SUPER_ADMIN}
                      variant='outlined'
                      value={platformFee}
                      onChange={evt => setPlatformFee(evt.target.valueAsNumber)}
                      margin='dense'
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>S$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label='Total price (incl. Service fee)'
                      type='number'
                      disabled
                      variant='outlined'
                      value={totalPriceIncPlatformFee.toFixed(2)}
                      margin='dense'
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>S$</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={10}>
                    <TextField
                      style={{ marginBottom: 20 }}
                      label='Total price (incl. Factorem 9% GST)'
                      type='number'
                      disabled
                      variant='filled'
                      value={editedTotalPriceInclGst}
                      margin='dense'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>S$</InputAdornment>
                        )
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}
                    style={{
                      display: 'flex',
                      paddingTop: '0.3rem',
                    }}
                  >
                    <Box style={{ width: '0.2rem' }} />
                    <InfoIcon toolTipText='Price with GST only applicable to customers in Singapore' />
                  </Grid>
                </Grid>
                <Divider />
                {renderPricingFeedbackAccordion()}
                <Divider />
                {renderAssignQuoteTo()}
                <TextField
                  style={{ marginBottom: 15 }}
                  label='Supplier remarks'
                  variant='outlined'
                  multiline={true}
                  rows={6}
                  rowsMax={6}
                  onChange={evt => setEditRemarks(evt.target.value)}
                  value={editRemarks}
                  margin='dense'
                />
                {!isVerifyQuote ? (
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    fullWidth
                    onClick={e => handleSubmit(e)}
                    disabled={disableSubmitButton}
                  >
                    Confirm changes for order
                  </Button>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant='contained' onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#4caf50',
                        marginLeft: '10px',
                      }}
                      variant='contained'
                      color='primary'
                      onClick={(e) => handleVerifyButtonClick(e)}
                      disabled={disableVerifyButton}
                      data-cy='verify-quote-btn'
                    >
                      Verify quote
                    </Button>
                  </div>
                )}
              </div>
              {hasReviewPermission(role) ? (
                <div
                  style={{
                    marginTop: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    startIcon={<DeleteIcon />}
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    onClick={() => onDeleteQuotePopup({ deletedOrderId: order.quotationID, history: props.history })}
                  >
                    Delete Quote
                  </Button>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </Paper>
      <Dialog
        open={warningDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby='warning-dialog'
      >
        <DialogTitle id='warning-dialog-title'>
          Existing accepted quotation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='warning-dialog-content'>
            {`You are about to change the status of quote ${editOrderId} to ACCEPTED. Doing so will reject the previously accepted quote ${acceptedOrder &&
              acceptedOrder.quotationID}.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialog(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmSubmit} color='primary'>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={poWarningDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby='warning-dialog-po'
      >
        <DialogTitle id='warning-dialog-title-po'>
          Changing properties that affects PO
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='warning-dialog-content-po'>
            {`You are about to change one or more properties of quote ${editOrderId} that will affect PO when it is regenerated.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPoWarningDialog(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => {
            setPoWarningDialog(false)
            submitEditOrder(getEditedOrder(true))
          }}
            color='primary'>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function renderAdminDataControlArea() {
    if (!dataPointLoaded || role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }

    return (
      <div>
        <div className={classes.row}>
          <Typography>
            PPE Data Point
          </Typography>
          <IOSSwitch
            checked={ppeDataPoint}
            onChange={handleUpdatePpeDataPoint}
            name='ppe-data-point'
            inputProps={{ 'aria-label': 'PPE Data Point' }}
            disabled={role !== ROLE_TYPES.SUPER_ADMIN} />
        </div>
        <div className={classes.row}>
          <Typography>
            BI Data Point
          </Typography>
          <IOSSwitch
            checked={biDataPoint}
            onChange={handleUpdateBiDataPoint}
            name='bi-data-point'
            inputProps={{ 'aria-label': 'BI Data Point' }}
            className={classes.switchBlue}
            disabled={!isAdminOrHigherRole(role)} />
        </div>
      </div>
    );
  }

  function renderPayPerSaleProgramField() {
    if (role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }

    return (
      <TextField
        label='Pay-Per-Sale Program'
        variant='outlined'
        onChange={evt => setEditPayPerSale(evt.target.value)}
        value={editPayPerSale}
        fullWidth
        margin='dense'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>%</InputAdornment>
          )
        }} />
    );
  }
}

export default compose(
  withRouter,
  withDeleteQuotePopupHOC
)(EditOrder);
