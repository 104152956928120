import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import GuestLinkToPage from '../../components/GuestLinkToPage';

import { getFilteredLocationSearch } from '../../utils/navigationUtils';

import { GUEST_PERMISSIONS } from '../../constants/authConstants';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../selectors/userSelector';

const GuestLinkToPageORM = ({ supplierID }) => {
  const location = useLocation();

  const { role: userRole } = useSelector(getUserSelector);

  const removeLocationSearchKeys = isAdminOrHigherRole(userRole)
    ? ['supplierID']
    : [];

  const filteredLocationSearch = getFilteredLocationSearch(
    location.search,
    removeLocationSearchKeys
  );
  const navigateTo = `${location.pathname}${
    filteredLocationSearch ? '?' : ''
  }${filteredLocationSearch}`;

  return (
    <GuestLinkToPage
      generateTokenParams={{
        permissions: [GUEST_PERMISSIONS.ORM],
        userIDToCreateFor: isAdminOrHigherRole(userRole) ? supplierID : null,
        navigateTo,
      }}
    />
  );
};

export default GuestLinkToPageORM;
