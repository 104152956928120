import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
  getAllUsers,
  getItemDetailsForSupplier,
  sendNewQuote,
} from "../actions";

import { CircularProgress, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";

import SupplierSubmitQuotationPopup from "../components/popups/SupplierSubmitQuotationPopup";
import ItemLabel from "../components/labels/ItemLabel";
import QuoteSubmitLabel from "../components/labels/QuoteSubmitLabel";
import PpeQuotationForm from "../components/forms/PpeQuotationForm";
import QuotationForm from "../components/forms/QuotationForm";
import { quotePpeItem } from "../apis/itemApi";
import { notifyError, notifySuccess } from "../services/notificationService";
import { ITEM_STAGE_TYPE, ROCKET_QUOTE_ACCEPT_TYPE } from "../constants";
import { getDefaultExpiredDate } from "../utils/dateTimeUtils";
import { QUANTITY_STR, UNIT_QUOTE_STR } from "../constants/quotationConstants";

import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
} from "../constants/NewPartConstants";

import { isEmptyValue } from "../utils/commonUtils";
import { isCustomMaterial, isCustomSurfaceFinish } from "../utils/inputUtils";
import { useParams } from "react-router-dom";
import { isAdminOrHigherRole } from "../utils/roleUtils";
import { QC_FORMAL_CMM_REPORT } from "../constants/projectConstants";

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  quoteSubmitLabelWrapper: {
    margin: "0 10px"
  },
}));

/**
 * @deprecated
 * @param {*} props 
 * @returns 
 */
export function ItemForSupplier(props) {
  const classes = useStyles();

  const { itemID } = useParams();

  const {
    onPageLoad,
    getAllUsers,
    item,
    role,
    submitQuoteForm,
    myUserId,
    users,
    currency,
    exchangeRate,
    isItemLoading,
  } = props;

  const [displayForm, setDisplayForm] = useState(true);

  const suppliers = users.filter(
    user => user.role === "supplier" || user.userID === myUserId
  );

  const [inputFields, setInputFields] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [dateOfExpiry, setDateOfExpiry] = useState(getDefaultExpiredDate());
  const [userID, setUserID] = useState(myUserId);
  const [errors, setErrors] = useState([{}]);
  const [acceptQuoteDialog, setAcceptQuoteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const isPpeForm = item.price && (!item.rocketQuote || item.status === ITEM_STAGE_TYPE.ORDER_IN_PROGRESS);

  const inputFieldsRef = useRef(inputFields)

  useEffect(() => {
    onPageLoad(itemID, userID);
  }, [itemID, userID]);

  useEffect(() => {
    if (item) {
      setDisplayForm(!item.supplierQuoted);
    }
    if (role === "admin" || role === "reviewer" || role === "superadmin") {
      getAllUsers();
    }
  }, [item]);

  const initInputField = (item) => {
    const {
      material,
      customMaterial,
      surfaceFinish,
      customSurfaceFinish,
      materialColor,
      color,
      technology
    } = item;
    if (
      !item.price ||
      (item.rocketQuote && item.acceptQuoteType !== ROCKET_QUOTE_ACCEPT_TYPE.PPE)
    ) {
      setInputFields([
        {
          technology,
          quantity: item.quantity || "",
          quote: "",
          material,
          otherMaterial: customMaterial,
          unitQuote: "",
          leadTime: "",
          surfaceFinish,
          otherSurfaceFinish: customSurfaceFinish,
          materialColor,
          color,
          ...item.metadata,
          cmmPrice: ''
        }
      ]);
    } else {
      setInputFields([
        {
          technology,
          quantity: item.quantity,
          quote: item.price,
          material: item.material,
          unitQuote: item.price,
          leadTime: item.leadTime || "14",
          surfaceFinish: item.surfaceFinish,
          ...item.metadata,
          cmmPrice: ''
        }
      ]);
    }
  }

  useEffect(() => {
    if (isEmpty(item)) {
      return;
    }
    initInputField(item);
  }, [item]);

  useEffect(() => {
    inputFieldsRef.current = inputFields
  }, [inputFields])

  const supplierAcceptPPEQuote = async (userId, itemId, remarks) => {
    const body = {
      supplierID: userId,
      itemId,
      remarks: remarks,
    };
    return quotePpeItem(itemId, body)
      .then(() => {
        notifySuccess(`You have accepted a quotation!`);
        if (!isAdminOrHigherRole(role)) {
          props.history.push({ pathname: `/orders` });
        }
      })
      .catch((err) => {
        if (err.message === 'ER_DUP_ENTRY') {
          notifyError(`Quotation for this supplier and this item is existed.`);
        } else {
          notifyError(`Quotation(s) cannot be accepted. Please contact us.`);
        }
      });
  };

  const handleAddFields = () => {
    const {
      material,
      customMaterial,
      surfaceFinish,
      customSurfaceFinish,
      materialColor,
      color,
      technology,
    } = item;
    const values = [...inputFields];
    values.push({
      quantity: "",
      quote: "",
      material,
      otherMaterial: customMaterial,
      unitQuote: "",
      leadTime: "",
      surfaceFinish,
      customSurfaceFinish,
      materialColor,
      color,
      technology,
      ...item.metadata,
    });
    setInputFields(values);
    const errorsCopy = [...errors];
    errorsCopy.push({});
    setErrors(errorsCopy);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    if (values.length > 1) {
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFieldsRef.current];
    values[index][event.target.name] = event.target.value;
    if ([QUANTITY_STR, UNIT_QUOTE_STR].includes(event.target.name)) {
      values[index].quote = values[index].unitQuote * values[index].quantity;
    }
    setInputFields(values);
    const errorsCopy = [...errors];
    errorsCopy[index] = {};
    setErrors(errorsCopy);
  };

  const resetForm = () => {
    initInputField(item);
    setRemarks('');
    setDateOfExpiry(getDefaultExpiredDate());
    setUserID(myUserId);
    setErrors([{}]);
    setAcceptQuoteDialog(false);
  }

  const handleRfqQuoteSubmit = e => {
    setIsLoading(true);
    e.preventDefault();
    const problemMessage = "Missing input!";
    let hasError = false;
    let errorsCopy = [...errors];
    for (let i = 0; i < inputFields.length; i++) {
      if (inputFields[i].quantity === "") {
        errorsCopy[i].quantity = problemMessage;
        hasError = true;
      }
      if (inputFields[i].quantity <= 0) {
        errorsCopy[i].quantity = "Quantity must be more than 0!";
        hasError = true;
      }
      if (inputFields[i].unitQuote === "") {
        errorsCopy[i].unitQuote = problemMessage;
        hasError = true;
      }
      if (inputFields[i].material === "") {
        errorsCopy[i].material = problemMessage;
        hasError = true;
      }
      if (isCustomMaterial(inputFields[i].material) && isEmpty(inputFields[i].otherMaterial)) {
        errorsCopy[i].otherMaterial = problemMessage;
        hasError = true;
        break;
      }
      if (inputFields[i].surfaceFinish === "") {
        errorsCopy[i].surfaceFinish = problemMessage;
        hasError = true;
      }
      if (isCustomSurfaceFinish(inputFields[i].surfaceFinish) && isEmpty(inputFields[i].otherSurfaceFinish)) {
        errorsCopy[i].otherSurfaceFinish = problemMessage;
        hasError = true;
        break;
      }
      if (item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
        && inputFields[i].threeDTechnology === THREE_D_P_FDM_TECH
        && isEmptyValue(inputFields[i].threeDInfill)
      ) {
        errorsCopy[i].threeDInfill = problemMessage;
        hasError = true;
        break;
      }
      if (item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
        && inputFields[i].threeDTechnology === THREE_D_P_FDM_TECH
        && isEmptyValue(inputFields[i].threeDLayerThickness)
      ) {
        errorsCopy[i].threeDLayerThickness = problemMessage;
        hasError = true;
        break;
      }
      if (inputFields[i].leadTime === "") {
        errorsCopy[i].leadTime = problemMessage;
        hasError = true;
        break;
      }
      if (inputFields[i].cmmPrice === "" && isCmmReport()) {
        errorsCopy[i].cmmPrice = problemMessage;
        hasError = true;
        break;
      }
    }
    if (hasError) {
      setErrors(errorsCopy);
      setIsLoading(false);
      return;
    }
    submitQuoteForm({
      inputFields,
      remarks,
      dateOfExpiry,
      userID,
      currency,
      exchangeRate,
      rfqQuote: !isPpeForm,
    }).then(() => {
      resetForm();
    }).finally(() => setIsLoading(false));
  };

  const handleAcceptPpeQuote = () => {
    setAcceptQuoteDialog(true);
  };

  const handleCloseAcceptQuoteDialog = () => {
    setAcceptQuoteDialog(false);
  };

  const handleConfirmAcceptQuote = () => {
    supplierAcceptPPEQuote(userID, item.itemID, remarks);
    setAcceptQuoteDialog(false);
    setIsLoading(false);
  };

  const handleQuoteExpiryDateChange = newDate => {
    setDateOfExpiry(newDate);
  };

  const handleQuoteUserIDChange = newUserID => {
    setUserID(newUserID);
  };

  const handleRemarksChange = newRemarks => {
    setRemarks(newRemarks);
  };

  function isCmmReport() {
    return item.qcReports && item.qcReports.main === QC_FORMAL_CMM_REPORT
  }

  const renderPpeQuotationForm = () => {
    return (
      <PpeQuotationForm
        item={item}
        inputFields={inputFields}
        userID={userID}
        currency={currency}
        exchangeRate={exchangeRate}
        role={role}
        remarks={remarks}
        dateOfExpiry={dateOfExpiry}
        suppliers={suppliers}
        isCmmReport={isCmmReport()}
        onRemarksChange={handleRemarksChange}
        onQuoteDateChange={handleQuoteExpiryDateChange}
        onQuoteUserIdChange={handleQuoteUserIDChange}
        onAcceptQuote={handleAcceptPpeQuote}
      />
    );
  }

  const renderManualQuotationForm = () => {
    return (
      <QuotationForm
        inputFields={inputFields}
        isLoading={isLoading}
        userID={userID}
        currency={currency}
        remarks={remarks}
        role={role}
        suppliers={suppliers}
        errors={errors}
        dateOfExpiry={dateOfExpiry}
        isCmmReport={isCmmReport()}
        onAddFields={handleAddFields}
        onRemoveFields={handleRemoveFields}
        onInputFieldsChange={handleInputChange}
        onRemarksChange={handleRemarksChange}
        onQuoteDateChange={handleQuoteExpiryDateChange}
        onQuoteUserIdChange={handleQuoteUserIDChange}
        onSubmitQuote={handleRfqQuoteSubmit}
      />
    )
  }

  if (isItemLoading) {
    return <CircularProgress color="primary" className={classes.circularProgress} />
  }

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <div style={{ marginBottom: 30 }}>
          <ItemLabel
            item={item}
            role={role}
          />
        </div>
        <Divider style={{ marginBottom: 10 }} />
        {displayForm && (
          <div>
            {isPpeForm
              ? renderPpeQuotationForm()
              : renderManualQuotationForm()
            }
          </div>
        )}
        {!displayForm && (
          <div className={classes.quoteSubmitLabelWrapper}>
            <QuoteSubmitLabel />
          </div>
        )}
      </div>
      <SupplierSubmitQuotationPopup
        dialog={acceptQuoteDialog}
        item={item}
        remarks={remarks}
        dateOfExpiry={dateOfExpiry}
        onClose={() => handleCloseAcceptQuoteDialog()}
        onConfirmAcceptQuote={() => handleConfirmAcceptQuote()}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    item: state.item.item,
    isItemLoading: state.item.getItemLoading,
    role: state.auth.user.role,
    myorders: state.myorders.myorders,
    myUserId: state.auth.user.userID,
    users: state.users.users,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: (itemID, userID) => {
      dispatch(getItemDetailsForSupplier(itemID, userID));
    },
    getAllUsers: () => dispatch(getAllUsers()),
    submitQuoteForm: (newQuote) => dispatch(sendNewQuote(newQuote, props))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ItemForSupplier);
