import React from 'react';

import FtrTooltip from '../FtrTooltip';
import { FtrIconButton } from '../FtrButton';
import FtrSvgImage from '../../images/FtrSvgImage';

import DownloadIcon from '../../../assets/icons/download_icon.svg';

import withActionTrackingHOC from '../../../hocs/withActionTrackingHOC';


// --------------------------------------------------------------------------------------------

function DownloadCombinedQuotePDFButton(props) {
  const {
    loading = false,
    disabled = false,
    onClick = () => { },
  } = props;

  return (
    <FtrTooltip description='Download Quote (.PDF)'>
      <div>
        <FtrIconButton
          onClick={onClick}
          loading={loading}
          disabled={disabled}
        >
          <FtrSvgImage
            src={DownloadIcon}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </FtrIconButton>
      </div>
    </FtrTooltip>
  );
}

export default withActionTrackingHOC(DownloadCombinedQuotePDFButton);
