import queryString from 'query-string';
import { sortBy } from 'lodash';

import { createAlertNotification } from './alertApi';

import { editUserFailure, getAllUsers } from '../actions/users';

import { fetchWithErrorHandling, getAuthorizedHeader, witchCacheResponse } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL, ROLE_TYPES } from '../constants';


// -------------------------------------------------------------------------------------------------

const ONE_HOUR_IN_SECS = 60 * 60;

export const isEmailExists = async (email) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/email-exists?email=${email}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getAllUsersApi = async (params = {}) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getAllSuppliersApi = async () => {
  const allUsers = await getAllUsersApi();
  const allSuppliers = await allUsers.filter(user => user.role === ROLE_TYPES.SUPPLIER);
  return sortBy(allSuppliers, item => item.name);
}

export const getAllSuppliersGroupByTech = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/suppliers/group-by-tech`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getAllBuyers = async () => {
  const params = {
    roles: [ROLE_TYPES.BUYER],
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/users?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const verifyUser = async (userId) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/verify/${userId}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
  });
}

export const getAssociatedOwners = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/owners`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json().then(response => response.data.associatedOwners);
  });
}

export const addAssociatedOwner = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/owners`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};


export const deleteAssociatedOwner = async (userID, ownerEmail) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/owners`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ ownerEmail }),
  });
}

export const changeUserPassword = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/change-password`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const getUnAcknowledgeQuotationsCount = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/quotations/un-acknowledged-count`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: { count } } = await response.json();
    return count;
  });
}

export const addCustomerNDAFile = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/nda-files`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const adminVerifyNDA = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/verify-nda-files`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export const uploadPoTerms = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/upload-po-terms`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const uploadPartnershipAgreement = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/upload-partnership-agreement`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const uploadQuotationTerms = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/upload-quotation-terms`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const updateUserFromCompanyData = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/updateUserFromCompanyData`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const getUserInfo = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getUserInfoWithCache = witchCacheResponse(
  getUserInfo,
  ONE_HOUR_IN_SECS,
);

// country data collected from ip address using external api
export const getUserCountry = async (additionalInfo) => {
  try {
    const response = await fetch(`https://ipapi.co/json/`);
    if (!response?.ok || response?.status !== 200) {
      const error = Error(response?.statusText);
      throw {
        message: error?.message,
        stack: error?.stack,
        statusCode: response?.status,
      }
    }
    const data = await response.json();
    if (data?.error) {
      const error = Error(data?.message || 'Error with statusCode 200');
      const body = {
        title: '[WARNING] [FE] Get User IP Address Info Failed!',
        message: error?.message,
        errorStack: error?.stack,
        additionalInfo: {
          ...additionalInfo,
          ...data,
          statusCode: response?.status,
        }
      }
      createAlertNotification(body);
    }
    return data;
  } catch (error) {
    console.error('ipapi error:', error);
    // Rethrow the error to be caught in the useEffect
    throw error;
  }
}

export const getUserCreditType = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/credit-type`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getUserAddresses = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/addresses`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const editSupplierInfo = (newData) => (dispatch) => {
  const supplierID = newData.userID;
  const data = {
    qualifications: newData.qualifications,
  };
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/users/${supplierID}/edit-qualifications`;
  fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(editUserFailure());
      } else {
        dispatch(getAllUsers());
      }
    })
    .catch(() => {
      dispatch(editUserFailure());
    });
};

export const getSalesConsultantAssociatedAccountsApi = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/sales-consultant/${userID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getAvailableAssociateAccountsApi = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/sales-consultant/${userID}/available`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const updateSalesConsultantAssociatedAccountsApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/sales-consultant`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}
