import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles/index';

import Decimal from 'decimal.js';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { DatePicker } from '@material-ui/pickers';

import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import OtherMaterialField from '../forms/form-fields/OtherMaterialField';
import OtherSurfaceFinishingField from '../forms/form-fields/OtherSurfaceFinishingField';
import ThreeDInfillFieldSelect from '../forms/form-fields/ThreeDInfillFieldSelect';
import ThreeDLayerThicknessFieldSelect from '../forms/form-fields/ThreeDLayerThicknessFieldSelect';
import { FtrBanner, FtrTypography, FtrButton, FtrDropdownV2 } from '../ftr-components';
import InfoIcon from '../icons/InfoIcon';
import ColorInputField from '../inputs/ColorInputField';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import { FlexRow } from '../layouts/FlexLayouts';

import WarningIcon from '../icons/WarningIcon';

import { getPlatformFeeByTotalPrice } from '../../apis/platformFeeApi';
import { getQuotationByID } from '../../apis/quotationApi';

import formulas from '../../formulas/formulas';

import { useItemInputConfig } from '../../hooks/useItemInputConfig';

import { validatePriceParams } from '../../utils/validators/paramsValidator';
import { debounce, isEmptyValue } from '../../utils/commonUtils';
import { getDefaultExpiredDate } from '../../utils/dateTimeUtils';
import { isCustomMaterial, isCustomSurfaceFinish, isAnodizingSurfaceFinish} from '../../utils/inputUtils';
import { is3DPTechnology } from '../../utils/itemUtils';
import { countDecimalsUtil, handleDigitDecimalUtil, isNumber } from '../../utils/numberUtils';
import { getPpePriceForItem } from '../../utils/ppeUtils';
import { convertPriceWithQuantityToCurrency } from "../../utils/currencyUtils";

import { notifyOngoing, notifyError, notifyWarning, updateNotification, dismissNotification } from '../../services/notificationService';

import {
  DEFAULT_FACTOREM_GST,
  DEFAULT_LEAD_TIME,
  DEFAULT_MARKUP_LEAD_TIME,
  DEFAULT_MARKUP_PERCENT,
  DEFAULT_SUPPLIER_GST_PERCENT,
  ROLE_TYPES,
} from '../../constants';
import {
  THREE_D_P_FDM_TECH,
  THREE_D_P_OLD_SLA_VALUE_TECH,
  THREE_D_P_SLA_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
} from '../../constants/NewPartConstants';
import { QC_FORMAL_CMM_REPORT } from '../../constants/projectConstants';
import { COUNTRY_NAMES } from '../../constants/countryConstants';
import { ANODIZING_TYPE_OPTIONS } from '../../constants/itemConstants';

import { getUserInfo } from '../../apis/userApi';
import { colors } from '../../palette';


const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  greenText: {
    '& .MuiOutlinedInput-input': {
      color: colors.successGreen
    }
  },
  redText: {
    '& .MuiOutlinedInput-input': {
      color: colors.errorRed
    }
  },
  derivedPpeText: {
    '& .MuiOutlinedInput-input': {
      color: colors.blue050,
    },

    '&.warning': {
      '& .MuiOutlinedInput-input': {
        color: colors.warningYellow
      }
    }
  }
}));

function RocketQuotePopup(props) {
  const classes = useStyles();

  const {
    dialog,
    handleClose,
    item,
    handleRocketQuoteButtonClick,
    role,
    copyQuotation = false
  } = props;

  const { data: customerInfo } = useQuery(
    ['userInfo', item.userID],
    () => getUserInfo(item.userID),
    {
      enabled: !!item.userID,
      refetchOnWindowFocus: false,
    }
  );

  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const exchangeRate = exchangeRates[currency];

  const [quantity, setQuantity] = useState(1);
  const [priceBiddedPerUnit, setPriceBiddedPerUnit] = useState('');
  const [totalPriceBidded, setTotalPriceBidded] = useState('');
  const [markupPercent, setMarkupPercent] = useState(Number(DEFAULT_MARKUP_PERCENT * 100).toFixed(2));
  const [gst, _] = useState(DEFAULT_SUPPLIER_GST_PERCENT);
  const [deliveryFee, setDeliveryFee] = useState(20);
  const [totalPriceExclGst, setTotalPriceExclGst] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [supplierRemarks, setSupplierRemarks] = useState('');
  const [technology, setTechnology] = useState('');
  const [threeDTechnology, setThreeDTechnology] = useState('');
  const [threeDInfill, setThreeDInfill] = useState('');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState('');
  const [material, setMaterial] = useState('');
  const [otherMaterial, setOtherMaterial] = useState('');
  const [materialColor, setMaterialColor] = useState('');
  const [surfaceFinish, setSurfaceFinish] = useState('');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');
  const [anodizingType, setAnodizingType] = useState('');
  const [color, setColor] = useState("");
  const [leadTime, setLeadTime] = useState(DEFAULT_LEAD_TIME);
  const [markupLeadTime, setMarkupLeadTime] = useState(DEFAULT_MARKUP_LEAD_TIME);
  const [dateOfExpiry, setDateOfExpiry] = useState(getDefaultExpiredDate());
  const [paramError, setParamError] = useState({});
  const [formError, setFormError] = useState({});
  const [disableQuoteButton, setDisableQuoteButton] = useState(false);
  const [loadingQuoteButton, setLoadingQuoteButton] = useState(false);
  const [isCmmReport, setIsCmmReport] = useState(false)
  const [cmmPrice, setCmmPrice] = useState('')
  const [platformFee, setPlatformFee] = useState(0)
  const [totalPriceIncPlatformFee, setTotalPriceIncPlatformFee] = useState(0)
  const [unitPrice, setUnitPrice] = useState(0)
  const [derivedFromPpe, setDerivedFromPpe] = useState(false)
  const [loadingPpe, setLoadingPpe] = useState(false)
  const [leadTimeWarning, setLeadTimeWarning] = useState(false)
  const [markupLeadTimeWarning, setMarkupLeadTimeWarning] = useState(false)
  const [totalPriceWarning, setTotalPriceWarning] = useState(false)
  const [priceWarning, setPriceWarning] = useState(false)

  const [
    {
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setSurfaceFinishOptions,
    }
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const populateItemDetails = useCallback(async (itemDetails) => {
    const {
      technology,
      material,
      surfaceFinish,
      materialColor,
      color,
    } = itemDetails;

    loadSelectColorSurfaces({ technology });


    const metadata = itemDetails.metadata || {};
    const threeDTechnology = !is3DPTechnology(technology)
      ? null
      : metadata.threeDTechnology === THREE_D_P_OLD_SLA_VALUE_TECH
        ? THREE_D_P_SLA_TECH
        : metadata.threeDTechnology;

    const anodizingType = metadata?.anodizingType

    let materialOptions = [];
    if (!is3DPTechnology(technology)) {
      const { options: materialCategories } = await loadMaterialCategoryOptions({
        technology,
      }, false);
      materialOptions = materialCategories.flatMap(item => item.options);
    } else {
      load3DTechnologyOptions(false);
      const { options: threeDPMaterials } = await loadThreeDMaterialOptions({
        technology,
        threeDTechnology,
      }, false);
      materialOptions = threeDPMaterials;
    }

    if (isCustomMaterial(material) || !materialOptions.includes(material)) {
      setMaterial('Custom Material');
      setOtherMaterial(itemDetails.customMaterial || material);
    } else {
      setMaterial(material);
      setOtherMaterial(itemDetails.customMaterial);
    }

    const params = {
      technology,
      threeDTechnology,
      material,
    }
    const { options: allSurfaceOptions } = await loadSurfaceFinishOptions(params, false);

    if (isCustomSurfaceFinish(surfaceFinish) || (surfaceFinish && !(allSurfaceOptions || []).includes(surfaceFinish))) {
      if (surfaceFinish && isEmptyValue(allSurfaceOptions)) {
        setSurfaceFinishOptions(['Custom Finish']);
      }
      setSurfaceFinish('Custom Finish');
      setOtherSurfaceFinish(itemDetails.customSurfaceFinish || surfaceFinish);
    } else {
      setSurfaceFinish(surfaceFinish);
      setOtherSurfaceFinish(itemDetails.customSurfaceFinish);
    }

    if (materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (color) {
      loadSurfaceFinishColorOptions({
        technology,
        surfaceFinish,
      }, false);
    }

    if (isAnodizingSurfaceFinish(surfaceFinish)) {
      setAnodizingType(anodizingType)
    }
  });

  useEffect(() => {
    if (quantity <= 0) { return }

    setTotalPriceBidded(priceBiddedPerUnit * quantity);
  }, [quantity, priceBiddedPerUnit]);

  useEffect(() => {
    setTotalPriceExclGst(formulas.calculateTotalPrice(
      totalPriceBidded || 0,
      markupPercent || 0,
      0, // remove supplier GST
      0, // remove delivery fee
    ));
  }, [totalPriceBidded, markupPercent]);

  useEffect(() => {
    const newTotalPriceIncPlatformFee = Number(totalPriceExclGst) + (Number(platformFee) || 0)
    setTotalPriceIncPlatformFee(newTotalPriceIncPlatformFee)
  }, [platformFee, totalPriceExclGst])

  useEffect(() => {
    const timeoutID = setTimeout(async () => {
      const data = await getPlatformFeeByTotalPrice(totalPriceExclGst);
      setPlatformFee(data.platformFee);
    }, 500);

    let tempUnitPrice = new Decimal(totalPriceExclGst).dividedBy(quantity).toFixed();
    tempUnitPrice = handleDigitDecimalUtil(tempUnitPrice);
    setUnitPrice(tempUnitPrice)
    return () => clearTimeout(timeoutID);
  }, [totalPriceExclGst]);

  useEffect(() => {
    const totalPrice = totalPriceIncPlatformFee * (1 + DEFAULT_FACTOREM_GST)
    setTotalPrice(Number(totalPrice).toFixed(2));
  }, [totalPriceIncPlatformFee]);

  useEffect(() => {
    if (isEmptyValue(item)) {
      return;
    }
    populateItemDetails(item);

    const copyQuotationByQuotationID = async (quotationID) => {
      const existingQuotation = await getQuotationByID(quotationID);
      const { priceBidded, quantity, markupPercent, deliveryFee, platformFee, leadTime, markupLeadTime } = existingQuotation
      setPriceBiddedPerUnit(priceBidded / quantity);
      setMarkupPercent(markupPercent)
      setDeliveryFee(deliveryFee)
      setPlatformFee(platformFee)
      setLeadTime(leadTime)
      setMarkupLeadTime(markupLeadTime)
    }

    setQuantity(item.quantity);
    setTechnology(item.technology);
    if (item.qcReports && item.qcReports.main === QC_FORMAL_CMM_REPORT) {
      setIsCmmReport(true)
    }

    const metadata = item.metadata || {};
    if (is3DPTechnology(item.technology)) {
      const threeDTech = metadata.threeDTechnology === THREE_D_P_OLD_SLA_VALUE_TECH
        ? THREE_D_P_SLA_TECH
        : metadata.threeDTechnology;
      setThreeDTechnology(threeDTech);
      if (threeDTech === THREE_D_P_FDM_TECH) {
        setThreeDInfill(metadata.threeDInfill);
        setThreeDLayerThickness(metadata.threeDLayerThickness);
      }
    }

    setColor(item.color || "");
    setMaterialColor(item.materialColor);

    if (copyQuotation && !isEmptyValue(item.quotationID)) {
      copyQuotationByQuotationID(item.quotationID)
    }
  }, [item]);

  const validateForm = () => {
    if (isCustomMaterial(material) && isEmptyValue(otherMaterial)) {
      setFormError({
        otherMaterial: 'Required field',
      });
      return false;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmptyValue(otherSurfaceFinish)) {
      setFormError({
        otherSurfaceFinish: 'Required field',
      });
      return false;
    }
    return true;
  }

  const handleQuoteButtonClick = () => {
    const data = {
      priceBiddedPerUnit,
      markupPercent,
      gst,
      deliveryFee,
      totalPrice,
    }
    if (isCmmReport) {
      data.cmmPrice = cmmPrice
    }
    const error = validatePriceParams(data);
    if (!isEmptyValue(error)) {
      setParamError(error);
      notifyError(Object.values(error)[0]);
      return;
    }
    setFormError({});
    const valid = validateForm();
    if (!valid) {
      return;
    }
    if (typeof handleRocketQuoteButtonClick === 'function') {
      const payload = {
        technology,
        id: item.itemID,
        priceBidded: totalPriceBidded,
        markupPercent,
        gst,
        deliveryFee,
        totalPrice: totalPriceExclGst,
        quantity,
        material,
        otherMaterial: isCustomMaterial(material) ? otherMaterial : null,
        materialColor,
        surfaceFinish,
        otherSurfaceFinish: isCustomSurfaceFinish(surfaceFinish) ? otherSurfaceFinish : null,
        color,
        leadTime,
        markupLeadTime,
        dateOfExpiry,
        supplierRemarks,
        platformFee,
        anodizingType
      }
      if (is3DPTechnology(technology)) {
        payload.threeDTechnology = threeDTechnology;
        payload.threeDInfill = threeDInfill;
        payload.threeDLayerThickness = threeDLayerThickness;
      }
      if (isCmmReport) {
        payload.cmmPrice = cmmPrice
      }
      setDisableQuoteButton(true);
      setLoadingQuoteButton(true);
      handleRocketQuoteButtonClick(payload).catch(() => {
        setDisableQuoteButton(false);
        setLoadingQuoteButton(false);
      });
    }
  }

  const debounceMarkupPercent = debounce((markupPercentParam) => {
    setMarkupPercent(markupPercentParam);
  }, 500)

  const handleChangeUnitPrice = (unitPriceParam) => {
    const tempTotalPrice = new Decimal(unitPriceParam).times(quantity).toFixed(2);
    let tempMarkupPercent = new Decimal(tempTotalPrice).minus(totalPriceBidded).dividedBy(totalPriceBidded).times(100).toFixed()
    tempMarkupPercent = handleDigitDecimalUtil(tempMarkupPercent)
    unitPriceParam = handleDigitDecimalUtil(unitPriceParam, false);
    setUnitPrice(unitPriceParam)
    debounceMarkupPercent(tempMarkupPercent)
  }

  const prepareUpdatedPart = (item) => {
    // ignore itemID for the rocket quote payload
    const { itemID: _itemID, ...rest } = item;

    return {
      ...rest,
      technology,
      material,
      qty: quantity,
      finish: surfaceFinish,
      cadPart: item?.cadFile?.split(',') || item?.originalFiles?.split(',')
    };
  };

  const handlePpeFailure = (error, toastId) => {
    let messages;
    if (typeof error === 'string') {
      messages = (<li>{error}</li>)
    } else {
      messages = Object.values(error).map((errMsg, index) => (
        <li key={index}>{errMsg}</li>
      ))
    }

    const errorMessages = (
      <div style={{ marginBottom: '16px' }}>
        <p>PPE Failed. Please update the rocket quote manually!</p>
        <p>Errors:</p>
        <ul>
          {messages}
        </ul>
      </div>
    );

    updateNotification(toastId, errorMessages, 'error');
    setLoadingPpe(false);
    setDerivedFromPpe(false);
  };

  const updateCustomerPrice = (totalPrice) => {
    const { totalPrice: customerPrice, unitPrice: customerUnitPrice } = convertPriceWithQuantityToCurrency({
      totalPrice: totalPrice || 0,
      quantity,
      currency,
      exchangeRate
    });
    setTotalPriceBidded(customerPrice);
    setPriceBiddedPerUnit(customerUnitPrice);
  }

  const updateSupplierPrice = (price) => {
    const { price: supplierPrice } = convertPriceWithQuantityToCurrency({
      totalPrice: price || 0,
      quantity,
      currency,
      exchangeRate
    });
    setTotalPrice(supplierPrice);
  }

  const validatePpeResponse = ({ leadTime, markupLeadTime, totalPrice, price, repeatOrder, similarOrder }) => {
    const createCheck = (label, value, invalidCheck, setWarning, updateValue) => ({
      label, value, invalidCheck, setWarning, updateValue
    });

    const checks = [
      createCheck('Total price bidded', totalPrice, !isNumber(totalPrice), setTotalPriceWarning, updateCustomerPrice),
      createCheck('Total price', price, !isNumber(price), setPriceWarning, updateSupplierPrice)
    ];

    if (repeatOrder || similarOrder) {
      checks.push(
        createCheck('Lead time', leadTime, !isNumber(leadTime), setLeadTimeWarning, setLeadTime),
        createCheck('Markup lead time', markupLeadTime, !isNumber(markupLeadTime), setMarkupLeadTimeWarning, setMarkupLeadTime)
      );
    } else {
      setMarkupLeadTimeWarning(true);
      setLeadTimeWarning(true);
    }

    const invalidFields = checks
      .filter(({ invalidCheck, setWarning, updateValue, value }) => {
        setWarning(invalidCheck);
        if (!invalidCheck) updateValue(value);
        return invalidCheck;
      })
      .map(({ label }) => label);

    if (invalidFields.length > 0) {
      notifyWarning(
        <div style={{ marginBottom: '16px' }}>
          <p>PPE responded with invalid values for the following fields:</p>
          <ul>
            {invalidFields.map((label, index) => (
              <li key={index}>{label}</li>
            ))}
          </ul>
          <p>Please double-check before quoting!</p>
        </div>
      );
      return false;
    }

    if (!repeatOrder && !similarOrder) {
      notifyWarning("This is a new order. The lead times are set to default. Please check and update them before submitting your quote!");
    }

    return true;
  };

  const processPpeSuccess = (ppeResponse, toastId) => {
    if (validatePpeResponse(ppeResponse)) {
      updateNotification(toastId, 'PPE Success!');
    } else {
      dismissNotification(toastId);
    }

    setLoadingPpe(false);
  };

  const handleGeneratePpeQuoteForItem = async (item) => {
    const toastId = notifyOngoing(`Generating PPE Quote for itemID=${item.itemID}`);
    setDerivedFromPpe(true);
    setLoadingPpe(true);

    const updatedPart = prepareUpdatedPart(item);

    try {
      const ppeResponse = await getPpePriceForItem(updatedPart);

      if (!ppeResponse.success && !isEmptyValue(ppeResponse.error)) {
        handlePpeFailure(ppeResponse.error, toastId);
        return;
      }

      processPpeSuccess(ppeResponse, toastId);
    } catch (error) {
      notifyError('An unexpected error occurred while generating the PPE quote.');
      setLoadingPpe(false);
      setDerivedFromPpe(false);
    }
  };

  const render3DPrintingTechnologySelect = () => {
    return (
      <div>
        <ThreeDPrintingTechnologyInputField
          visible={is3DPTechnology(technology)}
          value={threeDTechnology}
          onChange={(newValue) => {
            setThreeDTechnology(newValue);
            setFormError({});
            const params = {
              technology,
              threeDTechnology: newValue,
            }
            threeDTechnologyHasChanged(params);
            if (newValue === THREE_D_P_FDM_TECH) {
              setThreeDInfill(threeDPrintingInfillDefault);
              setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
            } else {
              setThreeDInfill('');
              setThreeDLayerThickness('');
            }
          }}
          error={formError.threeDTechnology}
          threeDTechnologyOptions={threeDTechnologyOptions}
          bootstrapStyle={false}
        />
      </div>
    );
  }

  const render3DInfillSelect = () => {
    return (
      <ThreeDInfillFieldSelect
        value={threeDInfill}
        onChange={evt => setThreeDInfill(evt.target.value)}
      />
    );
  }

  const render3DLayerThicknessSelect = () => {
    return (
      <ThreeDLayerThicknessFieldSelect
        onChange={evt => setThreeDLayerThickness(evt.target.value)}
        value={threeDLayerThickness}
      />
    );
  }

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmptyValue(surfaceFinishColorOptions) || color}
        value={color}
        colorInputLabel={'Color'}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => setColor(selectedColor || color)}
        bootstrapStyle={false}
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmptyValue(materialColorOptions)}
        value={materialColor}
        colorInputLabel="Material Color"
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) => setMaterialColor(selectedColor || materialColor)}
        bootstrapStyle={false}
      />
    );
  }

  return (
    <Dialog
      maxWidth='md'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <SubDesc content={`Rocket Quote Item #${item.itemID}`} />
      </DialogTitle>
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <React.Fragment>
            <div style={{
              marginBottom: '8px',
              textAlign: 'right'
            }}>
              <FtrButton
                color="blue"
                size="small"
                onClick={() => handleGeneratePpeQuoteForItem(item)}
                loading={loadingPpe}
                variant="outlined"
              >
                Generate PPE Quote
              </FtrButton>
            </div>
            {customerInfo?.country !== COUNTRY_NAMES.SINGAPORE && (
              <FtrBanner>
                <FlexRow>
                  <WarningIcon />
                  <FtrTypography>
                    This customers requires international shipping. Please adjust delivery fee and markup lead time accordingly.
                  </FtrTypography>
                </FlexRow>
              </FtrBanner>
            )}
            <TextField
              style={{
                marginBottom: '1rem',
              }}
              type='number'
              label="Quantity"
              variant="outlined"
              onChange={evt => setQuantity(evt.target.value)}
              value={quantity}
              margin="dense"
              fullWidth
            />
            <Divider />
            <TextField
              style={{
                marginTop: '1rem',
              }}
              className={`${derivedFromPpe && classes.derivedPpeText} ${totalPriceWarning && 'warning'}`}
              type='number'
              label="Price bidded/pc"
              variant="outlined"
              onFocus={() => setParamError({ ...paramError, priceBiddedPerUnit: undefined })}
              onChange={evt => setPriceBiddedPerUnit(evt.target.value)}
              value={priceBiddedPerUnit}
              error={!!paramError.priceBiddedPerUnit}
              helperText={paramError.priceBiddedPerUnit}
              margin="dense"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S$</InputAdornment>
                )
              }}
              autoFocus
            />
            <TextField
              className={`${derivedFromPpe && classes.derivedPpeText} ${priceWarning && 'warning'}`}
              style={{
                marginTop: '1rem',
              }}
              type='number'
              label="Total price bidded"
              variant="outlined"
              value={totalPriceBidded}
              margin="dense"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S$</InputAdornment>
                )
              }}
              disabled
            />
            <TextField
              type='number'
              label="Markup percentage"
              variant="outlined"
              onFocus={() => setParamError({ ...paramError, markupPercent: undefined })}
              onChange={evt => setMarkupPercent(evt.target.value)}
              value={markupPercent}
              error={!!paramError.markupPercent}
              helperText={paramError.markupPercent}
              margin="dense"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">%</InputAdornment>
                )
              }}
            />
            <TextField
              type='number'
              label="Delivery Fee"
              variant="outlined"
              onFocus={() => setParamError({ ...paramError, deliveryFee: undefined })}
              onChange={evt => setDeliveryFee(evt.target.value)}
              value={deliveryFee}
              error={!!paramError.deliveryFee}
              helperText={paramError.deliveryFee}
              margin="dense"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S$</InputAdornment>
                )
              }}
            />
            {isCmmReport && (
              <TextField
                label="CMM Price"
                variant="outlined"
                fullWidth
                type='number'
                placeholder='0'
                onChange={evt => setCmmPrice(evt.target.value)}
                value={cmmPrice}
                error={!!paramError.cmmPrice}
                helperText={paramError.cmmPrice}
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">S$</InputAdornment>
                  )
                }}
              />
            )}
            <Grid
              container
              spacing={1}
            >
              <Grid item xs={6}>
                <TextField
                  label="Unit Price"
                  className={countDecimalsUtil(unitPrice) < 3 ? classes.greenText : classes.redText}
                  variant="outlined"
                  value={unitPrice}
                  margin="dense"
                  type='number'
                  onChange={evt => handleChangeUnitPrice(evt.target.value)}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">S$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  label="Target Unit Price"
                  variant="outlined"
                  value={item.expectedPrice || 'N/A'}
                  margin="dense"
                  type='number'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">S$</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  type='number'
                  label='Total price'
                  variant='outlined'
                  value={totalPriceExclGst}
                  margin='dense'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>S$</InputAdornment>
                    ),
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type='number'
                  label='Service fee'
                  variant='outlined'
                  value={platformFee}
                  onChange={evt => setPlatformFee(evt.target.valueAsNumber)}
                  margin='dense'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>S$</InputAdornment>
                    ),
                  }}
                  disabled={role !== ROLE_TYPES.SUPER_ADMIN}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type='number'
                  label='Total price (incl. Platform fee)'
                  variant='outlined'
                  value={totalPriceIncPlatformFee}
                  margin='dense'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>S$</InputAdornment>
                    ),
                  }}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={11}>
                <TextField
                  style={{
                    marginBottom: '1rem',
                  }}
                  label="Total price (incl. Factorem 9% GST)"
                  type="number"
                  disabled
                  variant="filled"
                  value={totalPrice}
                  margin="dense"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">S$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={1}
                style={{
                  display: 'flex',
                  paddingTop: '0.3rem',
                }}
              >
                <Box style={{ width: '0.2rem' }} />
                <InfoIcon toolTipText="Price with GST only applicable to customers in Singapore" />
              </Grid>
            </Grid>
            <Divider />
            <TextField
              style={{
                marginTop: '1rem',
              }}
              label="Supplier remarks"
              variant="outlined"
              multiline={true}
              rows={3}
              rowsMax={6}
              onChange={evt => setSupplierRemarks(evt.target.value)}
              value={supplierRemarks}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Technology"
              variant="outlined"
              value={technology}
              margin="dense"
              fullWidth
              disabled
            />
            {is3DPTechnology(technology) && render3DPrintingTechnologySelect()}
            <MaterialCategoriesInputField
              visible={!is3DPTechnology(technology)}
              value={material}
              onSelect={(value) => {
                setMaterial(value);
                const params = {
                  technology,
                  threeDTechnology,
                  material: value,
                }
                materialHasChanged(params);
              }}
              materialCategoryOptions={materialCategoryOptions}
              bootstrapStyle={false}
            />
            <ThreeDPrintingMaterialField
              visible={is3DPTechnology(technology)}
              value={material}
              onSelect={(_material) => {
                setMaterial(_material);
                const params = {
                  technology,
                  threeDTechnology,
                  material: _material,
                }
                materialHasChanged(params);
              }}
              threeDMaterialOptions={threeDMaterialOptions}
              defaultThreeDMaterial={defaultThreeDMaterial}
              bootstrapStyle={false}
            />
            {isCustomMaterial(material) && (
              <OtherMaterialField
                value={otherMaterial}
                error={formError.otherMaterial}
                onChange={evt => setOtherMaterial(evt.target.value)}
                onFocus={() => setFormError({})}
              />
            )}
            {!isEmptyValue(materialColorOptions) && (
              <div className={classes.sectionFormField}>
                {renderMaterialColorInputField()}
              </div>
            )}
            {is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH && render3DInfillSelect()}
            {is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH && render3DLayerThicknessSelect()}
            <SurfaceFinishingField
              visible={!isEmptyValue(surfaceFinishOptions) || isCustomSurfaceFinish(surfaceFinish)}
              value={surfaceFinish}
              onSelect={(newValue) => {
                setSurfaceFinish(newValue);
                const params = {
                  technology,
                  material,
                  surfaceFinish: newValue,
                }
                surfaceFinishHasChanged(params);
              }}
              surfaceFinishOptions={surfaceFinishOptions}
              selectColorSurfaces={selectColorSurfaces}
              bootstrapStyle={false}
            />
            {isCustomSurfaceFinish(surfaceFinish) && (
              <OtherSurfaceFinishingField
                value={otherSurfaceFinish}
                error={formError.otherSurfaceFinish}
                onChange={evt => setOtherSurfaceFinish(evt.target.value)}
                onFocus={() => setFormError({})}
              />
            )}
            {(!isEmptyValue(surfaceFinishColorOptions) || color) && (
              <div className={classes.sectionFormField}>
                {renderSurfaceFinishColor()}
              </div>
            )}
            {
              (!isEmptyValue(surfaceFinish) && isAnodizingSurfaceFinish(surfaceFinish) && (
                <FlexRow>
                  <FtrDropdownV2
                    id='anodizing-type-dropdown'
                    key='anodizing-type-dropdown'
                    fullWidth
                    value={anodizingType || ANODIZING_TYPE_OPTIONS[0].key}
                    handleChange={(newType) => setAnodizingType(newType)}
                    items={ANODIZING_TYPE_OPTIONS}
                  />
                </FlexRow>
              ))
            }
            <TextField
              className={`${derivedFromPpe && classes.derivedPpeText} ${leadTimeWarning && 'warning'}`}
              label="Lead time"
              variant="outlined"
              onChange={evt => setLeadTime(evt.target.value)}
              value={leadTime}
              margin="dense"
              fullWidth
            />
            <TextField
              className={`${derivedFromPpe && classes.derivedPpeText} ${markupLeadTimeWarning && 'warning'}`}
              label="Markup lead time"
              variant="outlined"
              onChange={evt => setMarkupLeadTime(evt.target.value)}
              value={markupLeadTime}
              margin="dense"
              fullWidth
            />
            <DatePicker
              style={{
                marginBottom: '1rem',
              }}
              label={"Quote valid till:"}
              value={dateOfExpiry}
              onChange={setDateOfExpiry}
              animateYearScrolling
              inputVariant="outlined"
              margin="dense"
              fullWidth
              clearable
              clearLabel="No Preference"
            />
          </React.Fragment>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <WhiteButton
              onBtnClick={handleClose}
              btnContent='Back'
              size='small'
            />
            <Box className={classes.space}></Box>
            <BlueButton
              onBtnClick={handleQuoteButtonClick}
              btnContent='Quote'
              disabled={disableQuoteButton}
              loading={loadingQuoteButton}
            />
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(RocketQuotePopup));
