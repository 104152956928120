import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
  Badge,
  Button,
  Tooltip,
} from "@material-ui/core";

import {
  AssignmentOutlined as AssignmentOutlinedIcon,
  ContactSupportOutlined as ContactSupportOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  ImageSearch as ImageSearchIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  PermContactCalendar as PermContactCalendarIcon,
  MonetizationOn as MonetizationOnIcon,
  EqualizerOutlined as EqualizerOutlinedIcon,
  OfflinePin as OfflinePinIcon,
} from "@material-ui/icons";

import CountrySelectorPopup from "../components/popups/CountrySelectorPopup";
import ProfileMenuItem from "./list-items/ProfileMenuItem";
import ResponsiveSidebar from "./ResponsiveSidebar";

import { getUnAcknowledgedQuotationsCountAction } from "../actions/poAcknowledge";
import { getSupplierAdminControlAction } from "../actions/supplierControlSettings";
import { logoutUser } from "../actions";

import { getSingleSupplierDataDashboard, updateHasSeenSupplierDashboard } from "../apis/supplierDataDashboard";

import { isEmptyValue } from "../utils/commonUtils";

import { SUPPLIER_FEEDBACK_URL } from "../constants";
import { SUPPLIER_SIDEBAR_LINKS } from "../constants/sidebarConstants";

import { colors } from "../palette";

const DEFAULT_DRAWER_WIDTH = 270;

// Style component
const useStyles = makeStyles({
  top: {
    marginBottom: 38,
    "&:hover": {
      background: colors.bgLightGrey
    }
  },
  middle: {
    margin: "0 40px"
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    margin: "0 40px",
    paddingBottom: 20
  },
  icon: {
    display: "flex",
    marginRight: 15,
  },
  navlinks: {
    display: "flex",
    flexDirection: "column"
  },
  listText: {
    textTransform: "capitalize",
    fontSize: "16px",
    letterSpacing: "0.05em",
  },
  link: {
    textDecoration: "none",
    margin: "9px 0",
    display: "flex",
    color: "inherit",
  },
  logoutBtn: {
    padding: 0,
    margin: "9px 0",
    display: "flex",
    justifyContent: "flex-start",
    color: colors.fontLightGrey,
    '&:hover': {
      fontWeight: "600",
      color: colors.tertiaryBlue
    },
  },
  sidebarTitle: {
    color: colors.tertiaryBlue,
    textTransform: "uppercase",
    fontWeight: "600",
    margin: "9px 0",
    letterSpacing: "0.08em"
  },
  highlight: {
    color: 'white',
  },
  highlightMenu: {
    backgroundColor: colors.blue050,
    borderRadius: "8px",
    paddingLeft: "0.2rem",
    boxSizing: "border-box",
  },
  sidebarMenu: {
    textDecoration: "none",
    fontWeight: "600",
    margin: "9px 0",
    display: "flex",
    color: colors.tertiaryBlue,
    '&:hover': {
      fontWeight: "600",
      color: colors.tertiaryBlue
    },
  },
  sidebarTransparent: {
    textDecoration: "none",
    margin: "9px 0",
    display: "flex",
    color: colors.fontLightGrey,
    '&:hover': {
      fontWeight: "600",
      color: colors.tertiaryBlue
    },
  },
  selectorComponent: {
    margin: '30px 0',
  },
});

export function SupplierSidebar(props) {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const {
    location,
  } = props;

  const user = useSelector(state => state.auth.user);
  const poUnAcknowledgedCount = useSelector(state => state.poAcknowledge.poUnAcknowledgedCount);
  const allowViewDashboard = useSelector(state => state.supplierControlSettings.allowViewDashboard);
  const name = useSelector(state => state.editProfile.name || state.auth.user.name);
  const profilePic = useSelector(state => state.editProfile.profilePic || state.auth.user.profilePic);

  const menuLinkArr = location?.pathname?.split('/');
  const paramValue = menuLinkArr?.at(-1);

  // remove param value
  if (paramValue && !isNaN(Number(paramValue))) {
    menuLinkArr?.pop()
  }
  const defaultMenu = menuLinkArr?.join('/');

  const [selectedMenu, setSelectedMenu] = React.useState(defaultMenu);

  const {
    data: supplierDataDashboard,
  } = useQuery('getSingleSupplierDataDashboard', () =>
    getSingleSupplierDataDashboard(user?.userID)
  );

  useEffect(() => {
    if (isEmptyValue(user)) {
      return;
    }

    const { userID } = user;
    dispatch(getUnAcknowledgedQuotationsCountAction(userID));
    dispatch(getSupplierAdminControlAction(userID));
  }, [user]);

  const handleSelectedMenu = async (newMenu) => {
    setSelectedMenu((prevMenu) => {
      if (
        prevMenu === SUPPLIER_SIDEBAR_LINKS.DATA_DASHBOARD &&
        newMenu !== SUPPLIER_SIDEBAR_LINKS.DATA_DASHBOARD &&
        !supplierDataDashboard?.hasSeen
      ) {
        updateHasSeenSupplierDashboard(supplierDataDashboard?.id).then(() => {
          queryClient.invalidateQueries('getSingleSupplierDataDashboard');
        });
      }
      return newMenu;
    });
  };

  function changeColor(menu, selectedMenu) {
    if (menu === selectedMenu) {
      return classes.sidebarMenu;
    } else return classes.sidebarTransparent;
  }

  const renderDataDashboardMenu = (supplierDashboard) => {
    if (allowViewDashboard !== 1 || !supplierDataDashboard) {
      return null;
    }

    const dataDashboardMenu = () => (
      <Link
        to={`${SUPPLIER_SIDEBAR_LINKS.DATA_DASHBOARD}/${user.userID}`}
        className={changeColor(
          SUPPLIER_SIDEBAR_LINKS.DATA_DASHBOARD,
          selectedMenu
        )}
        onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.DATA_DASHBOARD)}
      >
        <div className={classes.icon}>
          <EqualizerOutlinedIcon />
        </div>
        <div className={classes.listText} data-cy='data-dashboard'>
          Data Dashboard
        </div>
      </Link>
    );

    if (!supplierDashboard?.hasSeen) {
      return (
        <Badge
          badgeContent='NEW'
          color='error'
        >
          {dataDashboardMenu()}
        </Badge>
      );
    }
    return dataDashboardMenu();
  };

  // Add tabs for supplier
  const renderListMenuItems = () => (
    <div className={classes.navlinks}>
      <div className={classes.sidebarTitle}>General</div>
      <Link to={SUPPLIER_SIDEBAR_LINKS.GENERAL}
        className={changeColor(SUPPLIER_SIDEBAR_LINKS.GENERAL, selectedMenu)}
        onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.GENERAL)} >
        <div className={classes.icon}>
          <ImageSearchIcon />
        </div>
        <div className={classes.listText}>Browse Parts</div>
      </Link>
      <Link to={SUPPLIER_SIDEBAR_LINKS.ORDERS}
        className={changeColor(SUPPLIER_SIDEBAR_LINKS.ORDERS, selectedMenu)}
        onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.ORDERS)}>
        <div className={classes.icon}>
          <AssignmentOutlinedIcon />
        </div>
        <div className={classes.listText} data-cy="my-quotes-sidebar">My Quotes</div>
      </Link>
      <Tooltip
        title={poUnAcknowledgedCount > 0
          ? `You have ${poUnAcknowledgedCount} unacknowledged quotation(s)`
          : ''}
      >
        <Badge badgeContent={poUnAcknowledgedCount} color="error">
          <Link to={SUPPLIER_SIDEBAR_LINKS.ACCEPTED_ORDERS}
            className={changeColor(SUPPLIER_SIDEBAR_LINKS.ACCEPTED_ORDERS, selectedMenu)}
            onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.ACCEPTED_ORDERS)}>
            <div className={classes.icon}>
              <MonetizationOnIcon />
            </div>
            <div className={classes.listText} data-cy="accepted-quotes-sidebar">Accepted Quotes</div>
          </Link>
        </Badge>
      </Tooltip>
      <Link to={SUPPLIER_SIDEBAR_LINKS.INSTANT_QUOTE}
        className={changeColor(SUPPLIER_SIDEBAR_LINKS.INSTANT_QUOTE, selectedMenu)}
        onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.INSTANT_QUOTE)}>
        <div className={classes.icon}>
          <SettingsOutlinedIcon />
        </div>
        <div className={classes.listText}>Price Predictor</div>
      </Link>
      <Link to={SUPPLIER_SIDEBAR_LINKS.DFM_CHECKER}
        className={changeColor(SUPPLIER_SIDEBAR_LINKS.DFM_CHECKER, selectedMenu)}
        onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.DFM_CHECKER)}>
        <div className={classes.icon}>
          <OfflinePinIcon />
        </div>
        <div className={classes.listText}>DFM Checker</div>
      </Link>
      {renderDataDashboardMenu(supplierDataDashboard)}
      <div className={classes.sidebarTitle} style={{ marginTop: 50 }}>
        Support
      </div>
      <Link to={SUPPLIER_SIDEBAR_LINKS.FAQ}
        className={changeColor(SUPPLIER_SIDEBAR_LINKS.FAQ, selectedMenu)}
        onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.FAQ)}>
        <div className={classes.icon}>
          <ContactSupportOutlinedIcon />
        </div>
        <div className={classes.listText}>FAQ</div>
      </Link>
      <Badge className={classes.highlightMenu}>
        <Link to={{ pathname: SUPPLIER_FEEDBACK_URL }} target="_blank" rel="noopener noreferrer" className={classes.link}>
          <div className={clsx(classes.icon, classes.highlight)}>
            <PermContactCalendarIcon color="inherit" />
          </div>
          <div className={clsx(classes.listText, classes.highlight)}>Feedback</div>
        </Link>
      </Badge>
    </div>
  );


  return (
    <ResponsiveSidebar drawerWidth={DEFAULT_DRAWER_WIDTH} Content={props.children}>
      <div>
        <div className={classes.top} onClick={() => handleSelectedMenu(SUPPLIER_SIDEBAR_LINKS.PROFILE)}>
          <ProfileMenuItem name={name} profilePic={profilePic} />
        </div>
        <div className={classes.middle}>{renderListMenuItems()}</div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.selectorComponent}>
          <CountrySelectorPopup />
        </div>
        <Button
          onClick={() => dispatch(logoutUser())}
          className={classes.logoutBtn}
          style={{ backgroundColor: "transparent" }}
        >
          <div className={classes.icon}>
            <ExitToAppOutlinedIcon />
          </div>
          <div className={classes.listText} data-cy="logout-btn">Logout</div>
        </Button>
      </div>
    </ResponsiveSidebar>
  );
}

export default SupplierSidebar;
