import React, { useReducer } from 'react';
import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
} from '@material-ui/core';
import {
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import PartUploadDragAndDropV2 from '../components/PartUploadDragAndDrop/PartUploadDragAndDropV2';

import { uploadFileToS3 } from '../services/s3Service';

import { getFileNameFromCadFile } from '../utils/itemUtils';

import { notifyError } from '../services/notificationService';

import { colors } from '../palette';
import { CADPART_DIRNAME } from '../constants';
import withDefectVisualizationPopupHOC from '../components/popups/withDefectVisualizationPopupHOC';
import { getDfmExtractDefectsWithCache } from '../apis/dfmApi';
import { FtrButton, FtrDropdown, FtrTooltip } from '../components/ftr-components';
import { techMapping } from '../constants/PPEConstants';
import FtrFieldLabel from '../components/ftr-components/FtrFieldLabel';
import { FlexRowCenter } from '../components/layouts/FlexLayouts';
import { getNotEmptyValueFromObject } from '../utils/objectUtils';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  circularProgress: {
    position: 'absolute',
  },
  circularProgressText: {
    position: 'absolute',
    top: '50%',
  },
  body: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(4),
  },
  paper: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
  },
}));

const DfmAnalysisButton = (props) => {
  const { handleView3DDefect, handleClick } = props;
  return (
    <FtrButton
      color='blue'
      variant='contained'
      style={{ marginTop: '1rem' }}
      onClick={(event) => handleClick({ event, handleView3DDefect })}
      {...props}
    >
      Get DFM Analysis
    </FtrButton>
  );
};

const GetDfmAnalysisButton = withDefectVisualizationPopupHOC(DfmAnalysisButton);

function DfmChecker() {
  const classes = useStyles();

  const [formState, updateFormState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      cadFile: '',
      process: 'CNC',
      fileLoading: false,
      dfmDefectsLoading: false,
    }
  );

  const handleCadFileChange = async (files) => {
    const file = files[0];
    const splitFilename = file.name.split('.');
    const preFilename = splitFilename.slice(0, -1).join('.');
    const fileExtension = splitFilename[splitFilename.length - 1];
    const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;

    updateFormState({ fileLoading: true });
    const dataFileToS3 = await uploadFileToS3(file, s3ObjectKey)
      .catch((err) => {
        notifyError(err.message || 'Error uploading file');
      })
      .finally(() => {
        updateFormState({ fileLoading: false });
      });
    const s3ObjectUrl = dataFileToS3.Location.split(' ').join('%20');

    updateFormState({ cadFile: s3ObjectUrl });
  };

  const handleExtract = async ({ handleView3DDefect }) => {
    const params = {
      file_url: formState.cadFile,
      process: formState.process,
    };
    updateFormState({
      dfmDefectsLoading: true,
    });
    getDfmExtractDefectsWithCache(params)
      .then((res) => {
        const output = res?.data?.defects
          ? { ...res.data.defects, trackingID: res.data.trackingID }
          : res?.data;
        const defectUrl = getNotEmptyValueFromObject(
          output?.visualization_links
        );
        updateFormState({
          output: output,
          defectUrl: defectUrl,
        });
        handleView3DDefect(defectUrl);
      })
      .catch((err) => {
        notifyError(err.message || 'Failed to get dfm extract defects!');
      })
      .finally(() => {
        updateFormState({
          dfmDefectsLoading: false,
        });
      });
  };

  return (
    <div className={classes.body}>
      <Paper className={classes.paper}>
        <h1 style={{ display: 'inline-block' }}>
          DFM Defects Checker (For Factorem Partners)
        </h1>
        <div style={{ display: 'inline-block', fontStyle: 'italic' }}>
          This is for Partner’s internal testing purposes. Uploaded parts will
          not be sent for fabrication.
        </div>
        <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
          <div style={{ fontStyle: 'italic', marginRight: 6 }}>Objective:</div>
          <div>
            To automatically identify and flag potential DFM defects, our DFM
            Defects Checker engine plays a crucial role in ensuring efficient
            design validation for our customers, saving hours in manual checks
            and administrative work. We have designed the Factorem DFM Defects
            Checker with in-house machine learning algorithms to accurately
            detect defects based on industry standards. Now, we need your help
            in validating it!
          </div>
        </div>
        <PartUploadDragAndDropV2
          supportFilesText='.step, .stp, .stl'
          supportFileExtensions='.step, .stp, .stl'
          handleFiles={handleCadFileChange}
          multiple={false}
          isAdditionalInfoShown={false}
        />
        {formState.fileLoading && (
          <CircularProgress style={{ marginTop: '1rem' }} />
        )}
        {!formState.cadFile && !formState.fileLoading && (
          <div style={{ fontStyle: 'italic', marginTop: 3, marginBottom: 20 }}>
            Please upload only 1 file at a time
          </div>
        )}
        <List>
          {formState.cadFile && (
            <ListItem
              style={{
                backgroundColor: colors.bgLightGrey,
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AttachFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{
                  width: 130,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
                primary={getFileNameFromCadFile(formState.cadFile)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => updateFormState({ cadFile: '' })}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>

        <FtrFieldLabel style={{ marginTop: '1rem' }}>Technology</FtrFieldLabel>
        <FtrDropdown
          key='technology-dropdown'
          fullWidth
          value={formState.process}
          handleChange={(newTech) => updateFormState({ process: newTech })}
          items={Object.values(techMapping)}
          style={{ marginBottom: '0.5rem' }}
        />

        <FlexRowCenter>
          <FtrTooltip description={!formState.cadFile ? 'Please upload a file' : ''} arrow>
            <div>
              <GetDfmAnalysisButton
                handleClick={handleExtract}
                cadFile={formState.cadFile}
                disabled={!formState.cadFile || !formState.process}
                defectsOutput={formState.output}
                defectUrl={formState.defectUrl}
                loading={formState.dfmDefectsLoading}
                showYesOrNoValue
                showDfmSuccess={false}
                showFeedback
              />
            </div>
          </FtrTooltip>
        </FlexRowCenter>
      </Paper>
    </div>
  );
}

export default withDefectVisualizationPopupHOC(DfmChecker);
