import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from 'react-query';

import FtrIOSSwitch from '../components/ftr-components/FtrIOSSwitch';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import { FtrB3, FtrBanner, FtrButton, FtrTooltip } from '../components/ftr-components';

import withActionTrackingHOC from './withActionTrackingHOC';

import { getActionTrackingApi } from '../apis/actionTrackingApi';

import { updateCustomerSettings } from '../actions/customerSettings';

import useManageProjectRevampLegacyCustomerHook from '../hooks/useManageProjectRevampLegacyCustomerHook';

import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';

import { getManageProjectsRevampFeatureSelector } from '../selectors/customerSettingsSelector';
import { getUserIDSelector, getUserRoleSelector } from '../selectors/userSelector';

import ProjectItemsContext from '../context/ProjectItemsContext';

import { ACTION_TYPE } from '../constants/actionTrackingConstants';


// --------------------------------------------------------------------------------------------

const DismissButtonWithTracking = withActionTrackingHOC(FtrButton);

function withManageProjectsRevampBannerHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const dispatch = useDispatch();

    const {
      showPermanent = true, // if false then auto close after 5 seconds
      alwaysShow = false, // if true then always show regardless of dismiss
    } = props;

    const {
      customerID,
    } = useContext(ProjectItemsContext);

    const userID = useSelector(getUserIDSelector);
    const userRole = useSelector(getUserRoleSelector);
    const manageProjectsRevampFeature = useSelector(getManageProjectsRevampFeatureSelector);

    const projectCustomerID = customerID || userID;

    const { isLegacyCustomer } = useManageProjectRevampLegacyCustomerHook(projectCustomerID);

    const enableBanner = !isAdminOrHigherRole(userRole);

    const {
      data: manageProjectsRevampDismissTracking,
      isLoading,
      refetch: refetchManageProjectsRevampDismissTracking,
    } = useQuery(
      ['getActionTrackings'],
      () => {
        if (isEmptyValue(projectCustomerID) || !enableBanner) {
          return null;
        }

        return getActionTrackingApi({
          userID: projectCustomerID,
          actionTrackingKey: ACTION_TYPE.MANAGE_PROJECTS_REVAMP_DISMISS,
        });
      },
    );

    const [showBanner, setShowBanner] = React.useState(true);

    useEffect(() => {
      if (showPermanent || alwaysShow) {
        return;
      }

      setTimeout(() => {
        setShowBanner(false);
      }, 5000);
    }, []);

    const renderSwitchButton = () => {
      return (
        <FtrIOSSwitch
          checked={Boolean(manageProjectsRevampFeature)}
          onChange={() => {
            dispatch(updateCustomerSettings(projectCustomerID, {
              manageProjectsRevampFeature: !manageProjectsRevampFeature
            }));
          }}
          name='manage-projects-revamp-switch'
        />
      );
    }

    const renderBanner = () => {
      return (
        <AnimatePresence>
          {enableBanner
            && ((showBanner
              && isLegacyCustomer
              && isEmptyValue(manageProjectsRevampDismissTracking)
              && !isLoading)
              || alwaysShow
            )
            && (
              <motion.div
                initial={{ y: -120 }}
                animate={{ y: 0 }}
                exit={{ y: -120 }}
              >
                <FtrBanner type='info'>
                  <FlexColumn style={{ alignItems: 'end', gap: 0 }}>
                    <FlexRow>
                      <FtrB3>
                        Welcome to the new interface! If you prefer the Legacy UI, you can switch back anytime from the User Profile menu.
                      </FtrB3>
                      {!alwaysShow && (
                        <DismissButtonWithTracking
                          variant='text'
                          onClick={() => {
                            setShowBanner(false);
                            setTimeout(() => {
                              refetchManageProjectsRevampDismissTracking();
                            }, 1000);
                          }}
                          userID={customerID || userID}
                          actionTrackingKey={ACTION_TYPE.MANAGE_PROJECTS_REVAMP_DISMISS}
                        >
                          Dismiss
                        </DismissButtonWithTracking>
                      )}
                    </FlexRow>
                    <FlexRow>
                      <FtrTooltip description='Enable/Disable New UI Interface' arrow>
                        <div>
                          {renderSwitchButton()}
                        </div>
                      </FtrTooltip>
                      <FtrB3>
                        On/Off
                      </FtrB3>
                    </FlexRow>
                  </FlexColumn>
                </FtrBanner>
              </motion.div>
            )}
        </AnimatePresence>
      );
    }

    return (
      <FlexColumn>
        {renderBanner()}
        <WrappedComponent {...props} />
      </FlexColumn>
    );
  }

  return WrappedComponentWithHOC;
}

export default withManageProjectsRevampBannerHOC;
