import React, { createContext, useContext } from 'react';

import useModifyPdfCanvas from "../hooks/useModifyPdfCanvas";

export const ModifyPdfCanvasContext = createContext({});

export const ModifyPdfCanvasContextProvider = ({ children }) => {
  const pdfCanvas = useModifyPdfCanvas();

  return (
    <ModifyPdfCanvasContext.Provider value={pdfCanvas}>
      {children}
    </ModifyPdfCanvasContext.Provider>
  );
};

export const useModifyPdfCanvasContext = () => {
  const context = useContext(ModifyPdfCanvasContext);
  if (!context) {
    throw new Error(
      'usePdfCanvas must be used within a PdfCanvasProvider'
    );
  }
  return context;
};
