import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import { Badge, Tab, Tabs } from '@material-ui/core';

import InstantQuotesLogsDataGridPagination from './InstantQuotesLogsDataGridPagination';
import LeaveQuoteFeedbackDataGridPagination from './LeaveQuoteFeedbackDataGridPagination';
import PpeLeadTimeFeedbackDataGridPagination from './PpeLeadTimeFeedbackDataGridPagination';
import PpePriceFeedbackDataGridPagination from './PpePriceFeedbackDataGridPagination';
import DefectsFeedbackDataGridPagination from './DefectsFeedbackDataGridPagination';
import TDELogs from './TDELogs';
import DFMLogs from './DFMLogs';

import { colors } from '../../palette';
import { getUnSeenPpeFeedbackLog } from '../../apis/ppeFeedbackApi';
import { getUnSeenUserInstantQuotesLog } from '../../apis/userInstantQuotesLogs';

import { getUserIDSelector } from '../../selectors/userSelector';
import SupplierLeadTimeFeedbackDataGridPagination from './SupplierLeadTimeFeedbackDataGridPagination';


// -------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  INSTANT_QUOTES_LOGS: 'Instant Quotes Logs',
  LEAVE_QUOTE_FEEDBACK: 'Leave Quote Feedback',
  TDE_LOGS: 'TDE Logs',
  LEAD_TIME_FEEDBACK: 'Lead Time Feedback',
  SUPPLIER_LEAD_TIME_FEEDBACK: 'Supplier Lead Time Feedback',
  PRICE_FEEDBACK: 'Price Feedback',
  DEFECTS_FEEDBACK: 'Defects Feedback',
  DFM_LOGS: 'DFM Logs',
};

const TABS = [
  TAB_KEYS.INSTANT_QUOTES_LOGS,
  TAB_KEYS.LEAVE_QUOTE_FEEDBACK,
  TAB_KEYS.TDE_LOGS,
  TAB_KEYS.PRICE_FEEDBACK,
  TAB_KEYS.LEAD_TIME_FEEDBACK,
  TAB_KEYS.SUPPLIER_LEAD_TIME_FEEDBACK,
  TAB_KEYS.DEFECTS_FEEDBACK,
  TAB_KEYS.DFM_LOGS,
];

function InstantQuotesLogs() {
  const classes = useStyles();

  const userID = useSelector(getUserIDSelector);

  // eslint-disable-next-line no-unused-vars
  const [tabs, setTabs] = useState(TABS);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const {
    data: unSeenPpeFeedbackLog,
  } = useQuery(
    ['getUnSeenPpeFeedbackLog', userID],
    () =>getUnSeenPpeFeedbackLog(userID),
  );

  const {
    data: unSeenInstantQuoteLog,
  } = useQuery(
    ['getUserInstantQuotesLog', userID],
    () => getUnSeenUserInstantQuotesLog(userID),
  );


  const renderTab = () => {
    const tabName = tabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.INSTANT_QUOTES_LOGS:
        return <InstantQuotesLogsDataGridPagination />;
      case TAB_KEYS.LEAVE_QUOTE_FEEDBACK:
        return <LeaveQuoteFeedbackDataGridPagination />;
      case TAB_KEYS.TDE_LOGS:
        return <TDELogs />;
      case TAB_KEYS.LEAD_TIME_FEEDBACK:
        return <PpeLeadTimeFeedbackDataGridPagination />;
      case TAB_KEYS.SUPPLIER_LEAD_TIME_FEEDBACK:
        return <SupplierLeadTimeFeedbackDataGridPagination />;
      case TAB_KEYS.PRICE_FEEDBACK:
        return <PpePriceFeedbackDataGridPagination />;
      case TAB_KEYS.DEFECTS_FEEDBACK:
        return <DefectsFeedbackDataGridPagination />;
      case TAB_KEYS.DFM_LOGS:
        return <DFMLogs />;
      default:
        return null;
    }
  };

  const renderTabWithBadge = (tab, badgeContent) => {
    return (
      <Tab
        classes={{
          root: classes.tabRoot,
          selected: classes.selectedTab,
        }}
        key={tab}
        label={(
          <Badge
            badgeContent={badgeContent}
            color="error"
          >
            {tab}
          </Badge>
        )}
      />
    )
  }

  return (
    <div className={classes.body}>
      <h1>Instant Quotes Logs</h1>
      <Tabs value={selectedTab} onChange={handleChange}>
        {tabs.map((tab) => {
          if (tab === TAB_KEYS.INSTANT_QUOTES_LOGS) {
            return renderTabWithBadge(tab, unSeenInstantQuoteLog?.totalUnSeenLog);
          }
          if (tab === TAB_KEYS.PRICE_FEEDBACK) {
            return renderTabWithBadge(tab, unSeenPpeFeedbackLog?.totalUnseenLog);
          }
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

export default InstantQuotesLogs;
