import {
  DB_3DP_INFILL_COLUMN,
  DB_3DP_TECHNOLOGY_COLUMN,
  DB_3DP_THICKNESS_COLUMN,
  DB_COLOR_COLUMN, DB_CUSTOM_MATERIAL_COLUMN,
  DB_CUSTOM_SURFACE_FINISH_COLUMN,
  DB_MATERIAL_COLUMN, DB_MATERIALCOLOR_COLUMN,
  DB_SURFACE_FINISH_COLUMN, DB_TECHNOLOGY_COLUMN,
  DB_TOLERANCE_COLUMN,
} from '../constants/itemConstants';

import { showUnitValueFromMetric } from './userUtils';
import { isEmptyValue } from './commonUtils';
import { getMaterialWithColorText, getSurfaceFinishWithCustomizationsText } from './itemUtils';

/**
 * Returns an object such that it contains all of an item's base properties,
 * but with the values of those properties overridden by the associated quotation
 * for that item.
 *
 * This is useful because certain properties such as technology and tolerance
 * exist only in items, and other properties such as surfaceFinish are updated
 * in the accepted quotation instead.
 * @param item
 * @param quotation
 * @returns {(*)|*|null}
 */
export const combineDataFromItemAndQuotation = (item, quotation) => {
  if (isEmptyValue(item)) {
    return null;
  }
  if (isEmptyValue(quotation)) {
    return item;
  }
  // override item with quotation's keys
  return {
    ...item,
    ...quotation,
  }
}

/**
 * Checks if any param in a group exists in differentParams
 * @param differentParams
 * @param {string[]} paramGroup
 */
const includesAnyParam = (differentParams, paramGroup) => {
  return paramGroup.some(param => differentParams.includes(param));
};

/**
 * Returns a string to display for repeat order tagging
 * @param {string} paramDisplayString
 * @param {*} value
 */
const getParamStringWithValue = (paramDisplayString, value) => {
  return isEmptyValue(value)
    ? `with a different ${paramDisplayString}`
    : `with ${paramDisplayString} as ${value}`;
}

const getSurfaceFinishString = (item) => {
  return getParamStringWithValue('finishing', getSurfaceFinishWithCustomizationsText(item));
};

const getToleranceString = (item) => {
  return getParamStringWithValue(DB_TOLERANCE_COLUMN, showUnitValueFromMetric(item[DB_TOLERANCE_COLUMN], item.unitType));
};

const getMaterialString = (item) => {
  return getParamStringWithValue(DB_MATERIAL_COLUMN, getMaterialWithColorText(item));
};

const getParamString = (paramDisplayString, value) => {
  return getParamStringWithValue(paramDisplayString, value);
};

/**
 * Returns a string with repeated substrings of the form 'with <param> as <param value>'.
 *
 * This function generates a string that contains details about which parameter values
 * are different for a new item.
 *
 * Values for the columns DB_SURFACE_FINISH_COLUMN, DB_CUSTOM_SURFACE_FINISH_COLUMN, DB_COLOR_COLUMN
 * are included in just one compound substring, starting as 'with finish as ...'.
 *
 * Values for the columns DB_MATERIAL_COLUMN, DB_CUSTOM_MATERIAL_COLUMN, DB_MATERIALCOLOR_COLUMN
 * are included in just one compound substring, starting as 'with material as ...'.
 *
 * The DB_TOLERANCE_COLUMN value includes the unit.
 *
 * @param differentParams Array<Param>, where Param is a string and a property of the item
 * @param item The new item
 * @returns {*|string} The final string
 */
export const getParamDifferencesString = (differentParams, item) => {
  if (isEmptyValue(differentParams)) {
    return '';
  }

  const strings = [];

  if (includesAnyParam(differentParams, [DB_SURFACE_FINISH_COLUMN, DB_COLOR_COLUMN, DB_CUSTOM_SURFACE_FINISH_COLUMN])) {
    strings.push(getSurfaceFinishString(item));
  }

  if (differentParams.includes(DB_TOLERANCE_COLUMN)) {
    strings.push(getToleranceString(item));
  }

  if (differentParams.includes(DB_TECHNOLOGY_COLUMN)) {
    strings.push(getParamString(DB_TECHNOLOGY_COLUMN, item[DB_TECHNOLOGY_COLUMN]));
  }

  if (includesAnyParam(differentParams, [DB_MATERIAL_COLUMN, DB_CUSTOM_MATERIAL_COLUMN, DB_MATERIALCOLOR_COLUMN])) {
    strings.push(getMaterialString(item));
  }

  const threeDParams = [
    { column: DB_3DP_THICKNESS_COLUMN, display: '3D layer thickness' },
    { column: DB_3DP_TECHNOLOGY_COLUMN, display: '3D technology' },
    { column: DB_3DP_INFILL_COLUMN, display: '3D infill' }
  ];

  threeDParams.forEach(({ column, display }) => {
    if (differentParams.includes(column)) {
      strings.push(getParamString(display, item.metadata[column]));
    }
  });

  return strings.join(', ');
};
