import { ceil, get, isEmpty } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  Dialog,
  FormControlLabel,
  Icon,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';

import { DataGrid } from '@mui/x-data-grid';

import {
  Edit as EditIcon,
  Repeat as RepeatIcon
} from '@material-ui/icons';

import PpeNoIcon from '../../assets/icons/ppe_no.svg';
import PpeYesIcon from '../../assets/icons/ppe_yes.svg';

// Import utilities
import { generateCurrentCustomDateTimeString } from '../../util';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { dateTzSingapore, getDateStr, getDateStrWithMonth } from '../../utils/dateTimeUtils';
import { getCancelledItemInfo, getIsRepeatOrder, getPpeUpdateHashcodeStatusText } from '../../utils/itemUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { adminExportItemsAsCsv, adminGetAllItems } from '../../apis/itemApi';

import { ITEM_STAGE_TYPE, ROLE_TYPES } from '../../constants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { FEATURE_FLAG_HUBSPOT } from '../../constants/featureFlagConstants';
import { ITEM_CANCELLATION_REASON } from '../../constants/itemConstants';
import { ITEM_STATUS_TEXT_MAPPING } from '../../constants/itemStatus';

import {
  materialWithColorCol,
  partIDWithNameCol,
  projectIDColumn,
  surfaceFinishingWithColorCol,
  toleranceCol,
} from '../../constants/itemTableConstants';

import NewHardwarePart from '../../components/NewHardwarePart';
import StatusButton from '../../components/buttons/StatusButton';
import CustomToolbar, { DataGridToolbarLayout } from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import ImageWith3DViewer from '../../components/images/ImageWith3DViewer';
import EditItemStatusPopup from '../../components/popups/EditItemStatusPopup';
import NotInterestedJobPopup from '../../components/popups/NotInterestedJobPopup';
import WatchingJobPopup from '../../components/popups/WatchingJobPopup';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import DeliveryDateCell from '../../components/tables/cells/DeliveryDateCell';

import {
  adminEditItem,
  displayEditItemPage,
  receiveFormData
} from '../../actions';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const cookies = new Cookies();

//environment variables
const stripe_url = process.env.REACT_APP_STRIPE_URL;

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  cancellationReason: {
    '& .MuiSelect-selectMenu': {
      color: `${colors.errorRed} !important`
    }
  },
  manualRfqIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '#ffa500',
  },
  ppeIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '##008000',
  },
  containerLog: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  activityLog: {
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column'
  }
}));

function CancelledItems(props) {
  const classes = useStyles();

  const {
    openEditItem,
    sendFormData,
    role,
    submitEditPart
  } = props;

  const [selectedItem, setSelectedItem] = useState(null);
  const [onEditStatus, setOnEditStatus] = useState(false);
  const [openNewPartDialog, setOpenNewPartDialog] = useState(false);
  const [showWatchingJobPopup, setShowWatchingJobPopup] = useState(false);
  const [showNotInterestedPopup, setShowNotInterestedPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
      status: ITEM_STATUS_TEXT_MAPPING.CANCELLED,
      cancellationReason: false
    }
  );

  const getTableData = () => {
    adminGetAllItems(tableQueryParams)
      .then(data => {
        setTableData(data.rows);
        updateTableQueryParams({
          totalCount: data.totalCount,
          loading: false,
        });
      });
  }

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      getTableData()
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData()
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [tableQueryParams.search, tableQueryParams.page, tableQueryParams.pageSize, tableQueryParams.cancellationReason]);

  const updateItem = (itemID, informationToUpdate) => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/edit/${itemID}`;
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('token')}`
      },
      body: JSON.stringify(informationToUpdate)
    })
      .then(function (response) {
        response.json().then(res => {
          if (res.success) {
            toast.success('Your item has been updated!', {
              position: toast.POSITION.TOP_RIGHT
            });
            getTableData();
          } else {
            toast.error(
              'Your item cannot be updated. Please try again.',
              {
                position: toast.POSITION.TOP_RIGHT
              }
            );
          }
        });
      })
  };

  const disableItemPPEQuotation = itemID => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/disablePPEQuotation/${itemID}`;
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('token')}`
      }
    });
  };

  async function getItemDetails(id) {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/${id}`;
    let response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${cookies.get('token')}`
      }
    });
    let data = await response.json().then(item => item.data[0]);
    return data;
  }

  async function convertPPEtoRFQ(itemID) {
    let item = await getItemDetails(itemID);
    const itemInformationToUpdate = {
      itemID: itemID,
      editName: item.name || '',
      editReferenceName:
        item.referenceName || `${String(item.itemID).padStart(4, '0')}01`,
      editStatus: 2,
      editPaymentStatus: item.paymentStatus,
      editTech: item.technology,
      editDatePosted: item.datePosted,
      editQuantity: item.quantity || 0,
      editDeadline: item.deadline
        ? new Date(item.deadline)
        : new Date(Date.now() + 12096e5),
      editDescription: item.description,
      editSurfaceFinish: item.surfaceFinish,
      editDeliveryPreference: item.deliveryPreference || 'on_premise',
      editPartApplication: item.partApplication,
      editTolerance: item.tolerance,
      editCadFile: item.cadFile ? item.cadFile.split(',') : [],
      editOriginalFiles: item.originalFiles
        ? item.originalFiles.split(',')
        : [],
      editMaterial: item.material,
      editExpectedPrice: item.expectedPrice,
      editUserID: item.userID,
      editPrice: null,
      editPpeMarkdown: item.ppeMarkdown,
      editColor: item.color,
      verifiedDate: item.verifiedDate
        ? getDateStr(item.verifiedDate)
        : null,
      editDeliveryDate: item.deliveryDate ? new Date(item.deliveryDate) : null,
      editCollectionDate: item.collectionDate
        ? new Date(item.collectionDate)
        : null
    };
    disableItemPPEQuotation(itemID);
    submitEditPart(itemInformationToUpdate);
    setTimeout(() => {
      getTableData();
    }, 2000); // wait for BE update item finished
  }

  const handleOnEditStatus = item => {
    setSelectedItem(item);
    setOnEditStatus(true);
  };

  const handleCancelEditStatus = () => {
    setSelectedItem(null);
    setOnEditStatus(false);
  };

  const handleEditStatus = (itemID, informationToUpdate) => {
    updateItem(itemID, informationToUpdate);
    setSelectedItem(null);
    setOnEditStatus(false);
  };

  const handleDownloadCsv = async () => {
    // Call the API to generate the CSV and get the download URL
    const response = await adminExportItemsAsCsv(tableQueryParams);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    // Download the CSV file using the download URL
    const link = document.createElement('a');
    link.href = downloadUrl;
    const fileName = `All Items With ${tableQueryParams.cancellationReason ? 'Value' : 'Empty'} Cancellation Reason ${generateCurrentCustomDateTimeString()}.csv`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function openStripePaymentIntentID(paymentIntentID) {
    const newWindow = window.open(
      `${stripe_url}${paymentIntentID}`,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  }

  const renderNonPPE = () => {
    return (
      <Tooltip title='Manual RFQ'>
        <Icon>
          <img className={classes.manualRfqIcon} src={PpeNoIcon} alt='' />
        </Icon>
      </Tooltip>
    );
  }

  const renderPPE = () => {
    return (
      <Tooltip title='Instant / Rocket Quoted'>
        <Icon>
          <img className={classes.ppeIcon} src={PpeYesIcon} alt='' />
        </Icon>
      </Tooltip>
    );
  }

  const renderRepeatOrder = () => (
    <Tooltip title='Repeat Order'>
      <RepeatIcon style={{ color: colors.repeatItemColor }} />
    </Tooltip>
  );

  const hubspotCardIdCol = FEATURE_FLAG_HUBSPOT === 'true'
    ? {
      title: 'Hubspot Card ID',
      field: 'hubspotDealID',
      width: 120,
    }
    : {}
    ;

  const columns = [
    {
      headerName: ' ',
      field: 'actions',
      renderCell: params => {
        const rowData = params.row;
        return (
          <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
            <IconButton
              aria-label='edit'
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey) {
                  window.open(`/item/edit/${rowData.itemID}`)
                  return;
                }
                openEditItem(rowData.itemID);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )
      },
      width: 60,
    },
    {
      ...projectIDColumn,
      width: 90,
    },
    partIDWithNameCol({
      setShowWatchingJobPopup,
      setSelectedItem,
      setShowNotInterestedPopup,
      showScheduleIcon: false,
      role,
    }),
    {
      title: 'Customer Part Name',
      headerName: 'Customer Part Name',
      field: 'name',
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.name} />,
      width: 150,
    },
    {
      title: 'Owner (ID)',
      headerName: 'Owner (ID)',
      field: 'ownerID',
      renderCell: params => {
        const rowData = params.row;
        let displayStr = '';
        if (isEmpty(rowData.owners)) {
          displayStr = rowData.buyerName ? `${rowData.buyerName} (${rowData.userID})` : '';
        } else {
          const owner = rowData.owners[0];
          displayStr = `${owner.ownerName} <${owner.ownerEmail}>`;
        }
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 120,
    },
    {
      title: 'Date Uploaded',
      headerName: 'Date Uploaded',
      field: 'datePosted',
      renderCell: params => {
        const rowData = params.row;
        return (
          <Tooltip title='Date and time displayed in SGT'>
            <div>
              {rowData.datePosted ? dateTzSingapore(rowData.datePosted) : ''}
            </div>
          </Tooltip>)
      },
      width: 150,
    },
    {
      title: 'Item Type',
      headerName: 'Item Type',
      field: 'itemType',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <div style={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
            {rowData.price ? renderPPE() : renderNonPPE()}
            {getIsRepeatOrder(rowData.instantQuotesLogMeta) && renderRepeatOrder()}
          </div>
        );
      },
      width: 120,
    },
    {
      title: 'Status',
      headerName: 'Status',
      field: 'status',
      renderCell: params => {
        const rowData = params.row;
        return (
          <div className={classes.containerLog}>
            <StatusButton
              onClickBtn={() => handleOnEditStatus(rowData)}
              item={rowData}
            />
            {rowData.status === ITEM_STAGE_TYPE.CANCELLED && rowData.cancelledDate && (
              <div className={classes.activityLog}>
                <span>{dateTzSingapore(rowData.cancelledDate)}</span>
                <span>{getCancelledItemInfo(rowData)}</span>
              </div>
            )}
          </div>
        );
      },
      width: 170,
    },
    {
      title: 'Cancellation Reason',
      headerName: 'Cancellation Reason',
      field: 'cancellationReason',
      renderCell: ({ row: rowData }) => (
        <TextField
          id='cancellation-reason'
          select
          value={rowData.cancellationReason}
          onChange={(evt) => updateItem(rowData.itemID, { cancellationReason: evt.target.value })}
          variant='outlined'
          margin='dense'
          helperText={isEmpty(rowData.cancellationReason) && 'Please fill cancellation reason'}
          error={isEmpty(rowData.cancellationReason)}
          fullWidth={true}
          className={classes.cancellationReason}
        >
          {Object.values(ITEM_CANCELLATION_REASON).sort().map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      ),
      width: 200,
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: (params) => {
        const rowData = params.row;
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <ImageWith3DViewer
            key={rowData.itemID}
            itemID={rowData.itemID}
            twoDImageUrl={url}
            cadFile={rowData.cadFile || rowData.originalFiles}
            isGenerateItemImage
            width={70}
            height={70}
            noBorder={false}
            borderRadius={0}
            textRenderStyle={{ fontSize: '9px' }}
          />
        );
      },
      width: 100,
    },
    {
      title: 'Technology',
      headerName: 'Technology',
      field: 'technology',
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.technology} />,
      width: 150,
    },
    {
      title: 'Qty',
      headerName: 'Qty',
      field: 'quantity',
      width: 60,
    },
    {
      title: 'Collection Date (from supplier)',
      headerName: 'Collection Date (from supplier)',
      field: 'collectionDate',
      renderCell: params => {
        const rowData = params.row;
        return rowData.collectionDate ? getDateStrWithMonth(rowData.collectionDate) : '';
      },
      width: 120,
    },
    {
      title: 'Delivery Date (to customers)',
      headerName: 'Delivery Date (to customers)',
      field: 'deliveryDate',
      renderCell: params => {
        const rowData = params.row;
        return (
          <DeliveryDateCell rowData={rowData} />
        );
      },
      width: 150,
    },
    {
      title: 'Target Delivery Date',
      headerName: 'Target Delivery Date',
      field: 'deadline',
      renderCell: params => {
        const rowData = params.row;
        const displayStr = rowData.deadline ? rowData.deadline.substring(0, 10) : '';
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 120,
    },
    {
      title: 'Target Unit Price (S$)',
      headerName: 'Target Unit Price (S$)',
      field: 'expectedPrice',
      renderCell: params => {
        const rowData = params.row;
        return rowData.expectedPrice ?
          convertPriceToCurrency(
            {
              price: rowData.expectedPrice,
              currency: CURRENCY_CODE.SGD,
              exchangeRate: 1,
            },
            ''
          )
          : '';
      },
      width: 120,
    },
    {
      title: 'Verified Date',
      headerName: 'Verified Date',
      field: 'verifiedDate',
      renderCell: params => {
        const rowData = params.row;
        const displayStr = rowData.verifiedDate ? rowData.verifiedDate.substring(0, 10) : '';
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 100,
    },
    {
      title: 'Payment Status',
      headerName: 'Payment Status',
      field: 'paymentStatus',
      renderCell: params => {
        const rowData = params.row;
        return rowData.paymentStatus ? (
          <Button
            style={{
              textTransform: 'capitalize',
              background: colors.fontWhite,
              border: `solid 1px ${colors.inputBorderBlue}`,
              borderRadius: '5px',
              fontSize: '9pt',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: '100%'
            }}
            variant='outlined'
            onClick={() => handleOnEditStatus(rowData)}
            size={'small'}
          >
            {rowData.paymentStatus.charAt(0).toUpperCase() +
              rowData.paymentStatus.slice(1)}
          </Button>
        ) : (
          <div></div>
        );
      },
      width: 180,
    },
    {
      title: 'Payment Intent ID',
      headerName: 'Payment Intent ID',
      field: 'paymentIntentID',
      renderCell: params => {
        const rowData = params.row;
        return rowData.paymentIntentID ? (
          <Button
            onClick={() => openStripePaymentIntentID(rowData.paymentIntentID)}
          >
            {rowData.paymentIntentID}
          </Button>
        ) : (
          <div>N/A</div>
        );
      },
      width: 200,
    },
    {
      title: null,
      headerName: ' ',
      field: 'convertRfq',
      renderCell: params => {
        const rowData = params.row;
        return (
          <div className={classes.containerLog}>
            <Button
              style={{
                whiteSpace: 'nowrap',
                textAlign: 'center',
                width: '100%'
              }}
              variant='contained'
              color='primary'
              disabled={
                rowData.price === null ||
                (rowData.price !== null && rowData.status > 3)
              }
              className={classes.button}
              onClick={() => convertPPEtoRFQ(rowData.itemID)}
            >
              Convert PPE to RFQ
            </Button>
            {rowData.ppeToRfqActionOwnerID && rowData.ppeToRfqActionOwnerName && rowData.ppeToRfqDate && (
              <div className={classes.activityLog}>
                <span>{dateTzSingapore(rowData.ppeToRfqDate)}</span>
                <span>{rowData.ppeToRfqActionOwnerName} ({rowData.ppeToRfqActionOwnerID})</span>
              </div>
            )}
          </div>
        );
      },
      width: 185,
    },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    {
      ...toleranceCol,
    },
    {
      title: 'PPE Hashcode Update Status',
      headerName: 'PPE Hashcode Update Status',
      field: 'ppeHashcodeUpdateStatus',
      renderCell: params => {
        const rowData = params.row;
        return getPpeUpdateHashcodeStatusText(rowData);
      },
      width: 120,
    },
    {
      ...hubspotCardIdCol
    },
    {
      title: 'Customer (ID)',
      headerName: 'Customer (ID)',
      field: 'customerID',
      renderCell: params => {
        const rowData = params.row;
        const displayStr = rowData.buyerName ? `${rowData.buyerName} (${rowData.userID})` : '';
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 150,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const navigateToCreateProjectPage = () => {
    props.history.push({
      pathname: `/make-order`,
    });
  }

  const handleClose = () => {
    setOpenNewPartDialog(false);
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <FormControlLabel
          control={
            <Switch
              checked={tableQueryParams.cancellationReason}
              onChange={event => updateTableQueryParams({ cancellationReason: event.target.checked})}
              color='primary'
            />
          }
          label='Cancellation Reason'
        />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  }

  return [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER].includes(role) ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location }
      }}
    />
  ) : (
    <div className={classes.body}>
      <div>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid
            autoHeight
            paginationMode='server'
            rows={tableData}
            columns={columns.map(col => ({
              ...col,
              sortable: false,
            }))}
            getRowId={(row) => row.itemID}
            rowHeight={80}
            headerHeight={80}
            components={{
              Toolbar: getCustomerToolbar,
              Pagination: () => (
                <GridDataPagination
                  pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
                />
              ),
            }}
            pageSize={tableQueryParams.pageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
            page={tableQueryParams.page}
            onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
            rowCount={tableQueryParams.totalCount}
            loading={tableQueryParams.loading}
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
          />
        </div>
        <Dialog
          open={openNewPartDialog}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <NewHardwarePart
            onClose={handleClose}
            sendFormData={sendFormData}
            isDialog={true}
          />
        </Dialog>
        {onEditStatus && (
          <EditItemStatusPopup
            dialog={onEditStatus}
            handleCancel={handleCancelEditStatus}
            item={selectedItem}
            handleEditStatus={handleEditStatus}
          />
        )}
      </div>
      <WatchingJobPopup
        open={showWatchingJobPopup}
        onClose={() => setShowWatchingJobPopup(false)}
        title={`Watching Part #${selectedItem && selectedItem.itemID}`}
        data={get(selectedItem, 'watchingJobs', [])}
      />
      <NotInterestedJobPopup
        open={showNotInterestedPopup}
        onClose={() => setShowNotInterestedPopup(false)}
        itemID={selectedItem && selectedItem.itemID}
        notInterestedJobs={get(selectedItem, 'notInterestedJobs', [])}
        onSuccess={() => {
          getTableData()
          setShowNotInterestedPopup(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    role: state.auth.user.role,
    myUserId: state.auth.user.userID
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    submitEditPart: editedPart => dispatch(adminEditItem(editedPart)),
    openEditItem: id => dispatch(displayEditItemPage(id, props)),
    sendFormData: data => dispatch(receiveFormData(data, props))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(CancelledItems));
