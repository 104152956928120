import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'redux';

import {
  Tooltip,
} from '@material-ui/core';

import CompareQuotesMultiCheckoutMuiTableV2 from '../tables/CompareQuotesMultiCheckoutMuiTableV2';
import PartDetailsDropdown from '../dropdowns/PartDetailsDropdown';
import FtrTabs from '../ftr-components/FtrTabs';
import PartDetailsDropdownV2 from '../dropdowns/PartDetailsDropdownV2';
import { FlexRow, FlexRowSpaceBetween } from '../layouts/FlexLayouts';
import { FtrButton } from '../ftr-components';

import withRequestMoreQuotesPopupHOC from '../../hocs/withRequestMoreQuotesPopupHOC';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import useFeatureFlagHook from '../../hooks/useFeatureFlagHook';

import { isPpeItem } from '../../utils/itemUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { ITEM_STATUS_MAPPING } from '../../constants';


// -------------------------------------------------------------------------------------------------

/**
 * This component for displaying expand item row in Customer individual project management
 * path: /manage-projects/:id
 * 
 * @param {*} props 
 * @returns 
 */
function MultiCheckoutDetailsPanelV2(props) {
  const {
    isAdminView,
    updateTourState = () => { },
  } = useContext(ProjectItemsContext);

  const { isManageProjectsRevampEnabled } = useFeatureFlagHook();

  const {
    rowData,
    allParts,
    isMct = false,
    combinedServiceFee = false,
    getItems = () => { },
    updateRequestQuotePopupState = () => { },
  } = props;

  const TABS = isMct
    ? [
      'Details',
      'Order Information',
    ]
    : [
      'Details',
      'All Quotes',
    ];

  const hasQuotesAvailable = rowData.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
  const hasAnyQuotes = !isEmptyValue(rowData.quotations);

  const [selectedTab, setSelectedTab] = useState(
    (isAdminView && hasAnyQuotes) || hasQuotesAvailable ? 1 : 0
  );

  useEffect(() => {
    if (selectedTab === 1 && !isEmptyValue(rowData.quotations)) {
      updateTourState({
        start: true,
      });
    }
  }, [selectedTab]);

  const handleChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const renderRequestMoreQuotesButton = () => {
    const shouldRender = [
      ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
      ITEM_STATUS_MAPPING.SOURCING,
    ].includes(rowData.status)
      || (rowData.status === ITEM_STATUS_MAPPING.VERIFYING
        && !isPpeItem(rowData)
      );

    if (!shouldRender) {
      return null;
    }

    return (
      <Tooltip title='Request more quotes for different quantities' arrow>
        <div style={{ marginLeft: '0.5rem' }}>
          <FtrButton
            id='request-more-quotes-btn'
            variant='text'
            onClick={() => {
              updateRequestQuotePopupState({
                open: true,
                selectedItem: rowData,
              });
            }}
          >
            Request More Quotes
          </FtrButton>
        </div>
      </Tooltip>
    )
  }

  const renderPartDetailsDropdown = () => {
    if (!isManageProjectsRevampEnabled) {
      return (
        <PartDetailsDropdown
          itemID={rowData.itemID}
          data={allParts}
          getItems={getItems}
        />
      );
    }

    return (
      <PartDetailsDropdownV2
        partInfo={allParts.find(item => item.itemID === rowData.itemID)}
      />
    );
  }

  return (
    <div
      style={{
        paddingLeft: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <FlexRow style={{ gap: 0 }}>
        <div
          style={{
            margin: '0 2rem',
            marginBottom: '1rem',
            width: '100%',
          }}
        >
          <FlexRowSpaceBetween style={{ position: 'relative' }}>
            <FtrTabs.FtrDivider />
            <FtrTabs
              itemList={TABS.map(tab => {
                return {
                  key: tab,
                  text: tab,
                }
              })}
              value={selectedTab}
              onChange={handleChange}
            />
            {renderRequestMoreQuotesButton()}
          </FlexRowSpaceBetween>
        </div>
      </FlexRow>
      {selectedTab === 0 && renderPartDetailsDropdown()}
      {selectedTab === 1 && (
        <CompareQuotesMultiCheckoutMuiTableV2
          item={rowData || {}}
          quotations={rowData ? rowData.quotations : []}
          getItems={getItems}
          combinedServiceFee={combinedServiceFee}
        />
      )}
    </div>
  );
}

export default compose(
  withRequestMoreQuotesPopupHOC,
)(MultiCheckoutDetailsPanelV2);
