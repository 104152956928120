import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { MenuItem, Select, TextField } from '@material-ui/core';

import BootstrapInput from './BootstrapInput';

import BootstrapInputSmall from './BootstrapInputSmall';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';

import { colors } from '../../palette';
import { renderSkeletonOverlay } from '../util/skeleton';
import { THREE_D_TECHNOLOGY } from '../../constants/quotationConstants';
import inputsStyles from './inputsStyles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function ThreeDPrintingTechnologyInputField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles()

  // Defining input styles
  classes.emptyField = inputStyles.emptyField
  classes.v2 = inputStyles.v2

  const {
    visible = false,
    threeDTechnologyOptions = [],
    onChange,
    value,
    error,
    rowDisplay = false,
    shortLabel = false,
    bootstrapStyle = true,
    disabled = false,
    onBlur = () => { },
    disableLabelOnBootstrapStyle = false,
    loading = false,
    v2 = false,
  } = props;

  if (!visible) {
    return null;
  }

  if (bootstrapStyle) {
    return (
      <div
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        {!disableLabelOnBootstrapStyle && (
          <FtrFieldLabel>
            {shortLabel ? '3D Technology' : '3D Printing Technology'}
          </FtrFieldLabel>
        )}
        <div style={{position: 'relative'}}>
          {loading && renderSkeletonOverlay()}
          <Select
            id='3d-technology'
            className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${!value ? classes.emptyField : ''}`}
            input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
            value={value ?? null}
            onChange={(evt) => onChange(evt.target.value)}
            disabled={disabled}
            onBlur={onBlur}
            displayEmpty
            renderValue={(selected) => selected ?? '3D Technology'}
          >
            {threeDTechnologyOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    )
  }

  return (
    <div style={{position: 'relative'}}>
      {loading && renderSkeletonOverlay()}
      <TextField
        name={THREE_D_TECHNOLOGY}
        select
        label="3D Printing Technology"
        variant="outlined"
        onChange={(evt) => onChange(evt.target.value)}
        error={error}
        value={value}
        margin="dense"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        onBlur={onBlur}
      >
        {threeDTechnologyOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

export default ThreeDPrintingTechnologyInputField;
