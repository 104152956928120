import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import FtrDownArrowButton from '../ftr-components/buttons/FtrDownArrowButton';
import FtrUpArrowButton from '../ftr-components/buttons/FtrUpArrowButton';

import { colors } from '../../palette';
import { isEmptyValue } from '../../utils/commonUtils';
import { isSelectableQuote } from '../../utils/quotationUtils';
import CustomerTableRowText from '../ftr-components/table/CustomerTableRowText';
import MultiCheckoutDetailsPanelV2 from '../panels/MultiCheckoutDetailsPanelV2';


// --------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  tableRowRoot: {
    verticalAlign: 'top',
    backgroundColor: colors.fontWhite,
    position: 'relative',
  },
  tableContainer: {
    overflowX: 'auto',
    '& .MuiTableCell-root': {
      padding: '0.8rem',
    },
  },
  iconExpandRow: {
    '& .MuiIconButton-root:not(.MuiCheckbox-root) > .MuiIconButton-label': {
      background: colors.buttonColorBlueGradient,
      borderRadius: '50%',
      '& .MuiSvgIcon-root': {
        fill: colors.fontWhite
      }
    }
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.neutral020,
    },
  },
  tableRowQuoteSelected: {
    backgroundColor: colors.blue020,
  },
}));

function CollapsibleRow(props) {
  const classes = useStyles();

  const {
    row,
    columns,
    allParts,
    getItems,
    expand,
    isAdminView,
    multiCheckoutForm,
    isMct = false,
    combinedServiceFee = false,
  } = props;
  const [open, setOpen] = React.useState(false);

  const hasRelevantQuotes = useMemo(() => {
    if (isEmptyValue(row.quotations)) {
      return false;
    }
    return row.quotations.some(quote => isSelectableQuote(quote, isAdminView));
  }, [isAdminView, row]);

  const isSelectedQuote = (quote) => {
    for (const selectedQuote of multiCheckoutForm.selectedQuotes) {
      if (quote.quotationID === selectedQuote.quotationID) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!hasRelevantQuotes) {
      setOpen(false);
    }
  }, [isAdminView]);

  useEffect(() => {
    if (expand && hasRelevantQuotes) {
      setOpen(true);
    } else if (!expand) {
      const hasItemAnySelectedQuotes = row.quotations.some(isSelectedQuote);
      if (!hasItemAnySelectedQuotes) {
        setOpen(false);
      }
    }
  }, [expand]);

  return (
    <>
      <TableRow
        key={row.name}
        onClick={() => setOpen(!open)}
        classes={{
          root: clsx(
            classes.tableRowRoot,
            row.quotations.some(isSelectedQuote) && !open && classes.tableRowQuoteSelected,
          ),
        }}
        className={classes.tableRow}
      >
        <TableCell className={classes.iconExpandRow} style={{ width: 50, borderBottom: 'none' }}>
          {open && <FtrUpArrowButton onClick={() => setOpen(!open)} />}
          {!open && <FtrDownArrowButton onClick={() => setOpen(!open)} />}
        </TableCell>
        {columns.map((column) => {
          const renderFunc = column.render;
          if (!renderFunc && !column.field) {
            return null;
          }
          return (
            <TableCell
              key={row.field}
              style={{
                width: column.width,
                borderBottom: 'none',
              }}
            >
              <CustomerTableRowText>
                {typeof renderFunc === 'function'
                  ? renderFunc(row)
                  : row[column.field]}
              </CustomerTableRowText>
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <MultiCheckoutDetailsPanelV2
              rowData={row}
              allParts={allParts}
              getItems={() => getItems(row.projectID)}
              isMct={isMct}
              combinedServiceFee={combinedServiceFee}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CustomerItemMuiTableV2Presentational(props) {
  const {
    columns = [],
    rows = [],
    isAdminView = false,
    triggerExpand = false,
    isMct = false,
    combinedServiceFee = false,
    getItems = () => { },
    multiCheckoutForm = [],
  } = props;

  return (
    <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            {columns.map(column => {
              if (column.hidden) {
                return <TableCell key={column.title} />;
              }

              return (
                <TableCell
                  key={column.title}
                  style={{
                    top: 0,
                    color: colors.neutral060,
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <CollapsibleRow
                key={row.name}
                row={row}
                columns={columns}
                allParts={rows}
                getItems={getItems}
                expand={triggerExpand}
                isAdminView={isAdminView}
                multiCheckoutForm={multiCheckoutForm}
                isMct={isMct}
                combinedServiceFee={combinedServiceFee}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomerItemMuiTableV2Presentational;
