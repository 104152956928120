import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';

import { Cancel } from '@material-ui/icons';

import MultiSelectChipDropdownWithCategory from '../dropdowns/MultiSelectChipDropdownWithCategory';
import FtrGrayBorderSquareBox from '../ftr-components/FtrGrayBorderSquareBox';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import { FtrB3, FtrButton, FtrH3, FtrH6 } from '../ftr-components';

import {
  getAvailableAssociateAccountsApi,
  getSalesConsultantAssociatedAccountsApi,
  updateSalesConsultantAssociatedAccountsApi,
} from '../../apis/userApi';

import { getAllUsers } from '../../actions';

import { isEmptyValue } from '../../utils/commonUtils';
import { compareStringsEqualsIgnoreCase, compareStringsIgnoreCase, snakeCaseToTitleCase } from '../../utils/stringUtils';
import { groupUsersByKeyForDropDown } from '../../utils/selectUserUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { ROLE_TYPES } from '../../constants';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    minHeight: 300,
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function SalesCustomersAssociationPopup(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    open,
    userID,
    onClose = () => { },
  } = props;

  const {
    data: associatedAccounts,
  } = useQuery(
    ['getSalesConsultantAssociatedAccountsApi', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getSalesConsultantAssociatedAccountsApi(userID);
    },
  );

  const {
    data: availableAccounts,
  } = useQuery(
    ['getAvailableAssociateAccounts', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getAvailableAssociateAccountsApi(userID);
    },
  );

  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [selectedDeleteAccountIds, setDeleteSelectedAccountIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const associatedAccountsDisplay = useMemo(() => {
    if (isEmptyValue(associatedAccounts?.role)) {
      return [];
    }

    const displayAccounts = associatedAccounts?.role === ROLE_TYPES.BUYER
      ? associatedAccounts?.sales || []
      : associatedAccounts.customers || [];

    return displayAccounts.filter((customer) => {
      return !selectedDeleteAccountIds.includes(customer.userID);
    });
  }, [
    associatedAccounts,
    selectedDeleteAccountIds,
  ]);

  // -----------------------------------------------------------------------------------------------

  const onAccountSelectChange = (newValues) => {
    setSelectedAccountIds(newValues);
  }

  const handleUpdateAssociation = () => {
    const body = {
      userID,
      addUserIDs: selectedAccountIds,
      removeUserIDs: selectedDeleteAccountIds,
    }

    setLoading(true);
    updateSalesConsultantAssociatedAccountsApi(body)
      .then(() => {
        dispatch(getAllUsers());
        setLoading(false);
        notifySuccess('Associated accounts updated successfully');
      })
      .catch(() => {
        setLoading(false);
        notifyError('Error updating associated accounts');
      });
  }

  const renderBlueButtonStyle = () => {
    return (
      <FlexRow
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton
          variant='text-black'
          onClick={onClose}
        >
          Cancel
        </FtrButton>
        <FtrButton
          id='proceed-delete-btn'
          color='blue'
          variant='contained'
          onClick={() => {
            handleUpdateAssociation();
            onClose();
          }}
          autoFocus
          disabled={loading || (isEmptyValue(selectedAccountIds) && isEmptyValue(selectedDeleteAccountIds))}
          loading={loading}
        >
          Update
        </FtrButton>
      </FlexRow>
    );
  }

  const renderDialogActions = () => {
    return renderBlueButtonStyle();
  }

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle>
        <FtrH6 style={{ marginBottom: '1rem' }}>
          Update Customers & Sales Consultant Accounts Association
        </FtrH6>
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <div>
          {renderMainContent()}
        </div>
      </DialogContent>
      <DialogActions>
        {renderDialogActions()}
      </DialogActions>
    </Dialog>
  );

  function renderMainContent() {
    let itemList = groupUsersByKeyForDropDown(availableAccounts);
    itemList.sort((a, b) => {
      if (compareStringsEqualsIgnoreCase(a.key, ROLE_TYPES.SALES_CONSULTANT)) {
        return -1;
      }

      return compareStringsIgnoreCase(a.key, b.key);
    });

    return (
      <FlexColumn style={{ alignItems: 'start', width: '100%' }}>
        <FlexRow>
          <FtrB3 style={{ fontWeight: 'bold' }}>User Info:</FtrB3>
          <FtrB3>
            {associatedAccounts?.name} ({associatedAccounts?.email}) ({associatedAccounts?.userID}) ({snakeCaseToTitleCase(associatedAccounts?.role)})
          </FtrB3>
        </FlexRow>
        <MultiSelectChipDropdownWithCategory
          id='multi-select-accounts'
          label='Select Account(s)'
          itemList={itemList}
          value={selectedAccountIds}
          onSelect={onAccountSelectChange}
          searchable
        />
        {renderAssociatedAccounts()}
      </FlexColumn>
    );
  }

  function renderAssociatedAccounts() {
    if (isEmptyValue(associatedAccountsDisplay)) {
      return;
    }

    return (
      <FlexColumn style={{ marginTop: '1rem' }}>
        <FtrH3>Associated Accounts</FtrH3>
        <FtrGrayBorderSquareBox style={{ width: '100%', maxHeight: 200, overflow: 'auto' }}>
          <FlexColumn>
            {associatedAccountsDisplay?.map((customer) => {
              return (
                <FlexRow key={customer.userID}>
                  <FtrB3>{customer.name} ({customer.email}) ({customer.userID})</FtrB3>
                  <Tooltip title='Remove'>
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        setDeleteSelectedAccountIds([
                          ...selectedDeleteAccountIds,
                          customer.userID,
                        ]);
                      }}
                      size='small'
                    >
                      <Cancel color='error' style={{ fontSize: '15pt' }} />
                    </IconButton>
                  </Tooltip>
                </FlexRow>
              );
            })}
          </FlexColumn>
        </FtrGrayBorderSquareBox>
      </FlexColumn>
    );
  }
}

export default SalesCustomersAssociationPopup;
