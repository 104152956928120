import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core';
import { isEmptyValue } from '../../utils/commonUtils';

import inputsStyles from '../inputs/inputsStyles';

import { FlexRow } from '../layouts/FlexLayouts';
import { colors } from '../../palette';
import { renderSkeletonOverlay } from '../util/skeleton';

const useStyles = (props) => makeStyles(() => ({
  selectDropdown: {
    borderRadius: 10,
    '& .MuiSelect-select': {
      color: '#4F4F4F',
      fontWeight: 600,
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
    backgroundColor: colors.neutral010,
    '&:hover': {
      backgroundColor: colors.neutral020,
    }
  },
  menuItemRoot: {
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&.Mui-selected': {
      backgroundColor: '#EDEDED',
      fontWeight: '600'
    },
  },
  paper: {
    borderRadius: '0.8rem',
    padding: '0.5rem',
    ...props.menuStyle
  },
}));

/**
 * Customised Dropdown component
 *
 * @param {object} props - The properties of the Dropdown component.
 * @param {String} props.id - A unique identifier for the dropdown.
 * @param {String} props.value - The value of the dropdown.
 * @param {Boolean} props.fullWidth - If true, the dropdown will take up the full width of the parent div.
 * @param {Function} props.handleChange - Callback function to be executed when the dropdown value is changed.
 * @param {Array.<{key: String, display: String=}>} props.items - The items to be displayed in the dropdown.
 * @param {Boolean} props.loading - The loading status of the component
 * @param {React.CSSProperties} props.menuStyle - Css style for menu
 *
 * @returns {React.ReactNode} The rendered Dropdown component.
 */
function FtrDropdown(props) {
  const {
    id,
    value,
    fullWidth = false,
    handleChange,
    items = [],
    loading = false,
    placeholder = '',
    menuStyle = {},
  } = props;

  const classes = useStyles({ menuStyle })();
  const inputStyles = inputsStyles()


  const menuProps = {
    classes: {
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    getContentAnchorEl: null,
  };

  // Defining input styles
  classes.emptyField = inputStyles.emptyField

  if (isEmptyValue(items)) {
    return;
  }

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      <FlexRow>
        {loading && (renderSkeletonOverlay())}
          <Select
            className={clsx(classes.selectDropdown, classes.disabled, !value && classes.emptyField)}
            id={id}
            variant="outlined"
            value={value ?? null}
            onChange={(event) => {
              // clicking the background component should not be allowed
              event.preventDefault();
              event.stopPropagation();
              handleChange(event.target.value)
            }}
            onClick={event => {
              // To handle cases when the component is disabled
              // clicking the background component should not be allowed
              event.preventDefault();
              event.stopPropagation();
            }}
            MenuProps={menuProps}
            margin="dense"
            fullWidth
            renderValue={value => value ?? placeholder}
            displayEmpty
            {...props}
          >
            {items.map((item) => {
              return (
                <MenuItem
                  key={item.key ?? item}
                  classes={{
                    root: classes.menuItemRoot,
                  }}
                  value={item}
                >
                  {item.key ?? item}
                </MenuItem>
              );
            })}
          </Select>
      </FlexRow>
    </FormControl>
  );
}

export default FtrDropdown;
