import React, { useReducer, forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { deleteOrder } from '../actions';

import FtrDeletePopup from '../components/ftr-components/FtrDeletePopup';

// ------------------------------------------------------------------------------

function withDeleteQuotePopupHOC(WrappedComponent) {
  const DeleteQuotePopupHOC = forwardRef((props, ref) => {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => ({ ...prev, ...next }),
      { open: false }
    );

    const dispatch = useDispatch();

    const submitDeleteOrder = () => {
      const { deletedOrderId, history } = localState;
      dispatch(
        deleteOrder({
          deletedOrderId, history
        })
      )
    }

    return (
      <>
        <WrappedComponent
          ref={ref} // Forward the ref to the WrappedComponent
          onDeleteQuotePopup={({ deletedOrderId, history }) => {
            updateLocalState({
              open: true,
              deletedOrderId,
              history
            });
          }}
          {...props}
        />

        <FtrDeletePopup
          open={localState.open}
          title={`Delete quote #${localState.deletedOrderId}?`}
          subtitle="Please ensure that you are deleting the correct quote as this
          action cannot be undone."
          onClose={() => updateLocalState({ open: false })}
          onConfirm={submitDeleteOrder}
        />
      </>
    );
  });

  // Set the displayName for the HOC
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  DeleteQuotePopupHOC.displayName = `withDeleteQuotePopup(${wrappedComponentName})`;

  return DeleteQuotePopupHOC;
}

export default withDeleteQuotePopupHOC;
