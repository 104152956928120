import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import { createAlertNotification } from '../apis/alertApi';

import {
  addOrUpdateCadPart,
  autoMatchingPdfFiles,
  generateDefectAction,
  generateImageAndGetPriceAction,
  generateImageAndGetPriceExcludePartHasPdf,
  generateImageForItem,
  extractTdeForItemAndGetPrice,
  GET_PPE_PRICE,
  getPpePriceForCadPart,
  removeCadPartAction,
  updateCadPartIfExists,
  updatePartUploadFormState,
} from '../actions';

import { getPartUploadFormSelector } from '../selectors/partUploadFormSelector';
import { getFormDataAvailableSelector } from '../selectors/formDataAvailableSelector';
import {
  getUserIDSelector,
  getUserUnitTypeSelector,
} from '../selectors/userSelector';

import { getItemNameFromCadFile } from '../utils/itemUtils';
import { extractAllZipFiles } from '../utils/zipUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { separateZipAndNonZipFiles } from '../actions/utils/cadPartUploadUtils';

import { uploadCadFileToS3 } from '../services/s3Service';
import { notifyError } from '../services/notificationService';

import { FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING } from '../constants/featureFlagConstants';
import {
  MAX_UPLOAD_FILES,
  MAX_UPLOAD_FILES_ERROR_MESSAGE,
  TECHNOLOGY_OPTION_TYPE,
} from '../constants/NewPartConstants';


// ------------------------------------------------------------------------------

const useCadUpload = () => {
  const userID = useSelector(getUserIDSelector);
  const unitType = useSelector(getUserUnitTypeSelector);
  const formDataAvailable = useSelector(getFormDataAvailableSelector);
  const partUploadForm = useSelector(getPartUploadFormSelector);

  const dispatch = useDispatch();

  const [technologyParamsInfo, setTechnologyParamsInfo] = useState({
    technology: TECHNOLOGY_OPTION_TYPE.CNC_MACHINING
  });

  const updateTechnologyParamsInfo = (params) => {
    setTechnologyParamsInfo(params);
  }

  const updateTechnologyParamsInfoForAllParts = (params) => {
    setTechnologyParamsInfo(params);
    for (const part of formDataAvailable) {
      dispatch(updateCadPartIfExists({
        id: part.id,
        ...params,
      }));
      dispatch(getPpePriceForCadPart(part.id));
    }
  }

  const uploadCadFilesBase = async (files = []) => {
    const {
      zipFiles,
      normalFiles,
    } = separateZipAndNonZipFiles(files);

    let processFiles = isEmptyValue(zipFiles)
      || FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true'
      ? normalFiles
      : [
        ...normalFiles,
        ...await extractAllZipFiles(zipFiles),
      ];

    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING === 'true') {
      const supportedFileTypes = partUploadForm.supportedFileTypes || [];
      if (!isEmptyValue(supportedFileTypes)) {
        processFiles = processFiles.filter(file => {
          return supportedFileTypes?.some(ext => file.name?.toLowerCase()?.endsWith(ext));
        });
      }
    }

    if (processFiles.length + formDataAvailable.length > MAX_UPLOAD_FILES) {
      notifyError(MAX_UPLOAD_FILES_ERROR_MESSAGE);
      return;
    }

    const newItemList = [];
    for (const file of processFiles) {
      const id = nanoid();
      newItemList.push(id);
      let cadPart = {
        id,
        uploadStatus: 'loading',
        imageStatus: 'loading',
        ppePricingStatus: 'loading',
        ...technologyParamsInfo,
        status: 1,
        qty: 1,
        userID,
        remarks: '',
        checked: true,
        deleted: false,
        imageFile: '',
        deliveryPreference: 'on_premise',
        price: null,
        markupPercent: null,
        totalPrice: null,
        originalPrice: null,
        expectedLeadTime: null,
        unitType,
      };
      dispatch(addOrUpdateCadPart(cadPart));
      try {
        const {
          s3ObjectUrl,
          fileSize,
        } = await uploadCadFileToS3(file);
        cadPart = {
          id,
          fileSize,
          s3ObjectUrl,
          uploadStatus: 'success',
          cadPart: [s3ObjectUrl],
          name: getItemNameFromCadFile(s3ObjectUrl),
        };
        dispatch(addOrUpdateCadPart(cadPart));
      } catch (error) {
        notifyError(`Failed to upload file: ${file.name}`);
        const body = {
          title: '[WARNING] [FE] Customer upload file failed!',
          message: error?.message,
          errorStack: error?.stack,
          additionalInfo: {
            userID,
            file: {
              name: file.name,
              size: file.size,
            },
          }
        }
        createAlertNotification(body);
        dispatch(removeCadPartAction(id));
      }
    }

    return newItemList;
  }

  const uploadCadFiles = async (files = []) => {
    const newItemList = await uploadCadFilesBase(files);
    for (const itemID of newItemList) {
      dispatch(generateImageAndGetPriceAction(itemID));
    }

    if (!userID) {
      dispatch(
        updatePartUploadFormState({
          totalUploadCountGuestUser:
            (partUploadForm?.totalUploadCountGuestUser || 0) + files.length,
        })
      );
    }
  }

  const uploadCadFilesStep1 = async (files = []) => {
    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      return uploadCadFiles(files);
    }

    const newItemList = await uploadCadFilesBase(files);

    // this should be fast and a sync dispatch
    dispatch(autoMatchingPdfFiles());

    for (const itemID of newItemList) {
      dispatch(generateImageAndGetPriceExcludePartHasPdf(itemID));
      dispatch(generateDefectAction(itemID));
    }
  }

  const uploadCadFilesStep2 = async (files = []) => {
    if (!userID) {
      dispatch(
        updatePartUploadFormState({
          totalUploadCountGuestUser:
            (partUploadForm?.totalUploadCountGuestUser || 0) + files.length,
        })
      );
    }

    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      return uploadCadFiles(files);
    }

    const newItemList = await uploadCadFilesBase(files);

    for (const itemID of newItemList) {
      // call this so that image generation and tde can be run in parallel
      dispatch(generateImageForItem(itemID));
    }

    dispatch(autoMatchingPdfFiles({
      getPpePrice: GET_PPE_PRICE.YES,
    }));

    for (const itemID of newItemList) {
      dispatch(extractTdeForItemAndGetPrice(itemID));
      dispatch(generateDefectAction(itemID));
    }
  }

  const removeCadPart = (id) => {
    dispatch(removeCadPartAction(id));
  }

  return [
    {
      data: formDataAvailable,
    },
    {
      uploadCadFiles,
      uploadCadFilesStep1,
      uploadCadFilesStep2,
      removeCadPart,
      updateTechnologyParamsInfo,
      updateTechnologyParamsInfoForAllParts,
    }
  ]
};

export default useCadUpload;
