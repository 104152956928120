import React from "react";

import { Grid } from "@material-ui/core";
import {
  CropSquare as SquareIcon,
  Delete as DeleteIcon,
  RemoveCircle as HideCanvasIcon,
  Backup as UploadIcon,
  Visibility as PreviewIcon
} from '@material-ui/icons';

import FtrButton from '../../ftr-components/FtrButton';

import { useModifyPdfCanvasContext } from "../../../context/ModifyPdfCanvasContext";

const ModifyPdfPopupActionBar = (props) => {
  const {
    itemID,
    editCadFile,
    setEditCadFile,
    handleClose,
    revision
  } = props
  const {
    addRect,
    deleteSelected,
    clearCanvas,
    createPdf
  } = useModifyPdfCanvasContext()

  return (
    <Grid
      item
      container
      xs={12}
      spacing={1}
      style={{
        margin: '1rem 0'
      }}
      justifyContent="center"
    >
      <Grid item xs={2}>
        <FtrButton
          color="blue"
          startIcon={<SquareIcon />}
          onClick={addRect}
          fullWidth
          variant="outlined"
        >
          Add white square
        </FtrButton>
      </Grid>
      <Grid item xs={2}>
        <FtrButton
          color="red"
          startIcon={<DeleteIcon />}
          onClick={deleteSelected}
          fullWidth
          variant="outlined"
        >
          Delete selected
        </FtrButton>
      </Grid>
      <Grid item xs={2}>
        <FtrButton
          color="red"
          startIcon={<HideCanvasIcon />}
          onClick={clearCanvas}
          fullWidth
        >
          Remove annotations
        </FtrButton>
      </Grid>
      <Grid item xs={2}>
        <FtrButton
          color="blue"
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => createPdf({previewPdf: true })}
          fullWidth
        >
          Preview
        </FtrButton>
      </Grid>
      <Grid item xs={2}>
        <FtrButton
          color="blue"
          startIcon={<UploadIcon />}
          onClick={() => createPdf({
            itemID,
            editCadFile,
            setEditCadFile,
            handleClose,
            revision
          })}
          fullWidth
        >
          Upload
        </FtrButton>
      </Grid>
    </Grid>
  )
}

export default ModifyPdfPopupActionBar;
