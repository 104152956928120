import React from 'react';
import { get } from 'lodash';

import { Button } from '@material-ui/core';

import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import { ItemRfqEmailScheduleConfigButton } from '../components/ItemRfqEmailScheduleConfigButton';
import { NotInterestedJob } from '../components/NotInterestedJob';
import { ViewAllowSuppliersButton } from '../components/ViewAllowSuppliersButton';
import { WatchingJob } from '../components/WatchingJob';

import { dateTzSingapore } from '../utils/dateTimeUtils';
import { getCancelledItemInfo, getMaterialWithColorText, getSurfaceFinishWithCustomizationsText, getTotalWatchingJobsByUserID } from '../utils/itemUtils';
import { includesIgnoreCase } from '../utils/stringUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { openAdminEditItem, openAdminEditProject } from '../utils/navigationUtils';
import { showUnitValueFromMetric } from '../utils/userUtils';

import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from './itemConstants';

import { colors } from '../palette';


// -------------------------------------------------------------------------------------------------

export const projectIDColumn = {
  title: 'Project ID',
  headerName: 'Project ID',
  field: 'projectID',
  renderCell: ({ row: rowData }) => {
    return (
      <Button
        style={{
          color: colors.blue050,
          textDecoration: 'underline',
          fontSize: '16px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          openAdminEditProject(rowData.projectID);
        }}
      >
        {rowData.projectID}
      </Button>
    );
  },
}

export const partIDColumnWithLink = {
  headerName: 'Part ID',
  field: 'itemID',
  renderCell: ({ row: rowData }) => {
    return (
      <Button
        style={{
          color: colors.blue050,
          textDecoration: 'underline',
          fontSize: '16px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          openAdminEditItem(rowData.itemID);
        }}
      >
        {rowData.itemID}
      </Button>
    );
  },
}

export const deliveryOptionCol = {
  title: 'Delivery Option',
  cellStyle: {
    minWidth: 150,
  },
  render: rowData => {
    const deliveryOptionDisplay = isEmptyValue(rowData.deliveryOption)
      ? 'N.A.'
      : DELIVERY_OPTIONS_DISPLAY_MAPPING[rowData.deliveryOption];
    return deliveryOptionDisplay;
  },
  customFilterAndSearch: (term, rowData) => {
    const data = isEmptyValue(rowData.deliveryOption)
      ? 'N.A.'
      : DELIVERY_OPTIONS_DISPLAY_MAPPING[rowData.deliveryOption];
    return includesIgnoreCase(data, term);
  },
  // for data grid
  headerName: 'Delivery Option',
  field: 'deliveryOption',
  valueGetter: (params) => {
    return isEmptyValue(params.row.deliveryOption)
      ? 'N.A.'
      : DELIVERY_OPTIONS_DISPLAY_MAPPING[params.row.deliveryOption];
  },
  renderCell: params => (
    <DataGridWrapTextCell text={params.value} />
  ),
  minWidth: 150,
};

const getCadFileLinksDesign = (fileStr) => {
  const cadFileLinks = fileStr
    ? fileStr
      .split(',')
      .filter(file => !file.toLowerCase().includes('.pdf'))
      .toString()
    : 'N.A.';
  return cadFileLinks || 'N.A.';
}

export const customerCadFileLinksDesignCol = {
  title: 'Customer Cad File links (Design)',
  render: rowData => getCadFileLinksDesign(rowData.originalFiles),
  customFilterAndSearch: (term, rowData) => {
    const data = getCadFileLinksDesign(rowData.originalFiles);
    return includesIgnoreCase(data, term);
  },
  // for data grid
  field: 'customerCadFileLinksDesign',
  headerName: 'Customer Cad File links (Design)',
  valueGetter: ({ row: rowData }) => getCadFileLinksDesign(rowData.originalFiles),
  renderCell: ({ row: rowData }) => (
    <DataGridWrapTextCell text={getCadFileLinksDesign(rowData.originalFiles)} />
  ),
  width: 380,
}

export const factoremCadFileLinksDesignCol = {
  title: 'Factorem Cad File links (Design)',
  render: rowData => getCadFileLinksDesign(rowData.cadFile),
  customFilterAndSearch: (term, rowData) => {
    const data = getCadFileLinksDesign(rowData.cadFile);
    return includesIgnoreCase(data, term);
  },
  // for data grid
  headerName: 'Factorem Cad File links (Design)',
  field: 'factoremCadFileLinksDesign',
  valueGetter: ({ row: rowData }) => getCadFileLinksDesign(rowData.cadFile),
  renderCell: ({ row: rowData }) => (
    <DataGridWrapTextCell text={getCadFileLinksDesign(rowData.cadFile)} />
  ),
  width: 380,
}

const getCadFileLinksPdf = (fileStr) => {
  const cadFileLinks = fileStr
    ? fileStr
      .split(',')
      .filter(file => file.toLowerCase().includes('.pdf'))
      .toString()
    : 'N.A.';
  return cadFileLinks || 'N.A.';
}

export const customerCadFileLinksPdfCol = {
  title: 'Customer Cad File links (PDF)',
  render: rowData => getCadFileLinksPdf(rowData.originalFiles),
  customFilterAndSearch: (term, rowData) => {
    const data = getCadFileLinksPdf(rowData.originalFiles);
    return includesIgnoreCase(data, term);
  },
  // for data grid
  headerName: 'Customer Cad File links (PDF)',
  field: 'customerCadFileLinksPdf',
  valueGetter: ({ row: rowData }) => getCadFileLinksPdf(rowData.originalFiles),
  renderCell: ({ row: rowData }) => (
    <DataGridWrapTextCell text={getCadFileLinksPdf(rowData.originalFiles)} />
  ),
  width: 380,
}

export const factoremCadFileLinksPdfCol = {
  title: 'Factorem Cad File links (PDF)',
  render: rowData => getCadFileLinksPdf(rowData.cadFile),
  customFilterAndSearch: (term, rowData) => {
    const data = getCadFileLinksPdf(rowData.cadFile);
    return includesIgnoreCase(data, term);
  },
  // for data grid
  field: 'factoremCadFileLinksPdf',
  headerName: 'Factorem Cad File links (PDF)',
  valueGetter: ({ row: rowData }) => getCadFileLinksPdf(rowData.cadFile),
  renderCell: ({ row: rowData }) => (
    <DataGridWrapTextCell text={getCadFileLinksPdf(rowData.cadFile)} />
  ),
  width: 380,
}

export const targetPriceCol = {
  title: 'Target Unit Price (S$)',
  field: 'expectedPrice',
  hidden: true,
  export: true,
}

export const deliveryCostCol = {
  title: 'Delivery Cost (S$)',
  field: 'actualDeliveryCost',
  hidden: true,
  export: true,
}

export const surfaceFinishingCol = {
  title: 'Finishing',
  field: 'surfaceFinish',
  export: true,
}

export const surfaceFinishingWithColorCol = {
  title: 'Finishing',
  headerName: 'Finishing', // for data grid
  field: 'surfaceFinish', // for data grid
  render: rowData => {
    return getSurfaceFinishWithCustomizationsText(rowData);
  },
  // for data grid
  valueGetter: params => {
    const rowData = params.row;
    return getSurfaceFinishWithCustomizationsText(rowData);
  },
  renderCell: params => {
    const rowData = params.row;
    const displayStr = getSurfaceFinishWithCustomizationsText(rowData);
    return <DataGridWrapTextCell text={displayStr} />;
  },
  customFilterAndSearch: (term, rowData) => {
    const data = getSurfaceFinishWithCustomizationsText(rowData);
    return includesIgnoreCase(data, term);
  },
  exportData: rowData => {
    return getSurfaceFinishWithCustomizationsText(rowData);
  },
  minWidth: 200, // for data grid
}

export const materialCol = {
  title: 'Material',
  field: 'material',
}

export const materialWithColorCol = {
  title: 'Material',
  headerName: 'Material', // for data grid
  field: 'material',
  render: rowData => {
    return getMaterialWithColorText(rowData);
  },
  // for data grid
  valueGetter: params => {
    const rowData = params.row;
    return getMaterialWithColorText(rowData);
  },
  renderCell: params => {
    const rowData = params.row;
    const displayStr = getMaterialWithColorText(rowData);
    return <DataGridWrapTextCell text={displayStr} />;
  },
  customFilterAndSearch: (term, rowData) => {
    const data = getMaterialWithColorText(rowData);
    return includesIgnoreCase(data, term);
  },
  exportData: rowData => {
    return getMaterialWithColorText(rowData);
  },
  minWidth: 200, // for data grid
}

export const descriptionCol = {
  title: 'Description',
  field: 'description',
  hidden: true,
  export: true,
}

export const partApplicationCol = {
  title: 'Part Application',
  field: 'partApplication',
  hidden: true,
  export: true,
}

export const deliveryPreferenceCol = {
  title: 'Delivery Preference',
  field: 'deliveryPreference',
  hidden: true,
  export: true,
}

export const toleranceCol = {
  title: 'Tightest Tolerance Required (mm)',
  headerName: 'Tightest Tolerance',
  field: 'tolerance',
  valueGetter: ({ row: rowData }) => {
    return `±${showUnitValueFromMetric(rowData.tolerance, rowData.unitType)}`;
  },
  hidden: true,
  export: true,
  width: 110,
}

export const colorCol = {
  title: 'Color',
  field: 'color',
  hidden: true,
  export: true,
}

export const threeDTechnologyCol = {
  title: '3D Printing Technology',
  exportData: rowData => {
    const data = get(rowData.metadata, 'threeDTechnology') || '';
    return data;
  },
  hidden: true,
  export: true,
}

export const threeDInfillCol = {
  title: '3D Infill',
  exportData: rowData => {
    const data = get(rowData.metadata, 'threeDInfill') || '';
    return isEmptyValue(data) ? '' : `${Number(data * 100).toFixed(2)}%`;
  },
  hidden: true,
  export: true,
}

export const threeDLayerThicknessCol = {
  title: '3D Layer Thickness',
  exportData: rowData => {
    const data = get(rowData.metadata, 'threeDLayerThickness') || '';
    return isEmptyValue(data) ? '' : `${data}mm`;
  },
  hidden: true,
  export: true,
}

export const partIDColumn = {
  title: 'Part ID',
  field: 'itemID',
  cellStyle: {
    width: '5%',
    maxWidth: 5
  },
  width: 50,
  // for datagrid
  headerName: 'Part ID',
}

export const renderCancelledItemInfo = (rowData) => {
  return (
    <div
      style={{
        fontSize: '12px',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span>
        {rowData.cancelledDate && dateTzSingapore(rowData.cancelledDate)}
      </span>
      <span>{getCancelledItemInfo(rowData)}</span>
    </div>
  );
};

export const partIDWithNameCol = ({
  setShowWatchingJobPopup,
  setSelectedItem,
  setShowNotInterestedPopup,
  updateItemAllowedSuppliersPopupState,
  showScheduleIcon = true,
  role,
}) => ({
  title: '(Part ID) Part Name',
  headerName: '(Part ID) Part Name',
  field: 'partName',
  renderCell: params => {
    const rowData = params.row;
    const watchingList = rowData.watchingJobs || []
    const hasWatchingUsers = watchingList.length > 0;
    const notInterestedList = rowData.notInterestedJobs || [];
    const totalNotInterestedSuppliers = notInterestedList.length;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <span>
          ({rowData.itemID}) {rowData.referenceName}
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <WatchingJob
            forAdmin
            isWatching={hasWatchingUsers}
            totalWatching={getTotalWatchingJobsByUserID(watchingList)}
            onClick={() => {
              if (hasWatchingUsers) {
                setShowWatchingJobPopup(true);
                setSelectedItem(rowData);
              }
            }}
          />
          <NotInterestedJob
            forAdmin
            totalSuppliers={totalNotInterestedSuppliers}
            onClick={() => {
              if (totalNotInterestedSuppliers > 0) {
                setShowNotInterestedPopup(true);
                setSelectedItem(rowData);
              }
            }}
          />
          {isAdminOrHigherRole(role) && updateItemAllowedSuppliersPopupState && (
            <ViewAllowSuppliersButton
              onClick={() => {
                updateItemAllowedSuppliersPopupState({
                  open: true,
                  itemID: rowData.itemID,
                  locationVisibility: rowData.locationVisibility,
                });
              }}
              disabled={!rowData.verifiedDate}
            />
          )}
          {isAdminOrHigherRole(role) && showScheduleIcon && (
            <ItemRfqEmailScheduleConfigButton
              item={rowData}
            />
          )}
        </div>
      </div>
    );
  },
  width: 180,
})
