import React, { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Badge,
  Box,
  Breadcrumbs,
  ButtonBase,
  CircularProgress,
  Fab,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';

import CheckoutSummaryDrawer from './CheckoutSummaryDrawer';
import ContactSupport from '../../components/ContactSupport';
import CustomerItemMuiTableV2 from '../../components/tables/CustomerItemMuiTableV2';
import CustomerProjectsMuiTable from '../../containers/Item/CustomerProjectsMuiTable';
import DeliveryOptionsPanel from '../../components/panels/DeliveryOptionsPanel';
import FtrDownArrowButton from '../../components/ftr-components/buttons/FtrDownArrowButton';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import MoreActionsButton from '../../components/ftr-components/MoreActionsButton';
import MultiCheckoutSummaryPanel from '../../components/panels/MultiCheckoutSummaryPanel';
import ProjectNameFieldV2 from '../../components/fields/ProjectNameFieldV2';
import QcReportsDisplayV2 from '../../components/info/QcReportsDisplayV2';
import ShareQuoteViaEmailButton from '../../components/ftr-components/buttons/ShareQuoteViaEmailButton';
import { FtrWhiteBox } from '../../components/ftr-components/FtrBox';
import { FlexColumn, FlexRow, FlexRowCenter } from '../../components/layouts/FlexLayouts';
import { FtrBoldText, FtrH4, FtrTypography } from '../../components/ftr-components';
import { RightBottomPositionAbsolute } from '../../components/ftr-components/FixedPosition';

import ShoppingBagIcon from '../../assets/icons/shopping_bag_icon.svg';

import withContactSupportPopupHOC from '../../hocs/withContactSupportPopupHOC';
import withManageProjectsRevampBannerHOC from '../../hocs/withManageProjectsRevampBannerHOC';

import { initMultiCheckoutFormState, updateShippingMode } from '../../actions/multiCheckoutForm';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import { isEmptyValue } from '../../utils/commonUtils';

import { COUNTRY_NAMES } from '../../constants/countryConstants';

import { getProjectMctColumns } from '../../constants/customerProjectsMuiTableConstants';

import { colors } from '../../palette';
import useCustomerSettingsHook from '../../hooks/useCustomerSettingsHook';


// ---------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020
    }
  },
  body: {
    height: '100%',
    width: '100%',
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: colors.fontWhite,
    }
  },
  formComponent: {
    padding: '1rem 2rem',
    position: 'relative',
  },
  projectLabel: {
    margin: '30px 0 0',
    display: 'flex',
  },
  projectHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    color: colors.fontBlack,
  },
  headerStyle: {
    color: colors.blue060,
    fontSize: '12pt',
    fontWeight: 600,
    marginRight: '10px',
  },
  textStyle: {
    fontSize: '12pt',
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: '11pt',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  breadcrumbLinkBold: {
    fontSize: '11pt',
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: colors.menuItemSelected,
  },
  quoteNumberBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'white',
      color: colors.blue050,
      marginTop: 4,
      marginRight: 1,
    },
  },
  cartLoading: {
    fontSize: 10,
  },
  menuItem: {
    borderRadius: 12,
    margin: '0 4px',
    fontWeight: 400,
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
  },
}));

function ProjectBreadcrumb(props) {
  const classes = useStyles();

  const { currentProjectName } = props;

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <Link
        className={classes.breadcrumbLink}
        onClick={() => props.history.goBack()}
        aria-current='page'
      >
        All projects
      </Link>
      <Typography className={classes.breadcrumbLinkBold}>
        {currentProjectName}
      </Typography>
    </Breadcrumbs>
  );
}

function ProjectInfoHeader(props) {
  const { style = {} } = props;
  return (
    <FtrTypography
      fontSize='16'
      style={{
        color: colors.neutral070,
        fontWeight: 600,
        ...style,
      }}
    >
      {props.children}
    </FtrTypography>
  );
}

function ProjectInfo() {
  const {
    projectID,
    projectOwner,
    qcReports,
    currentProjectName,
    currentProjectDetail,
    creatorEmail,
    customerID,
    isProjectDeletable,
    MoreProjectActionsMenuList,
    handleProjectNameOnChange,
    handleDeleteProject,
    updateEditProjectOwnerPopupState,
    updateProjectOwnerDisplay,
  } = useContext(ProjectItemsContext);

  const renderProjectOwner = () => {
    if (isEmptyValue(projectOwner)) {
      return null;
    }

    return (
      <FlexRow style={{ gap: '1rem' }}>
        <ProjectInfoHeader>
          Project Owner
        </ProjectInfoHeader>
        <FtrWhiteBox>
          <FlexRow>
            <FtrBoldText fontSize='16' style={{ color: colors.neutral070 }}>
              {`${projectOwner.ownerName} <${projectOwner.ownerEmail}>`}
            </FtrBoldText>
            <FtrDownArrowButton
              onClick={() => {
                updateEditProjectOwnerPopupState({
                  open: true,
                  projectID,
                  projectOwner,
                  currentProjectDetail,
                  creatorEmail,
                  customerID,
                  updateProjectOwnerDisplay,
                });
              }}
            />
          </FlexRow>
        </FtrWhiteBox>
      </FlexRow>
    );
  }

  const renderProjectQcReports = () => {
    return (
      <FlexRow
        style={{
          boxSizing: 'border-box',
          alignItems: 'start',
        }}
      >
        {/* TODO: temporarily removed */}
        {/* <div>
          <Button
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={() => updateQualityCertificationsPopupState({
              open: true,
              qcReports,
              projectID,
              setQcReports,
            })}
            disabled
          >
            Quality Certifications
          </Button>
        </div> */}
        <ProjectInfoHeader style={{ marginRight: 24, marginTop: 8 }}>
          Certification
        </ProjectInfoHeader>
        <FtrWhiteBox>
          <QcReportsDisplayV2 qcReports={qcReports} />
        </FtrWhiteBox>
      </FlexRow>
    );
  }

  const renderProjectActionButton = () => {
    return (
      <RightBottomPositionAbsolute>
        <MoreActionsButton
          menuList={MoreProjectActionsMenuList}
        />
      </RightBottomPositionAbsolute>
    );
  }

  return (
    <FlexColumn style={{ position: 'relative' }}>
      <FlexRow>
        <ProjectNameFieldV2
          projectName={currentProjectName}
          updateProjectName={handleProjectNameOnChange}
          onDeleteProject={() => handleDeleteProject(currentProjectDetail)}
          isProjectDeletable={isProjectDeletable}
          style={{
            paddingLeft: 0,
          }}
        />
      </FlexRow>
      <FlexRow>
        <ProjectInfoHeader>
          Project ID:
        </ProjectInfoHeader>
        <FtrTypography style={{ color: colors.neutral070 }} id="projectIDField">
          {projectID}
        </FtrTypography>
      </FlexRow>
      {renderProjectOwner()}
      {renderProjectQcReports()}
      {renderProjectActionButton()}
    </FlexColumn>
  );
}

function ShippingModes(props) {
  const {
    multiCheckoutFormStatus,
    shippingMode,
    shippingModeList = [],
    disableShippingMode,
    onChange = () => { },
  } = props;

  return (
    <div style={{ marginBottom: '1rem' }}>
      {['success', 'loading'].includes(multiCheckoutFormStatus) && (
        <DeliveryOptionsPanel
          shippingMode={shippingMode}
          onChange={onChange}
          shippingModeList={shippingModeList}
          disabled={disableShippingMode}
          status={multiCheckoutFormStatus}
        />
      )}
    </div>
  );
}

function CheckoutSummaryDrawerPanel() {
  const dispatch = useDispatch();

  const {
    showCheckoutSummaryFullPanel,
    setShowCheckoutSummaryFullPanel,
    setShippingMode,
    setUserSwitchShippingMode,
    selectedQuotes,
  } = useContext(ProjectItemsContext);

  return (
    <CheckoutSummaryDrawer
      open={showCheckoutSummaryFullPanel && !isEmptyValue(selectedQuotes)}
      selectShippingOption={(value) => {
        setShippingMode(value);
        dispatch(updateShippingMode(value));
        setUserSwitchShippingMode(true);
      }}
      onClose={() => {
        setShowCheckoutSummaryFullPanel(false);
      }}
    />
  );
}

function CheckoutSummary(props) {
  const dispatch = useDispatch();

  const {
    addresses,
    projectID,
    currentProjectDetail,
    customerID,
    multiCheckoutForm,
    shippingModeList,
    disableShippingMode,
    multiCheckoutFormStatus,
    selectedQuotes,
    shippingMode,
    currency,
    isShareQuotationForm,
    setShowCheckoutSummaryFullPanel,
    refetchProjectInfoByID,
    setAddresses,
    setShippingMode,
    setUserSwitchShippingMode,
    updateShareQuotePopupState,
    updateConfirmOrderPopupState,
  } = useContext(ProjectItemsContext);

  return (
    <FlexColumn
      style={{
        position: 'sticky',
        top: '2rem',
        width: '100%',
      }}
    >
      <ShippingModes
        shippingMode={multiCheckoutForm.shippingMode}
        onChange={(value) => {
          setShippingMode(value);
          dispatch(updateShippingMode(value));
          setUserSwitchShippingMode(true);
        }}
        shippingModeList={shippingModeList}
        disableShippingMode={disableShippingMode}
        multiCheckoutFormStatus={multiCheckoutFormStatus}
      />
      <MultiCheckoutSummaryPanel
        onCheckOutClick={() => {
          if (isShareQuotationForm) {
            setShowCheckoutSummaryFullPanel(false);
            updateShareQuotePopupState({
              open: true,
              projectID,
              selectedQuotes,
              shippingMode,
              customerID,
              addresses,
            });
          } else {
            updateConfirmOrderPopupState({
              open: true,
              customerID,
              selectedQuotes,
              shippingMode,
              shippingModeList,
              addresses,
              currency,
              currentProjectDetail,
              onClose: () => {
                setShowCheckoutSummaryFullPanel(true);
              },
              onShippingModeChange: (value) => {
                setShippingMode(value);
                setUserSwitchShippingMode(true);
              },
              setAddresses,
              refreshProject: () => {
                refetchProjectInfoByID();
                dispatch(initMultiCheckoutFormState());
              },
            });
            setShowCheckoutSummaryFullPanel(false);
          }
        }}
        showGst={addresses?.shipping?.country === COUNTRY_NAMES.SINGAPORE}
        buttonText={isShareQuotationForm ? 'Share Quote' : 'Checkout'}
        shippingCountry={addresses?.shipping?.country}
      />
      <span ref={props.checkoutPanelRef} />
    </FlexColumn>
  );
}

function ProjectMultiCheckoutsMuiTable(props) {
  const history = useHistory();

  const {
    projectMCTs = [],
    allMctItems = [],
    project = {},
  } = props;

  const handleRowClick = () => {
    history.push({
      pathname: `/customer-orders/projects/${project.projectID}`,
      state: { projectID: project.projectID }
    });
  };

  const projects = projectMCTs.map(order => {
    const projectItems = project?.items || [];
    return {
      ...order,
      ...project,
      items: allMctItems,
      twoDImageUrls: order.acceptedItems.map(ai => {
        const itemID = ai.itemID;
        const item = projectItems.find(i => i.itemID === itemID);
        return item?.imageUrl || item.imageFile || item.twoDImageUrl;
      }),
    }
  });

  return (
    <FlexColumn style={{ marginTop: '2.5rem', gap: '1.5rem' }}>
      <FtrH4>
        Ordered Items for {project.name}
      </FtrH4>
      <CustomerProjectsMuiTable
        projects={projects}
        onRowClick={handleRowClick}
        columns={getProjectMctColumns()}
      />
    </FlexColumn>
  )
}

function ManageProjectItemsPresentationalV3(props) {
  const classes = useStyles();

  const {
    updateContactSupportPopupHOCState = () => { },
  } = props;

  const [{ isMobile }] = useDeviceCheck();

  const {
    projectID,
    currentProjectDetail,
    multiCheckoutForm,
    multiCheckoutFormStatus,
    selectedQuotes,
    showCheckoutSummaryFullPanel,
    filteredParts,
    projectMCTs,
    shippingMode,
    customerID,
    addresses,
    isShareQuotationForm,
    setShowCheckoutSummaryFullPanel,
    refetchProjectInfoByID,
    setIsShareQuotationForm,
    updateShareQuotePopupState,
  } = useContext(ProjectItemsContext);

  const { customerSettings } = useCustomerSettingsHook(customerID);

  const checkoutPanelRef = useRef(null);

  const allMctItemIDs = projectMCTs?.map((mct) => mct.acceptedItems.map(ai => ai.itemID)).flat();

  const unCheckoutItems = filteredParts.filter(
    (item) => !allMctItemIDs.includes(item.itemID)
  );
  const allMctItems = filteredParts.filter(
    (item) => allMctItemIDs.includes(item.itemID)
  );

  const renderCustomerItemTable = () => {
    if (isEmptyValue(unCheckoutItems)) {
      return;
    }

    return (
      <CustomerItemMuiTableV2
        data={unCheckoutItems}
        getItems={refetchProjectInfoByID}
        projectID={projectID}
        multipleCheckouts={currentProjectDetail
          ? currentProjectDetail.multipleCheckouts
          : []
        }
      />
    );
  }

  const renderProjectMultiCheckouts = () => {
    if (isEmptyValue(allMctItems)) {
      return;
    }

    return (
      <ProjectMultiCheckoutsMuiTable
        project={currentProjectDetail}
        projectMCTs={projectMCTs}
        allMctItems={allMctItems}
        projectID={projectID}
      />
    );
  }

  const renderCheckoutFabButton = () => {
    const status = get(multiCheckoutForm, ['status']);

    let totalCostStr = get(multiCheckoutForm, ['checkoutPriceSummary', 'totalCostStr'])
      || get(multiCheckoutForm, ['checkoutPriceSummary', 'totalCost']);

    if (customerSettings?.combinedServiceFee === 1) {
      totalCostStr = get(multiCheckoutForm, ['checkoutPriceSummary', 'totalCostInclPlatformFeeStr']);
    }

    return (
      <ButtonBase
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          borderRadius: 10,
          backgroundColor: colors.successGreen,
          margin: 'auto',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isMobile) {
            checkoutPanelRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsShareQuotationForm(false);
            return;
          }

          if (isShareQuotationForm) {
            setIsShareQuotationForm(false);
            return;
          }

          setShowCheckoutSummaryFullPanel(!showCheckoutSummaryFullPanel);
          setIsShareQuotationForm(false);
        }}
        focusRipple
      >
        <Fab
          data-cy='shopping-cart-button'
          size={isMobile ? 'small' : 'medium'}
          color='primary'
          aria-label='checkout'
          disableRipple // Use ripple for the entire div instead
          style={{
            borderRadius: `10px 0 0 10px`,
            minWidth: 55,
            boxShadow: 'none',
          }}
        >
          <Badge
            className={classes.quoteNumberBadge}
            badgeContent={selectedQuotes.length}
          >
            <FtrSvgImage
              src={ShoppingBagIcon}
              style={{
                marginTop: 2,
              }}
            />
          </Badge>
        </Fab>
        {customerSettings?.combinedServiceFee === 1 && status === 'loading' && (
          <CircularProgress size={20} style={{ marginLeft: '1rem', marginRight: '1rem', color: 'white' }} />
        )}
        {(customerSettings?.combinedServiceFee !== 1 || status !== 'loading') && (
          <FtrTypography
            fontSize='17'
            style={{
              marginLeft: '0.8rem',
              marginRight: '1rem',
              fontWeight: 600,
              color: 'white',
            }}
          >
            {totalCostStr}
          </FtrTypography>
        )}
      </ButtonBase>
    );
  }

  const renderShareQuotationButton = () => {
    return (
      <ShareQuoteViaEmailButton
        onClick={() => {
          if (showCheckoutSummaryFullPanel) {
            updateShareQuotePopupState({
              open: true,
              projectID,
              selectedQuotes,
              shippingMode,
              customerID,
              addresses,
            });
          } else {
            setShowCheckoutSummaryFullPanel(true);
            setIsShareQuotationForm(true);
          }
        }}
      />
    )
  }

  const renderSimpleCheckoutInfoRow = () => {
    return (
      <FlexRowCenter
        style={{
          position: 'fixed',
          bottom: 0,
          left: 250,
          right: 0,
          backgroundColor: 'white',
          padding: '0.5rem',
          zIndex: 99,
          boxShadow: `0px -5px 5px #0000000D`,
        }}>
        <FlexRowCenter
          style={{
            width: 'fit-content',
            borderRadius: '5rem',
            margin: 'auto',
          }}
        >
          {renderCheckoutFabButton()}
          <Box style={{ width: '0.5rem' }} />
          {renderShareQuotationButton()}
        </FlexRowCenter>
      </FlexRowCenter>
    );
  }

  return (
    <div className={classes.formComponent}>
      {!isMobile && <CheckoutSummaryDrawerPanel />}
      <div className={classes.projectLabel}>
        {/* overflow: clip overwrites parent overflow for position: sticky to work */}
        <Grid container spacing={2} style={{ overflow: 'clip' }}>
          <FlexColumn>
            <ManageProjectItemsPresentationalV3.ProjectInfo />
            {/* TODO: temporary remove */}
            {/* <div
                style={{
                  marginTop: '1rem',
                }}
              >
                <ProjectStatusFiltersBar onFilterChange={handleFilterChange} />
              </div> */}
            <div
              className={classes.body}
              style={{
                marginTop: '1rem',
                marginBottom: 80,
              }}
            >
              {renderCustomerItemTable()}
              {renderProjectMultiCheckouts()}
              <div style={{ marginTop: '1.5rem', marginLeft: '1rem', width: 'fit-content' }}>
                <ContactSupport
                  text='Need help with your Orders?'
                  onClick={() => updateContactSupportPopupHOCState({ open: true })}
                  displayIn='row'
                />
              </div>
            </div>
          </FlexColumn>
          {['success', 'loading'].includes(multiCheckoutFormStatus)
            && !isEmptyValue(selectedQuotes)
            && renderSimpleCheckoutInfoRow()
          }
          {isMobile && selectedQuotes.length > 0 && (
            <div style={{ marginTop: '2rem', width: '100%' }}>
              <ManageProjectItemsPresentationalV3.CheckoutSummary
                checkoutPanelRef={checkoutPanelRef}
              />
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
}

ManageProjectItemsPresentationalV3.ProjectBreadcrumb = ProjectBreadcrumb;
ManageProjectItemsPresentationalV3.ProjectInfo = ProjectInfo;
ManageProjectItemsPresentationalV3.ShippingModes = ShippingModes;
ManageProjectItemsPresentationalV3.CheckoutSummary = CheckoutSummary;

export default withContactSupportPopupHOC(
  withManageProjectsRevampBannerHOC(ManageProjectItemsPresentationalV3)
);
