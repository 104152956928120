import React, { useEffect, useReducer, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { withRouter, useLocation } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { compose } from 'redux';
import { sample } from 'lodash';

import {
  Box,
  Button,
  Grid,
  Paper,
  makeStyles,
  useMediaQuery,
  useTheme,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Popper,
} from '@material-ui/core';

import { Add } from '@material-ui/icons';

import AddShippingAddressPopup from './part-upload-step-two/AddShippingAddressPopup';
import BulkConfigurePartsPopupV2 from './part-upload-step-two/BulkConfigurePartsPopupV2';
import ChangeTechnologyPopup from '../popups/ChangeTechnologyPopup';
import DisplayItemsCard from './part-upload-step-two/DisplayItemsCard';
import DisplayItemsTable from './part-upload-step-two/DisplayItemsTable';
import DisplayProjectSummaryRightPanel from './part-upload-step-two/DisplayProjectSummaryRightPanel';
import FtrDeletePopup from '../ftr-components/FtrDeletePopup';
import FtrSvgImage from '../images/FtrSvgImage';
import FtrTypography, { FtrNormalText, FtrLightText } from '../ftr-components/FtrTypography';
import ImperialMetricSwitchV2 from '../ImperialMetricSwitch/ImperialMetricSwitchV2';
import LeaveQuoteFeedbackPopup from './part-upload-step-two/LeaveQuoteFeedbackPopup';
import LoadingBackDropText from '../LoadingBackDropText';
import PpePriceLoadingBackdrop from '../backdrops/PpePriceLoadingBackdrop';
import ProjectCreatedCompletePopup from './part-upload-step-two/ProjectCreatedCompletePopup';
import ProjectNameField from '../fields/ProjectNameField';
import QCReportDrawer from '../fields/QCReportDrawer';
import Row from '../Row';
import SummaryClarificationPopup from './part-upload-step-two/SummaryClarificationPopup';
import UploadFilesField from './form-fields/UploadFilesField';
import { FlexRow, FlexRowSpaceBetween } from '../layouts/FlexLayouts';
import { FtrBanner } from '../ftr-components';
import { FtrSmallButton } from '../ftr-components/FtrButton';

import TrashSvgIcon from '../svg-icons/TrashSvgIcon';
import SearchIcon from '../../assets/icons/search.svg';
import AutoAwesomeIcon from '../../assets/icons/auto_awesome.svg';

import withPartUploadStepTwoTourPopupHoC from '../tour/withPartUploadStepTwoTourPopupHoC';
import withSignUpPopupHOC from '../../hocs/withSignUpPopupHOC';
import withSnackbarHOC from '../../hocs/withSnackbarHOC';
import withWelcomeBonusPopupHOC from '../../hocs/withWelcomeBonusPopupHOC';

import { createProjectGroup } from '../../apis/projectApi';
import { getFeatureFlags } from '../../apis/configurationApi';
import { getUserAddresses } from '../../apis/userApi';
import { updateUserIDForInstantLogs } from '../../apis/instantQuotesLogsApi';
import { updateTechnicalDrawingDB } from '../../apis/technicalDrawingExtractionApi'

import { sendLeaveFeedback } from '../../actions/feedbackActions';
import {
  cachePartsUploadFormAction,
  extractTdeAndGetPrice,
  insertUserIndustryCustomerType,
  openPartSummaryPage,
  regenerateImageAndGetPriceIfReload,
  resetPartSummaryDetails,
  savePartSummaryDetails,
  saveProject,
  clearErrorTdeStatus,
  resetPartUploadPdfMatchingStatus
} from '../../actions';

import estimateCostInfoHook from './part-upload-step-two/estimateCostInfoHook';
import estimateQcReportCostInfoHook from './part-upload-step-two/estimateQcReportCostInfoHook';
import useActionTracking from '../../hooks/useActionTrackingHook';
import useCadUpload from '../../hooks/useCadUpload';
import useCustomerAvailablePromoCode from '../../hooks/useCustomerAvailablePromoCode';
import { usePrevious } from '../../hooks/usePrevious';

import { getPartUploadFormSelector } from '../../selectors/partUploadFormSelector';
import { getFormDataAvailableSelector } from '../../selectors/formDataAvailableSelector';
import { getUserRoleSelector } from '../../selectors/userSelector';
import { getCreateUserSelector } from '../../selectors/createUserSelector'

import { addressInfoText, getDefaultAddresses } from '../../utils/addressUtils';
import { convertOldFormattedContact } from '../../utils/userUtils';
import { getPartsNeedClarify, validateSelectedItems } from '../../utils/partUploadUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { isCustomColor, isCustomMaterialColor, isCustomTechnology } from '../../utils/inputUtils';
import { isEmptyValue, sleep } from '../../utils/commonUtils';
import { isPpeItem } from '../../utils/itemUtils';
import { makePpeItemRfq, revertRfqToPpeItem } from '../../utils/ppeUtils';
import { asyncMap } from '../../utils/arrayUtils';

import { notifyError } from '../../services/notificationService';

import { AUTO_MATCHING_PDF_STATUS } from '../../reducers/partUploadFormReducer';
import { FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING } from '../../constants/featureFlagConstants';
import { MAX_UPLOAD_FILES_GUEST_USER } from '../../constants/NewPartConstants';
import { ROLE_TYPES, USER_AGENT_INFO_KEY } from '../../constants';
import {
  COUNTRY_NAMES,
  DEFAULT_DELIVERY_FEE_SGP,
  QC_FORMAL_CMM_REPORT,
  SUBMITTING_ORDER_MESSAGE_OPTIONS_ARRAY,
  TECHNOLOGY_OPTION_TYPE,
} from '../../constants/';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------------------------

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  autoAwesomeIcon: {
    height: '1rem !important',
    width: '1rem !important',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  container: {
    padding: '2rem 1.5rem',
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1rem',
    },
  },
  twoDImageContainer: {
    width: '160px',
    minWidth: '160px',
    height: '160px',
    minHeight: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  renderImage: {
    width: '160px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
  },
  tableRowRoot: {
    verticalAlign: 'top',
    backgroundColor: colors.fontWhite,
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1.25rem',
  },
  hovered: {
    backgroundColor: colors.neutral020,
  },
  menuItemSelected: {
    backgroundColor: colors.neutral020,
  },
  addAddressButton: {
    color: colors.blue050,
  },
  deleteBtn: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
      minWidth: 'auto',
    },
  },
  trashIcon: {
    background: 'red',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  }
}));

const WELCOME_BONUS_PART_UPLOAD_STEP_2 = 'welcome-bonus-part-upload-step-2';

function PartUploadStepTwoV2(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();
  // Access the 'from' field from the location state
  const { from } = location.state || { from: '/' }; //

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    userInfo,
    insertIndustryCustomerType,
    userID,
    unitType,
    industrySector,
    customerType,
    updateProject,
    currency,
    country,
    history,
    setSnackbarMessage = () => { },
    onSignUpPopup,
    updateWelcomeBonusPopupHOCState = () => { }
  } = props;

  const role = useSelector(getUserRoleSelector);

  const formDataAvailable = useSelector(getFormDataAvailableSelector);
  const partUploadForm = useSelector(getPartUploadFormSelector);
  const { questionAnswered } = useSelector(getCreateUserSelector);

  const exchangeRates = useSelector(state => state.auth.rates);
  const exchangeRate = exchangeRates[currency];

  const shippingCostRef = React.useRef(null);
  const shippingAddressRef = React.useRef(null);
  const historyBlock = useRef(null);

  const [{ promoCode }] = useCustomerAvailablePromoCode(userID);
  const {
    data: bonusTrackingData,
    isLoading: bonusTrackingLoading,
    isFetching: bonusTrackingFetching,
    refetch: bonusTrackingRefetch,
  } = useActionTracking(
    userID,
    WELCOME_BONUS_PART_UPLOAD_STEP_2,
  );

  const {
    data: featureFlags,
    isSuccess: isSuccessFetchFeatureFlags,
  } = useQuery(
    'getAllFeatureFlags',
    () => getFeatureFlags(),
  );

  const {
    data: addresses,
  } = useQuery(
    ['getUserAddresses', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getUserAddresses(userID);
    },
  );

  const {
    shippingAddressObj,
    updateShippingAddress,
    updateExchangeRate,
    updateCurrency,
    updateQuotationList,
  } = estimateCostInfoHook((state) => ({
    shippingMode: state.shippingMode,
    shippingAddressObj: state.shippingAddressObj,
    updateShippingAddress: state.updateShippingAddress,
    updateExchangeRate: state.updateExchangeRate,
    updateCurrency: state.updateCurrency,
    updateQuotationList: state.updateQuotationList,
    selectShippingOption: state.selectShippingOption,
  }));

  const {
    qcReports,
    qcOptionPrices,
    updateItemList,
    resetQcReport,
  } = estimateQcReportCostInfoHook((state) => ({
    qcReports: state.qcReports,
    qcOptionPrices: state.qcOptionPrices,
    updateItemList: state.updateItemList,
    resetQcReport: state.resetQcReport,
  }));

  const [
    cadPartListState,
    {
      uploadCadFilesStep2,
      updateTechnologyParamsInfo,
    }
  ] = useCadUpload();

  const previousFormData = usePrevious(formDataAvailable);

  const [
    projectCreatedPopupState,
    updateProjectCreatedPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      projectID: null,
    },
  );

  const [shippingAddressOptions, setShippingAddressOptions] = useState([]);
  const [itemState, setItemState] = useState(formDataAvailable);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loadingCreateProject, setLoadingCreateProject] = useState(false);
  const [unsupportedFileType, setUnsupportedFileType] = useState([]);
  const [projectName, setProjectName] = useState(null);
  const [selectedUnitType, setSelectedUnitType] = useState(unitType);
  const [isChangeTechPopupOpen, setIsChangeTechPopupOpen] = useState(false);
  const [newItemParams, setNewItemParams] = useState({
    technology: TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
  });
  const [selectedIndustry, setIndustry] = useState(industrySector ? industrySector : '');
  const [selectedCustomerType, setCustomerType] = useState(customerType ? customerType : '');
  const [showClarificationPopup, setShowClarificationPopup] = useState(false);
  const [partsNeedClarify, setPartsNeedClarify] = useState([]);
  const [showQcReportDrawer, setShowQcReportDrawer] = useState(false);
  const [saveItemOnCMMReport, setSaveItemOnCMMReport] = useState(false);
  const [showAddressOptionsDropdown, setShowAddressOptionsDropdown] = useState(false);
  const [showAddNewAddressPopup, setShowAddNewAddressPopup] = useState(false);
  const [showBulkConfigurationPopup, setShowBulkConfigurationPopup] = useState(false);
  const [showDeletePartsPopup, setShowDeletePartsPopup] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [targetLocation, setTargetLocation] = useState(null);
  const [showLoadingPrice, setShowLoadingPrice] = useState(true);

  const hasItemGettingPrice = cadPartListState.data?.some(
    part => part.ppePricingStatus === 'loading'
  );
  const hasItemGetPrice = !isEmptyValue(cadPartListState.data) && cadPartListState.data?.some(
    part => part.ppePricingStatus === 'success'
      || part.ppePricingStatus === 'failed'
  );
  const hasPpeItem = !isEmptyValue(cadPartListState.data) && cadPartListState.data?.some(
    part => part.ppePricingStatus === 'success'
      && part.totalPrice
      && part.totalPrice > 0
  );
  const hasRfqItem = !isEmptyValue(selectedItems)
    && selectedItems.some(
      item => !isPpeItem(item) && item.ppePricingStatus !== 'loading'
    );
  const hasUploadingItem = !isEmptyValue(cadPartListState.data)
    && cadPartListState.data?.some(part => part.uploadStatus === 'loading');
  const ppeItemHasTargetPrice = !isEmptyValue(selectedItems)
    && selectedItems.some(item => !!item.originalTargetPrice);
  const ppeItemHasExpectedLeadTime = !isEmptyValue(selectedItems)
    && selectedItems.some(item => !!item.expectedLeadTime);

  const hasTdeBeenRun = itemState.some(item => item.tdeStatus === "success")
    && itemState.every(item => item.tdeStatus !== "loading");

  // this effect is to ensure the popup will automatically appear
  // when the guest user has maxed out its max upload count
  useEffect(() => {
    setTimeout(() => {
      if (!userID && partUploadForm.totalUploadCountGuestUser >= MAX_UPLOAD_FILES_GUEST_USER) {
        return onSignUpPopup();
      }
    }, 3000);
  }, [])


  // this is to enhance if page is reload while there are some items getting price,
  // it should call get ppe price again
  useEffect(() => {
    window.onbeforeunload = function () {
      sessionStorage.setItem('pageReload', 'true');
    };

    dispatch(resetPartUploadPdfMatchingStatus());

    return () => {
      window.onbeforeunload = null;
      dispatch(resetPartUploadPdfMatchingStatus());
    };
  }, []);

  useEffect(() => {
    const pageReload = sessionStorage.getItem('pageReload');
    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING === 'true' && from !== '/parts-library') {
      dispatch(extractTdeAndGetPrice(pageReload === 'true'));
    } else {
      dispatch(regenerateImageAndGetPriceIfReload(pageReload === 'true'));
    }
    sessionStorage.removeItem('pageReload');

    // always show loading price for the first time at least 1 second
    // to make customer UX more intuitive
    setTimeout(() => {
      setShowLoadingPrice(false);
    }, 1000);

    return () => {
      resetQcReport();
      dispatch(cachePartsUploadFormAction());
    };
  }, []);

  useEffect(() => {
    if (
      role !== ROLE_TYPES.BUYER
      || isEmptyValue(userID)
      || isEmptyValue(promoCode)
      || hasItemGettingPrice
      || bonusTrackingLoading
      || bonusTrackingFetching
      || !isEmptyValue(bonusTrackingData)
      || hasRfqItem
      || !hasPpeItem
    ) {
      updateWelcomeBonusPopupHOCState({
        open: false,
      });
      return;
    }

    updateWelcomeBonusPopupHOCState({
      open: true,
      actionTrackingKey: WELCOME_BONUS_PART_UPLOAD_STEP_2,
      refetch: bonusTrackingRefetch,
    });
  }, [
    promoCode,
    userID,
    bonusTrackingData,
    bonusTrackingLoading,
    bonusTrackingFetching,
    hasItemGettingPrice,
    hasRfqItem,
    hasPpeItem,
  ]);

  useEffect(() => {
    if (partUploadForm.autoMatchingPdfStatus === AUTO_MATCHING_PDF_STATUS.SUCCESS_MATCHED) {
      const message = 'Your technical drawing has been auto-matched. Please review and verify for accuracy.';
      setSnackbarMessage(message);
    }
  }, [partUploadForm.autoMatchingPdfStatus]);

  useEffect(() => {
    if (userID && !isEmptyValue(formDataAvailable)) {
      const instantLogIDs = formDataAvailable.map(item => item.ppePriceLogId);
      const body = {
        userID,
        instantLogIDs,
      }
      updateUserIDForInstantLogs(body);
    }
  }, [userID]);

  useEffect(() => {
    if (
      !userID
      || isAdminOrHigherRole(role)
      || (hasItemGetPrice && !hasPpeItem)
      || isEmptyValue(cadPartListState.data)
    ) {
      if (historyBlock.current) {
        // unblock page navigation
        historyBlock.current();
      }
      return;
    }

    // block page navigation
    historyBlock.current = history.block((tx) => {
      let url = tx.pathname;
      setTargetLocation(url);
      setShowFeedbackPopup(true);
      return false;
    });

    return function cleanup() {
      if (historyBlock.current) {
        // unblock page navigation
        historyBlock.current();
      }
    };
  }, [
    userID,
    role,
    hasItemGetPrice,
    hasPpeItem,
    cadPartListState.data,
  ]);

  useEffect(() => {
    updateTechnologyParamsInfo(newItemParams);
  }, [newItemParams]);

  useEffect(() => {
    updateItemList(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (isEmptyValue(exchangeRate)) {
      return;
    }
    updateExchangeRate(exchangeRate);
  }, [exchangeRate]);

  useEffect(() => {
    updateCurrency(currency);
  }, [currency]);

  useEffect(() => {
    setItemState(formDataAvailable);
    if (!isEmptyValue(previousFormData)) {
      return;
    }

    const firstItem = formDataAvailable[0] ?? {};
    const {
      technology,
      material,
      materialColor,
      surfaceFinish,
      tolerance,
      unitType,
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
      projectLibraryID,
    } = firstItem;
    setNewItemParams({
      technology,
      material,
      materialColor,
      surfaceFinish,
      tolerance,
      unitType,
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
      projectLibraryID,
    });
    setSelectedUnitType(unitType); // update default value from user unit type to item unit type
  }, [formDataAvailable]);

  useEffect(() => {
    if (
      hasUploadingItem || hasItemGettingPrice
    ) {
      return;
    }
    const selectedItems = itemState.filter(item => item.checked);
    setSelectedItems(selectedItems);
    updateQuotationList(
      selectedItems.map(item => {
        return {
          totalPrice: Number(item.totalPrice ?? 0),
          exchangeRates: {
            exchangeRates: {
              exchange_rates: exchangeRates,
            },
          },
          ppeInformation: {
            weight: item.weight,
            boundingBoxX: item.boundingBoxX,
            boundingBoxY: item.boundingBoxY,
            boundingBoxZ: item.boundingBoxZ,
          },
          quantity: item.qty,
          itemQuantity: item.qty,
          deliveryFee: DEFAULT_DELIVERY_FEE_SGP,
          leadTime: item.leadTime || 10,
          markupLeadTime: item.markupLeadTime || 6,
        }
      }),
    );
  }, [itemState]);

  useEffect(() => {
    if (isEmptyValue(addresses)) {
      const shippingAddress = {
        country: country ?? COUNTRY_NAMES.SINGAPORE,
      }
      updateShippingAddress(shippingAddress);
      return;
    }
    setShippingAddressOptions(addresses?.filter(obj => !!obj.address));
    const result = getDefaultAddresses(addresses);
    const defaultShipping = result?.defaultShipping;
    const shippingAddress = !isEmptyValue(defaultShipping)
      ? defaultShipping
      : {
        country: country ?? COUNTRY_NAMES.SINGAPORE,
      }
    updateShippingAddress(shippingAddress);
  }, [addresses, country]);

  // if the state questionAnswered is true, then the user has completed the getquotenow flow
  useEffect(() => {
    if (questionAnswered) {
      handleSubmitForm();
    }
  }, [questionAnswered])

  // End useEffect() ----------------------------------------------------------------

  function handleCadFilesChange(files) {
    if (!userID && partUploadForm.totalUploadCountGuestUser >= MAX_UPLOAD_FILES_GUEST_USER) {
      return onSignUpPopup();
    }


    uploadCadFilesStep2(files);
  }

  const handleDeleteSelectedParts = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const copyItemState = [...itemState];
    const filteredItemState = copyItemState.filter((item) => !item.checked);
    setItemState(filteredItemState);
    dispatch(savePartSummaryDetails(filteredItemState));
  };

  const handleChangeProjectUnitType = (newUnitType) => {
    const copyItemState = [...itemState]
    copyItemState.forEach((item) => {
      if (item.unitType !== newUnitType) {
        item.unitType = newUnitType;
        item.generatedFields = item.generatedFields
          ? item.generatedFields.filter(field => field !== 'unitType')
          : [];
      }
    });
    setSelectedUnitType(newUnitType);
    setNewItemParams({
      ...newItemParams,
      unitType: newUnitType,
    });
    setItemState(copyItemState);
    dispatch(savePartSummaryDetails(copyItemState));
  }

  const handleCheckboxClickItemTableRow = event => {
    // Retrieve index
    const partID = event.target.name;

    // 1. Make a shallow copy of the items
    const items = [...itemState];

    // 2. Make a shallow copy of the item you want to mutate
    const item = { ...items.find(i => i.id === partID) };
    const itemIndex = items.findIndex(i => i.id === partID);

    // 3. Replace the property you're interested in
    item.checked = event.target.checked;

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[itemIndex] = item;

    // 5. Set the state to our new copy by replacing the entire array
    setItemState(items);
    dispatch(savePartSummaryDetails(items));
  };

  const handleCheckedAll = event => {
    // 1. Make a shallow copy of the items
    let items = [...itemState];

    // 2. Iterate over all items and update the 'checked' property
    items = items.map(item => {
      return {
        ...item,
        checked: event.target.checked
      };
    });

    // 3. Set the state to the new array
    setItemState(items);
    dispatch(savePartSummaryDetails(items));
  };

  const itemDetailCheck = (items) => {
    return items.reduce((acc, currentItem) => {
      return (
        acc && currentItem.name !== '' && currentItem.qty > 0
      );
    }, true);
  };

  function handleSubmitForm() {
    updateProjectCreatedPopupState({
      hasRfq: hasRfqItem,
    });

    const error = validateSelectedItems(selectedItems);
    if (!isEmptyValue(error)) {
      notifyError(`One or more items are ${error}`);
      return;
    }

    if (!showClarificationPopup) {
      setPartsNeedClarify([]);
      const _partsNeedClarify = getPartsNeedClarify(selectedItems);
      if (_partsNeedClarify.length || !industrySector || !customerType) {
        setPartsNeedClarify(_partsNeedClarify);
        setShowClarificationPopup(true);
        return;
      }
    }

    setShowClarificationPopup(false);
    setLoadingCreateProject(true);

    let payload = {
      projectName: projectName,
      projectStatus: 'Open',
      items: selectedItems
        .map(obj => {
          const partClarified = partsNeedClarify.find(item => item.itemID === obj.id);
          const requestSameFabricator = partClarified
            ? partClarified.requestSameFabricator
            : undefined;
          return {
            ...obj,
            expectedLeadTime: obj.expectedLeadTime
              ? obj.expectedLeadTime
              : null,
            requestSameFabricator,
            userID,
            technology: isCustomTechnology(obj.technology) ? obj.otherTechnology : obj.technology,
            color: isCustomColor(obj.color) ? obj.customColor : obj.color,
            materialColor: isCustomMaterialColor(obj.materialColor) ? obj.customMaterialColor : obj.materialColor,
          }
        }),
      userID,
      qcReports,
      userAgentInfo: cookies.get(USER_AGENT_INFO_KEY),
    };
    if (!itemDetailCheck(payload.items)) {
      setLoadingCreateProject(false);
      notifyError('Some items have missing details. Please fill in the necessary fields and try again.');
    } else {
      try {
        if (!industrySector || !customerType) {
          insertIndustryCustomerType({
            userID,
            industrySector: selectedIndustry,
            customerType: selectedCustomerType,
          });
        }

        createProjectGroup(payload)
          .then(async (data) => {
            if (historyBlock.current) {
              // unblock page
              historyBlock.current();
            }
            if (isAdminOrHigherRole(role)) {
              // wait for 2 seconds with each item. To make sure all processes completed in the background
              await sleep(2 * selectedItems?.length);
            }
            setLoadingCreateProject(false);
            updateProject({ projectName: '', projectStatus: '' });
            dispatch(resetPartSummaryDetails());
            updateProjectCreatedPopupState({
              open: true,
              projectID: data.projectID,
            });
            resetQcReport();

            // submit to the db original input if it's saved
            await asyncMap(payload.items, (item) => {
              if (!isEmptyValue(item.originalPayload)) {
                updateTechnicalDrawingDB({
                  pdfHash: item.itemHashcodePdf,
                  editedFields: item.originalPayload
                })
              }
            });
          })
          .catch(() => {
            notifyError('There was an error. Please fill in the necessary fields and try again.');
            setLoadingCreateProject(false);
          });
      } catch (err) {
        setLoadingCreateProject(false);
        notifyError('There was an error confirming your order. Please contact Factorem support team.');
      }
    }
  }

  const onSameFactoryCheckboxChange = (itemID, checkValue) => {
    const _partsNeedClarify = partsNeedClarify.map(item => {
      if (item.itemID !== itemID) {
        return item;
      }
      return {
        ...item,
        requestSameFabricator: checkValue,
      }
    });
    setPartsNeedClarify(_partsNeedClarify);
  }

  const submitLeaveFeedback = (data) => {
    dispatch(sendLeaveFeedback(data));
  }

  const renderItemsTable = () => {
    return (
      <DisplayItemsTable
        itemState={itemState}
        isSuccessFetchFeatureFlags={isSuccessFetchFeatureFlags}
        featureFlags={featureFlags}
        selectedUnitType={selectedUnitType}
        handleCheckedAll={handleCheckedAll}
        handleCheckboxClickItemTableRow={handleCheckboxClickItemTableRow}
        showLoadingPriceScreen={showLoadingPrice}
      />
    );
  };

  const renderItemsCard = () => {
    return (
      <DisplayItemsCard
        itemState={itemState}
        isSuccessFetchFeatureFlags={isSuccessFetchFeatureFlags}
        featureFlags={featureFlags}
        selectedUnitType={selectedUnitType}
        handleCheckedAll={handleCheckedAll}
        handleCheckboxClickItemTableRow={handleCheckboxClickItemTableRow}
      />
    );
  };

  const renderCheckoutSummaryInfo = () => {
    return (
      <DisplayProjectSummaryRightPanel
        hasRfqItem={hasRfqItem}
        selectedItems={selectedItems}
        shippingAddressRef={shippingAddressRef}
        shippingCostRef={shippingCostRef}
        qcReports={qcReports}
        qcOptionPrices={qcOptionPrices}
        loading={hasItemGettingPrice}
        loadingPpePrice={hasItemGettingPrice}
        handleSubmitForm={userID ? handleSubmitForm : onSignUpPopup}
        setShowQcReportDrawer={setShowQcReportDrawer}
        setShowAddressOptionsDropdown={userID ? setShowAddressOptionsDropdown : onSignUpPopup}
      />
    );
  };

  const renderAddressOptionsDropdown = () => {
    return (
      <Popper
        open={showAddressOptionsDropdown}
        anchorEl={shippingAddressRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper
              style={{
                maxHeight: '350px',
                overflowY: 'auto',
              }}
            >
              <ClickAwayListener
                onClickAway={() => setShowAddressOptionsDropdown(false)}
              >
                <MenuList
                  id='menu-list-grow'
                  autoFocusItem={open}
                  onKeyDown={() => {

                  }}
                >
                  {shippingAddressOptions.map(addressObj => {
                    return (
                      <MenuItem
                        key={addressObj.deliveryInfoID}
                        className={shippingAddressObj?.deliveryInfoID === addressObj.deliveryInfoID
                          ? classes.menuItemSelected
                          : null
                        }
                        onClick={() => {
                          updateShippingAddress(addressObj);
                          setShowAddressOptionsDropdown(false);
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Row>
                            <FtrTypography
                              type='subHeading'
                              fontSize='16'
                              style={{
                                color: colors.neutral070,
                                fontWeight: 600,
                              }}
                            >
                              {addressObj?.contactName || userInfo?.name}
                            </FtrTypography>
                            <span
                              style={{
                                width: '1px',
                                height: '1.5rem',
                                backgroundColor: colors.bgGrey,
                              }}
                            />
                            <FtrNormalText>
                              {addressObj.contactNumber || convertOldFormattedContact(userInfo?.contact, userInfo?.country)}
                            </FtrNormalText>
                          </Row>
                          <FtrTypography
                            type='subHeading'
                            fontSize='14'
                            style={{
                              color: colors.neutral060,
                              fontWeight: 400,
                              marginTop: '0.5rem',
                            }}
                          >
                            {addressInfoText(addressObj)}
                          </FtrTypography>
                        </div>
                      </MenuItem>
                    )
                  })}
                  <MenuItem
                    key='add-new-button'
                    className={classes.addAddressButton}
                    onClick={() => {
                      setShowAddressOptionsDropdown(false);
                      setShowAddNewAddressPopup(true);
                    }}
                  >
                    <Add />&nbsp;Add new address
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }

  const renderPpeItemHasTargetPriceWarning = () => {
    if (!ppeItemHasTargetPrice && !ppeItemHasExpectedLeadTime) {
      return;
    }

    return (
      <Grid item sm={8}>
        <FtrBanner
          style={{
            backgroundColor: colors.blue020,
          }}
        >
          <FlexRow>
            <img src={SearchIcon} />
            <FtrNormalText>
              Your Instant Quote needs a review due to your specified target lead time or price settings. Our team will strive to meet your requirements. Please submit your order for review.
            </FtrNormalText>
          </FlexRow>
        </FtrBanner>
      </Grid>
    );
  }

  const renderDeletePartsPopup = () => {
    const selectedItems = itemState.filter((item) => item.checked);
    const subtitle = selectedItems.length > 1
      ? 'Are you sure you want to delete these parts?'
      : 'Are you sure you want to delete this part?';

    return (
      <FtrDeletePopup
        open={true}
        title='Delete parts'
        subtitle={subtitle}
        onConfirm={(e) => {
          handleDeleteSelectedParts(e);
        }}
        onClose={() => setShowDeletePartsPopup(false)}
      />
    );
  }

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item sm={8}>
        <ProjectNameField
          projectName={projectName}
          updateProjectName={setProjectName}
        />
      </Grid>
      {renderPpeItemHasTargetPriceWarning()}
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <FlexRowSpaceBetween>
          <FlexRow>
            <FtrSmallButton
              color='blue'
              disabled={isEmptyValue(selectedItems) || selectedItems.length < 2}
              onClick={() => {
                setShowBulkConfigurationPopup(true);
                dispatch(clearErrorTdeStatus()).then((response) => {
                  setItemState(response);
                });
              }}
            >
              Bulk configure parts
            </FtrSmallButton>
            <FtrSmallButton
              style={
                isMobile
                  ? {
                    height: '35px',
                    padding: '3px 5px 3px 15px',
                    minWidth: 'auto',
                  }
                  : { height: '35px' }
              }
              color='black'
              variant='outlined'
              startIcon={
                <TrashSvgIcon style={isMobile ? { margin: 0 } : null} />
              }
              onClick={() => setShowDeletePartsPopup(true)}
              disabled={itemState.filter((obj) => obj.checked).length <= 0}
            >
              {!isMobile && 'Delete'}
            </FtrSmallButton>
          </FlexRow>
          <ImperialMetricSwitchV2
            selectedUnitType={selectedUnitType}
            onChange={handleChangeProjectUnitType}
            name='imperial-metric-switch'
          />
        </FlexRowSpaceBetween>
        {hasTdeBeenRun && (
          <FlexRow
            style={{
              marginTop: '8px',
            }}
          >
            <FtrSvgImage
              src={AutoAwesomeIcon}
              className={classes.autoAwesomeIcon}
            />
            <FtrLightText
              fontSize='13'
              style={{
                textAlign: 'flex-end',
                width: '100%',
                color: colors.blue050,
              }}
            >
              Part configurations have automatically been extracted from your
              technical drawing!
              <br />
              Please verify that configurations are correctly set.
            </FtrLightText>
          </FlexRow>
        )}
      </Grid>
      <Grid item xs={12} lg={8} style={{ height: 'auto' }}>
        {isMobile ? renderItemsCard() : renderItemsTable()}
        <Box height='1.5rem' />
        <div id='add-more-parts'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '1rem',
            }}
          >
            <FtrTypography
              type='subHeading'
              fontSize='18'
              style={{ color: colors.neutral070 }}
            >
              {newItemParams.technology}
            </FtrTypography>
            <Box width='1rem' />
            <Button
              type='text'
              onClick={() => setIsChangeTechPopupOpen(true)}
              style={{
                fontWeight: 600,
                fontSize: '14px',
                color: colors.blue060,
                textTransform: 'none',
              }}
            >
              Change Technology
            </Button>
          </div>
          <UploadFilesField
            fullWidth
            technology={newItemParams.technology}
            handleFiles={handleCadFilesChange}
            unsupportedFileType={unsupportedFileType}
            handleUnsupportedFileType={setUnsupportedFileType}
          />
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        lg={4}
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'sticky',
            top: 8,
          }}
        >
          {renderCheckoutSummaryInfo()}
        </div>
      </Grid>
      {isChangeTechPopupOpen && (
        <ChangeTechnologyPopup
          open={isChangeTechPopupOpen}
          onClose={() => setIsChangeTechPopupOpen(false)}
          technology={newItemParams.technology}
          retrieveParams={setNewItemParams}
        />
      )}
      {showClarificationPopup && (
        <SummaryClarificationPopup
          open={showClarificationPopup}
          handleClose={() => setShowClarificationPopup(false)}
          handleSubmit={handleSubmitForm}
          partsNeedClarify={partsNeedClarify}
          hasIndustry={!!industrySector}
          hasCustomerType={!!customerType}
          selectedIndustry={selectedIndustry}
          selectedCustomerType={selectedCustomerType}
          onSameFactoryCheckboxChange={onSameFactoryCheckboxChange}
          onIndustrySelect={setIndustry}
          onCustomerTypeSelect={setCustomerType}
        />
      )}
      {showQcReportDrawer && (
        <QCReportDrawer
          open={showQcReportDrawer}
          onConfirm={(value) => {
            if (value.main === QC_FORMAL_CMM_REPORT) {
              setItemState(itemState.map(makePpeItemRfq));
              setSaveItemOnCMMReport(true);
            } else if (saveItemOnCMMReport) {
              setItemState(itemState.map(revertRfqToPpeItem));
              setSaveItemOnCMMReport(false);
            }
          }}
          onClose={() => setShowQcReportDrawer(false)}
        />
      )}
      <LoadingBackDropText
        open={loadingCreateProject === true}
        text={
          loadingCreateProject
            ? sample(SUBMITTING_ORDER_MESSAGE_OPTIONS_ARRAY)
            : 'Updating...'
        }
      />
      {renderAddressOptionsDropdown()}
      <ProjectCreatedCompletePopup
        key={projectCreatedPopupState.projectID}
        open={projectCreatedPopupState.open}
        hasRfq={projectCreatedPopupState.hasRfq}
        projectID={projectCreatedPopupState.projectID}
      />
      {showAddNewAddressPopup && (
        <AddShippingAddressPopup
          open={showAddNewAddressPopup}
          onClose={() => setShowAddNewAddressPopup(false)}
          onSuccess={(newAddress) => {
            setShippingAddressOptions((pre) => [...pre, newAddress]);
            updateShippingAddress(newAddress);
          }}
        />
      )}
      {showBulkConfigurationPopup && (
        <BulkConfigurePartsPopupV2
          key={selectedItems[0]?.id}
          open={showBulkConfigurationPopup}
          selectedItems={selectedItems}
          onClose={() => setShowBulkConfigurationPopup(false)}
          unitType={selectedUnitType}
        />
      )}
      {!isEmptyValue(formDataAvailable) &&
        (!hasItemGetPrice || showLoadingPrice) && (
          <PpePriceLoadingBackdrop
            open={!hasItemGetPrice || showLoadingPrice}
          />
        )}
      {showDeletePartsPopup && renderDeletePartsPopup()}
      {showFeedbackPopup && userID && (
        <LeaveQuoteFeedbackPopup
          open={true}
          onClose={() => {
            setShowFeedbackPopup(false);
            if (historyBlock.current) {
              // unblock page
              historyBlock.current();
            }
            if (targetLocation) {
              history.push(targetLocation);
            }
          }}
          onCancel={() => {
            setShowFeedbackPopup(false);
          }}
          onConfirm={submitLeaveFeedback}
        />
      )}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userInfo: state.auth.user,
    userID: state.auth.user.userID,
    role: state.auth.user.role,
    industrySector: state.auth.user.industrySector,
    customerType: state.auth.user.customerType,
    project: state.project.project,
    currency: state.auth.location.currency,
    country: state.auth.user.country,
    unitType: state.auth.user.unitType,
    verificationDate: state.createUser.verificationDate
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    insertIndustryCustomerType: newData => dispatch(insertUserIndustryCustomerType(newData)),
    updateSummaryForm: (formDataAvailable) =>
      dispatch(openPartSummaryPage(formDataAvailable, props)),
    updateProject: (project) => dispatch(saveProject(project)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  withSnackbarHOC,
  withSignUpPopupHOC,
  withPartUploadStepTwoTourPopupHoC,
  withWelcomeBonusPopupHOC,
)(PartUploadStepTwoV2);
