import React, { useState, useEffect } from "react";

import {
  Container,
  Grid,
  Slide,
  Dialog,
  IconButton,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles/index";

import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { getPresignedUrl } from "../../../utils/presignedUrlUtils";
import { convertToDigits } from "../../../utils/stringUtils";
import { isPdfFile } from "../../../utils/fileUtils";

import { useModifyPdfCanvasContext } from "../../../context/ModifyPdfCanvasContext";

import { FlexColumnCenter } from '../../../components/layouts/FlexLayouts';
import LoadingBackDropText from "../../LoadingBackDropText";
import ModifyPdfPopupActionBar from "./ModifyPdfPopupActionBar";
import TitleBlockDisplay from "./TitleBlockDisplay";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  pdfContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  canvas: {
    zIndex: 10,
    PointerEvent: 'auto',
  }
}));

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function ModifyPdfPopup(props) {
  const {
    open,
    handleClose,
    pdfUrl,
    titleblockPayload,
    editCadFile,
    setEditCadFile
  } = props;

  const pdfFiles = editCadFile.filter(isPdfFile)

  const {
    setCanvasDimension,
    numPages,
    changePage,
    currentPage,
    pdfCanvasRef,
    printableRef,
    overlayCanvasRef,
    canvasWrapperRef,
    processingPdf,
    onDocumentLoadSuccess
  } = useModifyPdfCanvasContext()
  const classes = useStyles();
  const [presignedPdfUrl, setPresignedPdfUrl] = useState('')
  const [revision, setRevision] = useState(convertToDigits(pdfFiles.length + 1, 2) || '01')

  useEffect(() => {
    const signUrl = async () => {
      if (pdfUrl) {
        const signedUrl = await getPresignedUrl(pdfUrl);
        setPresignedPdfUrl(signedUrl)
      }
    }

    signUrl()
  }, [pdfUrl])

  return (
    <Dialog
      fullScreen
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div>
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <Container maxWidth="lg">
          <LoadingBackDropText text={`${processingPdf.process} modified PDF...`} open={processingPdf.state} />
          <Grid container>
            {/* the action bar for the pdf modifier */}
            <ModifyPdfPopupActionBar
              itemID={titleblockPayload.itemID}
              editCadFile={editCadFile}
              setEditCadFile={setEditCadFile}
              handleClose={handleClose}
              revision={revision}
            />
            {/* the pdf canvas */}
            <Grid item xs={12}>
              <FlexColumnCenter style={{ marginBottom: '1rem' }}>
                <div ref={printableRef}>
                  <Document file={presignedPdfUrl} className={classes.pdfContainer} onLoadSuccess={onDocumentLoadSuccess} >
                    <div ref={canvasWrapperRef} style={{
                      position: 'absolute',
                      zIndex: '9',
                      visibility: 'visible',
                      // border: '1px solid red' -- for debugging
                    }}>
                      <canvas id="canvas" ref={overlayCanvasRef} className={classes.canvas} />
                    </div>
                    <Page
                      canvasRef={pdfCanvasRef}
                      pageNumber={currentPage}
                      id="docPage"
                      onRenderSuccess={setCanvasDimension}
                    />
                  </Document>
                </div>
                {numPages > 1 && (
                  <Pagination
                    count={numPages}
                    page={currentPage}
                    onChange={(_, newPage) => changePage(newPage)}
                  å/>
                )}
              </FlexColumnCenter>
            </Grid>
            {/* the two versions of the titleblocks */}
            <TitleBlockDisplay
              titleblockPayload={titleblockPayload}
              currentPage={currentPage}
              numPages={numPages}
              revision={revision}
              setRevision={setRevision}
            />
          </Grid>
        </Container>
      </div>
    </Dialog>
  )
}

export default ModifyPdfPopup;
