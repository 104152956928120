import { updateGuidedTourCompletionStatus } from '../../../apis/tourApi';

import { backAction, continueAction, stepNumberButton } from './tourConstants';


// -------------------------------------------------------------------------------------------------

export const closeSidebarMobile = () => {
  document.querySelector('#sidebar-mobile-close')?.click();
};

export const openSidebarMobile = () => {
  document.querySelector('#sidebar-mobile-open')?.click();
};

export const openConfigurePart = () => {
  document.querySelector('#configure-part-0-button')?.click();
};

export const closeConfigurePart = () => {
  document.querySelector('#return-to-quote-btn')?.click();
};


/**
 * Hides popups by adding the 'display-none' class
 * to all elements with the class 'MuiDialog-root'.
 */
export const hideOtherPopup = () => {
  const elements = document.querySelectorAll('.MuiDialog-root')
  elements.forEach((element) => {
    element.classList.add('display-none');
  })
}

/**
 * Shows hidden popups by removing the 'display-none' class
 * from all elements with the class 'MuiDialog-root'.
 */
export const showHiddenPopup = () => {
  const elements = document.querySelectorAll('.MuiDialog-root')
  elements.forEach((element) => {
    element.classList.remove('display-none');
  })
}

export const stepFormatKey = (step, title) => {
  return `step ${step} - ${title}`;
};

export async function updateTrackingSteps(params, step) {
  const stepKey = stepFormatKey(step.STEP, step.TITLE);
  params.trackingSteps = {
    ...params.trackingSteps,
    [stepKey]: true,
  };
  const completed = true;
  updateGuidedTourCompletionStatus(params.userID, params.tourKey, completed, {
    trackingSteps: params.trackingSteps,
  });
  params.nextButtonClicked = true;
}

/**
 * Check if the current step should be shown to the user based on the trackingSteps object
 * and the userCompletedTourBefore flag.
 * 
 * @param {Object} params - The object containing the trackingSteps and userCompletedTourBefore flags.
 * @param {Object} step - The current step object.
 * 
 * @returns {Boolean} True if the current step should be shown, false otherwise.
 */
export const showCurrentStep = (params, step) => {
  const stepKey = stepFormatKey(step.STEP, step.TITLE);

  // only for first opening popup and overlapping it
  if (!params.nextButtonClicked) {
    hideOtherPopup();
  }

  if (params.showAll) {
    return true;
  }

  setTimeout(() => {
    params.showAll = true; // show all steps
  }, 500);
  if (params.trackingSteps?.[stepKey] || params.userCompletedTourBefore) {
    return false;
  }
  return true;
};


// Create the continue action helper
export const createContinueAction = (params, stepInfo, extraAction = null, customContinueClasses = null) => {
  return {
    ...continueAction,
    action: async function () {
      updateTrackingSteps(params, stepInfo);
      if (typeof extraAction === 'function') {
        await extraAction();
      }
      this.next();
    },
    ...(customContinueClasses && { classes: customContinueClasses }),
  };
};

// Create the back action helper
export const createBackAction = (extraBackAction = null, customBackClasses = null) => {
  return {
    ...backAction,
    action: async function () {
      if (typeof extraBackAction === 'function') {
        await extraBackAction();
      }
      this.back();
    },
    ...(customBackClasses && { classes: customBackClasses }),
  };
};


/**
 * Gets the last step number from an object containing step info.
 * 
 * @param {Object} steps - The object containing the step info
 * @returns {String} The last step number
 */
export const getLastStepNumber = (steps) => {
  const stepsArray = Object.keys(steps);
  const lastStep = steps[stepsArray?.at(-1)];
  const lastStepNumber = lastStep.STEP;
  return lastStepNumber;
}


/**
 * Creates a guided tour step.
 * 
 * @param {Object} params - The tour params to track user's progress
 * @param {Object} stepInfo - The step info containing STEP, TITLE and TEXT
 * @param {Object} attachTo - The element to attach the step to
 * @param {String} stepText - The text to display on the step number button
 * @param {Object} [options] - The options object
 * @param {Function} [options.extraContinueAction] - The extra function to run when the continue button is clicked
 * @param {Function} [options.extraBackAction] - The extra function to run when the back button is clicked
 * @param {Function} [options.extraShowAction] - The extra function to run when the step is shown
 * @param {Function} [options.onShow] - The function to run when the step is shown
 * @param {Function} [options.onHide] - The function to run when the step is hidden
 * @param {String} [options.customBackClasses] - The custom classes to apply to the back button
 * @param {String} [options.customContinueClasses] - The custom classes to apply to the continue button
 * @param {Function} [options.beforeShowPromise] - The promise to resolve before showing the step
 * @returns {Object} The created step object
 */
export const createStep = (params, stepInfo, attachTo, stepText) => {
  const {
    extraContinueAction = null,
    extraBackAction = null,
    extraShowAction = null,
    onShow = null,
    onHide = null,
    customBackClasses = null,
    customContinueClasses = null,
    beforeShowPromise = null,
  } = stepInfo.OPTIONS ?? {};
  return {
    attachTo,
    buttons: [
      createBackAction(extraBackAction, customBackClasses),
      { ...stepNumberButton, text: stepText },
      createContinueAction(params, stepInfo, extraContinueAction, customContinueClasses),
    ],
    ...(typeof onShow === 'function' && typeof onHide === 'function' && {
      when: {
        show: onShow,
        hide: onHide,
      }
    }),
    ...(typeof beforeShowPromise === 'function' && { beforeShowPromise }),
    showOn: function () {
      if (!showCurrentStep(params, stepInfo)) {
        return false;
      }
      if (typeof extraShowAction === 'function') {
        extraShowAction();
      }
      const element = document.querySelector(this.attachTo.element);
      return Boolean(element);
    },
    title: stepInfo.TITLE,
    text: stepInfo.TEXT,
  };
};
