import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const getPlatformFeeRulesLatest = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/platform-fee/rules/latest`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const createPlatformFeeRules = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/platform-fee/rules/create`;
  return fetch(requestUrl, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getPlatformFeeByTotalPrice = async (totalPrice) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/platform-fee/${totalPrice}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
