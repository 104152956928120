import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from 'redux';
import * as Sentry from '@sentry/react';

import EmailTriggerActions from "./pages/EmailTriggerActions";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import GetQuoteNowV2 from "./pages/GetQuoteNowV2";
import AdminCreateNewUser from "./pages/AdminCreateNewUser";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import SignupNewCustomer from "./pages/SignupNewCustomer";
import ResetPassword from "./pages/Reset";
import Recover from "./pages/Recover";
import MyOrdersDataGrid from "./pages/MyOrdersDataGrid";
import Profile from "./pages/Profile";
import CustomerFaq from "./pages/CustomerFaq";
import ItemForSupplier from "./pages/ItemForSupplier";
import Order from "./pages/Order";
import ManageQuotes from "./pages/ManageQuotes";
import AllProjectsDataGrid from "./pages/AllProjectsDataGrid";
import AllAcceptedOrdersDataGridPagination from "./pages/AllAcceptedOrdersDataGridPagination";
import AddMultipleParts from "./containers/Item/AddMultipleParts";
import MultiplePartSummaryForm from "./containers/Item/MultiplePartSummaryForm";
import ModifyAddPart from "./containers/Item/ModifyAddPart";
import ManageUsers from "./pages/ManageUsers";
import ManageInvoices from './pages/ManageInvoices';
import VerifyPartsDataGrid from "./pages/VerifyPartsDataGrid";
import { VerifyQuotesDataGridPagination } from "./pages/VerifyQuotesDataGridPagination";
import EditItem from "./pages/EditItem";
import EditOrder from "./pages/EditOrder";
import ItemForCustomer from "./pages/ItemForCustomer";
import CustomerProjectHistory from "./containers/Item/CustomerProjectHistory";
import ManageDeliveryOrders from './pages/ManageDeliveryOrders';
import ManageReceipts from './pages/ManageReceipts';
import PricePredictorV2 from "./pages/PricePredictorV2";
import AllPPEFeedbackDataGrid from "./pages/AllPPEFeedbackDataGrid";
import CustomerProfile from "./containers/User/CustomerProfile";
import CustomerFaqPage from "./containers/Misc/FAQPage";
import SupplierFaqPage from "./containers/Misc/SupplierFAQPage";
import UserFaqs from "./pages/UserFaqs";
import ViewCustomerQuotes from "./containers/Quotation/ViewCustomerQuotes";
import ManageOrders from "./containers/Item/ManageOrders";
import ManageProjectHistoryItems from "./containers/Item/ManageProjectHistoryItems";
import ItemCreatedSuccess from "./containers/Item/ItemCreatedSuccess";
import VerifyEmail from "./pages/VerifyEmail";
import EmailConfirmation from "./pages/EmailConfirmation";
import SummaryAddPart from "./containers/Item/SummaryAddPart";
import EditProjectPage from "./pages/EditProjectPage";
import ControlPanel from './pages/ControlPanel';
import GenerateRFQ from "./pages/GenerateRFQ";
import BrowseWithOptions from "./pages/BrowseWithOptions";
import ProjectQuoteForSupplier from "./pages/ProjectQuoteForSupplier";
import ChangePassword from "./pages/ChangePassword";
import MultipleCheckoutTracking from './pages/MultipleCheckoutTracking';
import SupplierAcceptedOrdersDataGrid from "./pages/SupplierAcceptedOrdersDataGrid";
import GeneratePoAutomation from "./pages/GeneratePoAutomation";
import { isAdminOrHigherRole } from "./utils/roleUtils";
import GenerationTools from "./pages/GenerationTools";
import ManageItems from "./pages/ManageItems";
import { hasReviewPermission } from "./utils/roleUtils";
import CADRendererPopup from "./components/popups/CADRendererPopup";
import ProjectOrderReady from "./pages/ProjectOrderReady";
import AllOrderReadyManagementDataGrid from "./pages/AllOrderReadyManagementDataGrid";
import SharedQuotationLogDataGrid from "./pages/SharedQuotationLogDataGrid";
import PartnerDataDashboard from "./pages/PartnerDataDashboard";
import ScrollManager from "./components/ScrollManager";
import RfqEmailSchedules from "./pages/pages/rfq-email-schedules/RfqEmailSchedules";
import PartsLibrary from "./pages/parts-library/PartsLibrary";
import PartUploadStepTwoV2 from "./components/forms/PartUploadStepTwoV2";
import InstantQuotesLogs from "./pages/instant-quotes-logs/InstantQuotesLogs";
import PaymentSuccessV2 from "./pages/payment-success/PaymentSuccessV2";
import PaymentSuccess from "./pages/payment-success/PaymentSuccess";
import Logout from "./pages/Logout";
import ProjectMultiCheckoutInfo from "./pages/ProjectMultiCheckoutInfo";
import ManageProjectItemsFactory from "./pages/manage-project-items/ManageProjectItemsFactory";
import GuestUserAccessPage from './pages/GuestUserAccessPage';
import CalendarView from './pages/CalendarView';
import ManageProjectsFactory from "./containers/Item/ManageProjectsFactory";
import DfmChecker from "./pages/DfmChecker";

import withLoadingBackDropTextHOC from "./hocs/withLoadingBackDropTextHOC";
import withSnackbarHOC from "./hocs/withSnackbarHOC";
import withQueryClientProviderHOC from "./hocs/withQueryClientProviderHOC";

import { verifyToken } from "./apis/jwtApi";
import { getClarityInfo } from "./apis/feEnvConfigApi";
import { getAllFileTypesSupport, getFeatureFlags } from "./apis/configurationApi";

import { updateAllFilesTypeSupport } from "./actions/pageSettings";
import { logoutUser, openPopupCadRenderer, retrieveExchangeRate, removeGuestUser } from "./actions";

import useFeatureFlagHook from "./hooks/useFeatureFlagHook";

import { setCache } from "./services/localStorageCacheService";
import { handleServerEvent } from "./services/serverSentEventHandlerService";
import { clarity, crisp, pbrain } from "./services/pluginService";

import { getGuestUserInfoSelector } from './selectors/userSelector';

import AppContext from "./context/AppContext";

import { GOOGLE_PLACES_API } from "./constants/apiConstants";
import {
  BACKEND_SERVICE_URL,
  CRISP_CHAT_ID,
  PROD_ENV,
  REACT_APP_ENV,
  ROLE_TYPES,
  STAGING_ENV,
} from "./constants";
import {
  FEATURE_FLAG_ORDER_READY_MANAGEMENT,
  FEATURE_FLAG_PART_UPLOAD_REVAMP,
  FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP,
} from './constants/featureFlagConstants';
import { GUEST_PERMISSIONS } from './constants/authConstants';


// --------------------------------------------------------------------------------------------

function App(props) {
  const {
    isAuthenticated,
    isVerifying,
    role,
    userID,
    dispatch,
    setLoadingMessage = () => { },
    setSnackbarMessage = () => { },
  } = props;

  const history = useHistory();
  const location = useLocation();
  const SentryRoute = Sentry.withSentryRouting(Route);
  const guestUserInfo = useSelector(getGuestUserInfoSelector);

  const { data: featureFlags } = useQuery('getAllFeatureFlags', () =>
    getFeatureFlags()
  );

  const { isManageProjectsRevampEnabled } = useFeatureFlagHook();

  const [heightWindow, setHeightWindow] = useState(window.innerHeight);
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);
  const [clarityInfo, setClarityInfo] = useState(null);

  const cookie = new Cookies();

  // retrieve rate once on first load
  useEffect(() => {
    dispatch(retrieveExchangeRate())
  }, [])

  useEffect(() => {
    getClarityInfo()
      .then((data) => {
        if (data) {
          setCache("clarityInfo", JSON.stringify(data));
          setClarityInfo(data)
        }
      });
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidthWindow(window.innerWidth)
      setHeightWindow(window.innerHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, []);

  useEffect(() => {
    let elPBrain = document.getElementById('pbrain-root');
    let elCrisp = document.getElementById('crisp-chatbox');
    let root = document.querySelector(':root');

    // Check is the user login
    if (!cookie.get("token") || !isAuthenticated) {
      if (elPBrain) elPBrain.style.cssText = 'display: none !important';
      if (elCrisp) elCrisp.style.cssText = 'display: none !important';
      return
    }

    // Crisp
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISP_CHAT_ID;

    if (role === ROLE_TYPES.BUYER) {
      clarity(window, document, "clarity", "script", clarityInfo?.CLARITY_BUYER_ID);
      pbrain(document, 'script', 'pbrain-jssdk', elPBrain);
      const bottom = '16px';
      const left = '16px';
      const insetValue = `auto auto ${bottom} ${left}`;
      root.style.setProperty('--pbrainInset', insetValue)
      root.style.setProperty('--pbrainHolderInset', 'auto auto 24px 64px');
      root.style.setProperty('--pbrainTranslateXBegin', '-100%');
      root.style.setProperty('--pbrainTranslateXEnd', 0);
      root.style.setProperty('--pbrainShadowLeft', 0);
      root.style.setProperty('--pbrainShadowRight', 'auto');
    } else if (role === ROLE_TYPES.SUPPLIER) {
      clarity(window, document, "clarity", "script", clarityInfo?.CLARITY_SUPPLIER_ID)
      crisp(elCrisp)
    } else if ([ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.ADMIN, ROLE_TYPES.REVIEWER].includes(role)) {
      clarity(window, document, "clarity", "script", clarityInfo?.CLARITY_ADMIN_ID);
      pbrain(document, 'script', 'pbrain-jssdk', elPBrain);
      crisp(elCrisp);
      const bottom = heightWindow < 600 || widthWindow < 480 ? '80px' : '95px';
      const right = heightWindow < 600 || widthWindow < 480 ? '15px' : '25px';
      const insetValue = `auto ${right} ${bottom} auto`;
      root.style.setProperty('--pbrainInset', insetValue);
      root.style.setProperty('--pbrainHolderInset', insetValue);
      root.style.setProperty('--pbrainTranslateXBegin', '100%');
      root.style.setProperty('--pbrainTranslateXEnd', 0);
      root.style.setProperty('--pbrainShadowLeft', 'auto');
      root.style.setProperty('--pbrainShadowRight', 0);
    }
  }, [cookie, isAuthenticated, heightWindow, widthWindow, clarityInfo]);

  // Declare function that sets a time interval to check validity of token
  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = GOOGLE_PLACES_API;
    script.async = true;
    document.body.appendChild(script);
    getAllFileTypesSupport()
      .then((data) => {
        dispatch(updateAllFilesTypeSupport(data))
      });

    // Server sent events handler
    const events = new EventSource(`${BACKEND_SERVICE_URL}/server-events`);
    events.onmessage = (e) => {
      const serverEvent = JSON.parse(e.data);
      handleServerEvent(serverEvent);
    };
  }, []);

  useEffect(() => {
    const handleTabClose = () => {
      dispatch(removeGuestUser());
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [guestUserInfo]);

  // Declare function that signs a user out if a token is not available
  const checkToken = async () => {
    let shouldLogout = false;
    const token = cookie.get("token");
    if (token) {
      const isValidToken = await verifyToken({ token });
      shouldLogout = !isValidToken;
    }
    else if (!token && isAuthenticated) {
      shouldLogout = true;
    }
    if (shouldLogout) {
      dispatch(logoutUser());
      toast.warn(
        "You have been logged out due to inactivity. Please log in again to access the portal"
      );
      if (location?.pathname !== '/login') {
        history.push('/login');
      }
    }
  };

  // Call the functions to check user login tokens every 5 mins
  checkToken();
  useInterval(checkToken, 5 * 60 * 1000);

  // -----------------------------------------------------------------------------------------------

  const getDefaultRouteComponent = () => {
    switch (role) {
      case ROLE_TYPES.BUYER:
        return AddMultipleParts;
      case ROLE_TYPES.SALES_CONSULTANT:
        return ManageItems;
      case ROLE_TYPES.ADMIN:
      case ROLE_TYPES.SUPER_ADMIN:
      case ROLE_TYPES.SUPPLIER:
      case ROLE_TYPES.REVIEWER:
      case ROLE_TYPES.ROCKET_MAN:
        return BrowseWithOptions;
      default:
        return Profile;
    }
  }

  return (
    <AppContext.Provider
      value={{
        setSnackbarMessage,
        setLoadingMessage,
        featureFlags,
        userID,
      }}
    >
      <Helmet>
        <title>
          {REACT_APP_ENV === PROD_ENV
            ? 'Factorem Portal'
            : REACT_APP_ENV === STAGING_ENV
              ? '(STG) Factorem Portal'
              : '(DEV) Factorem Portal'}
        </title>
        <meta
          name='description'
          content='On-demand manufacturing platform for ordering high-quality parts'
        />
      </Helmet>
      <Switch>
        <ProtectedRoute
          exact
          path='/'
          component={getDefaultRouteComponent()}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/orders'
          component={MyOrdersDataGrid}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.BUYER]}
        />
        <ProtectedRoute
          exact
          path="/data-dashboard/:userID"
          component={PartnerDataDashboard}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.BUYER]}
        />
        <ProtectedRoute
          exact
          path='/profile'
          component={role === 'buyer' ? CustomerProfile : Profile}
          pageTitle='Edit profile'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/change-password'
          component={ChangePassword}
          pageTitle='Edit profile'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/supplier-faq'
          component={SupplierFaqPage}
          pageTitle='Frequently asked questions'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.BUYER]}
        />
        <ProtectedRoute
          exact
          path='/items/:itemID'
          component={role === 'buyer' ? ItemForCustomer : ItemForSupplier}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path='/projects/:projectID/order-ready'
          component={ProjectOrderReady}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[
            ROLE_TYPES.BUYER,
            ROLE_TYPES.REVIEWER,
            ROLE_TYPES.ROCKET_MAN,
          ]}
          allowedPermissionsForGuests={[GUEST_PERMISSIONS.ORM]}
        />
        <ProtectedRoute
          path='/projects/:projectID/quote'
          component={ProjectQuoteForSupplier}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.BUYER]}
        />
        <ProtectedRoute
          exact
          path='/orders/:orderID'
          component={Order}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-items'
          component={ManageItems}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.REVIEWER,
            ROLE_TYPES.ROCKET_MAN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-invoices'
          component={
            isAdminOrHigherRole(role) ? ManageInvoices : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-delivery-orders'
          component={
            isAdminOrHigherRole(role) ? (
              ManageDeliveryOrders
            ) : (
              <Redirect to='/' />
            )
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-receipts'
          component={
            isAdminOrHigherRole(role) ? ManageReceipts : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/make-order'
          component={AddMultipleParts}
          pageTitle='Upload 3D Models'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[
            ROLE_TYPES.SUPPLIER,
            ROLE_TYPES.ROCKET_MAN,
            ROLE_TYPES.REVIEWER,
          ]}
        />
        <ProtectedRoute
          exact
          path='/view-quotes'
          component={role === 'buyer' ? ViewCustomerQuotes : <Redirect to='/' />}
          pageTitle='Manage quotations'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-parts'
          component={role === 'buyer' ? ManageOrders : <Redirect to='/' />}
          pageTitle='Manage my parts'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-projects'
          component={
            role === ROLE_TYPES.BUYER
              ? ManageProjectsFactory
              : AllProjectsDataGrid
          }
          pageTitle={isManageProjectsRevampEnabled && role === ROLE_TYPES.BUYER
            ? ''
            : 'Manage My Projects'
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[
            ROLE_TYPES.SUPPLIER,
            ROLE_TYPES.ONBOARDING_SUPPLIER,
          ]}
        />
        <ProtectedRoute
          exact
          path='/order-history'
          component={
            role === 'buyer'
              ? CustomerProjectHistory
              : <Redirect to='/' />
          }
          pageTitle='Order history'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/parts-library'
          component={role === 'buyer' ? PartsLibrary : <Redirect to='/' />}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-users'
          component={ManageUsers}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-orders'
          component={ManageQuotes}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.REVIEWER,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path="/accepted-orders"
          component={
            hasReviewPermission(role) ? (
              AllAcceptedOrdersDataGridPagination
            ) : role === ROLE_TYPES.SUPPLIER ? (
              SupplierAcceptedOrdersDataGrid
            ) : (
              <Redirect to='/' />
            )
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        {FEATURE_FLAG_ORDER_READY_MANAGEMENT === 'true' && (
          <ProtectedRoute
            exact
            path='/order-ready-management'
            component={(props) =>
              isAdminOrHigherRole(role) ? (
                <AllOrderReadyManagementDataGrid {...props} />
              ) : (
                <Redirect to='/' />
              )
            }
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
        )}
        <ProtectedRoute
          exact
          path='/customer-orders/projects/:projectID'
          component={
            isAdminOrHigherRole(role) || role === ROLE_TYPES.BUYER
              ? ProjectMultiCheckoutInfo
              : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/manage-projects/:projectID/rfq-email-schedules'
          component={
            isAdminOrHigherRole(role) ? (
              RfqEmailSchedules
            ) : (
              <Redirect to='/' />
            )
          }
          pageTitle='RFQ Email Schedules'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.ROCKET_MAN, ROLE_TYPES.SUPPLIER]}
        />
        <ProtectedRoute
          exact
          path='/manage-projects/:projectID/vac'
          component={ManageProjectItemsFactory}
          pageTitle={isManageProjectsRevampEnabled
            ? ''
            : 'Manage My Projects'
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
        />
        <ProtectedRoute
          exact
          path='/manage-projects/:projectID'
          component={
            isAdminOrHigherRole(role) || role === ROLE_TYPES.SALES_CONSULTANT
              ? EditProjectPage
              : role === ROLE_TYPES.BUYER
                ? ManageProjectItemsFactory
                : <Redirect to='/' />
          }
          pageTitle={isManageProjectsRevampEnabled
            ? ''
            : 'Manage My Projects'
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.ROCKET_MAN, ROLE_TYPES.SUPPLIER]}
        />
        <ProtectedRoute
          exact
          path='/project-history/:projectID'
          component={ManageProjectHistoryItems}
          pageTitle='Order History'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/verify-parts'
          component={
            hasReviewPermission(role)
              ? VerifyPartsDataGrid
              : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/verify-quotes'
          component={VerifyQuotesDataGridPagination}
          allowedRoles={[
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.REVIEWER,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/generate-rfq'
          component={
            isAdminOrHigherRole(role) ? GenerateRFQ : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/generate-po-automation'
          component={
            isAdminOrHigherRole(role) ? (
              GeneratePoAutomation
            ) : (
              <Redirect to='/' />
            )
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/multi-checkout-tracking'
          component={MultipleCheckoutTracking}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
        />
        <ProtectedRoute
          exact
          path='/admin/signup'
          component={AdminCreateNewUser}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/item/edit/:itemID'
          component={EditItem}
          allowedRoles={[
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.REVIEWER,
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/order/edit/:orderID'
          component={EditOrder}
          allowedRoles={[
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/customer-faq'
          component={role === 'buyer' ? CustomerFaqPage : CustomerFaq}
          pageTitle='Frequently asked questions'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[
            ROLE_TYPES.SUPPLIER,
            ROLE_TYPES.ROCKET_MAN,
            ROLE_TYPES.REVIEWER,
          ]}
        />
        <ProtectedRoute
          exact
          path='/user-faqs'
          component={
            isAdminOrHigherRole(role) ? UserFaqs : <Redirect to='/' />
          }
          pageTitle='Frequently asked questions'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/supplier/instant-quote'
          component={PricePredictorV2}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.BUYER]}
        />
        <ProtectedRoute
          exact
          path='/manage-ppe-feedback'
          component={AllPPEFeedbackDataGrid}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[
            ROLE_TYPES.BUYER,
            ROLE_TYPES.SUPPLIER,
            ROLE_TYPES.ROCKET_MAN,
          ]}
        />
        {FEATURE_FLAG_PART_UPLOAD_REVAMP === 'true'
          ? (
            <ProtectedRoute
              exact
              path='/summary'
              component={PartUploadStepTwoV2}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
              allowAll={true}
            />
          )
          : (
            <ProtectedRoute
              exact
              path='/summary'
              component={MultiplePartSummaryForm}
              pageTitle='Upload 3D Models'
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
              allowAll={true}
            />
          )
        }
        <ProtectedRoute
          exact
          path='/summary/edit'
          component={ModifyAddPart}
          pageTitle='Upload 3D Models'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.SUPPLIER, ROLE_TYPES.ROCKET_MAN]}
        />
        <ProtectedRoute
          exact
          path='/summary/add'
          component={SummaryAddPart}
          pageTitle='Upload 3D Models'
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.SUPPLIER, ROLE_TYPES.ROCKET_MAN]}
        />
        <ProtectedRoute
          exact
          path='/success'
          component={ItemCreatedSuccess}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.SUPPLIER, ROLE_TYPES.ROCKET_MAN]}
        />
        <ProtectedRoute
          exact
          path='/payment-success'
          component={FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP === 'true'
            ? PaymentSuccessV2
            : PaymentSuccess
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          deniedRoles={[ROLE_TYPES.SUPPLIER, ROLE_TYPES.ROCKET_MAN]}
        />
        <ProtectedRoute
          exact
          path='/dfm-checker'
          component={DfmChecker}
          deniedRoles={[ROLE_TYPES.BUYER]}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/control-panel'
          component={
            isAdminOrHigherRole(role) ? ControlPanel : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/instant-quotes-logs'
          component={
            isAdminOrHigherRole(role) ? (
              InstantQuotesLogs
            ) : (
              <Redirect to='/' />
            )
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/generation-tools'
          component={
            hasReviewPermission(role)
              ? GenerationTools
              : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/shared-quotation-logs/:projectID'
          component={
            isAdminOrHigherRole(role) ? SharedQuotationLogDataGrid : <Redirect to='/' />
          }
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path='/calendar'
          component={CalendarView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          allowedRoles={[
            ROLE_TYPES.SUPER_ADMIN,
            ROLE_TYPES.ADMIN,
            ROLE_TYPES.BUYER,
            ROLE_TYPES.SALES_CONSULTANT,
          ]}
        />
        <SentryRoute path='/login' component={Login} />
        <SentryRoute path='/logout' component={Logout} />
        <SentryRoute path='/signup' component={SignupNewCustomer} />
        <SentryRoute path='/getquotenow' component={GetQuoteNowV2} />
        <SentryRoute path='/verify-email' component={VerifyEmail} />
        <SentryRoute
          path='/email-confirmation/:confirmationCode'
          component={EmailConfirmation}
        />
        <SentryRoute path='/reset' component={ResetPassword} />
        <SentryRoute path='/recover' component={Recover} />
        <SentryRoute path='/terms' component={Terms} />
        <SentryRoute path='/privacy' component={Privacy} />
        <SentryRoute path='/email-trigger-actions/:actionID' component={EmailTriggerActions} />
        <SentryRoute path='/guest/:tokenID' component={GuestUserAccessPage} />
      </Switch>
      {isAuthenticated && (
        <CADRendererPopup
          onClose={(evt) => {
            dispatch(openPopupCadRenderer(false));
            evt.stopPropagation();
            evt.preventDefault();
          }}
        />
      )}
      <ScrollManager />
    </AppContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth && state.auth.isAuthenticated,
    isVerifying: state.auth && state.auth.isVerifying,
    role: state.auth?.user?.role,
    userID: state.auth?.user?.userID,
  };
}

export default compose(
  connect(mapStateToProps),
  withQueryClientProviderHOC,
  withSnackbarHOC,
  withLoadingBackDropTextHOC,
)(App);
