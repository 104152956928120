import React from 'react'
import { FtrTooltip } from './ftr-components';

function DfmDefinitionTooltip(props) {
  const { defectKey, children } = props;
  
  const EXTRACT_DEFECT_DEFINITIONS_DISPLAY = {
    deep_holes: 'Recommended hole depth less than 3 times the diameter (for diameters less than 2mm). Hole depth less than 5 times the diameter (for diameters greater than 2mm).',
    small_holes: 'Holes with a diameter less than 0.5mm may be too small.',
    thin_walls: 'Walls with thickness of 0.4mm or less may be challenging to fabricate.',
    sharp_edges: 'Internal sharp edges with a 90-degree angle may have fillet requirements',
    holes_close_to_edge: 'Holes with a distance to the edge less than 0.4mm may be challenging to fabricate.',
    part_sizes_too_big: 'Part sizes greater than 300mm may be challenging to fabricate.',
  };
  
  return (
    <FtrTooltip description={EXTRACT_DEFECT_DEFINITIONS_DISPLAY[defectKey] ?? ''} arrow>
      {children}
    </FtrTooltip>
  )
}

export default DfmDefinitionTooltip