import React from 'react';

export function FlexRow(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function FlexRowAlignStart(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}


export function FlexRowSpaceBetween(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function FlexRowCenter(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function FlexRowEnd(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function FlexRowTop(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function FlexColumn(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        width: '100%',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function FlexColumnCenter(props) {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
