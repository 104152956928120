/* eslint-disable react/no-unknown-property */
import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { extend, useThree, useFrame } from "react-three-fiber";
import { TrackballControls } from "three-stdlib";

// extend THREE to include TrackballControls
extend({ TrackballControls });

const Controls = (_, ref) => {
  const controls = useRef();

  useImperativeHandle(ref, () => ({
    resetControls: () => {
      console.log(controls);
      controls.current.target.set(0, 0, 0);
    },
  }));

  const { camera, gl } = useThree();
  camera.fov = 10;
  camera.far = 10000;
  camera.near = 10;
  camera.rotation.order = "YXZ";
  camera.rotation.y = -Math.PI / 4;
  camera.rotation.x = Math.atan(-1 / Math.sqrt(2)) / 2;
  camera.up.set(0, 1, 0);
  useFrame(() => {
    // update the view as the vis is interacted with
    controls.current.update();
  });

  return (
    <trackballControls
      ref={controls}
      args={[camera, gl.domElement]}
      rotateSpeed={2}
      panSpeed={0.2}
      zoomSpeed={0.2}
      maxDistance={10000}
    />
  );
};

export default forwardRef(Controls);
