import React, { useReducer, forwardRef } from 'react';

import ModifyPdfPopup from '../components/popups/modify-pdf-popup/ModifyPdfPopup';
import { ModifyPdfCanvasContextProvider } from '../context/ModifyPdfCanvasContext';
// ------------------------------------------------------------------------------

function withModifyPdfPopupHOC(WrappedComponent) {
  const modifyPdfPopupHOC = forwardRef((props, ref) => {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => ({ ...prev, ...next }),
      { open: false }
    );

    return (
      <>
        <WrappedComponent
          ref={ref} // Forward the ref to the WrappedComponent
          openPdfEditorPopup={(payload) => updateLocalState({
            open: true,
            ...payload
          })}
          {...props}
        />

        {localState.open && (
          <ModifyPdfCanvasContextProvider>
            <ModifyPdfPopup
              handleClose={() => updateLocalState({ open: false })}
              {...localState}
            />
          </ModifyPdfCanvasContextProvider>
        )}
      </>
    );
  });

  // Set the displayName for the HOC
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  modifyPdfPopupHOC.displayName = `withModifyPdfPopup(${wrappedComponentName})`;

  return modifyPdfPopupHOC;
}

export default withModifyPdfPopupHOC;
