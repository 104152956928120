import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

import CustomerProjectsSearchResult from './CustomerProjectsSearchResult';
import EmptyQuote from './EmptyQuote';
import FtrTabs from '../../components/ftr-components/FtrTabs';
import ManageProjectsQuotationTabPage from './ManageProjectsQuotationTabPage';
import ManageProjectsOrdersInProgressTabPage from './ManageProjectsOrdersInProgressTabPage';
import ManageProjectsOrdersCompleteTabPage from './ManageProjectsOrdersCompleteTabPage';
import FtrSearchBar from '../../components/ftr-components/FtrSearchBar';
import FtrCheckboxDropdown from '../../components/ftr-components/FtrCheckboxDropdown';
import { FlexColumn, FlexRow, FlexRowSpaceBetween } from '../../components/layouts/FlexLayouts';
import { FtrH7 } from '../../components/ftr-components';
import { TopRightPositionAbsolute } from '../../components/ftr-components/FixedPosition';
import ShipmentCalendarIcon from '../../components/icons/ShipmentCalendarIcon';

import withManageProjectsRevampBannerHOC from '../../hocs/withManageProjectsRevampBannerHOC';

import { getAllProjectsByUser } from '../../apis/projectApi';
import { getAllOrderByCustomerApi } from '../../apis/multiCheckoutApi';

import { getUserIDSelector } from '../../selectors/userSelector';

import { transformArrayToMapObject } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getLatestDeliveryDateForMct, isPpeItem } from '../../utils/itemUtils';

import { DATE_CREATED_FILTER_LIST, DEFAULT_ITEM_STATUS_FILTER_LIST } from '../../constants/filterConstants';
import { ITEM_STATUS_MAPPING } from '../../constants';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    }
  },
  body: {
    height: '100vh',
    padding: '2rem',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TABS = [
  'Quotation',
  'In-Progress',
  'Completed',
]

function ManageProjectsV2() {
  const classes = useStyles();

  const userID = useSelector(getUserIDSelector);

  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(0);
  const [searchStr, setSearchStr] = useState('');
  const [statusFilterList, setStatusFilterList] = useState([]);
  const [createdDateFilter, setCreatedDateFilter] = useState(null);

  const {
    data: allProjects = [],
    status: projectsLoadingStatus,
  } = useQuery(
    ['getAllProjectsByUser', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getAllProjectsByUser(userID);
    });

  const allProjectMapping = useMemo(() => {
    if (isEmptyValue(allProjects)) {
      return {};
    }

    return transformArrayToMapObject(allProjects, 'projectID');
  }, [allProjects]);

  const allItemsMapping = useMemo(() => {
    if (isEmptyValue(allProjects)) {
      return {};
    }

    const allItems = allProjects.map(project => project.items).flat();

    return transformArrayToMapObject(allItems, 'itemID');
  }, [allProjects]);

  const quotationProjects = allProjects.filter(project => {
    return project.items.some(item => {
      return [
        ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
        ITEM_STATUS_MAPPING.SOURCING,
      ].includes(item.status)
        || (ITEM_STATUS_MAPPING.VERIFYING === item.status && !isPpeItem(item));
    });
  });

  const { status: ordersLoadingStatus, data: allOrders = [] } = useQuery(
    ['allOrdersByCustomer', userID],
    () => getAllOrderByCustomerApi(userID),
  );

  const allOrdersInProgressWithProjectInfo = useMemo(() => {
    if (isEmptyValue(allOrders)) {
      return [];
    }

    return allOrders
      .filter(order => {
        const project = allProjectMapping[order.projectID];
        if (isEmptyValue(project)) {
          return false;
        }
        return true;
      })
      .map(order => {
        const project = allProjectMapping[order.projectID];
        const projectItems = project?.items || [];
        const mctItems = order.acceptedItems.map(ai => {
          const itemID = ai.itemID;
          const item = projectItems.find(i => i.itemID === itemID) || {};
          return {
            itemID,
            ...item,
          }
        });

        return {
          ...order,
          ...project,
          items: mctItems,
          twoDImageUrls: order.acceptedItems.map(ai => {
            const itemID = ai.itemID;
            const item = projectItems.find(i => i.itemID === itemID);
            return item?.imageUrl;
          }),
        }
      })
      .filter((order) => !checkOrderDelivered(order));
  }, [
    allOrders,
    allProjectMapping,
  ]);

  const allOrdersCompletedWithProjectInfo = useMemo(() => {
    if (isEmptyValue(allOrders)) {
      return [];
    }

    return allOrders
      .filter(order => {
        const project = allProjectMapping[order.projectID];
        if (isEmptyValue(project)) {
          return false;
        }
        return true;
      })
      .map(order => {
        const project = allProjectMapping[order.projectID];
        const projectItems = project?.items || [];
        const mctItems = order.acceptedItems.map(ai => {
          const itemID = ai.itemID;
          const item = projectItems.find(i => i.itemID === itemID) || {};
          return {
            itemID,
            ...item,
          }
        });

        const latestDeliveryDate = getLatestDeliveryDateForMct(mctItems);

        return {
          ...order,
          ...project,
          items: mctItems,
          twoDImageUrls: order.acceptedItems.map(ai => {
            const itemID = ai.itemID;
            const item = projectItems.find(i => i.itemID === itemID);
            return item?.imageUrl;
          }),
          latestDeliveryDate,
        }
      })
      .filter(checkOrderDelivered);
  }, [
    allOrders,
    allProjectMapping,
  ]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries('allOpenCompletedProjects');
    }
  }, []);

  function checkOrderDelivered(order) {
    return order.items.every(item => item.status === ITEM_STATUS_MAPPING.DELIVERED);
  }

  const renderFilterRow = () => {
    return (
      <FlexRow style={{ marginBottom: '1.5rem' }}>
        <FtrCheckboxDropdown
          id='project-status-created-date-dropdown'
          label='Date Created'
          items={DATE_CREATED_FILTER_LIST}
          handleChange={(value) => {
            const createdDateFilter = value.filter(o => o.checked).map(o => o.label);
            setCreatedDateFilter(createdDateFilter[0]);
          }}
          showCheckbox={false}
        />
        <FtrCheckboxDropdown
          id='project-status-filter-dropdown'
          label='Project Status'
          items={DEFAULT_ITEM_STATUS_FILTER_LIST}
          handleChange={(value) => {
            const statusFilterList = value.filter(o => o.checked).map(o => o.label);
            setStatusFilterList(statusFilterList);
          }}
        />
        <ShipmentCalendarIcon />
      </FlexRow>
    );
  }

  const renderQuotation = () => {
    if (isEmptyValue(quotationProjects)) {
      return (
        <EmptyQuote />
      );
    }

    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        {(!isEmptyValue(allProjects)
          || !isEmptyValue(quotationProjects))
          && renderFilterRow()}
        <FlexColumn
          style={{
            flex: '1 1 auto',
            justifyContent: isEmptyValue(quotationProjects) ? 'center' : 'start',
          }}
        >
          <ManageProjectsQuotationTabPage
            loading={projectsLoadingStatus === 'loading'}
            projects={quotationProjects}
            searchStr={searchStr}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
          />
        </FlexColumn>
      </FlexColumn>
    );
  }

  const renderOrdersInProgress = () => {
    if (isEmptyValue(allOrdersInProgressWithProjectInfo)) {
      return (
        <EmptyQuote
          text='Ongoing orders will appear here once the purchase is made.'
        />
      );
    }

    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        {(!isEmptyValue(allProjects)
          || !isEmptyValue(allOrdersInProgressWithProjectInfo))
          && renderFilterRow()}
        <FlexColumn
          style={{
            flex: '1 1 auto',
            justifyContent: isEmptyValue(allOrdersInProgressWithProjectInfo) ? 'center' : 'start',
          }}
        >
          <ManageProjectsOrdersInProgressTabPage
            loading={ordersLoadingStatus === 'loading'}
            projects={allOrdersInProgressWithProjectInfo}
            searchStr={searchStr}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
            allItemsMapping={allItemsMapping}
          />
        </FlexColumn>
      </FlexColumn>
    );
  }

  const renderOrdersCompleted = () => {
    if (isEmptyValue(allOrdersCompletedWithProjectInfo)) {
      return (
        <EmptyQuote
          text='Completed orders will appear here upon delivered.'
        />
      );
    }

    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        {(!isEmptyValue(allProjects)
          || !isEmptyValue(allOrdersCompletedWithProjectInfo))
          && renderFilterRow()
        }
        <FlexColumn
          style={{
            flex: '1 1 auto',
            justifyContent: isEmptyValue(allOrdersCompletedWithProjectInfo) ? 'center' : 'start',
          }}
        >
          <ManageProjectsOrdersCompleteTabPage
            loading={ordersLoadingStatus === 'loading'}
            projects={allOrdersCompletedWithProjectInfo}
            searchStr={searchStr}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
            allItemsMapping={allItemsMapping}
          />
        </FlexColumn>
      </FlexColumn>
    );
  }

  const renderTab = () => {
    return (
      <>
        {selectedTab === 0 && renderQuotation()}
        {selectedTab === 1 && renderOrdersInProgress()}
        {selectedTab === 2 && renderOrdersCompleted()}
      </>
    )
  }

  const renderCustomerProjectsSearchResult = () => {
    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        <CustomerProjectsSearchResult
          searchStr={searchStr}
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          quotationProjects={quotationProjects}
          orderInProgressProjects={allOrdersInProgressWithProjectInfo}
          orderCompletedProjects={allOrdersCompletedWithProjectInfo}
        />
      </FlexColumn>
    );
  }

  return (
    <div className={classes.body}>
      <FtrH7>
        Manage Projects
      </FtrH7>
      <Box style={{ height: '1rem', minHeight: '1rem', width: '100%' }} />
      <FlexRowSpaceBetween style={{ position: 'relative', marginTop: '1rem' }}>
        <FtrTabs.FtrDivider style={{ bottom: 8 }} />
        <FtrTabs
          itemList={TABS.map(tab => {
            return {
              key: tab,
              text: tab,
            }
          })}
          value={selectedTab}
          onChange={(newValue) => {
            setStatusFilterList([]);
            setSearchStr('');
            setSelectedTab(newValue);
          }}
          tabStyle={{
            fontSize: 20,
            marginRight: '2rem',
            paddingBottom: 16,
          }}
        />
        <TopRightPositionAbsolute>
          <FtrSearchBar
            autoFocus
            onSearch={setSearchStr}
            placeholder='Search parts, IDs and project'
            useDebounceSearch={true}
            // width={isMobile && '100%'}
            searchTerm={searchStr}
            style={{
              // margin: isMobile ? '0 0 1rem 0' : 0,
              padding: 0,
              marginTop: -16,
            }}
          />
        </TopRightPositionAbsolute>
      </FlexRowSpaceBetween>
      <Box style={{ height: '1rem', minHeight: '1rem', width: '100%' }} />
      <FlexColumn
        style={{
          justifyContent: 'center',
          flex: 1,
          marginTop: '1rem',
          paddingBottom: '1rem',
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {isEmptyValue(searchStr) && renderTab()}
        {!isEmptyValue(searchStr) && renderCustomerProjectsSearchResult()}
      </FlexColumn>
      <Box style={{ height: '1.5rem', minHeight: '1.5rem', width: '100%' }} />
    </div>
  );
}

export default withRouter(
  withManageProjectsRevampBannerHOC(ManageProjectsV2)
);
