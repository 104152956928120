import React, { useEffect, useReducer, useState } from 'react';
import { format } from 'date-fns';
import { ceil } from 'lodash';
import { CsvBuilder } from 'filefy';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Link as LinkButton,
  Tooltip,
} from '@material-ui/core';

import InstantLogInfoPopup from '../../components/popups/InstantLogInfoPopup';

import CustomToolbar, { DataGridToolbarLayout } from '../../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';

import { getAllPPELeadtimeQuoteFeedback } from '../../apis/ppeFeedbackApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 100;

function PpeLeadTimeFeedbackDataGridPagination() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: tableData,
  });

  const [instantLogInfoPopup, updateInstantLogInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true })
    getAllPPELeadtimeQuoteFeedback()
      .then((data) => {
        setTableData(data);
      })
      .catch((e) => console.log(e))
      .finally(() => updateTableQueryParams({ loading: false }))
  }

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const defaultColumns = [
    {
      headerName: 'iqLogsID',
      field: 'iqLogsID',
      width: 300,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.iqLogsID} />,
    },
    {
      headerName: "Part ID",
      field: "itemID",
      renderCell: ({ row: rowData }) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Tooltip title="Hold Ctrl/Cmd and click to edit in new tab">
              <Button
                className={classes.linkButton}
                href={`/item/edit/${rowData.itemID}`}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: 120,
    },
    {
      headerName: 'User (ID)',
      field: 'userID',
      width: 100,
    },
    {
      headerName: 'Role',
      field: 'role',
      width: 100,
    },
    {
      headerName: 'User Email',
      field: 'customerEmail',
      width: 250,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.customerEmail} />,
    },
    {
      headerName: 'Feedback',
      field: 'feedback',
      width: 120,
    },
    {
      headerName: 'Target Lead Time',
      field: 'targetLeadTime',
      minWidth: 175,
      flex: 0.5,
    },
    {
      headerName: 'Target Delivery Date',
      field: 'targetDeliveryDate',
      width: 150,
      renderCell: ({ row: rowData }) => {
        const text = rowData.targetDeliveryDate
          ? format(new Date(rowData.targetDeliveryDate), 'yyyy-MM-dd')
          : 'N.A.';
        return (
          <DataGridWrapTextCell
            text={text}
          />
        );
      },
    },
    {
      headerName: 'PPE Lead Time',
      field: 'leadTime',
      minWidth: 175,
      flex: 0.5,
    },
    {
      headerName: 'PPE express delivery Date',
      field: 'deliveryDate',
      width: 150,
      renderCell: ({ row: rowData }) => {
        const text = rowData.deliveryDate
          ? format(new Date(rowData.deliveryDate), 'yyyy-MM-dd')
          : 'N.A.';
        return (
          <DataGridWrapTextCell
            text={text}
          />
        );
      },
    },
    {
      headerName: "Request",
      field: "request",
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.meta.request, null, 2) ?? '';
        const rows = content.split("\n");
        const length = rows.length;
        content = rows.slice(0, 4).join('\n');
        return (
          <div style={{
            whiteSpace: 'pre-wrap',
            lineHeight: 'normal',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}>
            {content}
            {length > 4 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant="link"
                onClick={() => {
                  updateInstantLogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.request) ?? '';
      },
      width: 250,
    },
    {
      headerName: "Response",
      field: "response",
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.meta.response, null, 2) ?? '';
        const rows = content.split("\n");
        const length = rows.length;
        content = rows.slice(0, 4).join('\n');
        return (
          <div style={{
            whiteSpace: 'pre-wrap',
            lineHeight: 'normal',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}>
            {content}
            {length > 4 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant="link"
                onClick={() => {
                  updateInstantLogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.response) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      width: 150,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}
          />
        );
      },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    getTableData();
    const _columns = defaultColumns.filter(col => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  const handleDownloadCsv = async () => {
    const fileName = `All Lead Time Feedback ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData,
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.id}
          rowHeight={ROW_HEIGHT}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
        {instantLogInfoPopup.open && (
          <InstantLogInfoPopup
            open={instantLogInfoPopup.open}
            handleClose={() => updateInstantLogInfoPopup({ open: false })}
            rowData={instantLogInfoPopup.selectedData}
          />
        )}
      </div>
    </div>
  );
}

export default PpeLeadTimeFeedbackDataGridPagination;
