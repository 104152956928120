import queryString from 'query-string';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// -------------------------------------------------------------------------------------------------

export const adminSearchXeroInvoiceItems = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/search?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const adminSearchXeroInvoiceItemsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/search/csv-export?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const createXeroInvoiceItems = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const updateXeroInvoiceItemsStatus = async (xeroInvoiceID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/${xeroInvoiceID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const regenerateS3PdfInvoice = async (id) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/${id}/regenerate-s3-pdf`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
