import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Badge,
  Tooltip,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';

import {
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';

import FilesUploadActionButton from '../components/buttons/FilesUploadActionButton';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import withActionTrackingHOC from '../hocs/withActionTrackingHOC';

import { convertPriceToCurrency, convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import { downloadS3File } from '../utils/fileUtils';
import { getDateStrWithMonth } from '../utils/dateTimeUtils';
import { getIsRepeatOrder } from '../utils/itemUtils';
import { includesIgnoreCase } from '../utils/stringUtils';
import { isEmptyValue } from '../utils/commonUtils';
import {
  getBackgroundColorByQuoteStatus,
  getDiscountAmountUtil,
  getQuotationExchangeRate,
  isPpeQuote,
  isRocketQuote,
  reasonsTextUtil,
} from '../utils/quotationUtils';

import { ORDER_STATUS, ORDER_STATUS_INFO } from '../constants';
import { CURRENCY_CODE } from './currencyConstants';

import { colors } from '../palette';
import { ACTION_TYPE } from './actionTrackingConstants';


// --------------------------------------------------------------------------------

const CopyUrlIconButton = withActionTrackingHOC(IconButton);

export const dateAcceptedCol = {
  title: 'Date Accepted',
  headerName: 'Date Accepted',
  field: 'dateOfConfirmation',
  render: rowData => {
    const dateStr = rowData.dateOfConfirmation || rowData.dateOfAcceptance;
    return (
      <div>
        {dateStr
          ? getDateStrWithMonth(dateStr)
          : ''}
      </div>
    );
  },
  valueGetter: ({ row: rowData }) => {
    const dateStr = rowData.dateOfConfirmation || rowData.dateOfAcceptance;
    return dateStr ? getDateStrWithMonth(dateStr) : '';
  },
  cellStyle: {
    minWidth: 150,
    textAlign: 'center',
  },
  hidden: true,
  hide: true,
  export: true,
}

export const dateOfConfirmationCol = {
  title: 'Date of Confirmation',
  exportData: rowData => {
    const data = rowData.dateOfConfirmation
      ? rowData.dateOfConfirmation.substring(0, 10)
      : '';
    return data;
  },
  hidden: true,
  export: true,
}

export const markupPercentCol = {
  title: 'Markup Percent',
  headerName: 'Markup Percent',
  field: 'markupPercent',
  hidden: true,
  export: true,
}

export const quoteValidTillCol = {
  title: 'Quote Valid Till',
  exportData: rowData => {
    const data = rowData.dateOfExpiry
      ? rowData.dateOfExpiry.substring(0, 10)
      : '';
    return data;
  },
  hidden: true,
  export: true,
}

export const supplierRemarksCol = {
  title: 'Supplier Remarks',
  exportData: rowData => {
    const data = rowData.remarks || '';
    return data;
  },
  hidden: true,
  export: true,
}

const renderPurchaseOrder = (rowData, uploadCallback) => {
  const poFormURL = rowData.combinedPOsForm || rowData.purchaseOrderForm //for legacy data, some may not have combinedPOsForm
  const poUrlDisplay = poFormURL
    ? (
      <div>
        <Link
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            downloadS3File(poFormURL);
          }}
        >
          Download PO
        </Link>
        <CopyUrlIconButton
          actionTrackingKey={ACTION_TYPE.DOWNLOAD_FILE}
          additionalInfo={{
            urls: [poFormURL],
          }}
          trackingInfo={{
            quotationID: rowData.quotationID,
            itemID: rowData.itemID,
            projectID: rowData.projectID,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(poFormURL)
          }}>
          <FileCopyIcon />
        </CopyUrlIconButton>
      </div>
    ) : (
      <div>N.A.</div>
    );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 'normal',
      }}
    >
      {poUrlDisplay}
      {poFormURL && (
        <FilesUploadActionButton
          inputID={`file-upload-quotation-${rowData.quotationID}`}
          highlightButton
          buttonText='Upload PO Revision'
          multiple={false}
          handleUploadFiles={(files) => {
            if (typeof uploadCallback === 'function') {
              uploadCallback(rowData, { files, poFormURL });
            }
          }}
          accept='.pdf'
        />
      )}
    </div>
  );
}

export const purchaseOrderAdminCol = ({
  uploadCallback,
}) => ({
  title: 'Purchase Order',
  headerName: 'Purchase Order', // for data grid
  field: 'purchaseOrder',
  exportData: rowData => rowData.combinedPOsForm || rowData.purchaseOrderForm || 'N.A.',
  render: rowData => renderPurchaseOrder(rowData, uploadCallback),
  renderCell: ({ row: rowData }) => renderPurchaseOrder(rowData, uploadCallback), // for data grid
  width: 180, // for data grid
  cellStyle: {
    minWidth: 250,
  },
});

const renderTooltipStatus = (status) => {
  if (status !== 'cancelled') return status;
  return (
    <Tooltip title='Order Cancelled'>
      <div>
        {status}
      </div>
    </Tooltip>
  )
}

const renderReasonsText = (text) => {
  if (!text) {
    return null;
  }
  return (
    <div style={{
      display: 'flex',
      fontSize: '11px',
      fontWeight: '500',
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    }}>
      {text}
    </div>
  )
}

const renderQuotationStatus = (rowData) => {
  let status = rowData.ppeQuoteStatus || rowData.status;
  status = [ORDER_STATUS.VERIFYING, ORDER_STATUS.PENDING].includes(status)
    ? ORDER_STATUS_INFO.SUBMITTED
    : status === ORDER_STATUS.DISABLED
      ? ORDER_STATUS_INFO.CANCELLED
      : status
  const bgColor = getBackgroundColorByQuoteStatus(status);
  const dateStr = rowData.dateOfConfirmation || rowData.dateOfAcceptance;
  const reasonRejectedWithSymbol = reasonsTextUtil(rowData.reasonRejected);
  const disabledReasonWithSymbol = reasonsTextUtil(rowData.disabledReason);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: bgColor,
          display: 'flex',
          justifyContent: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          width: '100%',
          lineHeight: 'normal',
        }}
      >
        {rowData.price != null && rowData.status === ORDER_STATUS.VERIFYING
          ? 'Processing'
          : renderTooltipStatus(status)}
      </div>
      {status === ORDER_STATUS.ACCEPTED && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          {dateStr
            ? `Delivery Date: ${getDateStrWithMonth(rowData.maxCollectionDate || rowData.collectionDate)}`
            : ''}
        </div>
      )}
      {status === ORDER_STATUS.REJECTED && renderReasonsText(reasonRejectedWithSymbol)}
      {status === ORDER_STATUS_INFO.CANCELLED && renderReasonsText(disabledReasonWithSymbol)}
    </div>
  );
}

export const quotationStatusColumn = {
  title: 'Status',
  headerName: 'Status',
  field: 'status',
  exportData: rowData => {
    const status = rowData.ppeQuoteStatus || rowData.status;
    return rowData.price != null && rowData.status === ORDER_STATUS.VERIFYING
      ? 'Processing'
      : status;
  },
  customFilterAndSearch: (term, rowData) => {
    const status = rowData.ppeQuoteStatus || rowData.status;
    const data = rowData.price != null && rowData.status === ORDER_STATUS.VERIFYING
      ? 'Processing'
      : status;
    return includesIgnoreCase(data, term);
  },
  render: rowData => {
    return renderQuotationStatus(rowData);
  },
  renderCell: params => { // for data grid
    const rowData = params.row;
    return renderQuotationStatus(rowData);
  },
  valueGetter: params => { // for grid data
    const rowData = params.row;
    const status = rowData.ppeQuoteStatus || rowData.status;
    return rowData.price != null && rowData.status === ORDER_STATUS.VERIFYING
      ? 'Processing'
      : status;
  },
  width: 200,
}

const renderPoAcknowledge = (rowData) => {
  if (rowData.poAcknowledged === null) {
    return 'N.A.';
  }
  if (Boolean(rowData.poAcknowledged) === true) {
    return (
      <span style={{ color: 'green' }}>Yes</span>
    );
  } else if (Boolean(rowData.poAcknowledged) === false) {
    return (
      <span style={{ color: 'orange' }}>No</span>
    );
  } else {
    return 'N.A.';
  }
}

export const poAcknowledgeColumn = {
  title: 'PO Acknowledged',
  field: 'poAcknowledged',
  headerName: 'PO Acknowledged', // for data grid
  exportData: rowData => {
    if (rowData.poAcknowledged === null) {
      return 'N.A.';
    }
    if (Boolean(rowData.poAcknowledged) === true) {
      return 'Yes';
    } else if (Boolean(rowData.poAcknowledged) === false) {
      return 'No';
    } else {
      return 'N.A.';
    }
  },
  render: rowData => renderPoAcknowledge(rowData),
  renderCell: ({ row: rowData }) => renderPoAcknowledge(rowData), // for data grid
  width: 135, // for data grid
  cellStyle: {
    textAlign: 'center'
  },
}

export const originalQuoteWithCurrencyColumn = {
  title: 'Original Quote',
  render: rowData => {
    return convertPriceToCurrency(
      {
        price: rowData.originalQuote,
        currency: rowData.currency,
        exchangeRate: 1,
      },
      ''
    );
  },
  cellStyle: {
    minWidth: 150,
  },
}

export const originalQuoteColumn = {
  title: 'Original Quote',
  headerName: 'Original Quote',
  field: 'originalQuote',
  render: rowData => {
    return convertPriceToCurrency(
      {
        price: rowData.priceBidded,
        currency: CURRENCY_CODE.SGD,
        exchangeRate: 1,
      },
      ''
    );
  },
  renderCell: ({ row: rowData }) => { // for data grid
    return [undefined, null].includes(rowData.originalQuote)
      ? 'N.A.'
      : convertPriceToCurrency(
        {
          price: rowData.originalQuote,
          currency: rowData.currency,
          exchangeRate: 1,
        },
        ''
      );
  },
  width: 100,
}

export const totalPriceShownToCustomerWithOriginalCurrencyCol = {
  title: 'Total Price shown to customer (excl. Factorem GST)',
  render: rowData => {
    return rowData.totalPrice
      ? convertPriceToCurrency(
        {
          price: rowData.totalPrice,
          currency: rowData.currency,
          exchangeRate: 1,
        },
        ''
      )
      : '';
  },
  cellStyle: {
    width: 150,
  },
}

export const totalPriceShownToCustomerCol = {
  title: 'Total Price shown to customer (excl. Factorem GST)',
  headerName: 'Total Price shown to customer (excl. Factorem GST)',
  field: 'totalPriceShownToCustomerExclGst',
  render: rowData => {
    const totalPrice = rowData.totalPrice
      ? convertPriceToCurrency(
        {
          price: rowData.totalPrice,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        },
        ''
      )
      : '';
    const discountAmount = getDiscountAmountUtil(rowData)
    return (
      <div>
        <div style={{ color: colors.blue050 }}>
          {totalPrice}
        </div>
        {discountAmount === 'N.A.' ? null : (
          <div style={{ color: colors.errorRed, fontSize: '0.9rem' }}>{discountAmount}</div>
        )}
      </div>
    )
  },
  renderCell: ({ row: rowData }) => {
    const totalPrice = rowData.totalPrice
      ? convertPriceToCurrency(
        {
          price: rowData.totalPrice,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        },
        ''
      )
      : '';
    const discountAmount = getDiscountAmountUtil(rowData)
    return (
      <div>
        <div style={{ color: colors.blue050 }}>
          {totalPrice}
        </div>
        {discountAmount === 'N.A.' ? null : (
          <div style={{ color: colors.errorRed, fontSize: '0.9rem' }}>{discountAmount}</div>
        )}
      </div>
    )
  },
  exportData: rowData => {
    return rowData.totalPrice
      ? convertPriceToCurrency(
        {
          price: rowData.totalPrice,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        },
        ''
      )
      : ''
  },
  width: 200,
}

export const platformFeeCol = {
  title: 'Service Fee',
  render: (rowData) =>
    convertPriceToCurrency({
      price: rowData.platformFee,
      currency: CURRENCY_CODE.SGD,
      exchangeRate: 1,
    }),
  exportData: (rowData) =>
    convertPriceToCurrency({
      price: rowData.platformFee,
      currency: CURRENCY_CODE.SGD,
      exchangeRate: 1,
    }),
  // for data grid
  headerName: 'Service Fee',
  field: 'platformFee',
  renderCell: ({ row: rowData }) =>
    convertPriceToCurrency({
      price: rowData.platformFee,
      currency: CURRENCY_CODE.SGD,
      exchangeRate: 1,
    }),
  valueGetter: ({ row: rowData }) =>
    convertPriceToCurrency({
      price: rowData.platformFee,
      currency: CURRENCY_CODE.SGD,
      exchangeRate: 1,
    }),
  width: 130,
};

export const threeDTechnologyCol = {
  title: '3D Printing Technology',
  render: rowData => {
    const data = get(rowData.metadata, 'threeDTechnology') || '';
    return data;
  },
  // for data grid
  headerName: '3D Printing Technology',
  field: '3DPrintingTechnology',
  renderCell: ({ row: rowData }) => {
    const data = get(rowData.metadata, 'threeDTechnology') || '';
    return <DataGridWrapTextCell text={data} />;
  },
  valueGetter: ({ row: rowData }) => {
    const data = get(rowData.metadata, 'threeDTechnology') || '';
    return data
  },
  width: 150
}

export const threeDInfillCol = {
  title: '3D Infill',
  render: rowData => {
    const data = get(rowData.metadata, 'threeDInfill') || '';
    return isEmptyValue(data) ? '' : `${Number(data * 100).toFixed(2)}%`;
  },
  // for data grid
  headerName: '3D Infill',
  field: '3DInfill',
  renderCell: ({ row: rowData }) => {
    const data = get(rowData.metadata, 'threeDInfill') || '';
    const displayStr = isEmptyValue(data) ? '' : `${Number(data * 100).toFixed(2)}%`
    return <DataGridWrapTextCell text={displayStr} />
  },
  valueGetter: ({ row: rowData }) => {
    const data = get(rowData.metadata, 'threeDInfill') || '';
    const displayStr = isEmptyValue(data) ? '' : `${Number(data * 100).toFixed(2)}%`
    return displayStr
  },
  width: 120
}

export const threeDLayerThicknessCol = {
  title: '3D Layer Thickness',
  render: rowData => {
    const data = get(rowData.metadata, 'threeDLayerThickness') || '';
    return isEmptyValue(data) ? '' : `${data}mm`;
  },
  // for data grid
  headerName: '3D Layer Thickness',
  field: '3dLayerThickness',
  renderCell: ({ row: rowData }) => {
    const data = get(rowData.metadata, 'threeDLayerThickness') || '';
    return isEmptyValue(data) ? '' : `${data}mm`;
  },
  valueGetter: ({ row: rowData }) => {
    const data = get(rowData.metadata, 'threeDLayerThickness') || '';
    return isEmptyValue(data) ? '' : `${data}mm`;
  },
  width: 120
}

const renderYes = () => {
  return <span style={{ color: 'green' }}>Yes</span>;
}

const renderRocketQuoteBadgeTooltip = (rowData) => {
  const { numOfPpeQuotes, adminEmail } = rowData;
  const supplierText = numOfPpeQuotes > 1 ? 'Suppliers' : 'Supplier';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 'normal',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      <Tooltip
        title={`${numOfPpeQuotes} ${supplierText} quoted`}
        arrow
        placement='top'
      >
        <Badge
          badgeContent={numOfPpeQuotes}
          color='error'
        >
          {renderYes()}
        </Badge>
      </Tooltip>
      {adminEmail && (
        <Typography style={{ fontSize: '14px' }}>({adminEmail})</Typography>
      )}
    </div>
  );
}

const renderRocketQuoteBadgeTooltipForDataGrid = (rowData) => {
  const useStyles = makeStyles(() => ({
    tooltipBadge: {
      top: -10,
    },
  }));

  const classes = useStyles();

  const { numOfPpeQuotes, adminEmail } = rowData;
  const supplierText = numOfPpeQuotes > 1 ? 'Suppliers' : 'Supplier';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 'normal',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      <Tooltip
        title={`${numOfPpeQuotes} ${supplierText} quoted`}
        arrow
        placement='top'
      >
        <Badge
          badgeContent={numOfPpeQuotes}
          color='error'
          classes={{
            badge: classes.tooltipBadge,
          }}
        >
          {renderYes()}
        </Badge>
      </Tooltip>
      {adminEmail && (
        <Typography style={{ fontSize: '14px' }}>({adminEmail})</Typography>
      )}
    </div>
  );
}

const renderRocketQuoteYesWithAdminInfo = (rowData) => {
  const { adminEmail } = rowData;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 'normal',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      <span style={{ color: 'green' }}>Yes</span>
      {adminEmail && (
        <Typography style={{ fontSize: '14px' }}>({adminEmail})</Typography>
      )}
    </div>
  );
}

export const rocketQuoteInfoCol = {
  title: 'Rocket Quote',
  exportData: rowData => {
    if (!isRocketQuote(rowData)) {
      return 'No';
    }
    return 'Yes';
  },
  render: rowData => {
    if (!isRocketQuote(rowData)) {
      return <span style={{ color: 'orange' }}>No</span>;
    }
    const { numOfPpeQuotes } = rowData;
    return numOfPpeQuotes
      ? renderRocketQuoteBadgeTooltip(rowData)
      : renderRocketQuoteYesWithAdminInfo(rowData);
  },
  // datagrid
  headerName: 'Rocket Quote',
  field: 'rocketQuote',
  renderCell: (params) => {
    const rowData = params.row;
    if (!isRocketQuote(rowData)) {
      return <span style={{ color: 'orange' }}>No</span>;
    }
    const { numOfPpeQuotes } = rowData;
    return numOfPpeQuotes
      ? renderRocketQuoteBadgeTooltipForDataGrid(rowData)
      : renderRocketQuoteYesWithAdminInfo(rowData);
  },
  cellStyle: {
    textAlign: 'center',
  },
  width: 170,
}

const getSupplierPriceStr = (rowData, currency, exchangeRate) => {
  let totalPriceStr;
  if (isPpeQuote(rowData) || rowData.currency !== currency) {
    const { totalPriceStr: convertedTotalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: rowData.priceBidded ?? 0,
      quantity: rowData.quantity,
      currency,
      exchangeRate: getQuotationExchangeRate(rowData, currency) || exchangeRate,
    });
    totalPriceStr = convertedTotalPriceStr;
  } else {
    const { totalPriceStr: convertedTotalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: rowData.currency === CURRENCY_CODE.SGD
        ? rowData.priceBidded ?? 0
        : rowData.originalQuote,
      quantity: rowData.quantity,
      currency: rowData.currency,
      exchangeRate: 1,
    });
    totalPriceStr = convertedTotalPriceStr;
  }
  return totalPriceStr;
}

export const supplierPriceStrDisplayCol = ({ currency, exchangeRate }) => ({
  title: 'Price',
  headerName: 'Price',
  field: 'price',
  render: rowData => {
    return getSupplierPriceStr(rowData, currency, exchangeRate);
  },
  valueGetter: params => { // for data grid
    const rowData = params.row;
    return getSupplierPriceStr(rowData, currency, exchangeRate);
  },
  renderCell: params => { // for data grid
    const rowData = params.row;
    return getSupplierPriceStr(rowData, currency, exchangeRate);
  },
  cellStyle: {
    minWidth: 150,
    textAlign: 'center',
  },
  width: 130, // for data grid
})

export const repeatOrderInfoCol = {
  title: 'Repeat Order',
  headerName: 'Repeat Order',
  field: 'repeatOrder',
  exportData: (rowData) => getIsRepeatOrder(rowData.instantQuotesLogMeta) ? 'Yes' : 'No',
  render: (rowData) =>
    getIsRepeatOrder(rowData.instantQuotesLogMeta) ? (
      <span style={{ color: 'green' }}>Yes</span>
    ) : (
      <span style={{ color: 'orange' }}>No</span>
    ),
  renderCell: ({ row: rowData }) =>
    getIsRepeatOrder(rowData.instantQuotesLogMeta) ? (
      <span style={{ color: 'green' }}>Yes</span>
    ) : (
      <span style={{ color: 'orange' }}>No</span>
    ),
};

export const projectIDCol = {
  title: 'Project ID',
  headerName: 'Project ID',
  field: 'projectID',
  renderCell: ({ row: rowData }) => {
    const projectPath = `projects/${rowData.projectID}/quote?technology=${rowData.technology}&viewQuote=true&supplierID=${rowData.userID}`;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Tooltip title='View part details'>
          <Button
            style={{
              color: colors.blue050,
              textDecoration: 'underline',
              background: colors.paleBlue,
            }}
            href={projectPath}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(projectPath, '_blank');
            }}
          >
            {rowData.projectID}
          </Button>
        </Tooltip>
      </div>
    );
  },
}
