import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import { Tooltip } from "@material-ui/core";
import BrowseSeparateDisplay from "./BrowseSeparateDisplay";
import BrowseProjectDisplay from "./BrowseProjectDisplay";

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
  browseHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const DISPLAY_MODE = {
  SEPARATE_VIEW: 'SEPARATE_VIEW',
  PROJECT_VIEW: 'PROJECT_VIEW',
}

export function BrowseWithOptions() {
  const classes = useStyles();

  const [displayMode, setDisplayMode] = useState(DISPLAY_MODE.PROJECT_VIEW);
  const [showModeToggleButton] = useState(false);

  const handleToggleChange = (event, newDisplayMode) => {
    if (newDisplayMode === null) {
      return;
    }
    setDisplayMode(newDisplayMode);
  }

  const renderDisplayModeToggle = () => {
    return (
      <ToggleButtonGroup
        value={displayMode}
        exclusive
        onChange={handleToggleChange}
        aria-label="Display Mode"
        size="small"
      >
        <Tooltip title="Project View" placement="top">
          <ToggleButton
            selected={displayMode === DISPLAY_MODE.PROJECT_VIEW}
            value={DISPLAY_MODE.PROJECT_VIEW}
          >
            <FeaturedPlayListIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Separate View" placement="top">
          <ToggleButton
            selected={displayMode === DISPLAY_MODE.SEPARATE_VIEW}
            value={DISPLAY_MODE.SEPARATE_VIEW}
          >
            <ViewModuleIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    );
  }

  return (
    <div className={classes.body}>
      <div className={classes.browseHeader}>
        <h1>Partner Job Board</h1>
        {showModeToggleButton && renderDisplayModeToggle()}
      </div>
      {displayMode === DISPLAY_MODE.SEPARATE_VIEW && <BrowseSeparateDisplay />}
      {displayMode === DISPLAY_MODE.PROJECT_VIEW && <BrowseProjectDisplay />}
    </div>
  );
}

export default BrowseWithOptions;
