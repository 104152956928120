import _ from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { DataGrid } from "@mui/x-data-grid";

import {
  Box,
  Button,
  Checkbox,
  Tooltip,
} from "@material-ui/core";

import { Clear } from "@material-ui/icons";

import { useDataGridFilterHook } from "../../hooks/useDataGridFilterHook";

import BlueButton from "../../components/buttons/BlueButton";
import DateFormatMethod from "../../components/DateFormatMethod";
import HorizontalCarousel from "../../components/images/HorizontalCarousel";
import HorizontalExpandSpace from "../ftr-components/HorizontalExpandSpace";
import { CustomGridDataToolbarButton } from "../grid-data/buttons/CustomGridDataToolbarButton";
import { ReorderProjectsButton } from "../grid-data/buttons/ReorderProjectsButton";
import SearchBar from "../grid-data/buttons/SearchBar";
import CustomToolbar from "../grid-data/CustomToolbar";
import GridDataPagination from "../grid-data/GridDataPagination";
import DataGridWrapTextCell from "./cells/DataGridWrapTextCell";

import withOrderProjectsAgainPopupHOC from "../../hocs/withOrderProjectsAgainPopupHOC";

import { isEmptyValue } from "../../utils/commonUtils";

import { colors } from "../../palette";


// --------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .selected': {
      background: `${colors.green050}33 !important`,
    },
    '& .MuiDataGrid-root .MuiDataGrid-row:hover': {
      background: `${colors.secondaryBlue} !important`,
      cursor: 'pointer',
    },
  },
  deleteButton: {
    borderColor: colors.hotRed,
    color: colors.hotRed,
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
}));

const CustomerProjectHistoryDataGrid = (props) => {
  const classes = useStyles();
  const {
    data,
    updateOrderProjectAgainPopupHOCState = () => { },
  } = props;

  const [selectedReorderProjects, setSelectedReorderProjects] = useState([]);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    },
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: [],
  });

  useEffect(() => {
    setSourceData(data);
  }, [data]);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData ? filteredData.length : 0 });
  }, [filteredData]);

  const columns = [
    {
      headerName: ' ',
      field: 'action',
      renderCell: ({ row: rowData }) => {
        return (
          <Tooltip
            title="Select for reordering"
          >
            <Checkbox
              key={rowData.projectID}
              checked={selectedReorderProjects.includes(rowData.projectID)}
              onChange={(event) => {
                const checked = event.target.checked;
                if (checked) {
                  setSelectedReorderProjects([
                    ...selectedReorderProjects,
                    rowData.projectID,
                  ]);
                } else {
                  const _selectedReorderProjects = selectedReorderProjects.filter(projectID => projectID !== rowData.projectID);
                  setSelectedReorderProjects(_selectedReorderProjects);
                }
              }}
            />
          </Tooltip>
        )
      },
      width: 60,
    },
    {
      headerName: "Project ID",
      field: "projectID",
      align: "center",
      renderCell: params => {
        const rowData = params.row;
        return (
          <Tooltip title="View project">
            <Button
              className={classes.linkButton}
              onClick={(e) => {
                e.stopPropagation();
                handleViewButtonClick(params.row)
              }}
            >
              {rowData.projectID}
            </Button>
          </Tooltip>
        );
      },
      minWidth: 110,
      flex: 'auto',
    },
    {
      headerName: "Project Name",
      field: "name",
      renderCell: (params) => (
        <DataGridWrapTextCell text={params.row.name} />
      ),
      align: "center",
      minWidth: 180,
      flex: 0.5,
    },
    {
      headerName: "Parts",
      field: "parts",
      renderCell: (params) => {
        return <HorizontalCarousel urls={params.row.twoDImageUrls} />
      },
      align: "center",
      minWidth: 400,
      flex: 1,
    },
    {
      // Not shown, used for searching
      headerName: "Part Names",
      field: 'partNames',
      valueGetter: (params) => {
        const statusText = params.row.items?.map(item => item.name).join(', ');
        return statusText;
      },
      hide: true,
    },
    {
      // Not shown, used for searching
      headerName: "Part IDs",
      field: 'partIDs',
      valueGetter: (params) => {
        const itemIDs = params.row.items?.map(item => item.itemID).join(', ');
        return itemIDs;
      },
      hide: true,
    },
    {
      headerName: "Date created",
      field: "dateCreated",
      renderCell: (params) =>
        params.row.dateCreated ? (
          <DataGridWrapTextCell text={DateFormatMethod({ date: params.row.dateCreated })} />
        ) : (
          ""
        ),
      align: "center",
      minWidth: 120,
      flex: 'none',
    },
    {
      headerName: " ",
      field: "actions",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BlueButton
              onBtnClick={(e) => {
                e.stopPropagation();
                handleViewButtonClick(params.row)
              }}
              btnContent="View"
              size="small"
              disabled={false}
            />
            <Box style={{ width: '0.5rem' }}></Box>
            <BlueButton
              btnContent="Order Project Again"
              size="small"
              onBtnClick={(e) => {
                e.stopPropagation();
                const { projectID } = params.row;
                const project = data.find(p => p.projectID === projectID);
                updateOrderProjectAgainPopupHOCState({
                  open: true,
                  orderAgainItemList: project.items,
                });
              }}
            />
          </div>
        );
      },
      align: "center",
      minWidth: 350,
    },
  ];

  useEffect(() => {
    setColumnsDef(columns);
  }, []);

  function handleViewButtonClick(rowData) {
    props.history.push({
      pathname: `/project-history/${rowData.projectID}`,
      state: { project: rowData }
    });
  }

  const handleRowClick = (rowData) => {
    const projectID = rowData.projectID;
    const checked = !selectedReorderProjects.includes(projectID);
    if (checked) {
      setSelectedReorderProjects([
        ...selectedReorderProjects,
        rowData.projectID,
      ]);
    } else {
      const _selectedReorderProjects = selectedReorderProjects.filter(projectID => projectID !== rowData.projectID);
      setSelectedReorderProjects(_selectedReorderProjects);
    }
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      // TODO: Reset page to 0 when searching, GridDataPagination props not 
      // working as expected when it is used as controlled component
      page: 0,
      search: searchTerm,
    });
  }

  const getCustomerToolbar = () => {
    const buttons = [];
    if (!isEmptyValue(selectedReorderProjects)) {
      const buttonName = selectedReorderProjects.length > 1
        ? `Reorder Projects (${selectedReorderProjects.length})`
        : `Reorder Project`;
      const tooltip = selectedReorderProjects.length > 1
        ? `${selectedReorderProjects.length} projects are selected`
        : '';
      buttons.push((
        <ReorderProjectsButton
          key="reorder-projects"
          name={buttonName}
          tooltip={tooltip}
          handleClick={(e) => {
            e.stopPropagation();
            let listItems = [];
            for (const projectID of selectedReorderProjects) {
              const project = data.find(p => p.projectID === projectID);
              if (project) {
                listItems = listItems.concat(project.items);
              }
            }
            updateOrderProjectAgainPopupHOCState({
              open: true,
              orderAgainItemList: listItems,
            });
          }}
        />
      ));
    }

    if (selectedReorderProjects.length > 1) {
      buttons.push((
        <CustomGridDataToolbarButton
          key="clear-selection"
          name="Clear Selection"
          handleClick={(e) => {
            e.stopPropagation();
            setSelectedReorderProjects([]);
          }}
          icon={<Clear />}
          customStyle={classes.deleteButton}
        />
      ));
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          flexWrap: 'wrap',
        }}
      >
        <CustomToolbar
          buttons={buttons}
        />
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
          ]}
        />
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <DataGrid
        rows={!_.isEmpty(filteredData) ? filteredData : []}
        columns={columns}
        autoHeight
        rowHeight={140}
        headerHeight={80}
        getRowId={(row) => row.projectID}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading}
        disableSelectionOnClick
        disableColumnMenu
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={tableQueryParams ? _.ceil(tableQueryParams.totalCount / tableQueryParams.pageSize) : 0}
            />
          ),
        }}
        getCellClassName={({ row: rowData }) => {
          return selectedReorderProjects.includes(rowData.projectID) ? 'selected' : '';
        }}
        onRowClick={({ row: rowData }) => {
          handleRowClick(rowData);
        }}
      />
    </div>
  )
}

export default withRouter(
  withOrderProjectsAgainPopupHOC(CustomerProjectHistoryDataGrid)
);
