export const OMS_TECHNOLOGY_LIST = {
  CNC: [
    'Turning',
    'Milling',
    '5-Axis',
    'Die',
    'Die-sink EDM',
    'Wirecut',
    'Grinding',
  ],
  SMF: [
    'Laser Cutting',
    'Waterjet Cutting',
    'Welding',
    'Bending',
    'Punching',
    'Stamping',
    'Tube Cutting',
  ],
  '3DP': ['FDM', 'SLA', 'MFJ', 'SLM', 'DMLS', 'LMD', 'APF', 'DLP', 'WAAM'],
};
