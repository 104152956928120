import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';


// --------------------------------------------------------------------------------------------

function withProjectsTableLoadMoreHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const {
      projects = [],
      ...rest
    } = props;

    const originalData = [...projects];
    const [data, setData] = useState(originalData.splice(0, 10));
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
      // Fetch initial data
      fetchMoreData();

      // Attach the scroll event listener
      window.addEventListener('scroll', handleScroll);

      return () => {
        // Clean up the event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    async function fetchMoreData() {
      if (isLoading || !hasMore) return;

      setIsLoading(true);

      // Update the state with new data
      const newData = originalData.splice(0, 10);
      setData(prevData => [...prevData, ...newData]);

      // Check if there's more data to load
      if (newData.length === 0) {
        setHasMore(false);
      }

      setIsLoading(false);
    }

    function handleScroll() {
      // Check if the user has scrolled to the bottom
      if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100
        && !isLoading
      ) {
        fetchMoreData();
      }
    }

    return (
      <>
        <WrappedComponent
          projects={data}
          isLoading={isLoading}
          {...rest}
        />
        {hasMore && (
          <CircularProgress size={24} style={{ margin: '2rem' }} />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withProjectsTableLoadMoreHOC;
