import useUserShippingAddress from './useUserShippingAddressHook';

import { generateDeliveryMessage } from '../utils/multiCheckoutTrackingUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { addressInfoText } from '../utils/addressUtils';

import useUserInfo from './useUserInfoHook';

const useDeliveryMessageConfig = ({
  selectedItemsIDs,
  trackingInfo,
  pickUpUserID,
  dropOffUserID,
  deliveryMessageToggles,
  isShippingToCustomer,
  invoiceAmountStr,
}) => {
  const {
    packaging: { isChecked: isPackagingRequired },
    isQCRequired: { isChecked: isQCRequired },
    isDHLShipping: { isChecked: isDHLInternationalShipping },
    isDescriptionRequired: { isChecked: isDescriptionRequired },
  } = deliveryMessageToggles;

  const { data: dropOffUserInfo, isFetching: isFetchingDropOffUserInfo } = useUserInfo(dropOffUserID);
  const { data: pickUpUserInfo, isFetching: isFetchingPickUpUserInfo } = useUserInfo(pickUpUserID);

  const [{ addresses: dropOffUserAddressInfo }] = useUserShippingAddress(dropOffUserID);
  const [{ addresses: pickUpUserAddressInfo }] = useUserShippingAddress(pickUpUserID);
  const dropOffUserAddress = addressInfoText(dropOffUserAddressInfo?.shipping);
  const pickUpUserAddress = addressInfoText(pickUpUserAddressInfo?.shipping);

  if (isFetchingDropOffUserInfo || isFetchingPickUpUserInfo) {
    return {
      deliveryMessage: '',
      errorMessage: '',
    };
  }

  const deliveryMessage = generateDeliveryMessage({
    selectedItemsIDs,
    trackingInfo,
    pickUpUserInfo: {
      ...pickUpUserInfo,
      address: pickUpUserAddress,
    },
    dropOffUserInfo: {
      ...dropOffUserInfo,
      address: dropOffUserAddress,
    },
    isPackagingRequired,
    isQCRequired,
    isDHLInternationalShipping,
    isDescriptionRequired,
    isShippingToCustomer,
    invoiceAmountStr,
  });

  const errorMessage = isEmptyValue(pickUpUserAddress) || isEmptyValue(dropOffUserAddress)
    ? 'The pickup/dropoff address was not found, please edit the delivery message.'
    : '';

  return {
    deliveryMessage,
    errorMessage,
  }
};

export default useDeliveryMessageConfig;
