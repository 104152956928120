import React, { useContext } from 'react';
import { capitalize, get, lowerCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Delete, ExpandMore } from '@material-ui/icons';

import DownloadableFileWithActions from '../../components/list-items/DownloadableFileWithActions';
import ErrorLabel from '../../components/labels/ErrorLabel';
import FileDownloadListDisplay from '../../components/labels/FileDownloadListDisplay';
import FilesUploadButton from '../../components/FilesUploadButton';
import ImageUpload from '../orderReady/ImageUpload';
import ImageWith3DViewer from '../../components/images/ImageWith3DViewer';
import OrderReadyStatusDisplay from '../orderReady/OrderReadyStatusDisplay';
import OrmMaterialFileUploader from '../project-order-ready/OrmMaterialFileUploader';
import { FlexColumn, FlexRow, FlexRowSpaceBetween } from '../../components/layouts/FlexLayouts';
import { FtrB2, FtrBanner } from '../../components/ftr-components';

import EmailStatusIcon from '../../components/icons/EmailStatusIcon';
import { RepeatOrderIcon } from '../../components/icons/ItemIcon';

import { generatePresignedUrlForImage } from '../../apis/s3Api';
import { updateItemOrderReadyStatus, upsertOrderReadyResource } from '../../apis/orderReadyApi';
import { getItemRepeatOrmInfo } from '../../apis/orderReadyRepeatOrmApi';

import { getSubmittedProjectOrderReadyCountAction } from '../../actions/orderReady';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { itemProperties } from '../../utils/itemUtils';
import { asyncMap } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { extractFileNameWithoutTimestampFromUrl, getFileExtension } from '../../utils/fileUtils';
import { formatDateWithTime } from '../../utils/dateTimeUtils';
import { sortOrderReadyResource, uploadOrderReadyResource } from '../../utils/orderReadyUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import ProjectOrderReadyContext from '../../context/ProjectOrderReadyContext';

import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import { QC_REPORT_TEMPLATE_URL } from '../../constants/projectConstants';
import { IMG_PLACE_HOLDER_URL, ROLE_TYPES } from '../../constants';
import { FIFTY_MB_IN_BYTES } from '../../constants/fileConstants';
import {
  DENIED_ADDITIONAL_RESOURCE_TYPES,
  ORDER_READY_RESOURCE_TYPES,
  ORDER_READY_REVIEW_COMMENTS,
  ORDER_READY_STATUS,
} from '../../constants/orderReadyConstants';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 'bold',
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    color: 'inherit',
  },
  accordionSummaryContent: {
    margin: '12px 0 !important',
  },
}));

function ItemOrderReadyReview(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    expand = false,
    itemOrderReady = {},
    localState = {},
    userRole,
    showVerifyButton = false,
    itemFilesMap,
    loadOrderReadyInfo = () => { },
    handleItemOrderReadyNeedUpdate = () => { },
    changeStatusWordColor = () => { },
    handleVerifyItemOrderReady = () => { },
    renderOrderReadyHistoryUser = () => { },
    updateRequiredQcMaterialState = () => { },
    handleUploadOrderReadyResource = () => { },
    handleAddComment = () => { },
    updateLocalState = () => { },
  } = props;

  const {
    updateRepeatItemOrmDetailsPopupHOCState,
  } = useContext(ProjectOrderReadyContext);

  const [{ isMobile }] = useDeviceCheck();

  const itemOrderReadyID = itemOrderReady.info.itemOrderReadyID;
  const itemID = itemOrderReady.info.itemID;
  const itemOrderReadyStatus = itemOrderReady.info.status;

  const {
    data: itemRepeatOrmInfo,
  } = useQuery(
    ['getItemRepeatOrmInfo', itemID, userRole],
    () => {
      if (isEmptyValue(itemID) || !isAdminOrHigherRole(userRole)) {
        return null;
      }

      return getItemRepeatOrmInfo(itemID);
    },
  );

  const filteredImageResources = itemOrderReady.resources
    .filter(resource => lowerCase(resource.type).includes('image')
      && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
    )
    .sort(sortOrderReadyResource);

  const filteredVideoResources = itemOrderReady.resources
    .filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.VIDEO
      && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
    );

  const filteredDocumentResources = itemOrderReady.resources
    .filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
      && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
    );

  const additionalResources = itemOrderReady.resources
    .filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE
      && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
    );

  const noDocRequired = isEmptyValue(filteredImageResources)
    && localState.hasQcReportsRequired === false
    && itemOrderReady.info.requireDesignFiles === 0;

  // -----------------------------------------------------------------------------------------------

  const handleUpdateImageRequired = (event, resource) => {
    const { checked } = event.target;
    const body = {
      ...resource,
      required: checked,
    }
    const { projectOrderReadyID: porID } = resource;
    upsertOrderReadyResource(porID, body)
      .then(() => {
        loadOrderReadyInfo();
        notifySuccess('Updated successfully');
      }).catch(() => notifyError('Failed to updated.'));
  }

  const handleUploadAdditionalResource = (file) => {
    const fileExtension = getFileExtension(file.name);

    if (DENIED_ADDITIONAL_RESOURCE_TYPES.includes(fileExtension.toLocaleLowerCase())) {
      notifyError(`File type ${fileExtension} is not allowed`);
      return;
    }

    if (file.size > FIFTY_MB_IN_BYTES) {
      notifyError('File size should be less than 50 MB');
      return;
    }

    uploadOrderReadyResource({
      itemOrderReadyID,
      file,
      resourceType: ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE,
      porID: itemOrderReady.info.projectOrderReadyID,
    }).then(loadOrderReadyInfo);
  }

  const handleItemOrderReadySubmit = (itemOrderReadyID) => {
    const itemOrderReady = localState.itemOrderReadyList.find(itemOrderReady => {
      return itemOrderReady.info.itemOrderReadyID === itemOrderReadyID;
    });

    const requiredImageResources = itemOrderReady.resources.filter(resource => lowerCase(resource.type).includes('image') && Boolean(resource.required) === true);
    for (const imageResource of requiredImageResources) {
      if (imageResource.fileURL === IMG_PLACE_HOLDER_URL || imageResource.fileURL.includes('2dImgFiles')) {
        const message = `${capitalize(imageResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    const requiredVideoResources = itemOrderReady.resources.filter(resource =>
      resource.type === ORDER_READY_RESOURCE_TYPES.VIDEO
      && Boolean(resource.required) === true
    );
    for (const videoResource of requiredVideoResources) {
      if (typeof videoResource?.fileURL === 'undefined' || videoResource?.fileURL === IMG_PLACE_HOLDER_URL) {
        const message = `${capitalize(videoResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    const requiredDocumentResources = itemOrderReady.resources.filter(resource =>
      resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
      && Boolean(resource.required) === true
    );
    for (const documentResource of requiredDocumentResources) {
      if (typeof documentResource?.fileURL === 'undefined' || documentResource?.fileURL === IMG_PLACE_HOLDER_URL) {
        const message = `${capitalize(documentResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    if (localState.hasQcReportsRequired) {
      const hasQcReportUploaded = itemOrderReady.resources.some(resource => resource.type === ORDER_READY_RESOURCE_TYPES.QC_REPORT);
      if (!hasQcReportUploaded) {
        const message = 'QC Report is required';
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              qcReports: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    if (itemOrderReady.info.requireDesignFiles === 1) {
      const hasDesignFileUploaded = itemOrderReady.resources.some(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DESIGN_FILE);
      if (!hasDesignFileUploaded) {
        const message = 'Design Files required';
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              designFiles: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    const body = {
      status: ORDER_READY_STATUS.SUBMITTED,
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });

    const toastId = toast('Submitting item order ready for review...', { type: toast.TYPE.INFO, autoClose: false });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            toast.update(toastId, {
              render: 'Item order ready submitted successfully.',
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
            });
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        toast.update(toastId, {
          render: 'Item order ready submitted failed.',
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const renderStatusDropDown = (status, itemOrderReadyID, itemID) => {
    const handler = (newStatus) => {
      updateLocalState({
        adminEditStatus: {
          itemOrderReadyID,
          status: newStatus,
          itemID
        },
        showAdminEditStatusPopup: true
      });
    }
    return (
      <OrderReadyStatusDisplay status={status} onClick={handler} isAdminOrHigher={true} />
    )
  }

  const renderStatus = (status) => {
    return (
      <OrderReadyStatusDisplay status={status} />
    )
  }

  const renderRequireUpdateButton = () => {
    if (showVerifyButton) {
      return null;
    }
    return (
      <Button
        style={{
          backgroundColor: colors.warningYellow,
        }}
        variant='contained'
        component='span'
        onClick={() => handleItemOrderReadyNeedUpdate(itemOrderReadyID)}
        disabled={localState.updatingItemOrderReadyStatus === true}
      >
        {localState.updatingItemOrderReadyStatus === true && (
          <CircularProgress className={classes.circularProgress} size={20} />
        )}
        Require Update
      </Button>
    );
  }

  const renderLatestUpdateNotes = () => {
    let filteredArray = localState.orderReadyHistory.filter(
      item => item.notes.includes(itemOrderReady.info.itemID)
        && (item.notes.includes('Update required')
          || item.status === ORDER_READY_STATUS.NEED_UPDATE
        )
    );
    let history = filteredArray[filteredArray.length - 1];
    const name = renderOrderReadyHistoryUser(history)
    return (
      <Paper
        style={{
          padding: '0.6rem',
          margin: '0.3rem',
        }}
        key={`${history.notes} latest update`}
      >
        <Typography
          className={classes.heading}
          style={{
            textAlign: 'left'
          }}
        >
          {formatDateWithTime(history.createdDate)}, {name}
        </Typography>
        {history.notes.split('\n').map((note, index) => {
          return (
            <Typography
              key={note}
              style={{
                textAlign: 'left'
              }}
            >
              {index === 0 ? changeStatusWordColor(note, history.status) : note}
            </Typography>
          );
        })}
      </Paper>)
  }

  const renderVerifyButton = () => {
    if (!showVerifyButton) {
      return null;
    }

    return (
      <Button
        style={{
          backgroundColor: 'green',
          color: 'white',
        }}
        variant='contained'
        component='span'
        onClick={() => handleVerifyItemOrderReady(itemOrderReadyID)}
        disabled={localState.updatingItemOrderReadyStatus === true}
      >
        {localState.updatingItemOrderReadyStatus === true && (
          <CircularProgress className={classes.circularProgress} size={20} />
        )}
        Verify
      </Button>
    );
  }

  const renderAddRequiredMaterialsButton = () => {
    return isAdminOrHigherRole(userRole) && (
      <div>
        <Button
          style={{
            marginTop: '0.5rem',
          }}
          color='primary'
          variant='contained'
          onClick={() => updateRequiredQcMaterialState({
            showPopup: true,
            selectedItemOrderReady: itemOrderReady,
            editMode: false,
            resource: null,
          })}
        >
          ADD ADDITIONAL QC MATERIAL
        </Button>
      </div>
    );
  }

  const showImageListOfItemOrderReady = async (resources, resourceType) => {
    const imageResources = resources
      .filter(resource => isAdminOrHigherRole(userRole) || Boolean(resource.required) === true)
      .filter(resource => lowerCase(resource.type).includes('image'))
      .sort(sortOrderReadyResource);
    const imageUrlList = await asyncMap(imageResources, async (resource) => {
      const presignedUrl = await generatePresignedUrlForImage(resource.fileURL, 30 * 60); // 30 minutes in second
      return {
        original: presignedUrl,
        thumbnail: presignedUrl,
      }
    });
    const showImageIndex = imageResources.findIndex(resource => resource.type === resourceType);
    updateLocalState({
      imageUrlList,
      showImageView: true,
      showImageIndex,
    });
  }

  const renderPartImageUploadArea = () => {
    if (isEmptyValue(filteredImageResources)) {
      return null;
    }

    return (
      <FlexColumn style={{ gap: '0.3rem' }}>
        <FlexRowSpaceBetween>
          <div>
            <Typography className={classes.heading}>Part Media</Typography>
            <Typography>
              Please upload mandatory media for <span className={classes.heading}>Part #{itemOrderReady.info.itemID}</span> as shown below.
            </Typography>
          </div>
          {isAdminOrHigherRole(userRole) && (
            <FileDownloadListDisplay
              title='Design files'
              urlList={itemFilesMap[itemID] ? itemFilesMap[itemID].slice(0, 1) : []}
              showTitle={false}
            />
          )}
        </FlexRowSpaceBetween>
        {!isEmptyValue(get(localState, ['errors', itemOrderReadyID, 'requiredImages'])) && (
          <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'requiredImages'])} />
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: '0.8rem',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {filteredImageResources.map(resource => {
            const {
              type: resourceType,
              itemID,
            } = resource;
            const key = `itemID-${itemID}-resourceID-${resource.resourceID}-${resourceType}-image-upload-${resource.fileURL}`;
            const imageURL = resource.fileURL;
            const uploadAllowed = ![
              ORDER_READY_STATUS.VERIFIED,
              ORDER_READY_STATUS.READY_TO_SHIP,
              ORDER_READY_STATUS.IN_TRANSIT,
              ORDER_READY_STATUS.DELIVERED].includes(itemOrderReadyStatus)
              || isAdminOrHigherRole(userRole);

            return (
              <div key={key} style={{ margin: '1rem' }}>
                <Tooltip title={isEmptyValue(resource.description) ? '' : resource.description}>
                  <div style={{ cursor: uploadAllowed ? 'pointer' : 'cursor' }}>
                    <ImageUpload
                      type={resourceType}
                      id={key}
                      uploadAllowed={uploadAllowed}
                      onUpload={(file) => handleUploadOrderReadyResource({
                        resourceID: resource.resourceID,
                        itemOrderReadyID,
                        file,
                        resourceType,
                      })}
                      imageURL={imageURL}
                      placeHolderUrl={resource.placeHolderUrl}
                      onViewClick={() => showImageListOfItemOrderReady(itemOrderReady.resources, resourceType)}
                      showAlarm={imageURL === IMG_PLACE_HOLDER_URL || imageURL.includes('2dImgFiles')}
                    />
                  </div>
                </Tooltip>
                {
                  isAdminOrHigherRole(userRole) && (
                    <FlexRowSpaceBetween>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='required'
                            onChange={(event) => handleUpdateImageRequired(event, resource)}
                            checked={resource.required}
                          />
                        }
                        label='Required'
                      />
                      <Tooltip title='Delete required image that shows to supplier'>
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            updateLocalState({
                              showDeleteFilePopup: true,
                              deleteResource: {
                                resourceID: resource.resourceID,
                                fileName: resource.type,
                              },
                            });
                          }}
                        >
                          <Delete color='error' style={{ fontSize: '15pt' }} />
                        </IconButton>
                      </Tooltip>
                    </FlexRowSpaceBetween>
                  )
                }
              </div>
            );
          })}
        </div>
        {renderAddRequiredMaterialsButton()}
      </FlexColumn>
    );
  }

  const renderRequiredQcReportTexts = (qcReports) => {
    return qcReports.map((qcReport, index) => {
      const templateUrl = QC_REPORT_TEMPLATE_URL[qcReport];
      let qcReportText;
      if (templateUrl) {
        qcReportText = (
          <a href={templateUrl} target='_blank' rel='noreferrer'>
            {qcReport}
          </a>
        );
      } else {
        qcReportText = qcReport;
      }
      return (
        <span key={templateUrl}>
          {qcReportText}
          {index === qcReports.length - 1 ? '.' : ', '}
        </span>
      );
    })
  }

  const renderResourceFileWithActions = (file, showDeleteButton = true) => {
    return (
      <DownloadableFileWithActions
        key={`${file.resourceID}-${file.downloadCount}`}
        fileUrl={file.fileURL}
        showDeleteButton={showDeleteButton}
        onDelete={(event) => {
          event.preventDefault();
          event.stopPropagation();
          updateLocalState({
            showDeleteFilePopup: true,
            deleteResource: {
              resourceID: file.resourceID,
              fileName: extractFileNameWithoutTimestampFromUrl(file.fileURL),
            },
          });
        }}
      />
    );
  }

  const renderQcReportFiles = (resources) => {
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.QC_REPORT);
    if (isEmptyValue(fileList)) {
      return null;
    }
    return fileList.map((file) => renderResourceFileWithActions(file, true));
  }

  const renderQcReportUploadArea = () => {
    return (
      <FlexColumn style={{ marginTop: '1rem' }}>
        <Typography className={classes.heading}>
          QC Report{localState.hasQcReportsRequired === true && (
            <span style={{ color: 'red' }}>&nbsp;*</span>
          )}
        </Typography>
        {localState.hasQcReportsRequired === true && (
          <div>
            <Typography>
              Please upload the Quality Check (QC) {localState.projectQcReportsAddOns.length > 0 ? 'Reports' : 'Report'} for <b>Part #{itemOrderReady.info.itemID}</b>
            </Typography>
            <Typography>
              QC {localState.projectQcReportsAddOns.length > 0 ? 'Reports' : 'Report'} required: <b>{renderRequiredQcReportTexts([localState.projectQcReports, ...localState.projectQcReportsAddOns])}</b>
            </Typography>
            <div style={{ padding: '0.5rem' }}>
              <FilesUploadButton
                id={`upload - qc - report - ${itemOrderReadyID}`}
                buttonText='Upload QC Report'
                handleUploadFiles={(files) => handleUploadOrderReadyResource({
                  itemOrderReadyID,
                  file: files[0],
                  resourceType: ORDER_READY_RESOURCE_TYPES.QC_REPORT,
                })}
                multiple={false}
              />
            </div>
            {!isEmptyValue(get(localState, ['errors', itemOrderReadyID, 'qcReports'])) && (
              <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'qcReports'])} />
            )}
          </div>
        )}
        {localState.hasQcReportsRequired === false && (
          <Typography>
            No QC Report required.
          </Typography>
        )}
        <div style={{ padding: '0.5rem' }}>
          {renderQcReportFiles(itemOrderReady.resources)}
        </div>
      </FlexColumn>
    );
  }

  const renderUploadAdditionalResourceButton = () => {
    return (
      <FlexColumn style={{ marginTop: '1rem' }}>
        <Typography className={classes.heading}>
          Additional QC Material
        </Typography>
        <FilesUploadButton
          buttonText='Upload Additional QC Material'
          multiple={false}
          handleUploadFiles={(files) => handleUploadAdditionalResource(files[0])}
          note='Optional. Upload any other material that will help in verifying this order. Maximum file size 50MB. No .zip files.'
        />
      </FlexColumn>
    );
  }

  const renderRequireDesignFilesButton = () => {
    return isAdminOrHigherRole(userRole) && (
      <FormControlLabel
        control={
          <Checkbox
            name='require-design-files'
            onChange={(event) => {
              const { checked } = event.target;
              updateItemOrderReadyStatus(itemOrderReadyID, {
                requireDesignFiles: checked
              }).then(() => {
                notifySuccess(`Updated successfully`);
                loadOrderReadyInfo();
              }).catch(() => {
                notifyError(`Updated failed`);
              });
            }}
            checked={Boolean(get(itemOrderReady, ['info', 'requireDesignFiles']))}
          />
        }
        label='Require Design Files'
      />
    )
  }

  const renderDesignFiles = (resources) => {
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DESIGN_FILE);
    if (isEmptyValue(fileList)) {
      return null;
    }
    return fileList.map((file) => renderResourceFileWithActions(file, true));
  }

  const renderDesignFileUploadArea = () => {
    return (
      <FlexColumn style={{ marginTop: '1rem' }}>
        <Typography className={classes.heading}>
          Design Files{itemOrderReady.info.requireDesignFiles === 1 && (
            <span style={{ color: 'red' }}>&nbsp;*</span>
          )}
        </Typography>
        {itemOrderReady.info.requireDesignFiles === 1 && (
          <div>
            <Typography>
              Please upload the Design Files for <b>Part #{itemOrderReady.info.itemID}</b>
            </Typography>
            <div style={{ padding: '0.5rem' }}>
              <FilesUploadButton
                id={`upload - design - file - ${itemOrderReadyID}`}
                buttonText='Upload Design Files'
                handleUploadFiles={(files) => handleUploadOrderReadyResource({
                  itemOrderReadyID,
                  file: files[0],
                  resourceType: ORDER_READY_RESOURCE_TYPES.DESIGN_FILE,
                })}
                multiple={false}
              />
            </div>
            {!isEmptyValue(get(localState, ['errors', itemOrderReadyID, 'qcReports'])) && (
              <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'qcReports'])} />
            )}
          </div>
        )}
        {itemOrderReady.info.requireDesignFiles === 0 && (
          <Typography>
            No design files required.
          </Typography>
        )}
        <div style={{ padding: '0.5rem' }}>
          {renderDesignFiles(itemOrderReady.resources)}
        </div>
      </FlexColumn>
    );
  }

  const renderReviewArea = () => {
    const { itemOrderReadyID } = itemOrderReady.info;
    return itemOrderReadyStatus === ORDER_READY_STATUS.SUBMITTED
      && [ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(userRole)
      && (
        <div style={{ marginTop: '1rem' }}>
          <Typography className={classes.heading}>Review</Typography>
          <Typography>Please select or add comments for the part if there is any issue, otherwise click the Verify button to accept the order ready.</Typography>
          <Box style={{ height: '0.3rem' }} />
          <FormControlLabel
            control={
              <Checkbox
                name={ORDER_READY_REVIEW_COMMENTS.UPDATE_QC_REPORT}
                onChange={(event) => handleAddComment(event, itemOrderReadyID)}
              />
            }
            label={ORDER_READY_REVIEW_COMMENTS.UPDATE_QC_REPORT}
            style={{
              display: 'flex',
              margin: '0 0.5rem 0.5rem 0',
              backgroundColor: colors.paleBlue,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={ORDER_READY_REVIEW_COMMENTS.UPDATE_PART_IMAGES}
                onChange={(event) => handleAddComment(event, itemOrderReadyID)}
              />
            }
            label={ORDER_READY_REVIEW_COMMENTS.UPDATE_PART_IMAGES}
            style={{
              display: 'flex',
              margin: '0 0.5rem 0.5rem 0',
              backgroundColor: colors.paleBlue,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={ORDER_READY_REVIEW_COMMENTS.REWORK}
                onChange={(event) => handleAddComment(event, itemOrderReadyID)}
              />
            }
            label={ORDER_READY_REVIEW_COMMENTS.REWORK}
            style={{
              display: 'flex',
              margin: '0 0.5rem 0.5rem 0',
              backgroundColor: colors.paleBlue,
            }}
          />
          <Box style={{ height: '0.5rem' }} />
          <TextField
            id='outlined-multiline-static'
            label='Additional comment'
            multiline
            minRows={3}
            variant='outlined'
            fullWidth
            onChange={(e) => updateLocalState({
              additionalComment: {
                ...localState.additionalComment,
                [itemOrderReadyID]: e.target.value,
              },
            })}
          />
        </div>
      );
  }

  const renderPendingReviewPanel = () => {
    if (itemOrderReadyStatus !== ORDER_READY_STATUS.SUBMITTED
      || isAdminOrHigherRole(userRole)) {
      return;
    }

    return (
      <FtrBanner style={{ marginTop: '1rem' }}>
        <b>Pending review:</b> The order is being reviewed for shipment. You will be notified via email upon approval for the next steps.
      </FtrBanner>
    );
  }

  const renderVideoUploadArea = () => {
    return (
      <FlexColumn style={{ maxWidth: 500 }}>
        <Typography className={classes.heading}>Required Video(s)</Typography>
        {filteredVideoResources.map((resource) => {
          return (
            <OrmMaterialFileUploader
              key={resource?.resourceID}
              resource={resource}
              loadOrderReadyInfo={loadOrderReadyInfo}
              onEdit={() => {
                updateRequiredQcMaterialState({
                  showPopup: true,
                  selectedItemOrderReady: itemOrderReady,
                  editMode: true,
                  resource,
                });
              }}
            />
          );
        })}
      </FlexColumn>
    );
  }

  const renderDocumentUploadArea = () => {
    return (
      <FlexColumn style={{ maxWidth: 500, marginTop: '0.5rem' }}>
        <Typography className={classes.heading}>Required Document(s)</Typography>
        {filteredDocumentResources.map((resource) => {
          return (
            <OrmMaterialFileUploader
              key={resource?.resourceID}
              resource={resource}
              loadOrderReadyInfo={loadOrderReadyInfo}
              onEdit={() => {
                updateRequiredQcMaterialState({
                  showPopup: true,
                  selectedItemOrderReady: itemOrderReady,
                  editMode: true,
                  resource,
                });
              }}
            />
          );
        })}
      </FlexColumn>
    );
  }

  const renderAdditionalResources = () => {
    return (
      <FlexColumn style={{ maxWidth: 500, marginTop: '0.5rem' }}>
        {additionalResources.map((resource) => {
          return (
            <OrmMaterialFileUploader
              key={resource?.resourceID}
              resource={resource}
              loadOrderReadyInfo={loadOrderReadyInfo}
              onEdit={() => {
                updateRequiredQcMaterialState({
                  showPopup: true,
                  selectedItemOrderReady: itemOrderReady,
                  editMode: true,
                  resource,
                });
              }}
            />
          );
        })}
      </FlexColumn>
    );
  }

  return (
    <Accordion
      key={itemOrderReady.info.itemID}
      defaultExpanded={expand}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          content: classes.accordionSummaryContent,
        }}
      >
        <FlexRow>
          <ImageWith3DViewer
            twoDImageUrl={itemOrderReady.info.imageFile || itemOrderReady.info.twoDImageUrl}
            cadFile={itemOrderReady.info.cadFile || itemOrderReady.info.originalFiles}
            height={80}
            width={80}
            borderRadius={0}
            noBorder={false}
          />
          <Box style={{ width: '1rem' }} />
          <FlexColumn>
            <FlexRow>
              <Typography className={classes.heading}>
                Part #{itemOrderReady.info.itemID}
              </Typography>
              {renderRepeatIcon()}
              <Box style={{ width: '0.5rem' }} />
              {isAdminOrHigherRole(userRole)
                ? (
                  <>
                    {renderStatusDropDown(itemOrderReadyStatus, itemOrderReady.info.itemOrderReadyID, itemOrderReady.info.itemID)}
                    <FlexRow style={{ marginLeft: '1rem' }}>
                      <EmailStatusIcon
                        date={itemOrderReady.brevoOrderReadyNeedUpdate?.updatedAt}
                        emailType={itemOrderReady.brevoOrderReadyNeedUpdate?.type}
                        status={itemOrderReady.brevoOrderReadyNeedUpdate?.lastStatus}
                      />
                      <EmailStatusIcon
                        date={itemOrderReady.brevoOrderReadyReadyToShip?.updatedAt}
                        emailType={itemOrderReady.brevoOrderReadyReadyToShip?.type}
                        status={itemOrderReady.brevoOrderReadyReadyToShip?.lastStatus}
                      />
                    </FlexRow>
                  </>
                )
                : renderStatus(itemOrderReadyStatus)}
            </FlexRow>
            <FtrB2 style={{ marginTop: '3px' }}>{itemProperties(itemOrderReady.info)}</FtrB2>
          </FlexColumn>
        </FlexRow>
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <FlexColumn
          style={{
            marginTop: '1rem',
          }}
        >
          {ORDER_READY_STATUS.NEED_UPDATE === itemOrderReadyStatus && renderLatestUpdateNotes()}
          {noDocRequired === true && (
            <div>
              <Typography>
                There are no order ready documents required for this order.
              </Typography>
              {ORDER_READY_STATUS.NOT_STARTED === itemOrderReadyStatus && (
                <Typography>
                  Please click <b>SUBMIT</b> to get shipment approval.
                </Typography>
              )}
            </div>
          )}
          {noDocRequired !== true && renderPartImageUploadArea()}
          {
            //Even if there are no images, Admin should still be able to upload image
            isEmptyValue(filteredImageResources) && renderAddRequiredMaterialsButton()
          }
          {filteredVideoResources.length > 0 && renderVideoUploadArea()}
          {filteredDocumentResources.length > 0 && renderDocumentUploadArea()}
        </FlexColumn>
        {noDocRequired !== true && renderQcReportUploadArea()}
        {renderUploadAdditionalResourceButton()}
        {additionalResources.length > 0 && renderAdditionalResources()}
        {noDocRequired !== true && itemOrderReady.info.technology === TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE && renderDesignFileUploadArea()}
        {isAdminOrHigherRole(userRole) && itemOrderReady.info.technology === TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE && renderRequireDesignFilesButton()}
        {renderReviewArea()}
        {renderPendingReviewPanel()}
        <FlexRow
          style={{
            marginTop: '1rem',
            padding: '0.5rem',
            flexDirection: 'row-reverse',
          }}
        >
          {[ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE].includes(itemOrderReadyStatus) && (
            <Tooltip
              title={Boolean(itemOrderReady.info.poAcknowledged) !== true
                ? 'Upon clicking, your order will be reviewed for shipment. PO needs to be acknowledged to enable this button.'
                : 'Upon clicking, your order will be reviewed for shipment.'
              }
            >
              <div>
                <Button
                  id={`submit-itemOrderReadyID-${itemOrderReadyID}`}
                  variant='contained'
                  color='primary'
                  component='span'
                  onClick={() => handleItemOrderReadySubmit(itemOrderReadyID)}
                  disabled={localState.updatingItemOrderReadyStatus === true || Boolean(itemOrderReady.info.poAcknowledged) !== true}
                >
                  {localState.updatingItemOrderReadyStatus === true && (
                    <CircularProgress className={classes.circularProgress} size={20} />
                  )}
                  {ORDER_READY_STATUS.NEED_UPDATE === itemOrderReadyStatus
                    ? 'Re-Submit'
                    : 'Submit'
                  }
                </Button>
              </div>
            </Tooltip>
          )}
          {itemOrderReadyStatus === ORDER_READY_STATUS.SUBMITTED
            && isAdminOrHigherRole(userRole)
            && (
              <FlexRow>
                {renderRequireUpdateButton(itemOrderReady)}
                {renderVerifyButton(itemOrderReady)}
              </FlexRow>
            )}
        </FlexRow>
      </AccordionDetails>
    </Accordion>
  );

  function renderRepeatIcon() {
    if (isEmptyValue(itemRepeatOrmInfo) || !isAdminOrHigherRole(userRole)) {
      return;
    }

    return (
      <RepeatOrderIcon
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          updateRepeatItemOrmDetailsPopupHOCState({
            open: true,
            data: itemRepeatOrmInfo,
          });
        }}
      />
    );
  }
}

export default ItemOrderReadyReview;
