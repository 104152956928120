import queryString from 'query-string';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// -------------------------------------------------------------------------------------------------

export const getAllProjectOrderReady = async (params) => {
  const _params = params ? `?${queryString.stringify(params)}` : '';
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready${_params}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getProjectOrderReadyInfo = async ({ projectID, supplierID, poAcknowledgedVersion = 1 }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/project/${projectID}?supplierID=${supplierID}&poAcknowledgedVersion=${poAcknowledgedVersion}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getProjectOrderReadyHistory = async ({ projectOrderReadyID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/history`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getItemOrderReadyByItemID = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/itemID/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const updateItemOrderReadyStatus = async (itemOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/${itemOrderReadyID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const upsertOrderReadyResource = async (projectOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/resource`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const trackOrderReadyResourceDownload = async ({ resourceID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/resource/${resourceID}/download-count`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
  });
}

export const deleteOrderReadyResource = async (projectOrderReadyID, resourceID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/resource/${resourceID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  });
}

export const adminGetSubmittedProjectOrderReady = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready?status=submitted`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const updateOrderShipped = async (projectOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}/shipped`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const adminAddItemOrderReadyRequiredImages = async (itemOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/${itemOrderReadyID}/add-required-images`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const adminAddItemOrderReadyRequiredMaterials = async (itemOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/${itemOrderReadyID}/required-materials`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const adminUpdateItemOrderReadyRequiredMaterials = async (itemOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/item-order-ready/${itemOrderReadyID}/required-materials`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const updateProjectOrderReadyInfo = async (projectOrderReadyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/${projectOrderReadyID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}
