import React, { useReducer } from 'react';

import RequestResponseInfoPopup from '../components/popups/RequestResponseInfoPopup';

// -------------------------------------------------------------------------------------------------

function withRequestResponseInfoPopupHOC(WrappedComponent) {
  return function ShipmentInfoPopupHOC(props) {
    const defaultLocalState = {
      selectedData: null,
      open: false,
      request: null,
      response: null,
      title: undefined,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateRequestResponseInfoPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <RequestResponseInfoPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            request={JSON.stringify(localState.request, null, 2)}
            response={JSON.stringify(localState.response, null, 2)}
            title={localState.title}
          />
        )}
      </>
    );
  };
}

export default withRequestResponseInfoPopupHOC;
