import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';
import InfoIcon from '../icons/InfoIcon';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';

import { renderSkeletonOverlay } from '../util/skeleton';

import { useMaterialInfo } from '../../hooks/useMaterialInfo';

import { isEmptyValue } from '../../utils/commonUtils';

import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import { MATERIAL_STR } from '../../constants/quotationConstants';

import { colors } from '../../palette';
import inputsStyles from './inputsStyles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  inputLabelRowDisplayFixWidth: {
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function ThreeDPrintingMaterialField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles()

  // Defining input styles
  classes.emptyField = inputStyles.emptyField
  classes.v2 = inputStyles.v2

  const {
    technology,
    threeDTechnology,
    visible = false,
    threeDMaterialOptions = [],
    onSelect,
    onBlur = () => { },
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    defaultThreeDMaterial,
    disabled = false,
    loading = false,
    disableLabelOnBootstrapStyle = false,
    v2 = false,
  } = props;

  const [
    {
      description,
      datasheetUrl,
    },
    {
      setParams,
    }
  ] = useMaterialInfo();

  useEffect(() => {
    if (isEmptyValue(technology) || isEmptyValue(value) || isEmptyValue(threeDTechnology) || technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      return;
    }

    const params = {
      technology: TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
      category: threeDTechnology,
      material: value,
    }
    setParams(params);
  }, [value, threeDTechnology]);

  const handleChange = (evt) => {
    const newMaterial = evt.target.value;
    onSelect(newMaterial);
  }

  if (!visible) {
    return null;
  }

  const renderMenuItems = () => {
    return threeDMaterialOptions.map((option) => {
      const label = defaultThreeDMaterial === option
        ? (
          <span>
            {option}&nbsp;
            <span
              style={{
                fontStyle: 'italic',
                color: colors.blue050,
                fontSize: '0.8rem',
              }}
            >
              (Preferred)
            </span>
          </span>
        )
        : option;
      return (
        <MenuItem key={option} value={option}>
          {label}
        </MenuItem>
      )
    })
  }

  const renderDataSheetUrl = () => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          minWidth: 200,
        }}
      >
        <a
          style={{
            fontSize: 'small',
            fontStyle: 'italic',
          }}
          href={datasheetUrl}
          target="_blank"
          rel="noreferrer"
        >
          {value} Datasheet
        </a>
      </div>
    )
  }

  if (bootstrapStyle) {
    return (
      <div
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        {!disableLabelOnBootstrapStyle  && (
          <div
            className={rowDisplay ? classes.inputLabelRowDisplayFixWidth : null}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 7,
            }}
          >
            <FtrFieldLabel>
              Material
            </FtrFieldLabel>

            {description && (
              <div
                style={{
                  marginLeft: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InfoIcon toolTipText={description} />
              </div>
            )}
            {!rowDisplay && datasheetUrl && !loading && renderDataSheetUrl()}
          </div>
        )}
        <div style={{position: 'relative'}}>
          {loading && renderSkeletonOverlay()}
          <Select
            className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${
              !value ? classes.emptyField : ''
            }`}
            input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
            id='material'
            value={value ?? null}
            renderValue={value => value}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
          >
            {renderMenuItems()}
          </Select>
        {/* {rowDisplay && datasheetUrl && !loading && renderDataSheetUrl()} */}
        </div>
      </div>
    )
  }

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>

      <div style={{position: 'relative'}}>
        {loading && renderSkeletonOverlay()}
        <TextField
          name={MATERIAL_STR}
          labelId="material"
          label="Material"
          id="material"
          variant="outlined"
          select
          value={value ?? null}
          SelectProps={{
            renderValue: value => value,
          }}
          onChange={handleChange}
          onBlur={onBlur}
          margin="dense"
          fullWidth
          disabled={disabled}
        >
          {renderMenuItems()}
        </TextField>
      </div>
    </FormControl>
  );
}

export default ThreeDPrintingMaterialField;
