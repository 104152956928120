import { useQuery, useQueryClient } from 'react-query';

import { getActionTrackingApi } from '../apis/actionTrackingApi';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

const useActionTracking = (userID, actionTrackingKey) => {
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['getActionTrackings', userID, actionTrackingKey],
    () => {
      if (isEmptyValue(userID) || isEmptyValue(actionTrackingKey)) {
        return null;
      }

      return getActionTrackingApi({
        userID,
        actionTrackingKey,
      });
    },
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries('getActionTrackings');
  };

  return {
    data,
    isLoading,
    isFetching,
    refetch,
    invalidateQuery,
  };
};

export default useActionTracking;
