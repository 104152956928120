// Import settings
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles/index';
import libphonenumber from 'google-libphonenumber';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slide,
} from '@material-ui/core';

import factoremLogoSmall from '../assets/img/factorem-logo-with-name-small.png';
import factoremLogoWhite from '../assets/img/factorem-logo-white.png';

// Import actions
import { signupUser } from '../actions';

// Import customized component
import { isEmpty } from 'lodash';
import * as emailValidator from '../utils/validators/emailValidator';
import CountryDropDown from '../components/forms/CountryDropDown';
import { getContactWithCountryCode, getDefaultProfilePic } from '../utils/userUtils';
import { COUNTRY_NAMES, COUNTRY_LIST } from '../constants/countryConstants';
import Slideshow from '../components/images/Slideshow';
import Description from '../components/texts/Description';
import { getUserCountry } from '../apis/userApi';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    fontSize: '30pt',
    letterSpacing: '-0.6px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: '14pt',
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11pt',
      textAlign: 'center',
    },
  },
  signup: {
    marginTop: 12,
    fontSize: '14pt',
    fontWeight: 'bold',
    textTransform: 'none',
    color: colors.fontWhite,
    background: colors.buttonBlue,
    '&:hover': {
      backgroundColor: colors.blue050,
    },
  },
  goToLogin: {
    width: '100%',
    fontSize: '14pt',
    fontWeight: 'bold',
    textTransform: 'none',
    color: colors.fontGrey,
    background: colors.fontWhite,
    border: `solid 1px ${colors.fontGrey}`,
  },
  conditions: {
    fontSize: 12,
    marginTop: 10,
    lineHeight: 1.4,
    fontWeight: 500,
    marginBottom: 25,
    color: colors.fontBlackGrey,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  inputField: {
    width: '100%',
    textTransform: 'none',
    color: colors.fontGrey,
    marginBottom: '0.8rem',
  },
  inputLabel: {
    width: '100%',
    fontWeight: 600,
    fontSize: '12pt',
    color: colors.fontBlack,
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: colors.blue050,
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      backgroundSize: 'cover',
      backgroundImage: colors.mobileBackground,
    },
  },
  video: {
    left: "25%", 
    height: '100%',
    position: 'fixed',
    transform: "translate(-50%, -50%)",
  },
  form: {
    height: '100%',
    position: 'relative',
    alignItems: 'center',
    background: colors.mainLightBlue,
    [theme.breakpoints.down('sm')]: {
      background: 'none',
    },
  },
  mobile: { 
    fontSize: '10pt',
    paddingTop: '2rem',
    color: colors.fontWhite,
  },
  mobileIcon: {
    marginRight: '0.3rem',
    verticalAlign: 'middle',
  },
  paper: {
    display: "flex",
    borderRadius: 10,
    flexDirection: "column",
    padding: "1.75rem 1.55rem",
    boxShadow: '0 6px 1.25rem 0 rgba(0, 0, 0, 0.20)',
  },
  checkboxForm: {
    marginLeft: 5,
    marginRight: 5,
  },
  checkboxFormLabel: {
    fontSize: '10pt',
  }
}));

function SignUp(props) {
  const { openVerifyEmailPage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // name fields
  const [name, setName] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [nameValidated, setNameValidated] = useState(false);

  // email fields
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);

  // contact fields
  const [contact, setContact] = useState('');
  const [showCallingCode, setShowCallingCode] = useState(false);
  const [contactValidated, setContactValidated] = useState(false);
  const [callingCode, setCallingCode] = useState(COUNTRY_NAMES.SINGAPORE);
  const [countryValue, setCountryValue] = useState(COUNTRY_NAMES.SINGAPORE);

  // checkbox fields
  const [isEU, setIsEU] = useState(false);
  const [isCheckGDPR, setIsCheckGDPR] = useState(false);
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const [isCheckWhatsApp, setIsCheckWhatsApp] = useState(true);

  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const validateContact = (contact) => {
    if (isEmpty(contact) || contact.length <= 1 ) {
      setContactValidated(false);
      return false;
    }

    const region = COUNTRY_LIST[callingCode].code;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(contact, region);
      if (phoneUtil.isValidNumber(number)) {
        setContactValidated(true);
        return true;
      } 
      setContactValidated(false);
      return false;
    } catch (e) {
      setContactValidated(false);
      return false;
    }
  };

  useEffect(() => {
    setSubmitButtonEnabled(nameValidated && emailValidated && contactValidated && isCheckTerms);
  }, [nameValidated, emailValidated, contactValidated, isCheckTerms]);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const { in_eu, country_name } = await getUserCountry();
        setCountryValue(country_name !== 'United States' ? country_name : COUNTRY_NAMES.AMERICA)  // only america has shortened country name, quick fix
        setCallingCode(country_name !== 'United States' ? country_name : COUNTRY_NAMES.AMERICA)  // only america has shortened country name, quick fix
        if (in_eu) {
          setIsEU(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchCountryCode();
  }, []);

  useEffect(() => {
    validateContact(contact);
  }, [contact, callingCode]);

  const validateName = (name) => {
    if (!isEmpty(name)) {
      setNameValidated(true);
      return true;
    } else {
      setNameValidated(false);
      return false;
    }
  }

  const validateEmail = (email) => {
    if (emailValidator.validateEmail(email)) {
      setEmailValidated(true);
      return true;
    } else {
      setEmailValidated(false);
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const countryCode = COUNTRY_LIST[callingCode].phone;
    const formattedContact = getContactWithCountryCode(contact, countryCode);
    const userData = {
      email,
      name,
      role: 'buyer',
      country: countryValue ? countryValue : callingCode,  // fallback to use phone number country if ip address service doesn't work
      contact: formattedContact,
      profilePic: getDefaultProfilePic(name),
      isGDPRSubjected: isCheckGDPR,
      isContactableWhatsApp: isCheckWhatsApp,
    };
    openVerifyEmailPage(userData);
  };

  const renderConsentClauses = () => {
    return (
    <React.Fragment>
      <FormGroup
        className={classes.checkboxForm}
        row
      >
        <FormControlLabel
          className={classes.checkboxFormLabel}
          control={
            <Checkbox
              data-cy="term-required-checkbox"
              checked={isCheckTerms}
              onChange={evt => {
                setIsCheckTerms(evt.target.checked)
              }}
              name="check-terms-and-privacy"
            />
          }
          label={
            <React.Fragment>
              I agree to the&nbsp; 
              <Link
                target='_blank'
                to='/terms'
              >
                Terms of Use
              </Link> 
              &nbsp;and&nbsp;
              <Link
              target='_blank'
              to='/privacy'
              >
                Privacy Policy.
              </Link>
            </React.Fragment>
          }
        />
      </FormGroup>
      <FormGroup
        className={classes.checkboxForm}
        row
      >
        <FormControlLabel
          className={classes.checkboxFormLabel}
          control={
            <Checkbox
              checked={isCheckWhatsApp}
              onChange={evt => {
                setIsCheckWhatsApp(evt.target.checked)
              }}
              name="check-whatsApp"
            />
          }
          label="I agree to be contacted by Factorem via WhatsApp."
        />
      </FormGroup>
      {isEU && 
      <FormGroup
        className={classes.checkboxForm}
        row
      >
        <FormControlLabel
          className={classes.checkboxFormLabel}
          control={
            <Checkbox
              checked={isCheckGDPR}
              onChange={evt => {
                setIsCheckGDPR(evt.target.checked)
              }}
              name="check-GDPR"
            />
          }
          label="I am subjected to GDPR."
        />
      </FormGroup>}
    </React.Fragment>
    );
  }

  const renderSignUpForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Typography className={classes.title}>Sign Up</Typography>
        <Typography className={classes.subtitle}>
          to start your manufacturing journey with us
        </Typography>
        <Typography className={classes.inputLabel}>Name</Typography>
        <TextField
          id='name'
          autoFocus
          name='name'
          size='small'
          value={name}
          variant='outlined'
          error={isNameError}
          style={{ width: '100%' }}
          onChange={({ target }) => {
            validateName(target.value);
            setName(target.value);
          }}
          className={classes.inputField}
          onBlur={() => setIsNameError(!nameValidated)}
        />
        <Typography className={classes.inputLabel}>Email</Typography>
        <TextField
          id='email'
          name='email'
          className={classes.inputField}
          value={email}
          error={isEmailError}
          onChange={({ target }) => {
            validateEmail(target.value.trim());
            setEmail(target.value.trim());
          }}
          onBlur={() => setIsEmailError(!emailValidated)}
          variant='outlined'
          size='small'
          style={{ width: '100%' }}
        />
        <Typography className={classes.inputLabel}>Contact number</Typography>
        <div style={{ display: 'flex', overflow: 'hidden' }}>
          <Slide in={showCallingCode} direction="right">
            <div
            style={{ 
              width: isMobile ? '40%' : '30%',
              display: showCallingCode ? 'block' : 'none',
            }}
            >
              <CountryDropDown
                id='countryCode'
                isCountryCodeDropDown
                onSelect={(value) => setCallingCode(value)}
                valueSelected={callingCode}
                showDefaultFlag
                overrideStyle={{ width: '100%' }}
              />
            </div>
          </Slide>
          <TextField
            size='small'
            id='contact'
            name='contact'
            value={contact}
            variant='outlined'
            className={classes.inputField}
            onChange={({ target }) => setContact(target.value)}
            onFocus={() => setShowCallingCode(true)}
            error={!contactValidated && contact.toString().length > 7}
            helperText={!contactValidated && contact.toString().length > 7 ? 'Please enter a valid contact number.': ''}
            style={{ 
              width: !showCallingCode ? '100%' : isMobile ? '60%' : '70%',
            }}
          />
        </div>
        {renderConsentClauses()}
        <Button
          id='signup'
          type='submit'
          fullWidth
          variant='contained'
          className={classes.signup}
          disabled={!submitButtonEnabled}
        >
          Sign Up
        </Button>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          color: colors.fontGrey,
          fontWeight: 'bold',
          paddingTop: 40,
        }}>
          <Divider style={{ width: '47%' }}/> 
          &nbsp;or&nbsp;
          <Divider style={{ width: '47%' }}/>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 40,
            fontSize: '10pt'
          }}
        >
          <Button
            type='button'
            color='secondary'
            className={classes.goToLogin}
            component={Link}
            to='/login'
          >
            Login
          </Button>
        </div>
      </form>
    );
  } 

  const renderAdditionalInfo = useMemo(() => {
    return (
      <Grid container direction='column' spacing={1} className={classes.mobile}>
        <Description size={4}/>
      </Grid>
    );
  }, []);

  return (
    <Grid container className={classes.container}>
      {!isMobile 
        ? <Grid item sm={6}>
            <a href='https://www.factorem.co' target='_blank' rel="noreferrer">
              <img
                src={factoremLogoWhite}
                alt='icon'
                style={{ 
                  height: '4rem', 
                  position: 'absolute', 
                  top: '2rem',
                  left: '4rem',
                  zIndex: 2
                }}                
              />
            </a>
            <Slideshow/>
          </Grid>
        : <Grid item>
            <a href='https://www.factorem.co' target='_blank' rel="noreferrer">
              <img
                src={factoremLogoSmall}
                alt='icon'
                style={{ 
                  height: '3rem', 
                  position: 'absolute', 
                  top: '2rem',
                  left: '2rem'
                }}                
              />
            </a>
          </Grid>
      } 
      <Grid container component="main" justifyContent='center' className={classes.form}>
        <div style={{ 
          padding: isMobile ? '7rem 5% 5rem 5%' : '0 22% 0 22%',
        }}>
          {isMobile 
            ? <Fragment>
                <Paper className={classes.paper}>
                    {renderSignUpForm()}
                </Paper>
                {renderAdditionalInfo}
              </Fragment>
            : renderSignUpForm()  
          }
        </div>
      </Grid>
    </Grid>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps(dispatch, props) {
  return {
    openVerifyEmailPage: userData => dispatch(signupUser(userData, props))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(SignUp);
