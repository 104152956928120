export const QUESTION_TYPES = {
  RADIO: 'radio',
  SHORT_ANSWER: 'short_answer',
  RATE: 'rate',
  LINEAR_SCALE: 'linear_scale',
  MULTI_SELECTION: 'multi_selection',
}

export const QUESTION_LIST = {
  question1: {
    id: 1,
    question: 'How would you feel if you could no longer use Factorem?',
    type: QUESTION_TYPES.RADIO,
    options: [
      'Very disappointed',
      'Somewhat disappointed',
      'Not disappointed',
    ],
  },
  question2: {
    id: 2,
    question: 'What is the main benefit you receive from using Factorem?',
    type: QUESTION_TYPES.MULTI_SELECTION,
    options: [
      'Quick quotation',
      'Competitive price',
      'Short manufacturing lead time',
      'Manufacturing feedback',
      'Ease of use',
      'Access to manufacturing technology',
    ],
    useOther: true,
  },
  question3: {
    id: 3,
    question: 'What type of person do you think would benefit most from Factorem?',
    type: QUESTION_TYPES.SHORT_ANSWER,
  },
  question4: {
    id: 4,
    question: 'How can we improve Factorem for you?',
    type: QUESTION_TYPES.SHORT_ANSWER,
  },
  question5: {
    id: 5,
    question: 'What is your job title?',
    type: QUESTION_TYPES.SHORT_ANSWER,
  },
  question6: {
    id: 6,
    question: 'Rate Your experience!',
    type: QUESTION_TYPES.RATE,
    min: 1,
    max: 5,
  },
  question7: {
    id: 7,
    question: 'Tell us about your experience!',
    type: QUESTION_TYPES.SHORT_ANSWER,
  },
  question8: {
    id: 8,
    question: 'How do you rate the ease of obtaining quotations from Factorem?',
    type: QUESTION_TYPES.LINEAR_SCALE,
    min: 1,
    max: 10,
    leftLabel: 'Very poor',
    rightLabel: 'Fantastic',
  },
  question9: {
    id: 9,
    question: 'How do you rate the quality of our service to support your part manufacturing?',
    type: QUESTION_TYPES.LINEAR_SCALE,
    min: 1,
    max: 10,
    leftLabel: 'Very poor',
    rightLabel: 'Fantastic',
  },
}

export const USER_FEEDBACK_QUESTION_LIST = [
  QUESTION_LIST.question8,
  QUESTION_LIST.question9,
  QUESTION_LIST.question1,
  QUESTION_LIST.question2,
  QUESTION_LIST.question3,
  QUESTION_LIST.question4,
  QUESTION_LIST.question5,
]

export const USER_FEEDBACK_RATING_QUESTION_LIST = [
  QUESTION_LIST.question6,
  QUESTION_LIST.question7,
  QUESTION_LIST.question8,
  QUESTION_LIST.question9,
  QUESTION_LIST.question1,
  QUESTION_LIST.question2,
  QUESTION_LIST.question3,
  QUESTION_LIST.question4,
]
