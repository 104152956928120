import React, { useReducer } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';

import ConfigurePartsLibraryItemRow from './ConfigurePartsLibraryItemRow';
import ManagePartsLibraryVersionsPopup from '../popups/ManagePartsLibraryVersionsPopup';

import { updatePartsLibrarySingleData } from '../../../../actions';
import { isEmptyValue } from '../../../../utils/commonUtils';
import { getDefectItemLibrary, sortedItemsLibrary } from '../../../../utils/partsLibraryUtils';

import { colors } from '../../../../palette';
import useDeviceCheck from '../../../../hooks/useDeviceCheck';
import { getLatestDfmDefectsPartsLibrary } from '../../../../apis/partsLibraryApi';
import { getUserIDSelector } from '../../../../selectors/userSelector';

const useStyles = makeStyles({
  tableHead: {
    borderTop: `1px solid ${colors.neutral040}`,
  },
  table: {
    '& .MuiTableHead-root th': {
      borderBottom: `1px solid ${colors.neutral040} !important`,
      paddingTop: '0',
      paddingBottom: '0',
      color: colors.neutral090,
      fontWeight: 600,
    },
  },
});

/**
 * Represents the Configure Parts form.
 *
 * Maintains its own state for the project.
 * Only updates the project state when the user clicks Save.
 */
function ConfigurePartsLibrary() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [{ isTablet }] = useDeviceCheck();
  const userID = useSelector(getUserIDSelector);

  const project = useSelector((state) => state.partsLibrary?.data);

  const {
    data: dfmDefectsProjectLibrary,
    refetch: refetchDfmDefects,
  } = useQuery(
    ['getLatestDfmDefectsPartsLibrary', userID, project.projectLibraryID],
    () => getLatestDfmDefectsPartsLibrary({
      userID,
      projectLibraryID: project.projectLibraryID
    })
  );

  const [manageVersionPopup, updateManageVersionPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );

  const inUseItems = project?.items?.filter(
    (item) => item.inUse && !item.isDeleted
  );
  const sortedItems = sortedItemsLibrary(inUseItems);

  const allChecked = inUseItems?.every((item) => item.checked);

  const handleClickAllChecked = () => {
    const newItems = inUseItems.map((item) => {
      if (item.inUse) {
        return { ...item, checked: !allChecked };
      }
      return item;
    });
    dispatch(
      updatePartsLibrarySingleData(project?.projectLibraryID, {
        items: newItems,
      })
    );
  };

  return (
    <Table aria-label='table' className={classes.table}>
      {!isTablet && (
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                checked={allChecked}
                indeterminate={
                  inUseItems?.some((item) => item.checked) &&
                  inUseItems?.some((item) => !item.checked)
                }
                onChange={handleClickAllChecked}
              />
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Configuration</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {sortedItems?.map((item) => (
          <ConfigurePartsLibraryItemRow
            key={(item.itemLibraryID ?? item.id) + item.version}
            item={item}
            updateManageVersionPopup={updateManageVersionPopup}
            dfmDefect={getDefectItemLibrary(dfmDefectsProjectLibrary, item)}
            refetchDfmDefects={refetchDfmDefects}
          />
        ))}
      </TableBody>
      {manageVersionPopup.open &&
        !isEmptyValue(manageVersionPopup.selectedData) && (
          <ManagePartsLibraryVersionsPopup
            open={manageVersionPopup.open}
            onClose={() => updateManageVersionPopup({ open: false })}
            item={manageVersionPopup.selectedData}
            dfmDefectsProjectLibrary={dfmDefectsProjectLibrary}
          />
        )}
    </Table>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(ConfigurePartsLibrary));
