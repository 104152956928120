import React from 'react';
import { useSelector } from 'react-redux';

import {
  IconButton as MuiIconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { GetApp } from '@material-ui/icons';

import { FlexColumnCenter } from '../layouts/FlexLayouts';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import { getUserIDSelector } from '../../selectors/userSelector';

import { downloadS3File } from '../../utils/fileUtils';

import { ACTION_TYPE } from '../../constants/actionTrackingConstants';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const IconButton = withActionTrackingHOC(MuiIconButton)

function DownloadIconButton(props) {
  const {
    id = 'download-icon-button',
    tooltip = '',
    icon: IconComp = GetApp,
    url,
    label,
    onClick = () => { },
    navigateUrl = false,
  } = props;

  const userID = useSelector(getUserIDSelector);

  const handleButtonClick = (e) => {
    if (navigateUrl) {
      window.open(url);
      return;
    }
    if (url) {
      e.stopPropagation();
      downloadS3File(url);
      return;
    }
    if (typeof onClick === 'function') {
      e.stopPropagation();
      onClick(e);
    }
  }

  return (
    <Tooltip arrow title={tooltip} placement='top'>
      <div>
        <FlexColumnCenter style={{ margin: '0.5rem' }}>
          <IconButton
            actionTrackingKey={ACTION_TYPE.DOWNLOAD_FILE}
            userID={userID}
            additionalInfo={{
              urls: [url],
            }}
            id={id}
            style={{
              color: colors.fontGrey,
              padding: 2,
            }}
            aria-label='pdf'
            onClick={handleButtonClick}
          >
            <IconComp style={{ fontSize: '2rem' }} />
          </IconButton>
          {label && <Typography variant='caption'>{label}</Typography>}
        </FlexColumnCenter>
      </div>
    </Tooltip>
  );
}

export default DownloadIconButton;
