const queryString = require('query-string');

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// --------------------------------------------------------------------------------------------

export const getActionTrackingApi = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/action-tracking?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const trackActionApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/action-tracking`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
