import React, { useReducer } from 'react';
import { toast } from 'react-toastify';

import SingleFieldPopup from '../components/popups/SingleFieldPopup';

import { regeneratePpeForItem } from '../apis/dbMigrationApi';

import { notifyError } from '../services/notificationService';

import { stringToNumberArray } from '../utils/stringUtils';
import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withRegeneratePpeItemsPopupHOC(WrappedComponent) {
  return function RegeneratePpeItemsPopup(props) {
    const defaultLocalState = {
      open: false,
      refetchData: () => {},
      loading: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      return { ...prev, ...next };
    }, defaultLocalState);

    const handleRegenerateItemPpeLog = (itemIDs) => {
      const itemIDsNumber = stringToNumberArray(itemIDs).filter(Number);
      if (isEmptyValue(itemIDsNumber)) {
        notifyError('Please enter valid itemIDs.');
        return;
      }
      const toastId = toast(
        `Regenerating PPE Instant Log for itemIDs=${itemIDs}`,
        { type: toast.TYPE.INFO, autoClose: false }
      );
      updateLocalState({ loading: true });
      regeneratePpeForItem({ itemIDs: stringToNumberArray(itemIDs) })
        .then(localState.refetchData)
        .then(() => {
          toast.update(toastId, {
            renderCell: `Regenerating PPE Instant Log for itemIDs=${itemIDs} successfully.`,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          updateLocalState({ open: false });
        })
        .catch(() => {
          toast.update(toastId, {
            renderCell: `Regenerating PPE Instant Log for itemIDs=${itemIDs} failed.`,
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
        })
        .finally(() => {
          updateLocalState({ loading: false });
        });
    };

    return (
      <>
        <WrappedComponent
          updateRegeneratePpeItemsPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <SingleFieldPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            title='Generate Log for Item'
            label='ItemIDs (comma separated)'
            onSubmit={handleRegenerateItemPpeLog}
            textFieldProps={{
              placeholder: 'e.g. 2001,2002,2003',
              inputProps: {
                pattern: '[0-9,]*', // Allow only numbers and commas
                onKeyPress: (e) => {
                  if (!/^[0-9,]*$/.test(e.key)) {
                    e.preventDefault(); // Prevent invalid characters
                  }
                },
              },
            }}
            submitText='Generate'
            loading={localState.loading}
          />
        )}
      </>
    );
  };
}

export default withRegeneratePpeItemsPopupHOC;
