import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { createPortal } from 'react-dom';

import Shepherd from 'shepherd.js';
import { size, offset } from '@floating-ui/dom';

import {
  makeStyles,
  useTheme,
  useMediaQuery,
  MobileStepper,
} from '@material-ui/core';

import { colors } from '../../palette';
import './Tour.css';  // Shepherd is rendered in the same level as the root div of App, we cannot style with makeStyles

const useStyles = makeStyles({
  stepper: {
    '& .MuiMobileStepper-root': {
      background: colors.fontWhite,
      padding: '8px 8px 8px 0',
    }
  },
});

// This component is a wrapper for Shepherd.js
// It is used to initialize the tour through adding steps, adding MuiStepper, handling clean up etc.
export default function Tour(props) {
  const {
    setTour,
    tourOptions,
    tourSteps,
    showStepper = false,  // Show dots as progress indicator
    showStepNumber = false,  // Show step number as progress indicator
    onCompleteOrCancel = () => { },  // User-defined clean up when tour is complete,
  } = props;

  if (showStepNumber && showStepper) {
    throw new Error('showStepNumber and showStepper cannot be true at the same time.');
  }

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [progressBarContainer, setProgressBarContainer] = useState(null)
  const [stepsAdded, setStepsAdded] = useState(false);

  const options = {
    ...tourOptions,
    defaultStepOptions: {
      ...tourOptions.defaultStepOptions,
      when: {
        // TODO: Allow for custom when functions, by making this a higher order function
        // Need to be aware of how `this` works in Shepherd
        show() {
          if (!showStepper && !showStepNumber) {
            return;
          }

          // Add container to hold progress bar
          // Requires manual DOM manipulation because Shepherd don't have direct support for React
          // The container is needed regardless of stepper, to align the next button to the right          
          const currentStep = Shepherd.activeTour?.getCurrentStep();
          const currentStepElement = currentStep?.getElement();
          const footer = currentStepElement?.querySelector('.shepherd-footer');
          const progressBarContainer = document.createElement('div');
          if (showStepper) {
            setProgressBarContainer(progressBarContainer);
          }
          if (showStepNumber) {
            progressBarContainer.className = 'step-number';
            progressBarContainer.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
          }
          footer?.insertBefore(progressBarContainer, currentStepElement.querySelector('.shepherd-button:last-child'));
        }
      },
    }
  }

  // Mobile positioning, requires some manual styling
  // See Tour.css
  // Adds an white arrow, gap of 8px (set by maxWidth here, and left in .mobile-positioning)
  // and gap of 8px between the arrow and the step
  if (isMobile) {
    options.defaultStepOptions.floatingUIOptions = {
      ...options.defaultStepOptions.floatingUIOptions,
      middleware: [
        size({
          apply({ elements }) {
            Object.assign(elements.floating.style, {
              maxWidth: `${window.innerWidth - 16}px`,
            });
          }
        }),
        offset({
          mainAxis: 8
        })
      ]
    }
    options.defaultStepOptions.classes = `${options.defaultStepOptions.classes} mobile-positioning`;
    options.defaultStepOptions.arrow = true;
  }

  const tour = new Shepherd.Tour(options);

  useEffect(() => {
    // BUG: This never triggers even when tourSteps is updated.
    // Reason: We need to remove all steps first before adding new steps
    // Currently, it can cause update loop as tour is continuously updated
    // Impact: Positioning of steps when switching between mobile and desktop is not ideal (edge case)
    tour.addSteps(tourSteps);
    setStepsAdded(true);
  }, [tourSteps]);

  useEffect(() => {
    // We will only update the controlled tour when steps are added
    setTour(tour);
  }, [stepsAdded]);

  const deleteAllStepDomElements = () => {
    _.each(Shepherd.activeTour?.steps, function (step) {
      step.destroy();
    });
  }
  
  useEffect(() => {
    const events = ['complete', 'cancel'];
    events.forEach(event => {
      // Destroy all DOM elements created by Shepherd when the tour is complete or cancelled
      Shepherd.on(event, deleteAllStepDomElements);
      // User-defined clean up when tour is complete
      Shepherd.on(event, onCompleteOrCancel);
    });
    
    return () => {
      events.forEach(event => {
        Shepherd.off(event, deleteAllStepDomElements);
        Shepherd.off(event, onCompleteOrCancel);
      });
    }
  }, []);

  return (
    <>
      {showStepper && progressBarContainer !== null && createPortal(
        <div className={classes.stepper}>
          <MobileStepper
            position='static'
            steps={Shepherd.activeTour?.steps.length}
            activeStep={Shepherd.activeTour?.steps.indexOf(Shepherd.activeTour?.getCurrentStep())}
          />
        </div>, progressBarContainer)}
    </>
  );
}
