import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';
import Thumb from '../fields/Thumb';

import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Close, Info as InfoIcon } from '@material-ui/icons';

import CircleButton from '../buttons/CircleButton';
import FtrButton from '../ftr-components/FtrButton';
import FtrSvgImage from '../images/FtrSvgImage';
import FtrTypography, { FtrBoldText, FtrErrorText, FtrH6, FtrNormalText } from '../ftr-components/FtrTypography';
import MultiSelectChipDropdownWithCategoryFuzzySearch from '../dropdowns/MultiSelectChipDropdownWithCategoryFuzzySearch';
import SelectableOutlinedDiv from '../panels/SelectableOutlinedDiv';
import Title from '../Title';
import { FlexColumn, FlexRow, FlexRowCenter, FlexRowSpaceBetween } from '../layouts/FlexLayouts';
import { FtrBanner, FtrDropdown, FtrTooltip } from '../ftr-components';

import { RepeatOrderIcon, SimilarOrderIcon } from '../icons/ItemIcon';
import CheckIcon from '../icons/CheckIcon';
import WarningIcon from '../icons/WarningIcon';
import AutoAwesomeIcon from '../../assets/icons/auto_awesome.svg';
import ArrowLeftIcon from '../../assets/icons/left_arrow.svg';
import ArrowRightIcon from '../../assets/icons/right_arrow.svg';

import { getAllSuppliersGroupByTech } from '../../apis/userApi';
import {
  abortGetSuppliersRankForItems,
  getSuppliersGroupForItemsWithCache,
  getSuppliersRankForItemsWithCache,
  submitFeedback,
  getFeedback,
} from '../../apis/rfqEmailSchedulesApi';

import { useRfqEmailSchedulesConfigForm } from '../../hooks/useRfqEmailSchedulesConfigForm';

import { isEmptyValue } from '../../utils/commonUtils';

import { notifyError, notifyInfo, notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';
import { equalArrays } from '../../utils/arrayUtils';
import { getSupplierIcon } from '../../utils/supplierUtils';
import { OMS_TECHNOLOGY_LIST } from '../../constants/OMSConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 350,
    },
  },
  autoAwesomeIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  suggestionSupplier: {
    cursor: 'pointer',
    color: colors.blue060,
    '&:hover': {
      color: colors.blue100,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '0.8rem',
    top: '0.8rem',
    color: colors.neutral060,
    cursor: 'pointer',
  }
}));

const KEY_VALUE_MAPPING = [
  ['Item ID', 'item_id'],
  ['Quotation ID', 'quote_id'],
  ['Matched Item ID', 'matched_item_id'],
  ['Supplier\'s quote', 'supplierPriceText'],
  ['Technology', 'technology'],
  ['Material', 'material'],
  ['Surface Finish', 'finish'],
  ['Quantity', 'quantity'],
  ['Lead Time', 'leadTimeFinalText'],
  ['Status', 'status'],
]

function QuotationDetails({ quoteInfoList, supplierName, onClose }) {
  const classes = useStyles();

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  const hasRepeatItem = quoteInfoList.some((quoteInfo) => quoteInfo.isRepeat === true);
  const hasSimilarItem = quoteInfoList.some((quoteInfo) => quoteInfo.isSimilar === true);
  const hasBothRepeatAndSimilar = hasRepeatItem && hasSimilarItem;

  const quoteInfo = quoteInfoList[currentQuoteIndex];
  const isRepeat = get(quoteInfo, 'isRepeat', false);
  const isSimilar = get(quoteInfo, 'isSimilar', false);


  const renderNavigationButtons = () => {
    return (
      <FlexRowCenter>
        <CircleButton
          size="small"
          onClick={() => {
            setCurrentQuoteIndex(pre => pre - 1);
          }}
          style={{
            visibility: currentQuoteIndex > 0 ? 'visible' : 'hidden',
          }}
        >
          <img src={ArrowLeftIcon} />
        </CircleButton>
        <FtrBoldText style={{ color: colors.neutral060 }}>
          {Number(currentQuoteIndex) + 1}/{quoteInfoList.length}
        </FtrBoldText>
        <CircleButton
          size="small"
          onClick={() => {
            setCurrentQuoteIndex(pre => pre + 1);
          }}
          style={{
            visibility: currentQuoteIndex < quoteInfoList.length - 1 ? 'visible' : 'hidden',
          }}
        >
          <img src={ArrowRightIcon} />
        </CircleButton>
      </FlexRowCenter>
    );
  }

  return (
    <FtrBanner
      type='info'
      style={{
        backgroundColor: "white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <FtrTypography type='heading' fontSize='16'>
        Quote Info
      </FtrTypography>
      <Close className={classes.closeIcon} onClick={onClose} />
      <br />
      <FlexRow>
        <FtrBoldText type='body' fontSize='14' style={{ color: colors.blue060 }}>
          Supplier:
        </FtrBoldText>
        <FtrNormalText>
          {supplierName}
        </FtrNormalText>
      </FlexRow>
      {KEY_VALUE_MAPPING.map(([label, key, suffix]) => {
        const matched = get(quoteInfo, `${key}Matched`);
        const expectedValue = get(quoteInfo, `${key}Expected`);

        return (
          <FlexRow key={label}>
            <FtrBoldText type='body' fontSize='14' style={{ color: colors.blue060 }}>
              {label}:
            </FtrBoldText>
            <FtrNormalText>
              {get(quoteInfo, key) || ''} {suffix}
            </FtrNormalText>
            {matched === true && <CheckIcon />}
            {matched === false && (
              <FtrTooltip description={`Expected: ${expectedValue}`} style={{ zIndex: 1400 }}>
                <div>
                  <WarningIcon />
                </div>
              </FtrTooltip>
            )}
          </FlexRow>
        );
      })}
      {hasBothRepeatAndSimilar && (
        <FlexRow>
          <FtrBoldText type='body' fontSize='14' style={{ color: colors.blue060 }}>
            Type:
          </FtrBoldText>
          {isRepeat && (
            <RepeatOrderIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
          {isSimilar && (
            <SimilarOrderIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </FlexRow>
      )}
      {quoteInfoList?.length > 1 && (
        <div style={{ marginTop: '1rem' }}>
          {renderNavigationButtons()}
        </div>
      )}
    </FtrBanner>
  );
}

function AddRfqEmailSchedulesConfigPopup(props) {
  const classes = useStyles();
  const loggedInUserID = useSelector((state) => state.auth.user.userID);
  const {
    open,
    handleClose = () => { },
    callBack = () => { },
    availableItems = [],
  } = props;

  const { data: allSuppliers } = useQuery(
    "allSuppliers",
    getAllSuppliersGroupByTech,
  );

  const [groupingMap, setGroupingMap] = useState({});
  const [suppliersGroupData, setSuppliersGroupData] = useState([])

  const {
    isLoading: isSuppliersGroupDataLoading,
    isFetching: isSuppliersGroupDataFetching,
  } = useQuery(
    ["suppliersGroupData", availableItems],
    async () => {
      if (isEmptyValue(availableItems)) {
        return [];
      }

      const suppliersGroup = await getSuppliersGroupForItemsWithCache({
        itemIDs: availableItems,
      });
      if (suppliersGroup) {
        const feedbackRows = await getFeedback(suppliersGroup.omsResponseID);
        for (const feedback of feedbackRows) {
          const metadata = feedback.metadata;
          if (metadata.index !== undefined) {
            setGroupingMap(prevGroupingMap => ({
              ...prevGroupingMap,
              [metadata.index]: [feedback.feedback, feedback.id]
            }));
          }
        }
        setSuppliersGroupData(suppliersGroup);
        return suppliersGroup;
      }
    },
  );


  const [
    quoteDetailsTooltipState,
    updateQuoteDetailsTooltipState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      supplierName: '',
      quoteInfoList: [],
      anchorEl: null,
    },
  );

  const [
    {
      config,
      availableSuppliers,
      formErrorState,
    },
    {
      updateSelectedItem,
      addSelectedItemList,
      setSelectedItemList,
      resetSelectedItemList,
      createScheduleConfig,
      setAttachFiles,
      setRemarks,
      setSuppliers,
      setAllSuppliers,
      resetFormError,
    },
  ] = useRfqEmailSchedulesConfigForm();

  const [
    slotConfigState,
    updateSlotConfigState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        // reset state
        return {};
      }
      return { ...prev, ...next };
    },
    {
    },
  );

  const [
    selectedSuppliersGroupState,
    updateSelectedSuppliersGroupState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return {
          index: null,
          type: null,
        }
      }
      return { ...prev, ...next };
    },
    {
      index: null,
      type: null,
    },
  );

  const [selectAllItems, setSelectAllItems] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [suppliersRank, setSuppliersRank] = useState([]);
  const [rankingResponseID, setRankingResponseID] = useState(null);
  const [loadingSuppliersSuggestion, setLoadingSuppliersSuggestion] = useState(false);
  const [loadSupplierSuggestionTimer, setLoadSupplierSuggestionTimer] = useState(null);
  const [rankingMap, setRankingMap] = useState({});

  // -----------------------------------------------------------------------

  useEffect(() => {
    // reset state when component is unmounted
    return () => {
      updateSlotConfigState({});
    }
  }, []);

  useEffect(() => {
    const isAllItemSelected = availableItems.every(itemID => config.itemIDs.includes(itemID));
    setSelectAllItems(isAllItemSelected);
  }, [config]);

  const handleSelectedItemsChanged = useCallback(() => {
    if (loadSupplierSuggestionTimer) {
      clearTimeout(loadSupplierSuggestionTimer);
    }
    abortGetSuppliersRankForItems();
    setLoadingSuppliersSuggestion(false);

    if (
      selectedSuppliersGroupState.index !== null
      && selectedSuppliersGroupState.type === 'repeat-item'
    ) {
      return;
    }

    if (isEmptyValue(config.itemIDs)) {
      setSuppliersRank([]);
      return;
    }

    // if config.itemIDs is matched with 1 supplier group then load the suggested supplier
    if (!isEmptyValue(suppliersGroupData?.groups)) {
      for (const [index, group] of suppliersGroupData.groups.entries()) {
        const groupItemIDs = group.itemIDs;
        if (equalArrays(config.itemIDs, groupItemIDs)) {
          handleSelectSuppliersGroup(index);
          if (group.supplier) {
            // if it's repeat order then reuse the supplier in the group, otherwise continue to call supplier rank api
            return;
          }
        }
      }
    }
    setLoadingSuppliersSuggestion(true);

    if (loadSupplierSuggestionTimer) {
      clearTimeout(loadSupplierSuggestionTimer);
    }

    // get sub techs for each item
    const subTechs = config.itemIDs.reduce((acc, itemID) => {
      for (const group of suppliersGroupData?.groups || []) {
        if (group.itemIDs.includes(itemID)) {
          if (group.subTech) {
            acc[itemID] = group.subTech;
          }
          return acc;
        }
      }

      return acc;
    }, {});

    const timer = setTimeout(() => {
      getSuppliersRankForItemsWithCache({
        itemIDs: config.itemIDs,
        subTechs,
      }).then(async (suppliersRank) => {
        const feedbackRows = await getFeedback(suppliersRank.omsResponseID);
        for (const feedback of feedbackRows) {
          const metadata = feedback.metadata;
          if (metadata.slotID !== undefined) {
            suppliersRank.ranking[metadata.slotID].feedback = feedback.feedback;
            suppliersRank.ranking[metadata.slotID].feedbackID = feedback.id;
            setRankingMap(prevRankingMap => ({
              ...prevRankingMap,
              [metadata.slotID]: [feedback.feedback, feedback.id]
            }));
          }
        }
        setLoadingSuppliersSuggestion(false);
        setSuppliersRank(suppliersRank.ranking);
        setRankingResponseID(suppliersRank.omsResponseID);

        // get all related responses and match them to suppliersRank slotid

      }).catch((error) => {
        if (error.name === 'AbortError') {
          // start new request and aborted the old one, do nothing
          return;
        }
        notifyError('Load supplier suggestion failed.');
        setLoadingSuppliersSuggestion(false);
      });
    }, 300);
    setLoadSupplierSuggestionTimer(timer);
  }, [
    JSON.stringify(config.itemIDs),
    JSON.stringify(selectedSuppliersGroupState),
    JSON.stringify(suppliersGroupData),
  ]);

  useEffect(() => {
    handleSelectedItemsChanged();
  }, [
    handleSelectedItemsChanged,
  ]);

  useEffect(() => {
    setAllSuppliers(allSuppliers);
  }, [allSuppliers]);

  useEffect(() => {
    if (!isEmptyValue(availableSuppliers)) {
      setSupplierList(availableSuppliers.map(category => {
        return {
          ...category,
          value: category.value.map((supplier) => {
            const { companyName } = supplier;
            const text = companyName
              ? `${companyName} <${supplier.email}> (${supplier.name})`
              : `${supplier.name} <${supplier.email}>`;
            return {
              key: `${supplier.userID}`,
              text,
            }
          }),
        };
      }));
    }
  }, [availableSuppliers]);

  function handleSelectSuppliersGroup(index) {
    const group = suppliersGroupData.groups[index];
    // not able to select unverified items
    if (group.isUnverifiedItems) {
      return;
    }
    const itemIDs = group.itemIDs;
    const type = group.supplier ? 'repeat-item' : 'non-repeat-item';
    setSelectedItemList(itemIDs);
    updateSelectedSuppliersGroupState({
      index,
      type,
    });
    if (group.supplier) {
      setSuppliersRank([group.supplier]);
      if (loadSupplierSuggestionTimer) {
        clearTimeout(loadSupplierSuggestionTimer);
      }
      abortGetSuppliersRankForItems();
      setLoadingSuppliersSuggestion(false);
    } else {
      setSuppliersRank([]);
    }
  }


  async function handleSuppliersGroupFeedback({ index, feedback, omsFeedbackID }) {
    const group = suppliersGroupData.groups[index];
    group.feedback = feedback;
    const omsResponseID = suppliersGroupData.omsResponseID;

    const metadata = {};
    metadata.index = index;
    if (group.repeat_items) {
      metadata.supplier = group.supplier.userID;
      metadata.repeat_items = group.repeat_items;
    } else {
      metadata.technology = group.technology;
      metadata.subTech = group.subTech;
    }

    if (group.isUnverifiedItems) {
      metadata.isUnverifiedItems = group.isUnverifiedItems;
    }

    const payload = {
      id: omsFeedbackID,
      omsTrackingID: omsResponseID,
      userID: loggedInUserID,
      feedback,
      metadata
    };
    try {
      const id = await submitFeedback(payload);
      notifySuccess('Feedback submitted successfully');
      setGroupingMap(prevGroupingMap => ({
        ...prevGroupingMap,
        [index]: [feedback, id],
      }));
    } catch (error) {
      notifyError('Submit feedback failed');
    }
  }


  async function handleSuppliersRankFeedback({ feedback, slotID, hasSimilarItem, supplierType, feedbackID }) {
    if (hasSimilarItem) {
      supplierType = 'similar';
    }
    const metadata = {
      slotID,
      supplierType,
    };
    const payload = {
      id: feedbackID,
      omsTrackingID: rankingResponseID,
      userID: loggedInUserID,
      feedback,
      metadata,
    };
    try {
      const id = await submitFeedback(payload);
      notifySuccess('Feedback submitted successfully');
      setRankingMap(prevRankingMap => ({
        ...prevRankingMap,
        [slotID]: [feedback, id],
      }));
    } catch (error) {
      notifyError('Submit feedback failed');
    }
  }


  /**
   * this is to reset all slot suppliers to empty
   */
  // eslint-disable-next-line no-unused-vars
  function resetAllSlotSuppliers() {
    for (const slot of (config.details?.slots || [])) {
      setSuppliers([], slot.slotID);
      updateSlotConfigState({
        [slot.slotID]: { text: '', value: [] },
      });
    }
  }

  const handleCreateButtonClick = () => {
    createScheduleConfig(config)
      .then(() => {
        notifySuccess(`Schedule has been created successfully`);
        handleClose();
        callBack();
      })
      .catch((err) => {
        if (err.message !== 'Form error') {
          notifyError(`Error occurred. Created schedule failed.`);
        }
      });
  }

  const handleSelectAllItems = (e) => {
    const checked = e.target.checked;
    setSelectAllItems(checked);
    if (checked) {
      const remainItems = availableItems.filter(itemID => !config.itemIDs.includes(itemID));
      addSelectedItemList(remainItems);
    } else {
      resetSelectedItemList();
      updateSelectedSuppliersGroupState({});
    }
  }

  const handleSelectSuggestedSupplier = (slotID) => {
    const supplier = suppliersRank[slotID];
    const hasSupplierSelected = Object.values(slotConfigState || {}).some(slot => Number(slot.value?.[0]) === supplier.supplierID);
    if (hasSupplierSelected) {
      notifyInfo("The email has been selected!")
      return;
    }
    const text = `${supplier.supplierName} <${supplier.supplierEmail}>`;
    const value = [`${supplier.supplierID}`];
    const slotPosition = Object.keys(slotConfigState || {}).length;
    setSuppliers(value, slotPosition);
    updateSlotConfigState({
      [slotPosition]: { text, value },
    });
  }

  const handleUpdateTechnology = ({ rowIndex, technology }) => {
    setSuppliersGroupData((prev) => {
      const groups = prev?.groups?.map((group, index) => {
        if (index === rowIndex) {
          return {
            ...group,
            technology,
            subTech: OMS_TECHNOLOGY_LIST[technology]?.[0],
          }
        }
        return group
      })

      return {
        ...prev,
        groups,
      }
    })
  }

  const handleUpdateSubTechnology = ({ rowIndex, subTech }) => {
    setSuppliersGroupData((prev) => {
      const groups = prev?.groups?.map((group, index) => {
        if (index === rowIndex) {
          return {
            ...group,
            subTech
          }
        }
        return group
      })

      return {
        ...prev,
        groups,
      }
    })
    handleSelectSuppliersGroup(rowIndex);
  }

  const renderSuggestedSupplier = (slotID) => {

    if (isEmptyValue(suppliersRank) || suppliersRank.length <= slotID) {
      return;
    }

    const supplier = suppliersRank[slotID];
    const [supplierIcon, supplierTooltip] = getSupplierIcon(supplier.supplier_type);
    const supplierName = supplier.supplierCompany || supplier.supplierName;
    const displaySupplier = `${supplierName} <${supplier.supplierEmail}> (${supplier.supplierID}) (${supplier.supplierCountry})`;
    const quoteInfoList = get(supplier, ['itemList']);
    const hasRepeatItem = selectedSuppliersGroupState.type === 'repeat-item';
    const hasSimilarItem = quoteInfoList.some((quoteInfo) => quoteInfo.isSimilar === true);
    const feedback = rankingMap[slotID] ? rankingMap[slotID][0] : null;
    const feedbackID = rankingMap[slotID] ? rankingMap[slotID][1] : null;


    // -----------------------------------------------------------------------------

    const renderTooltip = () => {

      if (isEmptyValue(quoteInfoList)) {
        return null;
      }

      return (
        <InfoIcon
          style={{
            color: colors.neutral040,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (quoteDetailsTooltipState.timer) {
              clearTimeout(quoteDetailsTooltipState.timer);
            }
            updateQuoteDetailsTooltipState({
              open: true,
              supplierName,
              quoteInfoList,
              anchorEl: e.currentTarget,
            });
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const timer = setTimeout(() => {
              // auto close popup
              updateQuoteDetailsTooltipState({ open: false });
            }, 1000);
            updateQuoteDetailsTooltipState({ timer });
          }}
        />
      );
    }

    return (
      <FlexRow
        style={{
          paddingRight: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip title='Click to select'>
          <div>
            <FlexRow
              className={classes.suggestionSupplier}
              onClick={() => {
                handleSelectSuggestedSupplier(slotID);
              }}
            >
              <FtrSvgImage
                src={AutoAwesomeIcon}
                className={classes.autoAwesomeIcon}
              />
              <FtrNormalText
                style={{
                  textAlign: 'left',
                  color: 'inherit',
                  fontStyle: 'italic',
                }}
              >
                {displaySupplier}
              </FtrNormalText>
            </FlexRow>
          </div>
        </Tooltip>
        <FlexRow>
          {renderTooltip()}
          {hasRepeatItem && (
            <RepeatOrderIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
          {hasSimilarItem && (
            <SimilarOrderIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
          {!isEmptyValue(supplierIcon) && (
            <FtrTooltip description={supplierTooltip} style={{ zIndex: 1400 }}>
              <div>
                <FtrSvgImage
                  src={supplierIcon}
                  className={classes.autoAwesomeIcon}
                />
              </div>
            </FtrTooltip>
          )}
          {!hasRepeatItem && (
            <>
              <Thumb
                variant='up'
                isActive={feedback === 'good'}
                onClick={() => {
                  if (feedback !== 'good') {
                    handleSuppliersRankFeedback({
                      feedback: 'good',
                      slotID,
                      hasSimilarItem,
                      supplierType: supplier.supplier_type,
                      feedbackID: feedbackID || null,
                    });
                  }
                }}
                tooltipText={'Suggestion is useful'}
              />
              <Thumb
                variant='down'
                isActive={feedback === 'bad'}
                onClick={() => {
                  if (feedback !== 'bad') {
                    handleSuppliersRankFeedback({
                      feedback: 'bad',
                      slotID,
                      hasSimilarItem,
                      supplierType: supplier.supplier_type,
                      feedbackID: feedbackID || null,
                    });
                  }
                }}
                tooltipText={'Suggestion is not useful'}
              />
            </>
          )}
        </FlexRow>
      </FlexRow>
    );
  }

  const renderSuppliersGroups = () => {
    const loading = isSuppliersGroupDataLoading || isSuppliersGroupDataFetching;

    return (
      <FlexColumn style={{ alignItems: 'start' }}>
        <FtrBoldText fontSize='16'>Suggested Groups:</FtrBoldText>
        <FlexColumn
          style={{ padding: '0.5rem', width: '100%', boxSizing: 'border-box' }}
        >
          {loading && (
            <CircularProgress
              style={{
                width: 30,
                height: 30,
              }}
            />
          )}
          {isEmptyValue(suppliersGroupData?.groups) && !loading && (
            <FtrNormalText>No groups found.</FtrNormalText>
          )}
          {!loading &&
            suppliersGroupData?.groups?.map((group, index) => {
              const itemIDs = group.itemIDs;
              let feedback = null;
              let omsFeedbackID = null;

              if (index in groupingMap) {
                feedback = groupingMap[index][0];
                omsFeedbackID = groupingMap[index][1];
              }

              const itemIDListStr = itemIDs.join(',');
              let suggestedSupplierInfo;
              if (group.supplier) {
                const name = group.supplier.companyName || group.supplier.name;
                suggestedSupplierInfo = `${name} <${group.supplier.email}> (${group.supplier.userID})`;
              }

              const technology = group.subTech
                ? `${group.technology} (${group.subTech})`
                : group.technology;

              const renderThumbButtons = () => {
                return (
                  <FlexRow>
                    <Thumb
                      variant='up'
                      isActive={feedback === 'good'}
                      onClick={() => {
                        if (feedback !== 'good') {
                          handleSuppliersGroupFeedback({
                            index,
                            feedback: "good",
                            omsFeedbackID,
                          });
                        }
                      }}
                      tooltipText={'Suggestion is useful'}
                    />
                    <Thumb
                      variant='down'
                      isActive={feedback === 'bad'}
                      onClick={() => {
                        if (feedback !== 'bad') {
                          handleSuppliersGroupFeedback({
                            index,
                            feedback: "bad",
                            omsFeedbackID,
                          });
                        }
                      }}
                      tooltipText={'Suggestion is not useful'}
                    />
                  </FlexRow>
                );
              }

              return (
                <FlexRow key={index}>
                  <div style={{ flex: 1 }}>
                    <SelectableOutlinedDiv
                      key={group.supplier_id}
                      label={`Group ${index + 1} (${
                        group.supplier ? 'Repeat' : 'Non Repeat'
                      })`}
                      onClick={() => {
                        if (selectedSuppliersGroupState.index !== index) {
                          handleSelectSuppliersGroup(index)
                        }
                      }}
                      selected={index === selectedSuppliersGroupState.index}
                      style={
                        group.isUnverifiedItems
                          ? {
                              cursor: 'default',
                              backgroundColor: colors.neutral040,
                            }
                          : undefined
                      }
                    >
                      <FlexColumn style={{ alignItems: 'start' }}>
                        <FtrNormalText>Items: {itemIDListStr}</FtrNormalText>
                        {suggestedSupplierInfo && (
                          <FtrNormalText>
                            Supplier: {suggestedSupplierInfo}
                          </FtrNormalText>
                        )}
                        {technology && (
                          <FlexRow>
                            <FtrNormalText>
                              Technology:
                            </FtrNormalText>
                            <FtrDropdown
                              key='technology-dropdown'
                              value={group.technology}
                              disabled
                              handleChange={(newTech) =>
                                handleUpdateTechnology({
                                  technology: newTech,
                                  rowIndex: index,
                                })
                              }
                              items={Object.keys(OMS_TECHNOLOGY_LIST)}
                              style={{ width: '100px' }}
                            />
                            <FtrDropdown
                              key='sub-technology-dropdown'
                              disabled={group.isUnverifiedItems}
                              value={group.subTech}
                              handleChange={(newTech) =>
                                handleUpdateSubTechnology({
                                  subTech: newTech,
                                  rowIndex: index,
                                })
                              }
                              items={Object.values(OMS_TECHNOLOGY_LIST[group.technology] || {})}
                              style={{ width: '200px' }}
                            />
                          </FlexRow>
                        )}
                        {group.isUnverifiedItems && (
                          <FtrNormalText>Group: Unverified Items</FtrNormalText>
                        )}
                      </FlexColumn>
                    </SelectableOutlinedDiv>
                  </div>
                  {renderThumbButtons()}
                </FlexRow>
              );
            })}
        </FlexColumn>
      </FlexColumn>
    )
  }

  const renderAdditionalRemarksField = () => {
    return (
      <TextField
        style={{
          width: "100%",
          marginTop: "16px",
        }}
        variant="outlined"
        multiline
        minRows={3}
        margin="dense"
        id="remarks"
        label="Additional Remarks"
        placeholder=""
        type="text"
        fullWidth
        onChange={evt => setRemarks(evt.target.value)}
        value={config.details.remarks}
        InputLabelProps={{
          shrink: true
        }}
      />
    );
  }

  const renderBody = () => {
    return (
      <FlexRow style={{ flex: '1 1 auto', alignItems: 'start' }}>
        <FlexColumn style={{ flex: '1 1 auto', width: '100%' }}>
          <FlexColumn style={{ alignItems: 'start' }}>
            <FlexRow>
              <FtrBoldText fontSize='16' style={{ marginRight: '0.5rem', textAlign: 'left' }}>
                Select Item(s):
              </FtrBoldText>
              <FlexColumn style={{ width: '100%', flex: '1 1 auto' }}>
                {availableItems.length > 1 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAllItems}
                        onChange={(e) => {
                          handleSelectAllItems(e);
                          resetFormError();
                        }}
                        name="select-all"
                      />
                    }
                    label="Select All"
                  />
                )}
                <FlexRow style={{ flexWrap: 'wrap', gap: 0 }}>
                  {availableItems.map(item => {
                    return (
                      <FormControlLabel
                        key={item}
                        control={
                          <Checkbox
                            checked={config.itemIDs.includes(item)}
                            onChange={() => {
                              updateSelectedItem(item);
                              updateSelectedSuppliersGroupState({});
                              setSuppliersRank([]);
                              resetFormError();
                            }}
                            name={item}
                          />
                        }
                        label={item}
                      />
                    );
                  })}
                </FlexRow>
              </FlexColumn>
            </FlexRow>
            {
              !isEmptyValue(formErrorState?.selectItems) && (
                <Typography
                  variant='caption'
                  style={{
                    fontStyle: 'italic',
                    color: 'red',
                  }}
                >
                  {formErrorState?.selectItems}
                </Typography>
              )
            }
          </FlexColumn >
          {renderSuppliersGroups()}
          {renderAdditionalRemarksField()}
          <FormControlLabel
            control={
              <Checkbox
                checked={config.details.attachFiles}
                onChange={(e) => setAttachFiles(e.target.checked)}
                name="attachFiles"
              />
            }
            label="Attach Design Files"
          />
        </FlexColumn >
        <Divider orientation="vertical" flexItem style={{ margin: '0 0.5rem' }} />
        <FlexColumn style={{ flex: '1 1 auto', height: '100%', width: '100%', alignItems: 'start' }}>
          <FlexRowSpaceBetween style={{ width: '100%' }}>
            <FtrH6>
              Suppliers Config
            </FtrH6>
            {loadingSuppliersSuggestion && (
              <FlexRow>
                <CircularProgress color="primary" size={24} />
                <FtrNormalText style={{ color: colors.blue060 }}>
                  Loading suggestion...
                </FtrNormalText>
              </FlexRow>
            )}
          </FlexRowSpaceBetween>
          {formErrorState?.emptySuppliers && (
            <FtrErrorText>
              {formErrorState?.emptySuppliers}
            </FtrErrorText>
          )}
          <FlexColumn
            style={{
              overflow: 'auto',
              padding: '0.5rem',
              width: '100%',
              gap: '1rem',
              flex: '1 1 auto',
            }}
          >
            {
              config.details?.slots?.map((slot) => {
                const key = `${slot.slotID}-${slotConfigState[slot.slotID]?.text}`;

                return (
                  <>
                    <FlexRowCenter
                      key={key}
                    >
                      <Typography
                        style={{
                          marginTop: '1rem',
                          marginRight: '1rem',
                        }}>
                        #{slot.slotID + 1}
                      </Typography>
                      <FlexColumn
                        style={{
                          width: '100%',
                          gap: 0,
                        }}
                      >
                        {renderSuggestedSupplier(slot.slotID)}
                        <MultiSelectChipDropdownWithCategoryFuzzySearch
                          id={`multi-select-suppliers-slot-${key}`}
                          label="Select supplier"
                          itemList={supplierList}
                          value={slotConfigState[slot.slotID]?.value}
                          selectedText={slotConfigState[slot.slotID]?.text}
                          onSelect={(newValues, text) => {
                            setSuppliers(newValues, slot.slotID);
                            updateSlotConfigState({
                              [slot.slotID]: { text, value: newValues },
                            });
                          }}
                          searchable
                          multiple={false}
                        />
                      </FlexColumn>
                    </FlexRowCenter>
                    <Divider />
                  </>
                );
              })
            }
          </FlexColumn>
        </FlexColumn>
      </FlexRow>
    );
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        maxWidth='lg'
        open={open}
        aria-labelledby='confirmation-dialog-title'
        fullWidth
      >
        <DialogTitle id="confirmation-dialog-title">
          <Title contentTitle="New RFQ Emails Schedule" size="small" />
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0 30px',
            textAlign: 'center',
            display: 'flex',
          }}
        >
          {renderBody()}
        </DialogContent>
        <DialogActions style={{ padding: '1rem', borderTop: '1px solid #E0E0E0' }}>
          <FtrButton
            onClick={handleClose}
            color='white'
          >
            Cancel
          </FtrButton>
          <FtrButton
            onClick={handleCreateButtonClick}
            color='blue'
          >
            Create
          </FtrButton>
        </DialogActions>
      </Dialog>
      <Popper
        open={quoteDetailsTooltipState.open}
        anchorEl={quoteDetailsTooltipState.anchorEl}
        placement='right'
        transition
        style={{
          zIndex: 1300,
          marginLeft: '1rem',
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={(e) => {
              e.stopPropagation();
              e.preventDefault();
              updateQuoteDetailsTooltipState({ open: false });
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                onMouseEnter={() => {
                  if (quoteDetailsTooltipState.timer) {
                    clearTimeout(quoteDetailsTooltipState.timer);
                  }
                  updateQuoteDetailsTooltipState({ timer: null });
                }}
                onMouseLeave={() => {
                  const timer = setTimeout(() => {
                    // auto close popup
                    updateQuoteDetailsTooltipState({ open: false });
                  }, 1000);
                  updateQuoteDetailsTooltipState({ timer });
                }}
              >
                <QuotationDetails
                  quoteInfoList={quoteDetailsTooltipState.quoteInfoList}
                  supplierName={quoteDetailsTooltipState.supplierName}
                  onClose={() => updateQuoteDetailsTooltipState({ open: false })}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}


export default AddRfqEmailSchedulesConfigPopup;
