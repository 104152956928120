import React, { useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { makeStyles } from '@material-ui/core/styles';

import {
  CircularProgress,
} from '@material-ui/core';


import { FtrH7, FtrTypography } from '../components/ftr-components';

import { getAllProjectsByUser } from '../apis/projectApi';

import { getUserSelector } from '../selectors/userSelector';

import { isEmptyValue } from '../utils/commonUtils';
import { groupByObjectProperty } from '../utils/objectUtils';
import { getLatestDeliveryDateForItem } from '../utils/itemUtils';

import { withProjectMultiCheckoutInfoPopupHOC } from '../hocs/withProjectMultiCheckoutInfoPopupHOC';

import { ITEM_STATUS_MAPPING } from '../constants';

import { colors } from '../palette';


// -------------------------------------------------------------------------------------------------

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    }
  },
  body: {
    height: '100vh',
    padding: '2rem',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  calendar: {
    height: 600,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  event: {
    backgroundColor: colors.blue050,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const hideTimeCells = () => {
  const headerCells = document.querySelectorAll('.rbc-header') || [];
  const timeCells = document.querySelectorAll('.rbc-agenda-time-cell') || [];

  headerCells.forEach(cell => {
    if (cell.innerText.includes('Time')) {
      cell.style.display = 'none';
    }
  });

  timeCells.forEach(cell => {
    cell.style.display = 'none';
  });
};

function DeliveryCalendar({ updateProjectMultiCheckoutInfoPopupState }) {
  const classes = useStyles();

  const { userID } = useSelector(getUserSelector);

  const {
    data: allProjects = [],
    status: projectsLoadingStatus,
  } = useQuery(
    ['getAllProjectsByUser', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getAllProjectsByUser(userID);
    }
  );

  // itemsWithUnknownDates is not used at the moment, all items will be assumed to have a deliveryDate
  const { itemsWithDeliveryDates } = useMemo(() => {
    let i = 0;
    const itemsWithDeliveryDates = [];
    const itemsWithUnknownDates = [];

    for (const projectgroup of allProjects) {
      const { name, projectID, items } = projectgroup;
      const itemsWithLatestDeliveryDates = items
        .reduce((acc, item) => {
          if ([
            ITEM_STATUS_MAPPING.VERIFYING,
            ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS,
            ITEM_STATUS_MAPPING.ORDER_READY,
          ].includes(item.status)) {
            const latestDeliveryDate = getLatestDeliveryDateForItem(item);
            acc.push({
              itemID: item.itemID,
              deliveryDate: latestDeliveryDate?.slice(0, 10) || '',
            });
          }
          return acc;
        }, []);

      const itemsGroupedByDeliveryDate = groupByObjectProperty(
        itemsWithLatestDeliveryDates,
        'deliveryDate'
      );

      for (const itemGroup of Object.values(itemsGroupedByDeliveryDate)) {
        const deliveryDate = itemGroup[0].deliveryDate;
        if (isEmptyValue(deliveryDate)) {
          itemsWithUnknownDates.push({
            itemGroup,
            name,
            projectID,
          })
        } else {
          const date = new Date(deliveryDate);
          const displayName = name.includes(projectID) ? name : `Project ${projectID}`;
          const numberOfItems = itemGroup.length;
          itemsWithDeliveryDates.push({
            itemGroup,
            title: `${displayName} (${numberOfItems} item${numberOfItems > 1 ? 's' : ''})`,
            start: date,
            end: date,
            allDay: true,
            projectID,
            id: i++,
          });
        }
      }
    }

    return { itemsWithDeliveryDates, itemsWithUnknownDates };
  }, [allProjects]);

  // hide the Time cell in the header and corresponding time cells in the subsequent 
  // rows, for the agenda or the list view
  useEffect(() => {
    const observer = new MutationObserver(() => {
      hideTimeCells();
    });

    // Start observing the parent node where the calendar content is rendered
    const calendarContainer = document.querySelector('.rbc-calendar');
    if (calendarContainer) {
      observer.observe(calendarContainer, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSelectEvent = (event) => {
    const itemsFilterFn = (item) => {
      const latestDeliveryDate = getLatestDeliveryDateForItem(item);
      return latestDeliveryDate?.slice(0, 10) === event.start.toISOString().slice(0, 10);
    }
    updateProjectMultiCheckoutInfoPopupState({ projectID: event.projectID, itemsFilterFn, open: true });
  };

  if (projectsLoadingStatus === 'loading') {
    return <CircularProgress />;
  }

  if (projectsLoadingStatus === 'error') {
    return <FtrTypography>Error loading projects.</FtrTypography>;
  }

  return (
    <div className={classes.body}>
      <FtrH7>
        View Shipments
      </FtrH7>
      <div className={classes.calendar} elevation={3}>
        <Calendar
          localizer={localizer}
          events={itemsWithDeliveryDates}
          messages={{
            agenda: 'List View',
          }}
          onSelectEvent={handleSelectEvent}
          views={['month', 'agenda']}
          popup={true}
        />
      </div>
    </div>
  );
}

export default withProjectMultiCheckoutInfoPopupHOC(DeliveryCalendar);
