import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import BlueButton from '../buttons/BlueButton';
import Title from '../Title';
import { FlexColumn, FlexRowAlignStart } from '../layouts/FlexLayouts';

import { isEmptyValue } from '../../utils/commonUtils';
import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 600,
    maxWidth: 800,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function MultiCheckoutInfoPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => { },
    rowData = {},
  } = props;

  const renderBody = () => {
    const displayItems = !isEmptyValue(rowData.itemQuotationInfoList?.[0]?.itemID)
      ? rowData.itemQuotationInfoList
      : rowData.acceptedItems;

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        {displayItems.map((item) => {
          return (
            <FlexColumn key={item.itemID}>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Typography>
                  ItemID:&nbsp;
                  <Link to={{ pathname: `/item/edit/${item.itemID}` }}>
                    {item.itemID}
                  </Link>
                  &nbsp;-&nbsp;QuotationID:&nbsp;
                  <Link to={{ pathname: `/order/edit/${item.quotationID}` }}>
                    {item.quotationID}
                  </Link>
                </Typography>
              </div>
              {item.customerFinalRemarks && (
                <Typography style={{ textAlign: 'left', paddingLeft: '0.5rem' }}>
                  <FlexRowAlignStart>
                    <Typography>- Customer Final Remarks:</Typography>
                    <Typography style={{ color: colors.blue050, flex: 1, wordWrap: 'break-word' }}>{item.customerFinalRemarks}</Typography>
                  </FlexRowAlignStart>
                </Typography>
              )}
            </FlexColumn>
          );
        })}
      </FlexColumn>
    );
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Title contentTitle={`Checkout Info for Tracking ID ${rowData.id}`} size="small" />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent
            style={{
              padding: '0 30px',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            <React.Fragment>
              {renderBody()}
            </React.Fragment>
          </DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BlueButton
              onBtnClick={() => {
                handleClose();
              }}
              btnContent="OK"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default MultiCheckoutInfoPopup;
