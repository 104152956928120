import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory } from "react-router-dom";
import { cloneDeep } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  PlayArrow,
  PlayCircleOutline,
  Send,
  Stop,
} from '@material-ui/icons';

import AddRfqEmailSchedulesConfigPopup from '../../../components/popups/AddRfqEmailSchedulesConfigPopup';
import CircleButton from '../../../components/buttons/CircleButton';
import FtrChip from '../../../components/chips/FtrChip';
import InfoIcon from '../../../components/icons/InfoIcon';
import LoadingBackDropText from '../../../components/LoadingBackDropText';
import Row from '../../../components/Row';
import YesNoPopup from '../../../components/popups/YesNoPopup';
import MultiSelectChipDropdownWithCategory from '../../../components/dropdowns/MultiSelectChipDropdownWithCategory';
import EmailStatusIcon from '../../../components/icons/EmailStatusIcon';

import { usePrevious } from '../../../hooks/usePrevious';

import { getAllSuppliersGroupByTech } from '../../../apis/userApi';
import { getProjectInfoByID } from '../../../apis/projectApi';
import {
  deleteScheduleConfig,
  getRfqEmailSchedulesOfProject,
  sendRfqEmailByScheduleID,
  startScheduleConfig,
  updateRfqEmailSchedule,
} from '../../../apis/rfqEmailSchedulesApi';

import { useRfqEmailSchedulesConfigForm } from '../../../hooks/useRfqEmailSchedulesConfigForm';

import { hasItemAwarded } from '../../../utils/rfqEmailSchedulesUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import {
  dateTzSingapore,
  formatDate,
  isSameOrBefore,
} from '../../../utils/dateTimeUtils';

import { notifyError, notifySuccess } from '../../../services/notificationService';

import { ITEM_STATUS_MAPPING } from '../../../constants';
import {
  RFQ_EMAIL_SCHEDULE_CONFIG_STATUS,
  RFQ_EMAIL_SCHEDULE_STATUS,
  SCHEDULE_NOTE_TYPES,
} from '../../../constants/rfqEmailSchedulesConstants';

import { FACTOREM_BLUE, colors } from '../../../palette';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  body: {
    padding: '0 1rem',
  },
  tabRoot: {
    opacity: 1,
    '&:hover': {
      color: 'white',
      backgroundColor: FACTOREM_BLUE,
    },
  },
  selectedTab: {
    opacity: 1,
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: FACTOREM_BLUE,
    },
    backgroundColor: colors.secondaryBlue,
    color: FACTOREM_BLUE,
  },
}));

const VIEW = 'view';
const EDIT = 'edit';

function RfqEmailSchedules() {
  const classes = useStyles();

  const pathParams = useParams();
  const history = useHistory();

  const [viewableTabs, setViewableTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  // items are available for schedule when create/edit config
  const [availableItems, setAvailableItems] = useState([]);

  // items are cancelled or awarded
  const [unavailableItems, setUnAvailableItems] = useState([]);

  const [supplierList, setSupplierList] = useState([]);
  const [mode, setMode] = useState(VIEW);
  const [loading, setLoading] = useState(false);
  const [
    createRfqSchedulePopupState,
    updateCreateRfqSchedulePopupStateState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
    },
  );
  const [
    deleteConfigPopupState,
    updateDeleteConfigPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
    },
  );
  const [
    switchTabPopupState,
    updateSwitchTabPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
    },
  );
  const [
    startSchedulePopupState,
    updateStartSchedulePopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      title: '',
      scheduleID: null,
    },
  );
  const [
    {
      config,
      availableSuppliers,
      formErrorState,
    },
    {
      setConfig,
      updateSelectedItem,
      setAttachFiles,
      setRemarks,
      setSuppliers,
      setAllSuppliers,
      updateScheduleConfig,
      cancelEditScheduleConfig,
      resetFormError,
    },
  ] = useRfqEmailSchedulesConfigForm();

  const [showStartScheduleButton, setShowStartScheduleButton] = useState(false);
  const [editItemIDs, setEditItemIDs] = useState([]);

  const previousTabList = usePrevious(viewableTabs);

  const {
    data: schedulesData,
    status: dataLoadingStatus,
    refetch: refetchSchedulesData,
  } = useQuery(
    'getRfqEmailSchedulesOfProject',
    () => getRfqEmailSchedulesOfProject(pathParams.projectID),
  );
  const {
    data: projectInfo,
  } = useQuery(
    'getProjectInfoByID',
    () => getProjectInfoByID(pathParams.projectID),
  );
  const { data: allSuppliers } = useQuery(
    "allSuppliers",
    getAllSuppliersGroupByTech,
  );

  useEffect(() => {
    // set all suppliers used in form hook
    setAllSuppliers(allSuppliers);
  }, [allSuppliers]);

  useEffect(() => {
    if (!isEmptyValue(availableSuppliers)) {
      setSupplierList(availableSuppliers.map(category => {
        return {
          ...category,
          value: category.value.map((supplier) => ({
            key: `${supplier.userID}`,
            text: `${supplier.name} <${supplier.email}>`,
          })),
        };
      }));
    }
  }, [availableSuppliers]);

  /**
   * update available items, which are remain items not being scheduled
   * used in edit/create new schedule config
   */
  useEffect(() => {
    const _viewableTabs = schedulesData?.configs?.map(c => c.configID);
    setViewableTabs(_viewableTabs);
    if (isEmptyValue(projectInfo)) {
      return;
    }
    const allItemIDs = projectInfo.items.map(i => i.itemID);
    const unavailableItemIDs = projectInfo.items
      .filter(item => {
        return item.status === ITEM_STATUS_MAPPING.CANCELLED
          || hasItemAwarded(item);
      })
      .map(i => i.itemID);
    setUnAvailableItems(unavailableItemIDs);
    if (isEmptyValue(schedulesData?.configs)) {
      setAvailableItems(allItemIDs.filter(itemID => !unavailableItemIDs.includes(itemID)));
    } else {
      const scheduledItemIDs = schedulesData?.configs.flatMap(
        config => config.itemIDs
      );
      setAvailableItems(allItemIDs.filter(itemID =>
        !scheduledItemIDs.includes(itemID) && !unavailableItemIDs.includes(itemID))
      );
    }
  }, [schedulesData, projectInfo]);

  useEffect(() => {
    if (isEmptyValue(projectInfo)) {
      return;
    }
    const configItems = projectInfo.items.filter(item => config.itemIDs.includes(item.itemID));
    const showStartScheduleButton = config.status === RFQ_EMAIL_SCHEDULE_CONFIG_STATUS.NEW
      && configItems
        .filter(item => item.status !== ITEM_STATUS_MAPPING.CANCELLED)
        .every(item => !isEmptyValue(item.verifiedDate));
    setShowStartScheduleButton(showStartScheduleButton);
  }, [config, projectInfo]);

  useEffect(() => {
    if (isEmptyValue(schedulesData?.configs)) {
      return;
    }
    const config = schedulesData?.configs[selectedTab];
    if (config) {
      setConfig(config);
      setEditItemIDs([...config.itemIDs]);
    }
  }, [selectedTab, schedulesData]);

  useEffect(() => {
    if (previousTabList?.length > viewableTabs?.length) {
      setSelectedTab(0);
    } else if (previousTabList?.length < viewableTabs?.length && previousTabList?.length != 0) {
      setSelectedTab(viewableTabs.length - 1);
    }
  }, [viewableTabs]);

  const handleTabChange = (event, newValue) => {
    if (mode === EDIT) {
      updateSwitchTabPopupState({
        open: true,
        selectedTab: newValue,
      });
    } else {
      setSelectedTab(newValue);
      setMode(VIEW);
    }
  };

  const handleSaveScheduleConfig = () => {
    const body = cloneDeep(config);
    delete body.schedules;
    delete body.createdAt;
    updateScheduleConfig(body)
      .then(() => {
        notifySuccess(`Schedule has been saved successfully`);
        updateCreateRfqSchedulePopupStateState({ open: false });
        setMode(VIEW);
        refetchSchedulesData();
      })
      .catch((err) => {
        if (err.message !== 'Form error') {
          notifyError(`Error occurred. Saved schedule failed.`);
        }
      });
  }

  const renderScheduleConfigsTabs = () => {
    return (
      <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          {viewableTabs?.map((tab, index) => {
            return (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selectedTab,
                }}
                key={tab}
                label={`Schedule #${index + 1}`}
              />
            );
          })}
        </Tabs>
      </div>
    );
  }

  const renderStopButton = (schedule) => {
    return (
      <Tooltip
        title="Stop schedule for this slot"
      >
        <div>
          <CircleButton
            size='small'
            style={{
              backgroundColor: 'red',
            }}
            onClick={() => {
              const { scheduleID } = schedule;
              const body = {
                action: 'stop',
              }
              updateRfqEmailSchedule(scheduleID, body)
                .then(() => {
                  refetchSchedulesData();
                });
            }}
          >
            <Stop style={{ color: 'white' }} />
          </CircleButton>
        </div>
      </Tooltip>
    );
  }

  const renderSendNowButton = (schedule) => {
    return (
      <Tooltip
        title="Send email now"
      >
        <div>
          <CircleButton
            size='small'
            style={{
              backgroundColor: 'green',
            }}
            onClick={() => {
              const { scheduleID } = schedule;
              setLoading(true);
              sendRfqEmailByScheduleID(scheduleID)
                .then(() => {
                  setTimeout(() => {
                    notifySuccess(`Email has been sent successfully.`);
                    refetchSchedulesData()
                      .then(() => setLoading(false));
                  }, 2000);
                })
                .catch(() => {
                  notifyError(`Error occurred, email sent failed.`);
                });
            }}
          >
            <Send style={{ color: 'white' }} />
          </CircleButton>
        </div>
      </Tooltip>
    );
  }

  const renderResumeButton = (schedule) => {
    return (
      <Tooltip
        title="Resume schedule for this slot"
      >
        <div>
          <CircleButton
            size='small'
            style={{
              backgroundColor: 'green',
            }}
            onClick={() => {
              const { scheduleID, scheduledAt } = schedule;
              const body = {
                action: 'start',
              }
              if (isSameOrBefore(new Date(scheduledAt), new Date())) {
                updateStartSchedulePopupState({
                  open: true,
                  title: 'Schedule time is in the past, do you want to send email immediately?',
                  scheduleID,
                });
                return;
              }
              updateRfqEmailSchedule(schedule.scheduleID, body)
                .then(() => {
                  refetchSchedulesData();
                });
            }}
          >
            <PlayArrow style={{ color: 'white' }} />
          </CircleButton>
        </div>
      </Tooltip>
    );
  }

  const renderScheduleNotes = (schedule) => {
    const notes = schedule?.notes;
    if (isEmptyValue(notes)) {
      return;
    }
    return notes.map(note => {
      return (
        <Typography
          key={note.note}
          variant='caption'
          style={{
            fontStyle: 'italic',
            color: note.noteType === SCHEDULE_NOTE_TYPES.SUPPLIER_REMOVE_ITEM
              ? colors.orange
              : [
                SCHEDULE_NOTE_TYPES.AUTO_TRIGGER_NEXT_SCHEDULE,
                SCHEDULE_NOTE_TYPES.MANUAL_TRIGGERED,
              ].includes(note.noteType)
                ? colors.darkGreen
                : 'black',
          }}
        >
          &bull; {note.note} at {formatDate(note.createdAt, 'ddd, DD MMM YYYY HH:mm')}
        </Typography>
      );
    });
  }

  const renderEditSaveButtons = () => {
    const isNotEditable = config?.status === RFQ_EMAIL_SCHEDULE_CONFIG_STATUS.DONE
      || (!isEmptyValue(config?.schedules)
        && config.schedules.every(s => s.status && s.status == RFQ_EMAIL_SCHEDULE_STATUS.SUCCESS)
      );

    if (isNotEditable) {
      return;
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {mode === VIEW && config.status !== RFQ_EMAIL_SCHEDULE_CONFIG_STATUS.DONE && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => setMode(EDIT)}
          >
            Edit
          </Button>
        )}
        {mode === EDIT && config.status !== RFQ_EMAIL_SCHEDULE_CONFIG_STATUS.DONE && (
          <Row>
            <Button
              variant='contained'
              onClick={() => {
                cancelEditScheduleConfig();
                resetFormError();
                setMode(VIEW);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                handleSaveScheduleConfig();
              }}
            >
              Save
            </Button>
          </Row>
        )}
      </div>
    );
  }

  const renderScheduleConfigInfo = () => {
    if (isEmptyValue(schedulesData?.configs)) {
      return;
    }

    const {
      details,
      schedules,
      itemIDs,
    } = config;
    const {
      slots,
      remarks,
      attachFiles,
    } = details;

    return (
      <div
        style={{
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {mode === VIEW && (
            <Row>
              <Typography>
                Items:
              </Typography>
              {itemIDs.map(itemID => {
                const item = projectInfo?.items?.find(i => i.itemID === itemID);
                let type, tooltip, variant;
                if (item && hasItemAwarded(item)) {
                  type = 'success';
                  variant = 'contained';
                  tooltip = 'Item has been awarded';
                } else if (item && item.status === ITEM_STATUS_MAPPING.CANCELLED) {
                  type = 'disabled';
                  variant = 'contained';
                  tooltip = 'Item has been cancelled';
                }
                return (
                  <FtrChip
                    key={itemID}
                    label={itemID}
                    type={type}
                    variant={variant}
                    tooltip={tooltip}
                  />
                );
              })}
            </Row>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {mode === EDIT && (
              <Row>
                <Typography>
                  Items:
                </Typography>
                {[
                  ...editItemIDs,
                  ...availableItems
                ].map(item => {
                  return (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={config.itemIDs.includes(item)}
                          onChange={() => {
                            updateSelectedItem(item);
                            resetFormError();
                          }}
                          name={item}
                          disabled={unavailableItems.includes(item)}
                        />
                      }
                      label={item}
                    />
                  );
                })}
              </Row>
            )}
            {mode === EDIT && !isEmptyValue(formErrorState?.selectItems) && (
              <Typography
                variant='caption'
                style={{
                  fontStyle: 'italic',
                  color: 'red',
                }}
              >
                {formErrorState?.selectItems}
              </Typography>
            )}
          </div>
          {renderEditSaveButtons()}
        </div>
        <TextField
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="outlined"
          multiline
          minRows={3}
          margin="dense"
          id="remarks"
          label="Additional Remarks"
          placeholder=""
          type="text"
          fullWidth
          onChange={evt => setRemarks(evt.target.value)}
          value={remarks}
          InputLabelProps={{
            shrink: true
          }}
          disabled={mode === VIEW}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={attachFiles}
              onChange={() => setAttachFiles(!attachFiles)}
              name="attachFiles"
              disabled={mode === VIEW}
            />
          }
          label="Attach Design Files"
        />
        <Typography
          variant='h6'
        >
          Suppliers Config
        </Typography>
        <div
          style={{
            padding: '0 1rem',
            position: 'relative',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {slots.map((slot) => {
            const value = slot.suppliers.map(s => s.email).join(',');
            const schedule = schedules?.find(s => s.configID === config.configID
              && s.details.slotID === slot.slotID);
            return (
              <div
                key={`${schedule?.scheduleID}-slot-${slot.slotID}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Typography
                  style={{
                    marginTop: '1rem',
                    marginRight: '1rem',
                  }}>
                  #{slot.slotID + 1}
                </Typography>
                <div
                  style={{
                    maxWidth: 400,
                    width: '100%',
                  }}
                >
                  <MultiSelectChipDropdownWithCategory
                    id={`add-multi-select-suppliers-slot-${slot.slotID}`}
                    label="Select supplier"
                    itemList={supplierList}
                    value={[value]}
                    selectedText={value}
                    onSelect={(newValues) => {
                      setSuppliers(newValues, slot.slotID);
                    }}
                    searchable
                    multiple={false}
                    disabled={
                      mode === VIEW
                      || (schedule?.status
                        && [
                          RFQ_EMAIL_SCHEDULE_STATUS.SUCCESS,
                          RFQ_EMAIL_SCHEDULE_STATUS.CANCELLED
                        ].includes(schedule?.status)
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <Row>
                    {isEmptyValue(schedule?.status) && (
                      <Row>
                        <Typography>
                          Status:
                        </Typography>
                        <FtrChip
                          label="Not scheduled"
                          type="not_start"
                        />
                        {showStartScheduleButton && (
                          <InfoIcon toolTipText="Click Start Schedule button below to start jobs)" />
                        )}
                      </Row>
                    )}
                    {schedule && (
                      <Row>
                        <Row>
                          <Typography>
                            Scheduled At:
                          </Typography>
                          <FtrChip
                            label={formatDate(schedule.scheduledAt, 'ddd, DD MMM YYYY HH:mm')}
                          />
                        </Row>
                      </Row>
                    )}
                    {slot?.brevoRfqEmailScheduler && (
                      <Row>
                        <Row>
                          <Typography>Sent At:</Typography>
                          <FtrChip
                            label={dateTzSingapore(
                              slot.brevoRfqEmailScheduler.createdAt,
                              'ddd, DD MMM YYYY HH:mm'
                            )}
                          />
                        </Row>
                        <Row>
                          <Typography>Status:</Typography>
                          <EmailStatusIcon
                            status={slot.brevoRfqEmailScheduler.lastStatus}
                            emailType={slot.brevoRfqEmailScheduler.type}
                            date={slot.brevoRfqEmailScheduler.updatedAt}
                          />
                        </Row>
                      </Row>
                    )}
                    {
                      [
                        RFQ_EMAIL_SCHEDULE_STATUS.STOP,
                        RFQ_EMAIL_SCHEDULE_STATUS.FAILED,
                        RFQ_EMAIL_SCHEDULE_STATUS.SCHEDULED,
                      ].includes(schedule?.status) && (
                        <Divider orientation='vertical' style={{ height: 30 }} />
                      )
                    }
                    {mode === VIEW
                      && schedule
                      && schedule.status === RFQ_EMAIL_SCHEDULE_STATUS.SCHEDULED
                      && renderSendNowButton(schedule)
                    }
                    {mode === VIEW
                      && schedule
                      && schedule.status === RFQ_EMAIL_SCHEDULE_STATUS.SCHEDULED
                      && renderStopButton(schedule)
                    }
                    {
                      [
                        RFQ_EMAIL_SCHEDULE_STATUS.STOP,
                        RFQ_EMAIL_SCHEDULE_STATUS.FAILED,
                      ].includes(schedule?.status)
                      && renderResumeButton(schedule)
                    }
                  </Row>
                  {renderScheduleNotes(schedule)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <h1>RFQ Email Schedules (Project #{pathParams.projectID})</h1>
      <Row>
        {renderScheduleConfigsTabs()}
        {!isEmptyValue(availableItems) && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => updateCreateRfqSchedulePopupStateState({
              open: true,
            })}
          >
            Add Schedule
          </Button>
        )}
        {
          isEmptyValue(schedulesData?.configs)
          && isEmptyValue(availableItems) && (
            <Alert
              color="info"
              severity="info"
            >
              No item is available for schedule configuration.
            </Alert>
          )
        }
      </Row>
      <Container>
        {renderScheduleConfigInfo()}
        {mode === VIEW && viewableTabs?.length > 0 && (
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              bottom: 0,
              padding: '1rem 0',
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            <Button
              variant='contained'
              onClick={() => history.push(`/manage-projects/${pathParams.projectID}`)}
            >
              Back to Manage Project
            </Button>
            <Tooltip
              title={
                showStartScheduleButton
                  ? "First 3 suppliers RFQ Emails will be sent immediately"
                  : "Schedule can be started once all items in schedule are verified."
              }
            >
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setLoading(true);
                    startScheduleConfig(config.configID)
                      .then(() => {
                        setTimeout(() => {
                          refetchSchedulesData()
                            .then(() => setLoading(false));
                        }, 2000);
                      });
                  }}
                  disabled={!showStartScheduleButton}
                  startIcon={<PlayCircleOutline />}
                >
                  Start Schedule
                </Button>
              </div>
            </Tooltip>
            <Button
              variant='contained'
              onClick={() => {
                updateDeleteConfigPopupState({
                  open: true,
                });
              }}
              style={{
                backgroundColor: colors.hotRed,
                color: 'white',
              }}
            >
              Delete Schedule
            </Button>
          </div>
        )}
      </Container>
      {createRfqSchedulePopupState.open && (
        <AddRfqEmailSchedulesConfigPopup
          open={createRfqSchedulePopupState.open}
          handleClose={() => updateCreateRfqSchedulePopupStateState({
            open: false,
          })}
          availableItems={availableItems}
          callBack={() => {
            refetchSchedulesData();
          }}
        />
      )}
      {deleteConfigPopupState.open && (
        <YesNoPopup
          open={deleteConfigPopupState.open}
          title="Are you sure that you want to delete this config?"
          handleNo={() => updateDeleteConfigPopupState({
            open: false,
          })}
          handleYes={() => {
            deleteScheduleConfig(config.configID)
              .then(() => {
                refetchSchedulesData();
              });
            updateDeleteConfigPopupState({ open: false });
          }}
          showCloseButton={false}
        />
      )}
      {switchTabPopupState.open && (
        <YesNoPopup
          open={switchTabPopupState.open}
          title="Config has not been saved, unsaved data will be LOST, do you want to continue?"
          handleNo={() => updateSwitchTabPopupState({
            open: false,
          })}
          handleYes={() => {
            setSelectedTab(switchTabPopupState.selectedTab);
            setMode(VIEW);
            updateSwitchTabPopupState({ open: false });
          }}
          showCloseButton={false}
        />
      )}
      {startSchedulePopupState.open && (
        <YesNoPopup
          open={startSchedulePopupState.open}
          title={startSchedulePopupState.title}
          handleNo={() => updateStartSchedulePopupState({
            open: false,
          })}
          handleYes={() => {
            const body = {
              action: 'start',
            }
            updateRfqEmailSchedule(startSchedulePopupState.scheduleID, body)
              .then(() => {
                notifySuccess(`Email has been sent successfully.`);
                refetchSchedulesData();
              })
              .catch(() => {
                notifyError(`Error occurred, email sent failed.`);
              });
            updateStartSchedulePopupState({ open: false });
          }}
          showCloseButton={false}
        />
      )}
      {loading === true && (
        <LoadingBackDropText
          open={loading || dataLoadingStatus === 'loading'}
          text="Loading..."
        />
      )}
    </div>
  );
}

export default RfqEmailSchedules;
