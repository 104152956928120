import React, { useState } from 'react';

import { colors } from '../../palette';

// Import customised components
import WhiteButton from '../buttons/WhiteButton';
import BlueButton from '../buttons/BlueButton';

// Import material UI components
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles/index';
import { isEmpty } from 'lodash';
import { validateEmail } from '../../utils/validators/emailValidator';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const SELECT_OWNER_TAB = 'Select Owner';
const ADD_OWNER_TAB = 'Add Owner';

const TAB_LIST = [SELECT_OWNER_TAB, ADD_OWNER_TAB];

function TabPanel(props) {
  const { children, selectedTab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selectedTab === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function EditProjectOwnerPopup(props) {
  const classes = useStyles();

  const {
    dialog,
    handleClose,
    onOwnerChange,
    onDeleteItem,
    associatedOwners = [],
    currentOwnerEmail,
    creatorEmail,
  } = props;

  const [selectedTab, setSelectedTab] = useState(TAB_LIST.indexOf(SELECT_OWNER_TAB));
  const [selectedOwnerEmail, setSelectedOwnerEmail] = useState(currentOwnerEmail);
  const [addOwnerName, setAddOwnerName] = useState('');
  const [addOwnerNameError, setAddOwnerNameError] = useState(null);
  const [addOwnerEmail, setAddOwnerEmail] = useState('');
  const [addOwnerEmailError, setAddOwnerEmailError] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddButtonClick = () => {
    if (isEmpty(addOwnerName)) {
      setAddOwnerNameError('Name is required');
      return;
    }
    if (isEmpty(addOwnerEmail)) {
      setAddOwnerEmailError('Email is required');
      return;
    }
    if (!validateEmail(addOwnerEmail)) {
      setAddOwnerEmailError(`Email is not valid`);
      return;
    }
    if (associatedOwners.find(owner => owner.ownerEmail === addOwnerEmail)) {
      setAddOwnerEmailError(`Email has been used`);
      return;
    }
    onOwnerChange(addOwnerName, addOwnerEmail);
    setAddOwnerName('');
    setAddOwnerNameError(null);
    setAddOwnerEmail('');
    setAddOwnerEmailError(null);
    handleClose();
  }

  const handleSelectButtonClick = () => {
    const newOwner = associatedOwners.find(owner => owner.ownerEmail === selectedOwnerEmail);
    onOwnerChange(newOwner.ownerName, newOwner.ownerEmail);
    handleClose();
  }

  const renderOkCancelActions = (okButtonText, handleOkButtonClick) => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2rem',
        }}
      >
        <WhiteButton
          onBtnClick={handleClose}
          btnContent='Cancel'
          size='small'
        />
        <Box className={classes.space}></Box>
        <BlueButton
          onBtnClick={handleOkButtonClick}
          btnContent={okButtonText}
        />
      </div>
    );
  }

  const renderSelectOwnerTabContent = () => {
    return (
      <TabPanel selectedTab={selectedTab} index={TAB_LIST.indexOf(SELECT_OWNER_TAB)}>
        <List>
          {associatedOwners.map(owner => (
            <ListItem
              key={owner.ownerEmail}
              button
              selected={owner.ownerEmail === selectedOwnerEmail}
              onClick={() => setSelectedOwnerEmail(owner.ownerEmail)}
            >
              <ListItemText primary={`${owner.ownerName} <${owner.ownerEmail}>`} />
              {
                owner.ownerEmail !== creatorEmail
                && owner.ownerEmail !== currentOwnerEmail
                && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onDeleteItem(owner.ownerEmail)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
            </ListItem>
          ))}
        </List>
        {renderOkCancelActions('Select Owner', handleSelectButtonClick)}
      </TabPanel>
    );
  }

  const renderAddOwnerPanel = () => {
    return (
      <div>
        <TextField
          variant="outlined"
          margin="dense"
          id="addOwnerName"
          label="Name"
          placeholder=""
          type="text"
          fullWidth
          error={!!addOwnerNameError}
          helperText={addOwnerNameError}
          onFocus={() => setAddOwnerNameError(null)}
          onChange={evt => setAddOwnerName(evt.target.value)}
          value={addOwnerName}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="addOwnerEmail"
          label="Email"
          placeholder=""
          type="text"
          fullWidth
          error={!!addOwnerEmailError}
          helperText={addOwnerEmailError}
          onFocus={() => setAddOwnerEmailError(null)}
          onChange={evt => setAddOwnerEmail(evt.target.value)}
          value={addOwnerEmail}
          InputLabelProps={{
            shrink: true
          }}
        />
        {renderOkCancelActions('Add Owner', handleAddButtonClick)}
      </div>
    );
  }

  const renderAddOwnerTabContent = () => {
    return (
      <TabPanel selectedTab={selectedTab} index={TAB_LIST.indexOf(ADD_OWNER_TAB)}>
        {renderAddOwnerPanel()}
      </TabPanel>
    );
  }

  const renderPopupContent = () => {
    if (isEmpty(associatedOwners)) {
      return renderAddOwnerPanel();
    }
    return (
      <React.Fragment>
        <Tabs
          indicatorColor="primary"
          centered
          value={selectedTab}
          onChange={handleChangeTab}
        >
          {TAB_LIST.map((tabKey) => (
            <Tab
              key={tabKey}
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              label={tabKey}
            />
          ))}
        </Tabs>
        {renderSelectOwnerTabContent()}
        {renderAddOwnerTabContent()}
      </React.Fragment>
    );
  }

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          {renderPopupContent()}
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default EditProjectOwnerPopup;
