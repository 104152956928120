// Import settings
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";

// Style components
const useStyles = makeStyles(() => ({
  plainButton: {
    borderRadius: "5px",
    fontSize: "10pt",
    color: colors.solidBlue,
    whiteSpace: "nowrap",
    textAlign: "center",
    margin: "0 10px"
  }
}));

function OrderHistoryButton({ onClickBtn, type }) {
  const classes = useStyles();
  let buttonText = "";
  if (type === "order") {
    buttonText = "Order again";
  } else if (type === "details") {
    buttonText = "View details";
  }
  return (
    <Button className={classes.plainButton} onClick={onClickBtn}>
      {buttonText}
    </Button>
  );
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(null, matchDispatchToProps);

export default withRouter(withConnect(OrderHistoryButton));
