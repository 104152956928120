import { useState, useRef, useCallback } from 'react';
import * as fabric from 'fabric';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

import { isEmptyValue } from '../utils/commonUtils';
import { pdfFlatten } from '../apis/pdfApi';
import { notifySuccess, notifyError } from '../services/notificationService';

function useModifyPdfCanvas() {
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrPage] = useState(1);
  const [canvas, setCanvas] = useState(null);
  const [editedCanvas, setEditedCanvas] = useState({});
  const [processingPdf, setProcessingPdf] = useState({
    state: false,
    process: "Uploading"
  });

  const initCanvas = (node) => {
    const option = {
      isDrawingMode: false,
      backgroundColor: 'rgba(0,0,0,0)'
    }

    const newCanvas = new fabric.Canvas(node, option)
    newCanvas?.requestRenderAll();
    setCanvas(newCanvas);
  }

  // all the canvas and page refs
  const pdfCanvasRef = useRef(null);
  const printableRef = useRef(null);
  const canvasWrapperRef = useRef(null);
  const overlayCanvasRef = useCallback(node => {
    if (!isEmptyValue(node)) {
      initCanvas(node)
    }
  }, [open])

  // all the canvas modification fns
  const addRect = () => {
    if (isEmptyValue(canvas)) {
      console.error('Canvas not initialized!');
      return;
    }

    const rect = new fabric.Rect({
        height: 180,
        width: 200,
        fill: '#fff',
        stroke: '#fff',
        strokeWidth: 1,
        cornerStyle: 'circle',
        editable: true
    });
    canvas.add(rect);
    canvas.isDrawingMode = false
    canvas.requestRenderAll();
  }

  const deleteSelected = () => {
    const activeObject = canvas.getActiveObject();
    if (!isEmptyValue(activeObject)) {
      canvas.remove(activeObject);
    }
  }

  const clearCanvas = () => {
    canvas.clear()
  }

  const changeCanvas = (prevPage, nextPage) => {
    const newEditedCanvas = {
      ...editedCanvas,
      [prevPage]: canvas.toObject()
    }
    setEditedCanvas(newEditedCanvas)
    clearCanvas()
    canvas.loadFromJSON(newEditedCanvas[nextPage])
    canvas.requestRenderAll();
  }

  const setCanvasDimension = (pdf) => {
    // we need to manually adjust the width and height here because
    // there's a weird config where the pdf is displayed in portrait in the metadata
    const rotated = pdf.width < pdf.height
    const { width, height } = pdf
    const [w, h] = rotated ? [height, width] : [width, height]

    canvas.setDimensions({
      width: w,
      height: h
    })
  }

  const addImg = async (content) => {
    const titleBlockImg = await domtoimage.toPng(content);
    const canvasImage = await fabric.FabricImage.fromURL(titleBlockImg);
    canvasImage.scaleToWidth(canvas.getWidth() * 0.5);
    canvas.add(canvasImage).requestRenderAll();
  }

  const changePage = (newPage) => {
    changeCanvas(currentPage, newPage)
    setCurrPage(newPage)
  }

  // pdf processor handler

  const preparePdf = () => {
    const width = canvasWrapperRef.current.clientWidth;
    const height = canvasWrapperRef.current.clientHeight;

    const pdfOption = {
      orientation: 'landscape',
      unit: 'px',
      hotfixes: ['px_scaling'],
      format: [width, height]
    }
    const pdf = new jsPDF(pdfOption);
    return {
      pdf,
      pdfDimension: {
        width,
        height
      }
    }
  }

  const saveImagesToPdf = async (pdf, dimension) => {
    const { width, height } = dimension
    canvasWrapperRef.current.style.visibility = 'hidden'; // hide the wrapper from the dom so it's not copied
    const pdfImage = await domtoimage.toPng(printableRef.current);
    const canvasImage = canvas.toDataURL({
      enableRetinaScaling: true,
      format: "png",
      multiplier: 4, // Higher multiplier for better quality
    });

    pdf.addImage(pdfImage, "PNG", 0, 0, width, height);
    pdf.addImage(canvasImage, "PNG", 0, 0, width, height);

    // debugging pdf size: console.log(`PDF image size: ${pdfImage.length}, Canvas image size: ${canvasImage.length}`);
    return pdf
  }

  const processPdfPages = async (pdf, dimension) => {
    if (numPages > 1) {
      await changePage(1); // always return back to page 1 if pdf pages > 1
    }

    for (let i = 1; i <= numPages; i++) {
      if (i > 1) {
        await changePage(i);
      }

      // Wait for the page to render before capturing
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay if needed

      const updatedPdf = await saveImagesToPdf(pdf, dimension);

      if (i < numPages) {
        updatedPdf.addPage();
        updatedPdf.setPage(i + 1);
      }
    }
    return pdf;
  };

  const createPdf = async ({
    previewPdf = false,
    itemID,
    editCadFile,
    setEditCadFile = () => {},
    handleClose = () => {},
    revision
  } = {}) => {
    let stringOfLinks = [];

    if (editCadFile) {
      stringOfLinks = [...editCadFile]
    }

    setProcessingPdf({
      state: true,
      process: previewPdf ? "Previewing" : "Uploading"
    });
    const { pdf, pdfDimension } = preparePdf();

    try {
      const finalPdf = await processPdfPages(pdf, pdfDimension);
      if (previewPdf) {
        const blob = finalPdf.output("blob");
        window.open(URL.createObjectURL(blob), "_blank");
      } else {
        const blob = finalPdf.output("blob");
        const formData = new FormData();
        const file = new File([blob], { type: 'application/pdf' })
        formData.append('filename', `${itemID}${revision}.pdf`);
        formData.append('file', file);
        formData.append('flattenPdf', false);
        const { data } = await pdfFlatten(formData)
        stringOfLinks.push(data.split(" ").join("%20"));
      }
    } catch (error) {
      console.error("Error creating PDF:", error);
      notifyError('Modified file upload failed!')
    } finally {
      setEditCadFile(stringOfLinks);

      setProcessingPdf((prevState) => {
        return {
          process: prevState.process,
          state: false,
        }
      });
      canvasWrapperRef.current.style.visibility = 'visible';
      handleClose()

      if (editCadFile) {
        notifySuccess('Modified file is uploaded successfully! Please verify the part to complete the process.');
      }
    }
  }

  const onDocumentLoadSuccess = async (pdf) => {
    setNumPages(pdf.numPages);
  }

  return {
    canvas,
    setCanvas,
    editedCanvas,
    setEditedCanvas,
    addRect,
    deleteSelected,
    clearCanvas,
    changeCanvas,
    setCanvasDimension,
    addImg,
    numPages,
    currentPage,
    changePage,
    pdfCanvasRef,
    printableRef,
    overlayCanvasRef,
    canvasWrapperRef,
    processingPdf,
    createPdf,
    onDocumentLoadSuccess
  }
}

export default useModifyPdfCanvas;
