import React from 'react';
import { useSelector } from 'react-redux';
import {
  MenuItem,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { get } from 'lodash';

import BlueButton from '../../components/buttons/BlueButton';
import { FtrTypography } from '../../components/ftr-components';

import { isAdminOrHigherRole } from '../../utils/roleUtils';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { getUserSelector } from '../../selectors/userSelector';

import {
  ORDER_READY_RESOURCE_TYPES,
  ORDER_READY_STATUS,
  SHIPMENT_PROVIDERS,
} from '../../constants/orderReadyConstants';
import { FlexColumn } from '../../components/layouts/FlexLayouts';

const NextSteps = ({
  localState,
  handleOrderShipped,
  onUpdateShipmentInfo,
  onUpdateIsTrackingNumberNA,
}) => {
  const [{ isMobile }] = useDeviceCheck();

  const { role: userRole } = useSelector(getUserSelector);

  const itemOrderReadyList = localState.itemOrderReadyList || [];
  const readyToShipItems = itemOrderReadyList.filter(
    (itemOrderReady) =>
      itemOrderReady.info.status === ORDER_READY_STATUS.READY_TO_SHIP
  );

  const projectOrderReadyStatus = get(localState.projectOrderReadyInfo, [
    'info',
    'status',
  ]);

  if (
    readyToShipItems.length === 0 ||
    projectOrderReadyStatus !== ORDER_READY_STATUS.READY_TO_SHIP
  ) {
    return null;
  }

  const renderOrderShippedButton = () => {
    const deliveryDocs = (
      localState.projectOrderReadyInfo?.info?.resources || []
    ).filter((resource) =>
      [
        ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
        ORDER_READY_RESOURCE_TYPES.WAYBILL,
      ].includes(resource.type)
    );
    const buttonTooltip =
      deliveryDocs.length > 1
        ? 'Please download delivery documents above before shipping'
        : 'Please download delivery document above before shipping';

    return (
      <BlueButton
        size={isMobile ? 'small' : 'large'}
        onBtnClick={handleOrderShipped}
        btnContent='Order Shipped'
        disabled={
          (localState.noDeliveryDocRequired !== true &&
            localState.hasDownloadDeliverFiles !== true &&
            !isAdminOrHigherRole(userRole)) ||
          localState.orderShippedProcessing
        }
        tooltip={
          localState.noDeliveryDocRequired !== true &&
          localState.hasDownloadDeliverFiles !== true
            ? buttonTooltip
            : ''
        }
        loading={localState.orderShippedProcessing}
      />
    );
  };

  const renderShipmentInfoForm = () => {
    return (
      localState.isNonSgpSupplier && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            maxWidth: 800,
          }}
        >
          <Grid container spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  labelId='shipment-provider'
                  label='Shipment Provider'
                  id='shipment-provider'
                  variant='outlined'
                  select
                  onChange={onUpdateShipmentInfo('shipmentProvider')}
                  margin='dense'
                  fullWidth
                  error={!!localState.errors.shipmentProvider}
                  helperText={localState.errors.shipmentProvider}
                >
                  {SHIPMENT_PROVIDERS.map((shipment) => {
                    return (
                      <MenuItem key={shipment} value={shipment}>
                        {shipment}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              {localState.shipmentProvider === 'Other' && (
                <Grid item xs={12} md={6}>
                  <TextField
                    id='other-shipment-provider'
                    label='Other Shipment Provider'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='dense'
                    onChange={onUpdateShipmentInfo('otherShipmentProvider')}
                    error={!!localState.errors.otherShipmentProvider}
                    helperText={localState.errors.otherShipmentProvider}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='tracking-number-na'
                      onChange={onUpdateIsTrackingNumberNA}
                    />
                  }
                  label='Shipment number not available'
                />
              </Grid>
              {localState.shipmentTrackingNA !== true && (
                <Grid item xs={12}>
                  <TextField
                    id='shipment-tracking-number'
                    label='Shipment Tracking Number'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='dense'
                    onChange={onUpdateShipmentInfo('shipmentTrackingNumber')}
                    error={!!localState.errors.shipmentTrackingNumber}
                    helperText={localState.errors.shipmentTrackingNumber}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )
    );
  };

  return (
    <>
      <div style={{ marginTop: '25px', fontWeight: 'bold' }}>Next Steps</div>
      <FlexColumn>
        <FtrTypography>
          {localState.isNonSgpSupplier
            ? "Key in a shipment tracking number, along with shipment provider, and click 'Order Shipped' once the order has left your facility."
            : 'Please click this button once the order has left your facility.'}
        </FtrTypography>
        {renderShipmentInfoForm()}
        {renderOrderShippedButton()}
      </FlexColumn>
    </>
  );
};

export default NextSteps;
