import React, { useReducer, useMemo } from 'react';
import { useQuery } from 'react-query';

import NotePopup from '../components/popups/NotePopup';

import { getItemOrderReadyByItemID, updateProjectOrderReadyInfo } from '../apis/orderReadyApi';

import { notifySuccess } from '../services/notificationService';
import { isEmptyValue } from '../utils/commonUtils';
import { getPoNumber } from '../utils/quotationUtils';


// -------------------------------------------------------------------------------------------------

function withInternalNotePopupHOC(WrappedComponent) {
  return function InternalNotePopupHOC(props) {
    const defaultLocalState = {
      selectedData: null,
      open: false,
      refreshFn: () => {},
      label: 'Internal note'
    };
 
    const [localState, updateLocalState] = useReducer((prev, next) => {
      return { ...prev, ...next };
    }, defaultLocalState);

    const {
      data: itemOrderReady,
      isLoading
    } = useQuery(
      ['getItemOrderReadyByItemID', localState.selectedData?.itemID],
      () => getItemOrderReadyByItemID(localState.selectedData?.itemID),
      {
        enabled: Boolean(localState.selectedData?.itemID)
      }
    );

    const porInfo = useMemo(() => {
      const { selectedData } = localState;
      if (isEmptyValue(selectedData) || isLoading) {
        return null;
      }
      return { ...itemOrderReady, ...selectedData };
    }, [localState, itemOrderReady, isLoading]);
    
    const handleUpdateNote = (newNotes) => {
      updateProjectOrderReadyInfo(porInfo?.projectOrderReadyID, {
        notes: newNotes,
      }).then(() => {
        localState.refreshFn();
        updateLocalState(defaultLocalState);
        notifySuccess(`Note has been updated successfully.`);
      })
    }

    return (
      <>
        <WrappedComponent
          updateInternalNotePopupState={updateLocalState}
          internalNotePopupState={localState}
          {...props}
        />
        {localState.open && (
          <NotePopup
            title={`Note for PO ${getPoNumber(porInfo?.supplierID, porInfo?.projectID, porInfo?.poAcknowledgedVersion)}`}
            key={porInfo?.projectOrderReadyID}
            open={localState.open}
            value={porInfo?.notes}
            onClose={() => updateLocalState(defaultLocalState)}
            updateHandler={handleUpdateNote}
            label={localState.label}
          />
        )}
      </>
    );
  }
}

export default withInternalNotePopupHOC;
