import { isEmptyValue } from './commonUtils';

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};

export const openInSameTab = (url) => {
  window.location.href = url;
};

export const openCustomerOrdersProject = (projectID) => {
  const url = `/customer-orders/projects/${projectID}`;
  openInNewTab(url);
}

export const openCustomerSingleProject = (projectID) => {
  const url = `/manage-projects/${projectID}`;
  openInNewTab(url);
}

export const openAdminEditProject = (projectID) => {
  const url = `/manage-projects/${projectID}`;
  openInNewTab(url);
}

export const openCustomerSingleProjectVac = (projectID) => {
  const url = `/manage-projects/${projectID}/vac`;
  openInNewTab(url);
}

export const openAdminEditItem = (itemID) => {
  const url = `/item/edit/${itemID}`;
  openInNewTab(url);
}


export const getFilteredLocationSearch = (locationSearch, removeKeys) => {
  if (isEmptyValue(locationSearch)) {
    return '';
  }

  const obj = new URLSearchParams(locationSearch);

  if (isEmptyValue(removeKeys)) {
    // note that this has the leaing '?' stripped
    return obj.toString();
  }

  removeKeys.forEach((key) => {
    if (obj.has(key)) {
      obj.delete(key);
    }
  });

  return obj.toString();
};
