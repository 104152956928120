import React, { useState, useEffect, Fragment, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { uniqueId } from 'lodash';

import {
  makeStyles,
  ButtonBase,
  Checkbox,
  Box,
  InputBase,
  TableRow,
  TableCell,
  Tooltip,
  TextField,
  Typography,
} from '@material-ui/core';

import { colors } from '../../../../palette';

import StatusIcon from '../../assets/status_icon.svg';
import DeleteDrawingIcon from '../../../../assets/icons/delete_drawing.svg';
import AutoAwesomeIcon from '../../../../assets/icons/auto_awesome.svg';

import {
  THREE_D_P_FDM_TECH,
  DEFAULT_SETTINGS,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
} from '../../../../constants/NewPartConstants';

import {
  ppe3dpInfillOptions,
  ppe3dpLayerThicknessOptions,
  techMapping,
} from '../../../../constants/PPEConstants';
import { PDF_IMAGES_EXTENSIONS } from '../../../../constants/fileConstants';
import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../../../constants/configurations';
import { DFM_AUTOMATION_EXTENSIONS } from '../../../../constants/dfmConstants';

import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../../../utils/inputUtils';
import {
  getFileNameFromCadFile,
  is3DPTechnology,
} from '../../../../utils/itemUtils';
import { isIDSame, showLabelPartsLibraryInImage } from '../../../../utils/partsLibraryUtils';
import { isEmptyValue } from '../../../../utils/commonUtils';
import { filterFilesByExtension, findCadFiles } from '../../../../utils/fileUtils';
import { getDefaultTolerance } from '../../../../utils/toleranceUtils';
import { isSourceUrlAndTechValid, showDfmPartLibrary } from '../../../../utils/dfmExtractionUtils';

import { useItemInputConfig } from '../../../../hooks/useItemInputConfig';
import useDeviceCheck from '../../../../hooks/useDeviceCheck';

import MaterialCategoriesInputField from '../../../../components/inputs/MaterialCategoriesInputField';
import ThreeDPrintingMaterialField from '../../../../components/inputs/ThreeDPrintingMaterialField';
import FtrSvgImage from '../../../../components/images/FtrSvgImage';
import CustomInputField from '../inputs/CustomInputField';
import { renderSkeletonOverlay } from '../../../../components/util/skeleton';
import ThreeDInfillSelectField from '../../../../components/inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../../../../components/inputs/ThreeDLayerThicknessField';
import MenuList from '../../../../components/MenuList';
import DrawingDragDrop from '../../../../components/DrawingDragDrop';
import { FtrDropdown, FtrTypography } from '../../../../components/ftr-components';
import PartNameField from '../../../../components/fields/PartNameField';
import ColorFtrDropdown from '../../../../components/ftr-components/ColorFtrDropdown';
import { TdeWarning } from '../../../../components/forms/part-upload-step-two/DisplayItemsTable';
import {
  FlexColumn,
  FlexRow,
  FlexRowEnd,
  FlexRowSpaceBetween,
} from '../../../../components/layouts/FlexLayouts';
import ImageWith3DViewer from '../../../../components/images/ImageWith3DViewer';
import ErrorLabel from '../../../../components/labels/ErrorLabel';
import withLabelHoC from '../../../../components/images/withLabelHOC';

import {
  uploadFileToS3,
  getCadPartS3Key,
} from '../../../../services/s3Service';
import { notifyError } from '../../../../services/notificationService';

import { extractDfmDefectPartsLibrary } from '../../../../apis/partsLibraryApi';

import {
  updatePartsLibraryItemData,
  updatePartsLibrarySingleData,
  addTechnicalDrawingPartsLibrary,
} from '../../../../actions';

import ConfigurePartsLibraryContext from '../../../../context/ConfigurePartsLibraryContext';
import AppContext from '../../../../context/AppContext';
import DfmAnalysisButton from '../../../../components/DfmDefects/DfmAnalysisButton';

const useStyles = makeStyles(() => ({
  autoAwesomeIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  name: {
    display: 'flex',
  },
  addDrawingButton: {
    padding: 0,
    marginTop: 2,
    color: colors.blue060,
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '0.875rem',
  },
  fileName: {
    color: '#565656', // TODO: use palette
    fontWeight: 600,
    margin: 0,
    marginTop: '2rem',
  },
  fileExtension: {
    fontWeight: 400,
  },
  nameDetailsContainer: {
    minWidth: '200px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& .MuiInputBase-input': {
      color: colors.neutral080,
      fontWeight: 600,
      fontSize: 16
    }
  },
  refreshDrawingButton: {
    minWidth: 0,
    padding: 2,
    margin: 2,
    marginLeft: 4,
  },
  techDrawingNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  techDrawingFileName: {
    color: colors.neutral060,
    fontWeight: 600,
  },
  techDrawingFileExt: {
    color: '#808080',
  },
  inputField: {
    minHeight: 32,
    fontSize: 14,
    fontWeight: 600,
    color: colors.neutral080,
    backgroundColor: colors.fontWhite,
    padding: '4px 16px',
    borderRadius: 10,
    border: '0.8px solid #CCC',
  },
  quantityInput: {
    width: 85,
  },
  containerActionMenu: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
    borderRadius: 10,
  },
  itemMenu: {
    textDecoration: 'none',
    color: colors.fontGrey,
    width: '100%',
    fontSize: '0.9rem',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
    '&:is(:hover, :active)': {
      backgroundColor: colors.factoremBlue,
      color: `${colors.fontWhite} !important`,
    },
  },
  tableRow: {
    boxSizing: 'border-box',
    '& .MuiTableCell-root': {
      padding: '1rem 1.5rem'
    }
  },
  selectedRow: {
    backgroundColor: colors.blue010
  }
}));

const ImageWithLabel = withLabelHoC(ImageWith3DViewer);

function TechnicalDrawingFileDisplay(props) {
  const { fileStr, onDelete } = props;

  return (
    <Box
      style={{
        width: '137.6px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        padding: '0.5rem 0.7rem',
        justifyContent: 'space-between',
        backgroundColor: colors.neutral020,
        border: `0.5px solid ${colors.neutral030}`,
        minHeight: 24,
      }}
    >
      <FtrTypography
        type='subHeading'
        fontSize='12'
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: colors.neutral070,
        }}
      >
        {getFileNameFromCadFile(fileStr)}
      </FtrTypography>
      <img
        src={DeleteDrawingIcon}
        alt='delete'
        style={{ cursor: 'pointer' }}
        onClick={onDelete}
      />
    </Box>
  );
}

/**
 * Represents a row in the Configure Parts table.
 *
 * Maintains its own state for the item, and updates the parent's state
 * as the item changes.
 */
function ConfigurePartsLibraryItemRow(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [{ isTablet }] = useDeviceCheck();
  
  const { item, updateManageVersionPopup, dfmDefect, refetchDfmDefects } = props;
  const isRunningTDE = item?.tdeStatus === "loading";
  const isTdeError = item?.tdeStatus === "error";

  const { handleSavePartsLibrary } = useContext(ConfigurePartsLibraryContext);
  const { featureFlags } = useContext(AppContext);

  const project = useSelector((state) => state.partsLibrary?.data);
  const user = useSelector((state) => state.auth.user);
  
  const [dragging, setDragging] = useState(false);
  const [loadingDfmApi, setLoadingDfmApi] = useState(false);
  const [initialItem] = useState(item);

  const technicalDrawingFiles = useMemo(() => {
    return isEmptyValue(item.cadFile)
      ? []
      : filterFilesByExtension(item.cadFile?.split(','), PDF_IMAGES_EXTENSIONS);
  }, [item.cadFile])


  const handleItemChange = (params, itemInfo) => {
    const _item = itemInfo ?? item;
    if (isTdeError) {
      params.tdeStatus = null;
    }
    dispatch(
      updatePartsLibraryItemData(
        project.projectLibraryID,
        { itemLibraryID: _item.itemLibraryID, id: _item.id, version: _item.version },
        params
      )
    );
  };

  const removeTDEGenField = (fieldsToRemove) => {
    const generatedFields = new Set(item.generatedFields || []);
    fieldsToRemove.forEach(field => generatedFields.delete(field));
    handleItemChange({
      generatedFields: Array.from(generatedFields)
    }, item);
  }

  const renderTDEGenImage = (item, fieldNames) => {
    const generatedFieldsSet = new Set(item.generatedFields || []);
    if (fieldNames.some(fieldName => generatedFieldsSet.has(fieldName))) {
      return (
        <Tooltip title={"Automatically extracted parameter"} arrow>
          <div>
            <FtrSvgImage
              src={AutoAwesomeIcon}
              className={classes.autoAwesomeIcon}
            />
          </div>
        </Tooltip>

      );
    }
    return null;
  };

  const setThreeDInfill = (threeDInfill, itemInfo) =>
    handleItemChange({
      metadata: {
        ...item.metadata,
        threeDInfill,
      },
    }, itemInfo);
  const setThreeDLayerThickness = (threeDLayerThickness, itemInfo) =>
    handleItemChange({
      metadata: {
        ...item.metadata,
        threeDLayerThickness,
      },
    }, itemInfo);

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptionsForSupplier,
      loadMaterialColorOptions,
      loadSurfaceFinishOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setThreeDMaterialOptions,
      setMaterialColorOptions,
      setSurfaceFinishColorOptions,
    },
  ] = useItemInputConfig({
    setTechnology: (technology) => handleItemChange({ technology }),
    setMaterial: (material) => {
      handleItemChange({ material })
    },
    setThreeDTechnology: (threeDTechnology) => {
      let metadata = {
        threeDTechnology,
        threeDInfill: '',
        threeDLayerThickness: '',
      }
      if (threeDTechnology === THREE_D_P_FDM_TECH) {
        metadata = {
          threeDTechnology,
          threeDInfill: threeDPrintingInfillDefault,
          threeDLayerThickness: threeDPrintingLayerThicknessDefault,
        }
      }
      handleItemChange({ metadata });
    },
    setSurfaceFinish: (surfaceFinish) => {
      handleItemChange({ surfaceFinish })
    },
    setMaterialColor: (materialColor) => handleItemChange({ materialColor, customMaterialColor: null }),
    setColor: (color) => handleItemChange({ color, customColor: null }),
  });

  useEffect(() => {
    // Update custom materialColor to key customMaterialColor and custom color to key customColor
    // If there is no matching color
    let timerID = setTimeout(() => {
      const isCustomMaterialColor = !isEmptyValue(materialColorOptions) && !Object.keys(materialColorOptions).includes(item.materialColor);
      const isCustomColor = !isEmptyValue(surfaceFinishColorOptions) && !Object.keys(surfaceFinishColorOptions).includes(item.color);

      if (isCustomMaterialColor || isCustomColor) {
        handleItemChange({
          ...(isCustomMaterialColor && {
            materialColor: "Custom Color",
            customMaterialColor: item.materialColor
          }),
          ...(isCustomColor && {
            color: "Custom Color",
            customColor: item.color
          })
        })
      }
    }, 500)
    return () => {
      clearTimeout(timerID);
    }
  }, [
    materialCategoryOptions,
    threeDTechnologyOptions,
    threeDMaterialOptions,
    surfaceFinishOptions,
    materialColorOptions,
    surfaceFinishColorOptions
  ])

  useEffect(() => {
    loadTechnologyOptions(false);

    if (isEmptyValue(item.technology)) {
      return;
    }

    loadSelectColorSurfaces({ technology: item.technology });
    loadMaterialCategoryOptions({ technology: item.technology }, false);
    if (is3DPTechnology(item.technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology: item.technology,
          threeDTechnology: item.metadata?.threeDTechnology,
        },
        false
      ).catch(() => {
        setThreeDMaterialOptions(['Custom Material']);
      });
    }

    const params = {
      technology: item.technology,
      threeDTechnology: item.metadata?.threeDTechnology,
      material: item.material,
    }
    loadSurfaceFinishOptionsForSupplier(params, false);
    if (item.materialColor) {
      loadMaterialColorOptions(params, false);
    }
  }, [item.technology]);

  useEffect(() => {
    if (isEmptyValue(item.materialColor)) {
      setMaterialColorOptions(null);
      return;
    }

    if (!isEmptyValue(materialCategoryOptions)) {
      return;
    }

    const params = {
      technology: item.technology,
      threeDTechnology: item.metadata?.threeDTechnology,
      material: item.material,
    }
    loadMaterialColorOptions(params, false);
  }, [item.materialColor]);

  useEffect(() => {
    if (isEmptyValue(item.color)) {
      setSurfaceFinishColorOptions([]);
      return;
    }

    loadSurfaceFinishColorOptions({
      technology: item.technology,
      surfaceFinish: item.surfaceFinish,
    }, false);
  }, [item.color]);

  const handleTechDrawingChange = (techDrawingFile, itemInfo) => {
    uploadFileToS3(techDrawingFile, getCadPartS3Key(techDrawingFile))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        const newCadFile = itemInfo.cadFile
          ? itemInfo.cadFile.split(',').concat(s3ObjectUrl).join(',')
          : s3ObjectUrl;
        handleItemChange({ cadFile: newCadFile }, itemInfo);
        handleItemChange({ tdeStatus: "loading" }, itemInfo);
        dispatch(addTechnicalDrawingPartsLibrary(project, item, s3ObjectUrl)).then((response) => {
          if (!response) {
            return;
          }
          if (isEmptyValue(item?.technology) && response?.tdeStatus === "success") {
            handleItemChange({
              ...DEFAULT_SETTINGS,
              tolerance: getDefaultTolerance(
                {
                  technology: DEFAULT_SETTINGS.technology,
                  unitType: item.unitType ?? user.unitType
                }),
            }, itemInfo)
          }
          const params = {
            technology: item.technology,
            threeDTechnology: item.threeDTechnology,
            material: response.material,
            surfaceFinish: response.surfaceFinish || item.surfaceFinish,
          }
          loadMaterialColorOptions(params, false);
          loadSurfaceFinishOptions(params, false);
          loadSurfaceFinishColorOptions(params, false);
          handleItemChange(response, itemInfo);
        }).catch(() => {
          handleItemChange({ tdeStatus: "error" }, itemInfo)
        })
      }).catch(() => {
        notifyError(`Couldn't upload technical drawing file.`);
      });
  }

  const handleDuplicateItem = () => {
    const newItem = {
      isNewlyItem: true,
      id: uniqueId('d-') + '-' + item.itemLibraryID,
      itemLibraryID: null,
      version: 1,
      inUse: true,
    };
    dispatch(
      updatePartsLibrarySingleData(project.projectLibraryID, {
        items: [...project.items, { ...item, ...newItem }],
      })
    );
  };

  const handleSelectVersion = (itemVersion) => {
    const newItems = project?.items?.map((i) => {
      return {
        ...i,
        inUse: isIDSame(item, i) ? i.version === itemVersion : i.inUse,
      };
    });
    dispatch(
      updatePartsLibrarySingleData(project.projectLibraryID, {
        items: newItems,
      })
    );
  };

  const resetMaterialAndFinishTDEFields = () => {
    const generatedFields = new Set(item.generatedFields || []);
    const updatedGeneratedFields = new Set([
      generatedFields.has('unitType') ? 'unitType' : undefined,
      generatedFields.has('qty') ? 'qty' : undefined,
    ].filter(field => field !== undefined))
    handleItemChange({ generatedFields: Array.from(updatedGeneratedFields) })
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDeleteTechnicalDrawing = (fileStr, itemInfo) => {
    const newCadFile = itemInfo.cadFile?.split(',').filter(url => url !== fileStr).join(',');
    handleItemChange({ cadFile: newCadFile }, itemInfo)
  }

  const handleGetDfmAnalysis = () => {
    const technology = item.technology
    const body = {
      version: item.version,
      process: techMapping[technology] || technology,
      sourceUrl: findCadFiles(item.cadFile?.split(','), DFM_AUTOMATION_EXTENSIONS)
    }
    setLoadingDfmApi(true);
    extractDfmDefectPartsLibrary(item.itemLibraryID, body)
      .then(() => {
        // auto save if the technology has changed
        if (initialItem.technology !== item.technology) {
          handleSavePartsLibrary();
        }
        refetchDfmDefects();
      })
      .catch(() => {
        notifyError(`Couldn't get DFM analysis.`);
      })
      .finally(() => {
        setLoadingDfmApi(false);
      })
  }

  const renderQuantityInput = () => {
    return (
      <FlexRow>
        <div style={{ position: 'relative' }}>
          {isRunningTDE && renderSkeletonOverlay()}
          <InputBase
            id='quantity'
            type='number'
            className={`${classes.inputField} ${classes.quantityInput}`}
            inputProps={{
              step: 1,
              min: 0,
              type: 'number',
            }}
            value={item.quantity}
            onChange={(evt) => {
              handleItemChange({
                quantity: evt.target.valueAsNumber,
              }, item);
              removeTDEGenField(['qty'])
            }}
          />
        </div>
        {renderTDEGenImage(item, ['qty'])}
      </FlexRow>
    );
  };

  const renderVersionList = () => {
    const listVersion = project.items.filter((i) => isIDSame(i, item)).sort((a, b) => a.version - b.version);
    return (
      <MenuList parent={<div className={classes.itemMenu}>Revert</div>}>
        <div className={classes.containerActionMenu}>
          {listVersion.map((item, index) => (
            <div
              key={index}
              className={classes.itemMenu}
              onClick={() => handleSelectVersion(item.version)}
            >
              Version {item.version}
            </div>
          ))}
        </div>
      </MenuList>
    );
  };

  const renderActionButton = () => {
    return (
      <MenuList
        parent={
          <ButtonBase onClick={() => { }} disableRipple={true}>
            <img src={StatusIcon} alt='' />
          </ButtonBase>
        }
      >
        <div className={classes.containerActionMenu}>
          <div className={classes.itemMenu} onClick={handleDuplicateItem}>
            Duplicate
          </div>
          <div className={classes.itemMenu} onClick={() => updateManageVersionPopup({ open: true, selectedData: item })}>
            View Versions
          </div>
          <div className={classes.itemMenu} style={{ padding: '0' }}>{renderVersionList()}</div>
        </div>

      </MenuList>
    );
  };

  const renderMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) && isCustomMaterial(item.material))
      || (isEmptyValue(materialColorOptions)) || (isEmptyValue(item.materialColor))
    ) {
      return null;
    }
    return (
      <FlexRow>
        <ColorFtrDropdown
          key='material-color-dropdown'
          fullWidth
          value={item.materialColor ?? null}
          handleChange={(materialColor) => {
            handleItemChange({ materialColor, customMaterialColor: null });
            removeTDEGenField(['materialColor', 'customMaterialColor']);
          }}
          colorPalette={materialColorOptions || []}
          loading={isRunningTDE}
        />
        {renderTDEGenImage(item, ['materialColor'])}
      </FlexRow>
    )
  }

  const renderCustomMaterialColorField = () => {
    if ((isEmptyValue(materialCategoryOptions)
      && isCustomMaterial(item.material))
      || (isEmptyValue(materialColorOptions))
      || item.materialColor !== 'Custom Color'
    ) {
      return null;
    }

    return (
      <FlexRow>
        <div style={{ position: 'relative' }}>
          {isRunningTDE && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder="e.g. Yellow orange (RAL 2000)"
            value={item.customMaterialColor ?? null}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%', backgroundColor: colors.fontWhite }}
            onChange={(evt) => {
              const materialColor = evt.target.value;
              handleItemChange({
                customMaterialColor: materialColor,
              });
              removeTDEGenField(['customMaterialColor'])
            }}
            error={(item.materialColor === 'Custom Color') && isEmptyValue(item.customMaterialColor)}
            helperText={(item.materialColor === 'Custom Color') && isEmptyValue(item.customMaterialColor) ? "Required" : ""}
          />
          <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
            Refer <a href="https://www.ralcolor.com/" target="_blank" rel="noopener noreferrer">https://www.ralcolor.com/</a> for more colors.
          </Typography>
        </div>
        {renderTDEGenImage(item, ['customMaterialColor'])}
      </FlexRow>
    );
  }

  const renderSurfaceFinishField = () => {
    if (
      isEmptyValue(item.surfaceFinish)
      || isEmptyValue(surfaceFinishOptions)
    ) {
      return null;
    }

    return (
      <FlexRow>
        <FtrDropdown
          id='surface-finish-dropdown'
          key='surface-finish-dropdown'
          fullWidth
          value={item.surfaceFinish ?? null}
          handleChange={(newValue) => {
            removeTDEGenField(['surfaceFinish', 'otherSurfaceFinish', 'color', 'customColor'])
            handleItemChange({
              surfaceFinish: newValue,
              otherSurfaceFinish: null,
            });
            const params = {
              technology: item.technology,
              material: item.material,
              surfaceFinish: newValue,
            }
            surfaceFinishHasChanged(params);
          }}
          items={surfaceFinishOptions}
          menuStyle={{ maxHeight: '250px' }}
          loading={isRunningTDE}
        />
        {renderTDEGenImage(item, ['surfaceFinish'])}
      </FlexRow>
    )
  }

  const renderFinishColorField = () => {
    if (
      (isEmptyValue(surfaceFinishOptions) || isCustomSurfaceFinish(item.surfaceFinish)) ||
      (isEmptyValue(surfaceFinishColorOptions) || isEmptyValue(item.color))
    ) {
      return null;
    }

    return (
      <FlexRow>
        <ColorFtrDropdown
          key='surface-finish-color-dropdown'
          fullWidth
          value={item.color}
          handleChange={(newValue) => {
            handleItemChange({ color: newValue, customColor: null })
            removeTDEGenField(['color', 'customColor'])
          }}
          colorPalette={surfaceFinishColorOptions}
          loading={isRunningTDE}
        />
        {renderTDEGenImage(item, ['color'])}
      </FlexRow>
    )
  }

  const renderCustomFinishColorField = () => {
    if ((isEmptyValue(surfaceFinishOptions)
      && !isCustomSurfaceFinish(item.surfaceFinish)) ||
      (isEmptyValue(surfaceFinishColorOptions)) ||
      (item.color !== 'Custom Color')
      && isEmptyValue(item.customColor)
    ) {
      return;
    }

    return (
      <FlexRow>
        <div style={{ position: 'relative' }}>
          {isRunningTDE && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder="e.g. Yellow orange (RAL 2000)"
            value={item.customColor}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              const color = evt.target.value;
              removeTDEGenField(['customColor'])
              handleItemChange({
                customColor: color,
              });
            }}
            error={item.color === 'Custom Color' && isEmptyValue(item.customColor)}
            helperText={item.color === 'Custom Color' && isEmptyValue(item.customColor) ? "Required" : ""}
          />
          <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
            Refer <a href="https://www.ralcolor.com/" target="_blank" rel="noopener noreferrer">https://www.ralcolor.com/</a> for more colors.
          </Typography>
        </div>
        {renderTDEGenImage(item, ['customColor'])}
      </FlexRow>
    );
  }

  const renderTechnologyField = () => {
    return (
      <FlexColumn style={{ gap: 5 }}>
        <FtrDropdown
          key='technology-dropdown'
          fullWidth
          value={item.technology}
          handleChange={(newTech) => {
            const technology = newTech;
            const tolerance = getDefaultTolerance({ technology, unitType: item.unitType ?? user.unitType });
            let updatedFormState = {
              technology,
              tolerance,
              otherTechnology: null,
              otherMaterial: null,
              otherSurfaceFinish: null,
              isTechnologyError: false,
            }
            if (is3DPTechnology(technology)) {
              updatedFormState = {
                ...updatedFormState,
                metadata: {
                  threeDTechnology: THREE_D_P_FDM_TECH,
                  threeDInfill: threeDPrintingInfillDefault,
                  threeDLayerThickness: threeDPrintingLayerThicknessDefault,
                }
              }
            } else {
              updatedFormState = {
                ...updatedFormState,
                metadata: {
                  threeDTechnology: null,
                  threeDInfill: null,
                  threeDLayerThickness: null,
                },
              }
            }
            handleItemChange(updatedFormState)
            technologyHasChanged(technology);
            resetMaterialAndFinishTDEFields();
          }}
          items={technologyOptions}
          placeholder='Technology'
          id='technology-dropdown'
          menuStyle={{ maxHeight: '300px' }}
          loading={isRunningTDE}
          error={item.isTechnologyError}
        />
        {item.isTechnologyError && (
          <ErrorLabel message='Please configure fabrication technology'/>
        )}
      </FlexColumn>
    )
  }


  const renderGetDfmAnalysis = () => {
    const featureFlag = featureFlags?.config?.[FE_FEATURE_FLAGS_CONFIGURATION.DFM_AUTOMATION_BUYER];
    if (!featureFlag || !showDfmPartLibrary(user.dfmType)) {
      return;
    }

    const isAbleToGetDfmAnalysis = isSourceUrlAndTechValid(
      item.cadFile?.split(','),
      item.technology
    );

    if (!isEmptyValue(dfmDefect) || loadingDfmApi) {
      return (
        <DfmAnalysisButton
          onClick={() => updateManageVersionPopup({ open: true, selectedData: item })}
          showIcon
          style={{ paddingLeft: '5px' }}
          dfmDefectOutput={dfmDefect?.output?.defects ?? dfmDefect?.output}
          isLoading={loadingDfmApi || dfmDefect?.output === 'loading'}
        />
      )
    }

    let tooltipGetDfmAnalysisText = '';
    if (!isAbleToGetDfmAnalysis) {
      tooltipGetDfmAnalysisText = 'Unable to get DFM Analysis'
    }
    if (isEmptyValue(item.itemLibraryID)) {
      tooltipGetDfmAnalysisText = 'Please save the item first'
    }

    return (
      <Tooltip title={tooltipGetDfmAnalysisText} arrow>
        <div>
          <DfmAnalysisButton
            onClick={handleGetDfmAnalysis}
            style={{
              paddingLeft: '5px',
              ...(tooltipGetDfmAnalysisText && { color: colors.neutral040 }),
            }}
            disabled={tooltipGetDfmAnalysisText}
          />
        </div>
      </Tooltip>
    );
  }

  const renderPartNameInfo = () => {
    return (
      <Box
        className={classes.nameDetailsContainer}
        style={
          isTablet
            ? null
            : {
                marginLeft: '2rem',
                paddingRight: '1rem',
              }
        }
      >
        <PartNameField
          partName={item.name}
          updatePartName={(name) => handleItemChange({ name }, item)}
        />
        {renderGetDfmAnalysis()}
      </Box>
    );
  }
  const renderFields = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: isTablet ? 'auto' : '330px',
        }}
      >
        {isTablet && <FtrTypography style={{ fontWeight: 600 }}>Configuration</FtrTypography>}
        {isRunningTDE && (
          <FtrTypography
            type='body'
            fontSize='14'
            style={{ color: colors.neutral060, paddingBottom: '0.5rem' }}
          >
            Extracting information from your technical drawing
          </FtrTypography>
        )}
        {isTdeError && <TdeWarning />}
        {renderTechnologyField()}
        {is3DPTechnology(item.technology) && (
          <FtrDropdown
            key='technology-3d-printing-dropdown'
            fullWidth
            value={item.metadata?.threeDTechnology ?? null}
            handleChange={(newValue) => {
              const threeDTechnology = newValue;
              const tolerance = getDefaultTolerance({
                technology: item.technology,
                unitType: item.unitType ?? user.unitType,
                threeDTechnology,
              });
              let updatedFormState = { tolerance };
              if (newValue === THREE_D_P_FDM_TECH) {
                updatedFormState = {
                  ...updatedFormState,
                  metadata: {
                    threeDTechnology,
                    threeDInfill: threeDPrintingInfillDefault,
                    threeDLayerThickness: threeDPrintingLayerThicknessDefault,
                  }
                }
              } else {
                updatedFormState = {
                  ...updatedFormState,
                  metadata: {
                    threeDTechnology,
                    threeDInfill: '',
                    threeDLayerThickness: '',
                  }
                }
              }
              handleItemChange(updatedFormState)
              const params = {
                technology: item.technology,
                threeDTechnology: newValue,
              }
              threeDTechnologyHasChanged(params);
            }}
            items={threeDTechnologyOptions}
            menuStyle={{ maxHeight: '300px' }}
            loading={isRunningTDE}
          />
        )}
        {isCustomTechnology(item.technology) && (
          <div>
            {isRunningTDE && renderSkeletonOverlay()}
            <TextField
              size='small'
              type='text'
              placeholder='Custom Technology'
              margin='none'
              value={item.otherTechnology}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => handleItemChange({ otherTechnology: evt.target.value })}
              error={isCustomTechnology(item.technology)
                && isEmptyValue(item.technology)
              }
              helperText={isCustomTechnology(item.technology)
                && isEmptyValue(item.technology)
                ? "Required"
                : ""
              }
            />
          </div>
        )}
        <div style={{ display: "inline-block" }}>
          <FlexRow>
            <ThreeDPrintingMaterialField
              technology={item.technology}
              threeDTechnology={item.metadata?.threeDTechnology}
              visible={is3DPTechnology(item.technology)}
              value={item.material ?? null}
              onSelect={(_material) => {
                handleItemChange({
                  material: _material,
                  otherMaterial: null,
                })

                // reset the material and surface finish

                const params = {
                  technology: item.technology,
                  threeDTechnology: item.metadata?.threeDTechnology,
                  material: _material,
                }
                materialHasChanged(params);
                resetMaterialAndFinishTDEFields();
              }}
              threeDMaterialOptions={threeDMaterialOptions}
              defaultThreeDMaterial={defaultThreeDMaterial}
              materialCategoryOptions={materialCategoryOptions}
              disableLabelOnBootstrapStyle={true}
              v2={true}
              loading={isRunningTDE}
            />
            {is3DPTechnology(item.technology) && renderTDEGenImage(item, ['material'])}
          </FlexRow>
        </div>

        {item.technology && !is3DPTechnology(item.technology) && (
          <FlexRow>
            <MaterialCategoriesInputField
              technology={item.technology}
              visible={!is3DPTechnology(item.technology)}
              value={item.material ?? null}
              onSelect={(_material) => {
                handleItemChange({
                  material: _material,
                  otherMaterial: null,
                })
                const params = {
                  technology: item.technology,
                  threeDTechnology: item.metadata?.threeDTechnology,
                  material: _material,
                }
                materialHasChanged(params);
                resetMaterialAndFinishTDEFields();
              }}
              materialCategoryOptions={materialCategoryOptions}
              isBuyer
              datasheetPosition='bottom'
              withLabel={false}
              treeStyle={{ maxHeight: '300px' }}
              loading={isRunningTDE}
            />
            {!is3DPTechnology(item.technology) && renderTDEGenImage(item, ['material'])}
          </FlexRow>
        )}


        <FlexRow>
          <CustomInputField
            visible={isCustomMaterial(item.material)}
            value={item.customMaterial}
            onChange={(customMaterial) => handleItemChange({ customMaterial }, item)}
            placeholder='Custom Material'
            loading={isRunningTDE}

          />
          {isCustomMaterial(item.material) && renderTDEGenImage(item, ['otherMaterial'])}
        </FlexRow>

        {renderMaterialColorField()}
        {renderCustomMaterialColorField()}
        <ThreeDInfillSelectField
          visible={
            is3DPTechnology(item.technology) &&
            item.metadata?.threeDTechnology === THREE_D_P_FDM_TECH
          }
          value={item.metadata?.threeDInfill ?? null}
          onSelect={(newValue) => setThreeDInfill(newValue, item)}
          ppe3dpInfillOptions={ppe3dpInfillOptions}
          disableLabelOnBootstrapStyle={true}
          v2={true}
          loading={isRunningTDE}
        />
        <ThreeDLayerThicknessField
          visible={
            is3DPTechnology(item.technology) &&
            item.metadata?.threeDTechnology === THREE_D_P_FDM_TECH
          }
          value={item.metadata?.threeDLayerThickness ?? null}
          onSelect={(newValue) => setThreeDLayerThickness(newValue, item)}
          ppe3dpLayerThicknessOptions={ppe3dpLayerThicknessOptions}
          disableLabelOnBootstrapStyle={true}
          v2={true}
          loading={isRunningTDE}
        />
        {renderSurfaceFinishField()}

        <FlexRow>
          <CustomInputField
            visible={isCustomSurfaceFinish(item.surfaceFinish)}
            value={item.customSurfaceFinish ?? null}
            onChange={(customSurfaceFinish) =>
              handleItemChange({ customSurfaceFinish }, item)
            }
            placeholder='Custom Finish'
            loading={isRunningTDE}
          />
          {isCustomSurfaceFinish(item.surfaceFinish) && renderTDEGenImage(item, ['otherSurfaceFinish'])}
        </FlexRow>
        {renderFinishColorField()}
        {renderCustomFinishColorField()}
      </Box>
    )
  }

  if (isTablet) {
    return (
      <TableRow
        className={clsx(classes.tableRow, item.checked && classes.selectedRow)}
        onDragOver={handleDragOver}
      >
        <TableCell>
          <Box
            className={classes.name}
            id={`ID-${item.itemLibraryID ?? item.id}`}
          >
            <FlexRowSpaceBetween style={{ width: '100%' }}>
              <FlexRow>
                <Checkbox
                  style={{
                    alignSelf: 'self-start',
                    marginLeft: '-14px',
                    padding: 0,
                  }}
                  checked={item.checked ?? null}
                  onChange={(e) =>
                    handleItemChange({ checked: e.target.checked }, item)
                  }
                />
                <ImageWithLabel
                  cadFile={item.cadFile}
                  twoDImageUrl={item.twoDImageUrl}
                  isLoading={item.is2DImageLoading}
                  version={item.version}
                  width={100}
                  height={100}
                  borderRadius={8}
                  label={showLabelPartsLibraryInImage(item.status)}
                />
                <FlexColumn>
                  {renderPartNameInfo()}
                  {technicalDrawingFiles?.map((fileStr, index) => {
                    return (
                      <Fragment key={fileStr}>
                        <TechnicalDrawingFileDisplay
                          item={item}
                          index={index}
                          fileStr={fileStr}
                          onDelete={() => handleDeleteTechnicalDrawing(fileStr, item)}
                        />
                        <Box height='6px' />
                      </Fragment>
                    );
                  })}
                  {isEmptyValue(technicalDrawingFiles) && (
                    <DrawingDragDrop
                      style={{ marginLeft: '5px' }}
                      id={item.itemLibraryID ?? item.id}
                      handleFiles={(files) => {
                        handleTechDrawingChange(files[0], item);
                      }}
                      onlyText
                      dragging={dragging}
                      setDragging={setDragging}
                    />
                  )}
                </FlexColumn>
              </FlexRow>
              <FlexRowEnd style={{ alignSelf: 'start' }}>
                {renderActionButton()}
              </FlexRowEnd>
            </FlexRowSpaceBetween>
          </Box>
          <div style={{ marginTop: '1.5rem' }}>
            {renderFields()}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <FtrTypography style={{ fontWeight: 600, marginBottom: '0.5rem' }}>Qty</FtrTypography>
            {renderQuantityInput()}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow className={clsx(item.checked && classes.selectedRow)} style={{ position: 'relative' }} onDragOver={handleDragOver}>
      <TableCell padding='checkbox'>
        <Checkbox
          checked={item.checked ?? null}
          onChange={(e) => handleItemChange({ checked: e.target.checked }, item)}
        />
      </TableCell>
      <TableCell>
        <Box className={classes.name} id={`ID-${item.itemLibraryID ?? item.id}`}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.5rem',
            }}
          >
            <ImageWithLabel
              cadFile={item.cadFile}
              twoDImageUrl={item.twoDImageUrl}
              isLoading={item.is2DImageLoading}
              version={item.version}
              label={showLabelPartsLibraryInImage(item.status)}
            />
            {technicalDrawingFiles?.map((fileStr, index) => {
              return (
                <Fragment key={fileStr}>
                  <TechnicalDrawingFileDisplay
                    item={item}
                    index={index}
                    fileStr={fileStr}
                    onDelete={() => handleDeleteTechnicalDrawing(fileStr, item)}
                  />
                  <Box height='6px' />
                </Fragment>
              );
            })}
            {isEmptyValue(technicalDrawingFiles) && (
              <DrawingDragDrop
                id={item.itemLibraryID ?? item.id}
                handleFiles={(files) => {
                  handleTechDrawingChange(files[0], item);
                }}
                dragging={dragging}
                setDragging={setDragging}
              />
            )}
          </Box>
          {renderPartNameInfo()}
        </Box>
      </TableCell>
      <TableCell>
        {renderFields()}
      </TableCell>
      <TableCell>{renderQuantityInput()}</TableCell>
      <TableCell>{renderActionButton()}</TableCell>
    </TableRow>
  );
}

export default ConfigurePartsLibraryItemRow;
