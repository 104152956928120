import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import digitalSupplyChain from '../../assets/img/slideshow-digital-supply-chain.png';
import orderInClicks from '../../assets/img/slideshow-order-in-clicks.png';
import onTimeDelivery from '../../assets/img/slideshow-on-time-delivery.png';
import noMOQ from '../../assets/img/slideshow-no-moq.png';
import customParts from '../../assets/img/slideshow-custom-parts.png';
import powerYourProduction from '../../assets/img/slideshow-power-your-production.png';
import accessSEA from '../../assets/img/slideshow-access-sea.png';

const fadeImages = [
  digitalSupplyChain,
  orderInClicks,
  onTimeDelivery,
  noMOQ,
  customParts,
  powerYourProduction,
  accessSEA,
];

function Slideshow() {
  return (
    <Fade arrows={false} pauseOnHover={false} duration={2500}>
      {fadeImages.map((fadeImage, index) => (
        <div key={index}>
          <img style={{ width: '100%', zIndex: 2 }} src={fadeImage} alt={`${fadeImage}`} />
        </div>
      ))}
    </Fade> 
  );
}

export default Slideshow;