import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader, witchCacheResponse, fetchWithErrorHandling } from '../utils/apiUtils';

export const getDfmExtractDefects = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/extract-defects`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getDfmExtractDefectsWithCache = witchCacheResponse(
  getDfmExtractDefects,
  10 * 60 * 60 // 10 hours in seconds
);


export const getDfmExtractDimensions= async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/admin/extract-dimensions`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const dfmExtractDimensionsApi= async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/extract-dimensions`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};


export const getDefectFeedbackByID = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/defects-feedback?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
}

export const searchDfmTrackingApi = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/tracking/search?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  })
}

export const exportCsvDfmTrackingApi = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/tracking/search/csv-export?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
}

export const upsertDefectsFeedback = async (request) => {
  const { dfmTrackingID, userID, itemID, feedback } = request;
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/defects-feedback`;
  return fetch(requestUrl, {
    method: "POST",
    headers: getAuthorizedHeader(),
    body: JSON.stringify({
      dfmTrackingID,
      userID,
      itemID,
      feedback
    })
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return await response.json();
  });
}

export const getAllDefectsFeedback = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dfm/defects-feedback`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
}
