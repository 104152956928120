import React from 'react';

import { trackActionApi } from '../apis/actionTrackingApi';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withActionTrackingHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const {
      actionTrackingKey = '',
      additionalInfo,
      trackingInfo = {},
      onClick = () => { },
    } = props;

    return (
      <>
        <WrappedComponent
          {...props}
          onClick={(...args) => {
            const shouldTrackAction = !isEmptyValue(actionTrackingKey);
            if (shouldTrackAction) {
              trackActionApi({
                actionTrackingKey,
                additionalInfo,
                ...trackingInfo,
              });
            }

            onClick(...args);
          }}
        />
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withActionTrackingHOC;
