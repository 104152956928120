import { isEmptyValue } from './commonUtils';
import { findCadFiles } from './fileUtils';

import {
  DFM_AUTOMATION_EXTENSIONS,
  DFM_TYPES,
  EXTRACT_DEFECT_KEYS_MAPPING,
  EXTRACT_DEFECT_RULES_MAPPING,
} from '../constants/dfmConstants';
import { techMapping } from '../constants/PPEConstants';

/**
 *
 * @param {Object} defectOutput
 */
export const defectsStatus = (defectOutput) => {
  const results = [];
  if (isEmptyValue(defectOutput)) {
    return results;
  }
  Object.entries(defectOutput)
    .filter(([key]) => ![EXTRACT_DEFECT_KEYS_MAPPING.dfm_success, 'trackingID'].includes(key))
    .map(([key, value]) => {
      let ruleValue = EXTRACT_DEFECT_RULES_MAPPING[key];
      if (
        EXTRACT_DEFECT_RULES_MAPPING[key]?.tech === '3DP' &&
        key === EXTRACT_DEFECT_KEYS_MAPPING.part_sizes_too_big
      ) {
        ruleValue = EXTRACT_DEFECT_RULES_MAPPING[key].value;
      }
      const result = {
        key,
        value,
        ruleValue,
      };
      if (value !== ruleValue && !isEmptyValue(ruleValue)) {
        results.push({ ...result, defect: true });
      } else {
        results.push({ ...result, defect: false });
      }
    });
  if (!isEmptyValue(defectOutput?.dfm_success)) {
    results.push({
      value: defectOutput?.dfm_success,
      ruleValue: EXTRACT_DEFECT_RULES_MAPPING.dfm_success,
      key: 'dfm_success',
    })
  }
  return results;
};

/**
 * Checks if the given sourceUrls and tech are valid for dfm extraction.
 *
 * @param {String[]} sourceUrls - The urls of the cad files.
 * @param {String} tech - The technology to use for dfm extraction.
 *
 * @returns {Boolean} true if the sourceUrls and tech are valid, false otherwise.
 */
export const isSourceUrlAndTechValid = (sourceUrls, tech) => {
  if (isEmptyValue(sourceUrls) || isEmptyValue(tech)) {
    return false;
  }
  if (!techMapping[tech]) {
    return false;
  }
  return Boolean(findCadFiles(sourceUrls, DFM_AUTOMATION_EXTENSIONS));
}

export const showDfmPartLibrary = (dfmType) => {
  return [DFM_TYPES.HAS_DFM, DFM_TYPES.HAS_PARTS_LIBRARY_DFM_ONLY].includes(dfmType)
}

export const showDfmPueMpr = (dfmType) => {
  return [DFM_TYPES.HAS_DFM, DFM_TYPES.HAS_PUE_MPR_DFM_ONLY].includes(dfmType)
}