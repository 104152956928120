import { convert2DImageWitchCache } from '../apis/convertImageApi';

import { IMG_PLACE_HOLDER_URL } from '../constants';
import { isEmptyValue } from '../utils/commonUtils';

export const convert2DImage = async (parameters, options = {}) => {
  const { timeout = 30000 } = options;
  const body = {
    parameters,
    timeout,
  }
  return convert2DImageWitchCache(body);
};

/**
 * Convert a 3D model to a 2D image.
 * If the conversion fails, return a placeholder image.
 * @param {Object} parameters - The parameters to convert a 3D model to a 2D image.
 * @param {Object} [options] - The options to the function.
 * @param {number} [options.timeout] - The timeout in milliseconds. Default is 30000.
 * @returns {Promise<string>} The URL of the converted 2D image.
 */
export const convert2DImageWithPlaceholder = async (
  parameters,
  options = {}
) => {
  const { timeout = 30000 } = options;
  const body = {
    parameters,
    timeout,
  };
  const respConvert2DImage = await convert2DImageWitchCache(body)
    .then((result) => {
      if (!isEmptyValue(result.error)) {
        return {
          s3_file_url: IMG_PLACE_HOLDER_URL,
        };
      }
      return result;
    })
    .catch(() => ({
      s3_file_url: IMG_PLACE_HOLDER_URL,
    }));
  return respConvert2DImage['s3_file_url'];
};
