import React from 'react';

function ColoredWordsSentence(props) {
  const { sentence, highlightWords, color } = props;
  let words = sentence.split(" ");
  words = words.map((word, index) => highlightWords.includes(word) ? <span key={index} style={{ color: color, fontWeight: 'bold' }}>{word}</span> :  <span key={index}>{word}</span>)

  return (
    <div>
      {words.map((word, index) => (
        <span key={index}>{word} </span>
      ))}
    </div>
  );
}

export default ColoredWordsSentence